// ---------------------------------------------- modules import
import { ISubsetToken } from "../models/subset_token";

export const newUpdateSubsetTokenDto = (subsetToken: ISubsetToken) => {
  const { name, status } = subsetToken;

  return {
    data: {
      name,
      status,
    },
  };
};
