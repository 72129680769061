// ---------------------------------------------- modules import
import { ITag } from "../../../../models/tag";

// ---------------------------------------------- the hooks
export const useTagListItem = (tag: ITag, onChangeTag: (tag: ITag) => void) => {
  // ---------------------------------------------- handlers
  const handleClick = () => onChangeTag(tag);

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
