// ---------------------------------------------- modules import
import { IProvince, emptyProvince } from "./province";
import { IRegency, emptyRegency } from "./regency";

// ---------------------------------------------- document interfaces
/**
 * Represents the data fields of a participant group document in Firestore.
 *
 * Subcollections of each document:
 * - Modules
 */
export interface IParticipantGroupRecord {
  dateCreated: string;
  description: string;
  lastModified: string;
  location: {
    province: IProvince;
    regency: IRegency;
  };
  name: string;
}

/**
 * Represents a participant group returned to the frontend from Firestore.
 *
 * Extends the IParticipantGroupRecord interface.
 */
export interface IParticipantGroup extends IParticipantGroupRecord {
  id: string;
  [key: string]: any;
}

// ---------------------------------------------- empty document
export const emptyParticipantGroupRecord = (): IParticipantGroupRecord => ({
  dateCreated: "",
  description: "",
  lastModified: "",
  location: {
    province: emptyProvince(),
    regency: emptyRegency(),
  },
  name: "",
});

export const emptyParticipantGroup = (): IParticipantGroup => ({
  ...emptyParticipantGroupRecord(),
  id: "",
});
