// ---------------------------------------------- modules import
import { faFaceDizzy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

// ---------------------------------------------- the component
const NotFound: FunctionComponent = () => (
  <div className="bg-slate-600 h-screen justify-center items-center flex">
    <div className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 ">
      <FontAwesomeIcon
        className="text-gray-800 text-4xl md:text-6xl mb-4"
        icon={faFaceDizzy}
      />

      <h1 className="mb-4 text-4xl font-bold tracking-tight leading-none lg:mb-6 md:text-5xl xl:text-6xl text-yellow-400">
        404
      </h1>

      <p className="font-light md:text-lg xl:text-xl text-gray-200">
        Sorry, we can't find that page. You'll find lots to explore on the home
        page.
      </p>
    </div>
  </div>
);

export default NotFound;
