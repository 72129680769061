// ---------------------------------------------- modules import
import {
  faFileCode,
  faFileCsv,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";

// ---------------------------------------------- the hooks
export const useSubmissionOptions = () => {
  // ---------------------------------------------- router state
  const navigate = useNavigate();

  const options = [
    {
      name: "JSON",
      icon: faFileCode,
      route: ROUTES.SUBMISSION + "/json",
      implemented: true,
    },
    {
      name: "Excel",
      icon: faFileExcel,
      route: ROUTES.SUBMISSION + "/excel",
      implemented: false,
    },
    {
      name: "CSV",
      icon: faFileCsv,
      route: ROUTES.SUBMISSION + "/csv",
      implemented: false,
    },
  ];

  // ---------------------------------------------- handlers
  const handleClick = (route: string) => {
    navigate(route);
  };

  // ---------------------------------------------- return value
  return {
    options,
    onClick: handleClick,
  };
};
