// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgKepribadian from "../../../../assets/images/bg-kepribadian.png";

import { IResultPagePersonalityProps } from "./common";
import { IConclusion_Personality } from "../conclusion/personality/conclusion";
import { colorTheme } from "../../../../constants/color_theme";
import { IAttribute } from "../../../../models/attribute";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  section: {
    marginBottom: 8,
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
    width: "100%",
  },
  logo: {
    justifyContent: "center",
    width: 100,
  },
  headingPage: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
    marginTop: 50,
  },
  heading: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 18,
    marginBottom: 8,
  },
  attribute: {
    flexDirection: "row",
    fontSize: 14,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  attributeName: {
    fontSize: 14,
  },
  dominantAttribute: {
    marginTop: 20,
  },
  dominantPersonality: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  dominantName: {
    color: colorTheme.YELLOW_LIGHT,
    fontFamily: "Helvetica-Bold",
    fontSize: 24,
    marginRight: 4,
  },
  personality: {
    color: colorTheme.PURPLE_LIGHT,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  description: {
    fontSize: 9,
    marginBottom: 4,
  },
  traits: {
    fontSize: 9,
    marginBottom: 4,
  },
  growth: {
    fontSize: 9,
    marginBottom: 4,
  },
  bulletPoint: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 4,
  },
  bulletText: {
    fontSize: 12,
    marginRight: 4,
  },
  text: {
    marginBottom: 4,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    color: colorTheme.YELLOW_LIGHT,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
  },
  attributeValue: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    textAlign: "right",
  },
  credit: {
    bottom: 30,
    color: colorTheme.GRAY_DOFF,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  barContainer: {
    flexDirection: "row",
    marginBottom: 10,
    alignItems: "center",
    position: "relative",
    height: 20,
    width: "80%",
    backgroundColor: "#FFF",
    borderRadius: 5, // Menambahkan borderRadius untuk membuat ujung membulat
  },
  label: {
    width: 100,
    textAlign: "right",
    marginRight: 10,
    fontSize: 9,
  },
  labelRight: {
    width: 100,
    textAlign: "left",
    marginLeft: 10,
    fontSize: 9,
  },
  barFillLeft: {
    height: "100%",
    position: "absolute",
    left: 0,
    borderLeftRadius: 5,
  },
  barFillRight: {
    height: "100%",
    position: "absolute",
    right: 0,
    borderRightRadius: 5,
  },
  percentageText: {
    position: "absolute",
    fontSize: 10,
    width: "100%",
    textAlign: "center",
    marginTop: 5,
    fontWeight: "bold",
    color: "white",
  },
  svgContainer: {
    width: "100%",
    height: 40,
    marginBottom: 10,
  },
  percentageTextAbove: {
    fontSize: 10,
    fontWeight: "bold",
    fill: "black",
  },
});

// ---------------------------------------------- the component
const ResultPagePersonality: FunctionComponent<IResultPagePersonalityProps> = ({
  questionnaire,
}) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(result.conclusion) as IConclusion_Personality;

  const reduced = result.attributes.reduce(
    (reduced, attribute) => ({
      ...reduced,
      [attribute.id]: attribute,
    }),
    {} as { [key: string]: IAttribute }
  );

  const sortedBars = [
    {
      labelLeft: "Introvert",
      labelRight: "Ekstrovert",
      value: Math.round(reduced["I"].value * 100),
      colorLeft: "#00BFFF",
      colorRight: "#800080",
    },
    {
      labelLeft: "Sensing",
      labelRight: "iNtuition",
      value: Math.round(reduced["S"].value * 100),
      colorLeft: "#00BFFF",
      colorRight: "#800080",
    },
    {
      labelLeft: "Thinking",
      labelRight: "Feeling",
      value: Math.round(reduced["T"].value * 100),
      colorLeft: "#00BFFF",
      colorRight: "#800080",
    },
    {
      labelLeft: "Judging",
      labelRight: "Perceiving",
      value: Math.round(reduced["J"].value * 100),
      colorLeft: "#00BFFF",
      colorRight: "#800080",
    },
  ];

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.headingPage}></Text>
        {sortedBars.map((item, index) => (
          <View
            key={index}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Text style={styles.label}>{item.labelLeft}</Text>
            <View style={styles.svgContainer}>
              <svg width="100%" height="100%">
                <text
                  x="25%"
                  y="10"
                  textAnchor="middle"
                  style={styles.percentageTextAbove}
                >
                  {`${item.value}%`}
                </text>
                <text
                  x="75%"
                  y="10"
                  textAnchor="middle"
                  style={styles.percentageTextAbove}
                >
                  {`${100 - item.value}%`}
                </text>
                <rect
                  x={`${50 - item.value / 2}%`}
                  y="15"
                  width={`${item.value / 2}%`}
                  height="20"
                  fill={item.colorLeft}
                />
                <rect
                  x="50%"
                  y="15"
                  width={`${(100 - item.value) / 2}%`}
                  height="20"
                  fill={item.colorRight}
                />
                <text
                  x="25%"
                  y="30"
                  textAnchor="middle"
                  style={styles.percentageText}
                >
                  {`${item.value}%`}
                </text>
                <text
                  x="75%"
                  y="30"
                  textAnchor="middle"
                  style={styles.percentageText}
                >
                  {`${100 - item.value}%`}
                </text>
              </svg>
            </View>
            <Text style={styles.labelRight}>{item.labelRight}</Text>
          </View>
        ))}

        {/* <View style={styles.attributeTable}>
          {attributes.map((attribute) => (
            <View key={attribute.id} style={styles.attributeRow}>
              <Text style={styles.attributeDescription}>
                {attribute.description}
              </Text>
              <Text style={styles.attributeValue}>
                {(attribute.value * 100).toFixed(0)}
              </Text>
            </View>
          ))}
        </View> */}
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Tipe Kepribadian</Text>
        <View style={styles.dominantPersonality}>
          <Text style={styles.dominantName}>{conclusion.name}</Text>
          <Text
            style={styles.personality}
          >{`/ ${conclusion.personality}`}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.description}>
          {conclusion.descriptions.map((description, index) => (
            <Text key={index} style={styles.description}>
              {description}
            </Text>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Karakter Yang Kuat</Text>
        <View style={styles.traits}>
          {conclusion.traits.map((trait, index) => (
            <View key={index} style={styles.bulletPoint}>
              <Text style={styles.bulletText}>•</Text>
              <Text style={styles.text}>{trait}</Text>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Butuh Pengembangan</Text>
        <View style={styles.growth}>
          {conclusion.suggestedGrowth.map((growth, index) => (
            <View key={index} style={styles.bulletPoint}>
              <Text style={styles.bulletText}>•</Text>
              <Text style={styles.text}>{growth}</Text>
            </View>
          ))}
        </View>
      </View>
      <Image source={BgKepribadian} style={styles.backgroundImage} fixed />
    </Page>
  );
};
export default ResultPagePersonality;
