// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IAssessmentContextProviderState,
  IAssessmentContextProviderProps,
} from "./common";
import { useAssessmentContext } from "./hooks";

// ---------------------------------------------- create context
const initialState: IAssessmentContextProviderState = {
  assessments: [],
  error: null,
  fetching: true,
};

export const AssessmentContext = createContext(initialState);

// ---------------------------------------------- the component
const AssessmentContextProvider: FunctionComponent<
  IAssessmentContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const { assessments, error, fetching } = useAssessmentContext();

  // ---------------------------------------------- content
  return (
    <AssessmentContext.Provider value={{ assessments, error, fetching }}>
      {children}
    </AssessmentContext.Provider>
  );
};

export default AssessmentContextProvider;
