// ---------------------------------------------- modules import
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import AssessmentRow from "./assessment_row";
import SearchForm from "./search_form";

import { IAssessmentTableProps } from "./common";
import { useAssessmentTable } from "./hooks/use_assessment_table";

// ---------------------------------------------- the component
const AssessmentTable: FunctionComponent<IAssessmentTableProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    assessments,
    criteria,
    error,
    fetching,
    filtered,
    sortBy,
    onChangeAssessment,
    onFilter,
    onSort,
  } = useAssessmentTable();

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col h-screen p-8 md:m-8">
      <div className="flex items-center justify-between mb-4">
        <div className="py-4 px-6">
          <h1 className="md:text-3xl text-xl font-bold text-gray-100">
            {"assessment".toUpperCase()}
          </h1>
        </div>
      </div>

      <div>
        <SearchForm
          criteria={criteria}
          error={error}
          fetching={fetching}
          result={{
            filtered: filtered.length,
            total: assessments.length,
          }}
          onFilter={onFilter}
        />
      </div>

      <section className="shadow-lg overflow-x-auto max-h-screen">
        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <div className="shadow-lg overflow-x-auto h-[calc(100vh-256px)]">
            <table className="w-full text-sm text-left text-gray-400">
              <thead className="text-xs uppercase bg-gray-500 text-gray-800 sticky top-0 z-10">
                <tr>
                  {[
                    {
                      prop: "name",
                      label: "Name",
                      className: "px-6 py-3",
                    },
                    {
                      prop: "lastModified",
                      label: "Last modified",
                      className: "px-6 py-3",
                    },
                  ].map((header, index) => {
                    const { prop, label, className } = header;

                    return (
                      <th
                        className={className}
                        key={index}
                        style={{ cursor: prop ? "pointer" : "default" }}
                        onClick={() => {
                          if (!prop) return;

                          onSort(prop);
                        }}
                      >
                        <div className="flex items-center">
                          {label}

                          {sortBy.prop === header.prop && (
                            <FontAwesomeIcon
                              icon={sortBy.asc > 0 ? faSortDown : faSortUp}
                              className="ml-1"
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className="overflow-x-auto max-h-96">
                {!fetching
                  ? filtered.map((assessment) => (
                      <AssessmentRow
                        key={assessment.id}
                        assessment={assessment}
                        onChangeAssessment={onChangeAssessment}
                      />
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssessmentTable;
