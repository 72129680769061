// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IAttributeContextProviderState,
  IAttributeContextProviderProps,
} from "./common";
import { useAttributeContext } from "./hooks";
import { emptyAssessment } from "../../models/assessment";

// ---------------------------------------------- create context
const initialState: IAttributeContextProviderState = {
  assessment: emptyAssessment(),
  attributes: [],
  error: null,
  fetching: true,
  onChangeAssessment: () => {
    return;
  },
};

export const AttributeContext = createContext(initialState);

// ---------------------------------------------- the component
const AttributeContextProvider: FunctionComponent<
  IAttributeContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const { assessment, attributes, error, fetching, onChangeAssessment } =
    useAttributeContext();

  // ---------------------------------------------- content
  return (
    <AttributeContext.Provider
      value={{ assessment, attributes, error, fetching, onChangeAssessment }}
    >
      {children}
    </AttributeContext.Provider>
  );
};

export default AttributeContextProvider;
