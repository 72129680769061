// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import validateProvince from "./province";
import validateRegency from "./regency";
import { IParticipantGroup } from "../models/participant_group";

export const pattern: IPattern = {
  description: {
    minLength: 2,
    maxLength: 100,
    regex: /^[\w\s&,.():\-'"/]{2,100}$/,
  },
  name: {
    minLength: 2,
    maxLength: 50,
    regex: /^[\w\s&,.():\-'"/]{2,50}$/,
  },
};

const validateParticipantGroup = (
  participantGroup: IParticipantGroup,
  propsToValidate = ["name"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { description, location } = participantGroup;
  const { province, regency } = location;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["description"] = description
    ? pattern.description.regex.test(description)
    : true;
  extraConditions["province"] = validateProvince(province);
  extraConditions["regency"] = validateRegency(regency);

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    participantGroup,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateParticipantGroup;
