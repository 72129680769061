// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IStatusBadgeProps } from "./common";

// ---------------------------------------------- the component
const StatusBadge: FunctionComponent<IStatusBadgeProps> = ({
  error,
  fetching,
  loading,
}) =>
  fetching ? (
    <div className=" absolute flex items-center justify-center w-auto h-auto p-3 border rounded-xl bg-gray-800 border-gray-700">
      <span className="px-3 py-1 text-xl font-medium leading-none text-center rounded-full animate-pulse bg-blue-900 text-blue-200">
        Now Fetching
      </span>
    </div>
  ) : loading ? (
    <div className=" absolute flex items-center justify-center w-auto h-auto p-3 border rounded-xl bg-gray-800 border-gray-700">
      <span className="px-3 py-1 text-xl font-medium leading-none text-center rounded-full animate-pulse bg-blue-900 text-blue-200">
        Now Loading
      </span>
    </div>
  ) : error ? (
    <div className=" absolute flex items-center justify-center w-auto h-auto p-3 border rounded-xl bg-gray-800 border-gray-700">
      <span className="px-3 py-1 text-xl font-medium leading-none text-center rounded-full animate-pulse bg-blue-900 text-red-700">
        Error
      </span>
    </div>
  ) : null;

export default StatusBadge;
