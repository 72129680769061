// ---------------------------------------------- modules import
import { useContext } from "react";

import { QuestionContext } from "../../../../contexts/question/context";
import { TagContext } from "../../../../contexts/tag/context";

// ---------------------------------------------- the hooks
export const useTagList = () => {
  // ---------------------------------------------- consume context
  const { tags, error, fetching } = useContext(TagContext);
  const { onChangeTag } = useContext(QuestionContext);

  // ---------------------------------------------- content
  return {
    error,
    fetching,
    tags,
    onChangeTag,
  };
};
