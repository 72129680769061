// ---------------------------------------------- modules import
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import ConsolidatedWorksheetTable from "./consolidated_worksheet_table";
import NotFound from "../../../components/fallbacks/not_found";
import Footer from "../../../components/layouts/footer";
import PrivateRoute from "../../../components/route_guards/private_route";

import ConsolidatedWorksheetContextProvider from "../../../contexts/consolidated_worksheets/context";
import ParticipantGroupContextProvider from "../../../contexts/participant_group/context";

import * as ROUTES from "../../../constants/routes";

// ---------------------------------------------- the component
const Consolidation = () => {
  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // ---------------------------------------------- content
  return (
    <ParticipantGroupContextProvider>
      <ConsolidatedWorksheetContextProvider>
        <div
          style={{
            height: "100vh",
            backgroundColor: "",
          }}
        >
          <Routes>
            <Route
              element={
                <PrivateRoute isAuthorized={true}>
                  <ConsolidatedWorksheetTable isAuthorizedToWrite={true} />
                </PrivateRoute>
              }
              path=""
            />

            <Route element={<NotFound />} path={ROUTES.OTHERS} />
          </Routes>

          <Footer />
        </div>
      </ConsolidatedWorksheetContextProvider>
    </ParticipantGroupContextProvider>
  );
};

export default Consolidation;
