export const ASSESSMENT = "assessments";
export const ATTRIBUTE = "attributes";
export const CONSOLIDATED_WORKSHEET = "consolidatedWorksheets";
export const MODULE = "modules";
export const PROVINCE = "provinces";
export const PARTICIPANT_GROUP = "participantGroups";
export const QUESTION = "questions";
export const REGENCY = "regencies";
export const SUBSET_TOKEN = "subsetTokens";
export const TAG = "tags";
export const TOKEN = "tokens";
export const USER = "users";
export const WORKSHEET = "worksheets";
