// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import { ITagContextProviderState, ITagContextProviderProps } from "./common";
import { useTagContext } from "./hooks";
import { emptyAssessment } from "../../models/assessment";

// ---------------------------------------------- create context
const initialState: ITagContextProviderState = {
  assessment: emptyAssessment(),
  tags: [],
  error: null,
  fetching: true,
  onChangeAssessment: () => {
    return;
  },
};

export const TagContext = createContext(initialState);

// ---------------------------------------------- the component
const TagContextProvider: FunctionComponent<ITagContextProviderProps> = ({
  children,
}) => {
  // ---------------------------------------------- local state
  const { assessment, tags, error, fetching, onChangeAssessment } =
    useTagContext();

  // ---------------------------------------------- content
  return (
    <TagContext.Provider
      value={{ assessment, tags, error, fetching, onChangeAssessment }}
    >
      {children}
    </TagContext.Provider>
  );
};

export default TagContextProvider;
