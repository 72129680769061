// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IButtonProps } from "./common";

// ---------------------------------------------- the component
const Button: FunctionComponent<IButtonProps> = ({
  children,
  className,
  dataModalTarget,
  dataModalToggle,
  id,
  style,
  type,
  valid = true,
  onClick,
}) => {
  const validClassname = "";
  const invalidClassname = "";

  // const validClassname =
  //   "text-white focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-blue-800";
  // const invalidClassname =
  //   "text-white bg-blue-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center";

  // ---------------------------------------------- content
  return (
    <button
      className={`${className || ""} ${
        valid ? validClassname : invalidClassname
      }`}
      // className={valid ? validClassname : invalidClassname}
      data-modal-target={dataModalTarget}
      data-modal-toggle={dataModalToggle}
      disabled={!valid}
      id={id}
      onClick={onClick}
      style={{ ...style }}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
