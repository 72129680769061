// ---------------------------------------------- modules import
import { useNavigate } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";
import { IAssessment } from "../../../../models/assessment";

// ---------------------------------------------- the hooks
export const useAssessmentRow = (
  assessment: IAssessment,
  onChangeAssessment: (assessment: IAssessment) => void
) => {
  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- handlers
  const handleClick = () => {
    onChangeAssessment(assessment);

    navigate(`${ROUTES.ASSESSMENT}/details/${assessment.id}`, {
      replace: true,
    });
  };

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
