// ---------------------------------------------- modules import
import { useNavigate } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";
import { IWorksheet } from "../../../../models/worksheet";

// ---------------------------------------------- the hooks
export const useWorksheetRow = (
  worksheet: IWorksheet,
  onChangeWorksheet: (worksheet: IWorksheet) => void
) => {
  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- handlers
  const handleClick = () => {
    onChangeWorksheet(worksheet);

    navigate(`${ROUTES.REPORT}/details`, {
      replace: true,
    });
  };

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
