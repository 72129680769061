// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-kepribadian.png";

import { IResultPagePersonalityV2Props } from "./common";
import { IConclusion_PersonalityV2 } from "../conclusion/personality_v2/conclusion";
import { IAttribute } from "../../../../models/attribute";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  section: {
    margin: 5,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
    width: "100%",
  },
  logo: {
    justifyContent: "center",
    width: 100,
  },
  headingPage: {
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
    marginTop: 50,
  },
  heading: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
  },
  attribute: {
    flexDirection: "row",
    fontSize: 14,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  attributeName: {
    fontSize: 14,
  },
  dominantAttribute: {
    marginTop: 20,
  },
  dominantPersonality: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  dominantName: {
    fontFamily: "Helvetica-Bold",
    fontSize: 16,
    marginRight: 3,
  },
  personality: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
  },
  description: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "justify",
  },
  traits: {
    fontSize: 12,
    marginBottom: 5,
  },
  growth: {
    fontSize: 12,
    marginBottom: 5,
  },
  bulletPoint: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 5,
  },
  bulletPointList: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 5,
  },
  bulletText: {
    fontSize: 12,
    marginRight: 5,
    textAlign: "justify",
  },
  text: {
    marginBottom: 5,
  },
  textList: {
    fontSize: 11,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
  },
  attributeValue: {
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    textAlign: "right",
  },
  credit: {
    bottom: 30,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: -1,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  barContainer: {
    flexDirection: "row",
    marginBottom: 10,
    alignItems: "center",
    position: "relative",
    height: 30,
    width: "80%",
    backgroundColor: "#FFF",
    borderRadius: 5,
  },
  label: {
    width: 100,
    textAlign: "right",
    marginRight: 10,
    fontSize: 10,
  },
  labelRight: {
    width: 100,
    textAlign: "left",
    marginLeft: 10,
    fontSize: 10,
  },
  barFillLeft: {
    height: "100%",
    position: "absolute",
    left: 0,
    borderLeftRadius: 5,
  },
  barFillRight: {
    height: "100%",
    position: "absolute",
    right: 0,
    borderRightRadius: 5,
  },
  percentageText: {
    position: "absolute",
    fontSize: 10,
    width: "100%",
    textAlign: "center",
    marginTop: 5,
    fontWeight: "bold",
    color: "white",
  },
});

// ---------------------------------------------- the component
const ResultPagePersonalityV2: FunctionComponent<
  IResultPagePersonalityV2Props
> = ({ questionnaire }) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(result.conclusion) as IConclusion_PersonalityV2;

  const reduced = result.attributes.reduce(
    (reduced, attribute) => ({
      ...reduced,
      [attribute.id]: attribute,
    }),
    {} as { [key: string]: IAttribute }
  );

  const sortedBars = [
    {
      labelLeft: "Introvert",
      labelRight: "Ekstrovert",
      value: Math.round(reduced["I"].value * 100),
      colorLeft: "#00BFFF",
      colorRight: "#800080",
    },
    {
      labelLeft: "Sensing",
      labelRight: "Intuition",
      value: Math.round(reduced["S"].value * 100),
      colorLeft: "#00BFFF",
      colorRight: "#800080",
    },
    {
      labelLeft: "Thinking",
      labelRight: "Feeling",
      value: Math.round(reduced["T"].value * 100),
      colorLeft: "#00BFFF",
      colorRight: "#800080",
    },
    {
      labelLeft: "Judging",
      labelRight: "Perceiving",
      value: Math.round(reduced["J"].value * 100),
      colorLeft: "#00BFFF",
      colorRight: "#800080",
    },
  ];

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        {sortedBars.map((item, index) => (
          <View
            key={index}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Text style={styles.label}>{item.labelLeft}</Text>
            <View style={styles.barContainer}>
              <View
                style={[
                  styles.barFillLeft,
                  {
                    width: `${item.value}%`,
                    backgroundColor: item.colorLeft,
                  },
                ]}
              >
                <Text style={styles.percentageText}>{`${item.value}%`}</Text>
              </View>
              <View
                style={[
                  styles.barFillRight,
                  {
                    width: `${100 - item.value}%`,
                    backgroundColor: item.colorRight,
                  },
                ]}
              >
                <Text style={styles.percentageText}>{`${
                  100 - item.value
                }%`}</Text>
              </View>
            </View>
            <Text style={styles.labelRight}>{item.labelRight}</Text>
          </View>
        ))}
      </View>

      <Text style={styles.heading}>Tipe Kepribadian</Text>
      <View style={styles.dominantPersonality}>
        <Text style={styles.dominantName}>{conclusion.name}</Text>
        <Text style={styles.personality}>{`/ ${conclusion.personality}`}</Text>
      </View>
      <Text style={styles.description}>{conclusion.description}</Text>
      <View style={styles.description}>
        {conclusion.descriptions.map((description, index) => (
          <View key={index} style={styles.bulletPoint}>
            <Text style={styles.bulletText}>•</Text>
            <Text style={styles.description}>{description}</Text>
          </View>
        ))}
      </View>
      <View wrap={false}>
        <Text style={styles.heading}>Karakter Yang Kuat</Text>
        <View style={styles.traits}>
          {conclusion.traits.map((trait, index) => (
            <View key={index} style={styles.bulletPointList}>
              <Text style={styles.bulletText}>•</Text>
              <Text style={styles.textList}>{trait}</Text>
            </View>
          ))}
        </View>
      </View>

      <View wrap={false}>
        <Text style={styles.heading}>Butuh Pengembangan</Text>
        <View style={styles.growth}>
          {conclusion.suggestedGrowth.map((growth, index) => (
            <View key={index} style={styles.bulletPointList}>
              <Text style={styles.bulletText}>•</Text>
              <Text style={styles.textList}>{growth}</Text>
            </View>
          ))}
        </View>
      </View>

      <View wrap={false}>
        <Text style={styles.heading}>Rekomendasi Karir</Text>
        <View style={styles.traits}>
          {conclusion.careerRecommendations.map((career, index) => (
            <View key={index} style={styles.bulletPointList}>
              <Text style={styles.bulletText}>•</Text>
              <Text style={styles.textList}>{career}</Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  );
};
export default ResultPagePersonalityV2;
