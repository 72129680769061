// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { emptyAssessment, IAssessment } from "../../models/assessment";
import { ITag, ITagRecord } from "../../models/tag";

// ---------------------------------------------- the hooks
export const useTagContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [assessment, setAssessment] = useState<IAssessment>(emptyAssessment());
  const [tags, setTags] = useState<ITag[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- handlers
  const handleChangeAssessment = (assessment: IAssessment) =>
    setAssessment(assessment);

  // ---------------------------------------------- effects
  useEffect(() => {
    if (assessment) {
      setFetching(true);

      const ref = firebase.firestore
        .collection(PATHS.ASSESSMENT)
        .doc(assessment.id.length ? assessment.id : "empty id")
        .collection(PATHS.TAG);

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          setTags(
            querySnapshot.docs.reduce(
              (tags, doc) => [
                ...tags,
                {
                  id: doc.id,
                  ...(doc.data() as ITagRecord),
                },
              ],
              [] as ITag[]
            )
          );

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, assessment]);

  // ---------------------------------------------- return value
  return {
    assessment,
    tags,
    error,
    fetching,
    onChangeAssessment: handleChangeAssessment,
  };
};
