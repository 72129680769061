// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgBakat from "../../../../assets/images/bg-bakat.png";

import { IResultPageRiasecProps } from "./common";
import { IConclusion_Riasec } from "../conclusion/riasec/conclusion";
import { colorTheme } from "../../../../constants/color_theme";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  section: {
    marginBottom: 8,
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
    width: "100%",
  },
  logo: {
    justifyContent: "center",
    width: 100,
  },
  headingPage: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
    marginTop: 20,
  },
  heading: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    marginBottom: 8,
  },
  attribute: {
    flexDirection: "row",
    fontSize: 10,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  descriptionsContainer: {
    marginBottom: 8,
  },
  dominantName: {
    color: colorTheme.YELLOW_LIGHT,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  description: {
    marginBottom: 5,
    fontSize: 9,
  },
  suggestedMajorsContainer: {
    marginTop: 5,
    fontSize: 9,
  },
  suggestedMajor: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 12,
  },
  supportingSubjectsContainer: {
    marginTop: 10,
    fontSize: 12,
  },
  supportingSubject: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 12,
  },
  bulletPoint: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 4,
  },
  bulletText: {
    fontSize: 9,
    marginRight: 4,
  },
  text: {
    marginBottom: 4,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    color: colorTheme.YELLOW_LIGHT,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
  },
  attributeValue: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
    textAlign: "right",
  },
  credit: {
    bottom: 30,
    color: colorTheme.GRAY_DOFF,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  barContainer: {
    height: 15,
    backgroundColor: "#ccc",
    width: "70%", // Adjusted width to 90% for a better fit
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
  },
  bar: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 5,
  },
  barText: {
    position: "absolute",
    right: 10,
    top: 2,
    color: "white",
    fontSize: 10,
  },
});

// ---------------------------------------------- the component
const ResultPageRiasec: FunctionComponent<IResultPageRiasecProps> = ({
  questionnaire,
}) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(result.conclusion) as IConclusion_Riasec[];

  const [dominantConclusion, subdominantConclusion] = conclusion;

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.logoContainer}></View>

      <View style={styles.section}>
        <Text style={styles.headingPage}></Text>
        <View style={styles.attributeTable}>
          <View style={styles.attributeRow}>
            <Text style={styles.attributeHeader}>Atribut</Text>
            <Text style={styles.attributeHeaderValue}>Nilai</Text>
          </View>
          {/* {result.attributes.map((attribute) => (
            <View key={attribute.id} style={styles.attributeRow}>
              <Text style={styles.attributeDescription}>
                {attribute.description}
              </Text>
              <Text style={styles.attributeValue}>
                {(attribute.value * 100).toFixed(0)}
              </Text>
            </View>
          ))} */}
          {result.attributes.map((attribute) => (
            <View key={attribute.id} style={styles.attributeRow}>
              <Text style={styles.attributeDescription}>
                {attribute.description}
              </Text>
              <View style={styles.barContainer}>
                <View
                  style={[
                    styles.bar,
                    {
                      width: `${attribute.value * 100}%`,
                      backgroundColor: attribute.color || "#4682b4",
                    },
                  ]}
                />
                <Text style={styles.barText}>
                  {(attribute.value * 100).toFixed(0)}%
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.descriptionsContainer}>
          <Text style={styles.heading}>Atribut Primer</Text>
          <Text style={styles.dominantName}>{dominantConclusion.name}</Text>
          <Text style={styles.description}>
            {dominantConclusion.descriptions.join(" ")}
          </Text>
        </View>

        <View style={styles.descriptionsContainer}>
          <Text style={styles.heading}>Atribut Sekunder</Text>
          <Text style={styles.dominantName}>{subdominantConclusion.name}</Text>
          <Text style={styles.description}>
            {subdominantConclusion.descriptions.join(" ")}
          </Text>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={styles.suggestedMajorsContainer}>
            <Text style={styles.heading}>Jurusan Kuliah Yang Disarankan</Text>
            {dominantConclusion.suggestedMajors.map((major, index) => (
              <View key={index} style={styles.bulletPoint}>
                <Text style={styles.bulletText}>•</Text>
                <Text style={styles.bulletText}>{major}</Text>
              </View>
            ))}
          </View>

          <View style={styles.supportingSubjectsContainer}>
            <Text style={styles.heading}>Mata Pelajaran Yang Mendukung</Text>
            {dominantConclusion.supportingSubjects.map((subject, index) => (
              <View key={index} style={styles.bulletPoint}>
                <Text style={styles.bulletText}>•</Text>
                <Text style={styles.bulletText}>{subject}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>

      <Image source={BgBakat} style={styles.backgroundImage} fixed />
    </Page>
  );
};

export default ResultPageRiasec;
