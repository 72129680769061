// ---------------------------------------------- modules import
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import Button from "../buttons/button";

import { IDeleteModalProps } from "./common";
import { useDeleteModal } from "./hooks/use_delete_modal";

// ---------------------------------------------- the component
const DeleteModal: FunctionComponent<IDeleteModalProps> = ({
  className,
  icon,
  id,
  style,
  valid = true,
  version = "v1", // Add this prop to toggle between old and new design
  onDelete,
}) => {
  const { isVisible, onConfirm, onHideModal, onShowModal } =
    useDeleteModal(onDelete);

  // ---------------------------------------------- content
  return (
    <>
      <Button
        type="button"
        valid={valid}
        className={`inline-flex items-center justify-center w-10 h-10 text-white rounded-full ${className}`}
        onClick={onShowModal}
        style={{ ...style }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>

      {isVisible && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          id={id}
        >
          {version === "v2" ? (
            <div className="bg-gray-800 w-full max-w-md p-6 rounded-lg shadow-xl">
              <h5 className="text-xl font-bold mb-4 text-red-500">
                Confirm Delete
              </h5>
              <p className="mb-6 text-gray-300">
                Are you sure you want to continue?
              </p>
              <div className="flex justify-end space-x-4">
                <Button
                  type="button"
                  valid
                  className="rounded-lg bg-red-600 text-white px-4 py-2 hover:bg-red-700"
                  onClick={onConfirm}
                >
                  Yes, I'm Sure
                </Button>
                <Button
                  type="button"
                  valid
                  className="rounded-lg bg-gray-600 text-white px-4 py-2 hover:bg-gray-700"
                  onClick={onHideModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className="bg-gray-800 w-1/3 p-8 rounded-lg shadow-xl">
              <h5 className="text-lg font-semibold mb-4 text-red-700">
                Confirm Delete
              </h5>
              <p className="mb-6 text-gray-100">
                Are you sure you want to continue?
              </p>
              <div className="flex justify-between">
                <Button
                  type="button"
                  valid
                  className="rounded-full bg-green-600 text-white px-5 py-3 hover:bg-green-700"
                  onClick={onConfirm}
                >
                  Yes, I'm Sure
                </Button>
                <Button
                  type="button"
                  valid
                  className="rounded-full bg-red-600 text-white px-5 py-3 hover:bg-red-700"
                  onClick={onHideModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DeleteModal;
