// ---------------------------------------------- modules import
import { Document } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import CoverPage from "./cover_page";
import DisclaimerPage from "../pdf_personal_report/disclaimer_page";
import ResultPageGayaBelajar from "./result_page_gaya_belajar";
import ResultPageKebiasaanBelajar from "./result_page_kebiasaan_belajar";
import ResultPageKecerdasanMajemuk from "./result_page_kecerdasan_majemuk";
import ResultPageKesejahteraanPsikologiDanEmosional from "./result_page_kesejahteraan_psikologi_dan_emosional";
import ResultPageKondisiKeluarga from "./result_page_kondisi_keluarga";
import ResultPagePersonality from "./result_page_personality";
import ResultPageRiasec from "./result_page_riasec";

import ResultPageGayaBelajarSdKelas1Sampai3 from "./result_page_gaya_belajar_sd_kelas_1_sampai_3";

import { IPdfTokenGroupedReportProps } from "./common";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";


// ---------------------------------------------- the component
const PdfTokenGroupedReport: FunctionComponent<IPdfTokenGroupedReportProps> = ({
  participantGroup,
  module,
  subsetToken,
  worksheets,
}) => {
  // ---------------------------------------------- content
  return (
    <Document>
      <CoverPage
        participantGroup={participantGroup}
        module={module}
        subsetToken={subsetToken}
      />

      <DisclaimerPage />

      {module.assessmentIds.includes(AssessmentIdEnum.PERSONALITY) && (
        <ResultPagePersonality worksheets={worksheets} />
      )}

      {module.assessmentIds.includes(AssessmentIdEnum.GAYA_BELAJAR) && (
        <ResultPageGayaBelajar worksheets={worksheets} />
      )}

{module.assessmentIds.includes(AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3) && (
        <ResultPageGayaBelajarSdKelas1Sampai3 worksheets={worksheets} />
      )}

      

      {module.assessmentIds.includes(AssessmentIdEnum.KECERDASAN_MAJEMUK) && (
        <ResultPageKecerdasanMajemuk worksheets={worksheets} />
      )}

      {module.assessmentIds.includes(
        AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
      ) && (
        <ResultPageKesejahteraanPsikologiDanEmosional worksheets={worksheets} />
      )}

      {module.assessmentIds.includes(AssessmentIdEnum.KEBIASAAN_BELAJAR) && (
        <ResultPageKebiasaanBelajar worksheets={worksheets} />
      )}

      {module.assessmentIds.includes(AssessmentIdEnum.KONDISI_KELUARGA) && (
        <ResultPageKondisiKeluarga worksheets={worksheets} />
      )}

      {module.assessmentIds.includes(AssessmentIdEnum.RIASEC) && (
        <ResultPageRiasec worksheets={worksheets} />
      )}
    </Document>
  );
};

export default PdfTokenGroupedReport;
