// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsGayaBelajarKelas1Sampai3Props } from "./common";
import { IConclusion_GayaBelajarSdKelas1Sampai3 } from "../conclusion/gaya_belajar_sd_kelas_1_sampai_3/conclusion";

// ---------------------------------------------- the component
const DetailsGayaBelajarKelas1Sampai3: FunctionComponent<
  IDetailsGayaBelajarKelas1Sampai3Props
> = ({ questionnaire }) => {
  const { assessment, result } = questionnaire;

  const conclusions = JSON.parse(
    result.conclusion
  ) as IConclusion_GayaBelajarSdKelas1Sampai3[];

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {result.attributes.map((attribute) => (
          <div
            key={attribute.id}
            className="bg-gray-700 p-4 rounded-lg shadow-md"
          >
            <div className="flex items-center justify-between">
              <span className="font-semibold text-gray-300">
                {attribute.name}
              </span>
              <span className="text-yellow-400 font-bold">{`${(
                attribute.value * 100
              ).toFixed(0)}%`}</span>
            </div>
          </div>
        ))}
      </div>

      {conclusions.map((conclusion, index) => (
        <div key={index} className="mb-8">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            {conclusion.name}
          </h3>
          <div className="space-y-4">
            <div>
              <h4 className="font-semibold text-yellow-300">Deskripsi:</h4>
              <p className="text-gray-300">
                {conclusion.descriptions.join(" ")}
              </p>
            </div>
            <div>
              <h4 className="font-semibold text-yellow-300">
                Strategi Pembelajaran:
              </h4>
              <p className="text-gray-300">
                {conclusion.learningStrategies.join(" ")}
              </p>
            </div>
            <div>
              <h4 className="font-semibold text-yellow-300">Ciri-ciri:</h4>
              <p className="text-gray-300">{conclusion.traits.join(" ")}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailsGayaBelajarKelas1Sampai3;
