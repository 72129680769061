// ---------------------------------------------- modules import
import {
  faCoins,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import SubsetTokenListItem from "./subset_token_list_item";
import StatusBadge from "../../../components/ui/others/status_badge";

import { ISubsetTokenListProps } from "./common";
import { useSubsetTokenList } from "./hooks/use_subset_token_list";

// ---------------------------------------------- the component
const SubsetTokenList: FunctionComponent<ISubsetTokenListProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    copiedId,
    error,
    fetching,
    loading,
    subsetTokens,
    onCopyId,
    onToggleStatus,
  } = useSubsetTokenList();

  // ---------------------------------------------- content
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="bg-gray-700 p-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white flex items-center">
            <FontAwesomeIcon icon={faCoins} className="mr-3 text-yellow-400" />
            TOKENS
          </h1>
          <span className="text-sm font-semibold text-gray-300 bg-gray-600 px-3 py-1 rounded-full">
            {!subsetTokens.length
              ? "NO TOKENS REGISTERED"
              : subsetTokens.length === 1
              ? "1 TOKEN REGISTERED"
              : `${subsetTokens.length} TOKENS REGISTERED`}
          </span>
        </div>

        <div className="p-6">
          {subsetTokens.length > 0 ? (
            <div className="space-y-4">
              {subsetTokens.map((subsetToken) => (
                <SubsetTokenListItem
                  key={subsetToken.id}
                  copiedId={copiedId}
                  subsetToken={subsetToken}
                  onCopyId={onCopyId}
                  onToggleStatus={onToggleStatus}
                />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-yellow-400 text-5xl mb-4"
              />
              <p className="text-gray-400 text-lg">
                Looks like there are no modules selected right now.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 flex justify-center">
        <StatusBadge fetching={fetching} loading={loading} error={error} />
      </div>
    </div>
  );
};

export default SubsetTokenList;
