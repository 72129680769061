// ---------------------------------------------- document interfaces
/**
 * Represents the data fields of a subset token document in Firestore.
 *
 * Subcollections of each document:
 * - Worksheets
 */
export interface ISubsetTokenRecord {
  dateCreated: string;
  lastModified: string;
  name: string;
  status: boolean;
}

/**
 * Represents a subset token returned to the frontend from Firestore.
 *
 * Extends the ISubsetTokenRecord interface.
 */
export interface ISubsetToken extends ISubsetTokenRecord {
  id: string;
  [key: string]: any;
}

// ---------------------------------------------- empty document
export const emptySubsetTokenRecord = (): ISubsetTokenRecord => ({
  dateCreated: "",
  lastModified: "",
  name: "",
  status: false,
});

export const emptySubsetToken = (): ISubsetToken => ({
  ...emptySubsetTokenRecord(),
  id: "",
});
