// ---------------------------------------------- modules import
import { ChangeEvent, FunctionComponent } from "react";

import { INumberInputProps } from "./common";

// ---------------------------------------------- the component
const NumberInput: FunctionComponent<INumberInputProps> = ({
  className_input,
  className_label,
  className_root,
  disabled,
  id,
  label,
  max,
  min,
  name,
  required,
  style,
  value,
  onChange,
}) => {
  // ---------------------------------------------- handlers
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(e.currentTarget.name, e.currentTarget.value);

  // ---------------------------------------------- content
  return (
    <div className={`${className_root || "relative mb-3"}`} style={style}>
      {label && (
        <label
          className={`flex text-gray-100 items-center ${className_label}`}
          htmlFor={id}
        >
          <span className="flex-1">{label}</span>
        </label>
      )}

      {disabled ? (
        <input className="" disabled id={id} type="number" value={value} />
      ) : (
        <input
          className={
            className_input
              ? className_input
              : "w-full border text-sm rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
          }
          id={id}
          max={max}
          min={min}
          name={name}
          onChange={handleChange}
          required={required}
          step="any"
          style={{ fontSize: "0.85rem" }}
          type="number"
          value={value}
        />
      )}
    </div>
  );
};

export default NumberInput;
