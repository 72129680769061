// ---------------------------------------------- modules import
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import AddModuleForm from "./add_module_form";
import AddParticipantGroupForm from "./add_participant_group_form";
import Details from "./details";
import ParticipantGroupTable from "./participant_group_table";
import UpdateModuleForm from "./update_module_form";
import UpdateParticipantGroupForm from "./update_participant_group_form";
import NotFound from "../../../components/fallbacks/not_found";
import Footer from "../../../components/layouts/footer";
import PrivateRoute from "../../../components/route_guards/private_route";

import AssessmentContextProvider from "../../../contexts/assessment/context";
import ModuleContextProvider from "../../../contexts/module/context";
import ParticipantGroupContextProvider from "../../../contexts/participant_group/context";
import ProvinceContextProvider from "../../../contexts/province/context";
import RegencyContextProvider from "../../../contexts/regency/context";
import SubsetTokenContextProvider from "../../../contexts/subset_token/context";

import * as ROUTES from "../../../constants/routes";

// ---------------------------------------------- the component
const ParticipantGroup = () => {
  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // ---------------------------------------------- content
  return (
    <ParticipantGroupContextProvider>
      <ProvinceContextProvider>
        <RegencyContextProvider>
          <ModuleContextProvider>
            <AssessmentContextProvider>
              <SubsetTokenContextProvider>
                <div
                  style={{
                    height: "100vh",
                    backgroundColor: "",
                  }}
                >
                  <Routes>
                    <Route
                      element={
                        <PrivateRoute isAuthorized={true}>
                          <ParticipantGroupTable isAuthorizedToWrite={true} />
                        </PrivateRoute>
                      }
                      path=""
                    />

                    <Route
                      element={
                        <PrivateRoute isAuthorized={true}>
                          <AddModuleForm />
                        </PrivateRoute>
                      }
                      path="add-module"
                    />

                    <Route
                      element={
                        <PrivateRoute isAuthorized={true}>
                          <AddParticipantGroupForm />
                        </PrivateRoute>
                      }
                      path="add-participant-group"
                    />

                    <Route
                      element={
                        <PrivateRoute isAuthorized={true}>
                          <Details isAuthorizedToWrite={true} />
                        </PrivateRoute>
                      }
                      path="details/:participant_group_id"
                    />

                    <Route
                      element={
                        <PrivateRoute isAuthorized={true}>
                          <UpdateModuleForm />
                        </PrivateRoute>
                      }
                      path="update-module/:module_id"
                    />

                    <Route
                      element={
                        <PrivateRoute isAuthorized={true}>
                          <UpdateParticipantGroupForm />
                        </PrivateRoute>
                      }
                      path="update-participant-group/:participant_group_id"
                    />

                    <Route element={<NotFound />} path={ROUTES.OTHERS} />
                  </Routes>

                  <Footer />
                </div>
              </SubsetTokenContextProvider>
            </AssessmentContextProvider>
          </ModuleContextProvider>
        </RegencyContextProvider>
      </ProvinceContextProvider>
    </ParticipantGroupContextProvider>
  );
};

export default ParticipantGroup;
