// ---------------------------------------------- modules import
import { FirebaseOptions } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCz4AV-O4shelhTZHm2CVUt6siCmNRyA6A",
  authDomain: "aktara-talenthive.firebaseapp.com",
  projectId: "aktara-talenthive",
  storageBucket: "aktara-talenthive.appspot.com",
  messagingSenderId: "37713780794",
  appId: "1:37713780794:web:7d1ad4598ef63d7e401503",
  measurementId: "G-VVHLH0LXG4",
};

export default firebaseConfig;
