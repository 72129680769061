// ---------------------------------------------- modules import
import { useContext, useEffect, useState } from "react";

import { WorksheetContext } from "../../../../contexts/worksheet/context";
import { ISubsetToken } from "../../../../models/subset_token";

// ---------------------------------------------- the hooks
export const useSubsetTokenTable = () => {
  // ---------------------------------------------- consume context
  const { module, subsetTokens, error, fetching, onChangeSubsetToken } =
    useContext(WorksheetContext);

  // ---------------------------------------------- local state
  const [criteria, setCriteria] = useState({
    keywords: "",
    prop: "name",
  });
  const [filtered, setFiltered] = useState<ISubsetToken[]>([]);
  const [sortBy, setSortBy] = useState({
    asc: 1,
    prop: "name",
  });
  const [sorted, setSorted] = useState<ISubsetToken[]>([]);

  // ---------------------------------------------- handlers
  const handleFilter = (criterion: string, value: string) => {
    if (/^[^()[\\\]|]{0,}$/.test(value))
      setCriteria({
        ...criteria,
        [criterion]: value,
      });
  };

  const handleSort = (prop: string) =>
    setSortBy((prev) => ({
      ...prev,
      prop,
      asc: prev.asc * -1,
    }));

  // ---------------------------------------------- effects
  useEffect(() => {
    const { prop, asc } = sortBy;

    setSorted(
      [...subsetTokens].sort((a, b) => (a[prop] > b[prop] ? asc : asc * -1))
    );
  }, [sortBy, subsetTokens]);

  useEffect(() => {
    const { keywords, prop } = criteria;

    setFiltered(
      sorted.filter((subsetToken) =>
        RegExp(`${keywords}`, "i").test(subsetToken[prop])
      )
    );
  }, [criteria, sorted]);

  // ---------------------------------------------- return value
  return {
    criteria,
    error,
    fetching,
    filtered,
    module,
    subsetTokens,
    sortBy,
    onChangeSubsetToken,
    onFilter: handleFilter,
    onSort: handleSort,
  };
};
