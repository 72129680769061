// ---------------------------------------------- modules import
import { ChangeEvent, FunctionComponent } from "react";

import { ISelectInputProps } from "./common";

// ---------------------------------------------- the component
const SelectInput: FunctionComponent<ISelectInputProps> = ({
  className_root,
  className_internal_container,
  className_label,
  className_select,
  defaultOption,
  disabled,
  fallbackValue,
  id,
  label,
  multiple,
  name,
  options,
  style,
  value,
  onChange,
}) => {
  // ---------------------------------------------- handlers
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) =>
    onChange(e.currentTarget.name, e.currentTarget.value);

  // ---------------------------------------------- content
  return (
    <div className={className_root} style={style}>
      <div className={className_internal_container}>
        {label && (
          <label className={`text-gray-100 ${className_label}`}>{label}</label>
        )}

        <select
          className={`w-full border text-sm rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500 ${className_select}`}
          disabled={disabled}
          id={id}
          multiple={multiple}
          name={name}
          onChange={handleChange}
          value={value}
        >
          {options.length ? (
            <>
              {defaultOption && (
                <option disabled value={defaultOption.value}>
                  {defaultOption.label}
                </option>
              )}

              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </>
          ) : (
            <option disabled value={fallbackValue}>
              Cannot find any options.
            </option>
          )}
        </select>
      </div>
    </div>
  );
};

export default SelectInput;
