// ---------------------------------------------- modules import
import {
  Page,
  StyleSheet,
  Text,
  View,
  Image,
  Svg,
  Rect,
} from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-majemuk.png";
import { IResultPageKecerdasanMajemukProps } from "./common";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";
import { AttributeIdEnum_KecerdasanMajemuk } from "../../../../enums/attribute_id";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  section: {
    marginBottom: 10,
  },

  // table
  table: {
    display: "flex",
    width: "100%",
    borderColor: "#000000",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 30,
    textAlign: "center",
  },
  tableCell: {
    fontSize: 8,
    padding: 5,
    width: 30,
    textAlign: "center",
  },
  tableHeaderCellName: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 130,
  },
  tableCellName: {
    fontSize: 9,
    padding: 5,
    width: 130,
  },
  tableHeaderCellResult: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 80,
    textAlign: "center",
  },
  tableCellResult: {
    fontSize: 9,
    padding: 5,
    width: 80,
    textAlign: "center",
  },
  tableHeaderCellNo: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  tableCellNo: {
    fontSize: 9,
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  evenRow: {
    backgroundColor: "#D9E1F2",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  // Chart
  chart: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  bar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  barLabel: {
    width: "40%",
    fontSize: 10,
    textAlign: "right",
    marginRight: 3,
  },
  barValue: {
    marginLeft: 5,
    fontSize: 10,
  },
  barChart: {
    marginTop: 10,
    width: "70%",
  },
  barRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  barContainer: {
    width: "100%",
    height: 15,
  },

  // Text
  subheading: {
    fontSize: 12,
    fontWeight: "heavy",
    marginTop: 10,
    marginBottom: 5,
  },
  list: {
    marginLeft: 10,
  },
  listItem: {
    fontSize: 9,
    marginBottom: 2,
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#3b1c78",
  },
  description: {
    marginBottom: 10,
    textAlign: "justify",
    fontSize: 9,
  },
});

// ---------------------------------------------- the component
const ResultPageKecerdasanMajemuk: FunctionComponent<
  IResultPageKecerdasanMajemukProps
> = ({ worksheets }) => {
  // Fungsi untuk mendapatkan 1 atribut terbesar
  const getTopAttribute = (attributes: { id: string; value: number }[]) => {
    return attributes.reduce((max, attr) =>
      max.value > attr.value ? max : attr
    ).id;
  };

  // Objek untuk menyimpan jumlah murid per atribut
  const attributeCounts: { [key: string]: number } = {
    [AttributeIdEnum_KecerdasanMajemuk.BODILY_KINESTHETIC]: 0,
    [AttributeIdEnum_KecerdasanMajemuk.INTERPERSONAL]: 0,
    [AttributeIdEnum_KecerdasanMajemuk.INTRAPERSONAL]: 0,
    [AttributeIdEnum_KecerdasanMajemuk.LINGUISTIC]: 0,
    [AttributeIdEnum_KecerdasanMajemuk.LOGICAL_MATHEMATICAL]: 0,
    [AttributeIdEnum_KecerdasanMajemuk.MUSICAL]: 0,
    [AttributeIdEnum_KecerdasanMajemuk.NATURALISTIC]: 0,
    [AttributeIdEnum_KecerdasanMajemuk.SPATIAL]: 0,
  };

  // Hitung jumlah murid per atribut
  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KECERDASAN_MAJEMUK
    );

    if (questionnaire) {
      const topAttribute = getTopAttribute(questionnaire.result.attributes);
      attributeCounts[topAttribute]++;
    }
  });

  // Fungsi helper untuk mendapatkan nama atribut
  const getAttributeName = (attrId: string): string => {
    switch (attrId) {
      case AttributeIdEnum_KecerdasanMajemuk.BODILY_KINESTHETIC:
        return "Kinestetik";
      case AttributeIdEnum_KecerdasanMajemuk.INTERPERSONAL:
        return "Interpersonal";
      case AttributeIdEnum_KecerdasanMajemuk.INTRAPERSONAL:
        return "Intrapersonal";
      case AttributeIdEnum_KecerdasanMajemuk.LINGUISTIC:
        return "Linguistik";
      case AttributeIdEnum_KecerdasanMajemuk.LOGICAL_MATHEMATICAL:
        return "Matematis";
      case AttributeIdEnum_KecerdasanMajemuk.MUSICAL:
        return "Musikal";
      case AttributeIdEnum_KecerdasanMajemuk.NATURALISTIC:
        return "Naturalis";
      case AttributeIdEnum_KecerdasanMajemuk.SPATIAL:
        return "Spasial";
      default:
        return "Tidak Dikenal";
    }
  };

  // Hitung total murid
  const totalStudents = worksheets.length;

  // Fungsi untuk menghitung persentase
  const calculatePercentage = (count: number) => {
    return ((count / totalStudents) * 100).toFixed(1);
  };

  // Fungsi untuk mendapatkan warna untuk setiap atribut
  const getColorForAttribute = (attrId: string): string => {
    const colors: { [key: string]: string } = {
      [AttributeIdEnum_KecerdasanMajemuk.INTRAPERSONAL]: "#00FFFF",
      [AttributeIdEnum_KecerdasanMajemuk.SPATIAL]: "#008000",
      [AttributeIdEnum_KecerdasanMajemuk.LINGUISTIC]: "#800080",
      [AttributeIdEnum_KecerdasanMajemuk.BODILY_KINESTHETIC]: "#FFA500",
      [AttributeIdEnum_KecerdasanMajemuk.LOGICAL_MATHEMATICAL]: "#87CEEB",
      [AttributeIdEnum_KecerdasanMajemuk.MUSICAL]: "#4169E1",
      [AttributeIdEnum_KecerdasanMajemuk.INTERPERSONAL]: "#FFA07A",
      [AttributeIdEnum_KecerdasanMajemuk.NATURALISTIC]: "#FF0000",
    };
    return colors[attrId] || "#CCCCCC"; // Warna default jika tidak ditemukan
  };

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section} wrap={false}>
        <Text style={styles.heading}>Grafik Distribusi Frekuensi</Text>
        <View style={styles.barChart}>
          {Object.entries(attributeCounts)
            .sort(([, a], [, b]) => b - a) // Urutkan dari yang terbesar
            .map(([attrId, count], index) => {
              const percentage = calculatePercentage(count);
              return (
                <View key={index} style={styles.barRow}>
                  <Text style={styles.barLabel}>
                    {getAttributeName(attrId)}
                  </Text>
                  <View style={styles.barContainer}>
                    <Svg width="200%" height="15">
                      <Rect
                        x="0"
                        y="0"
                        width={`${percentage}%`}
                        height="15"
                        fill={getColorForAttribute(attrId)}
                      />
                    </Svg>
                  </View>
                  <Text style={styles.barValue}>{percentage}%</Text>
                </View>
              );
            })}
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.section}>
          <Text style={styles.heading}>Tipe Kecerdasan Majemuk</Text>
          <View style={styles.section}>
            <Text style={styles.heading}>Intrapersonal</Text>
            <Text style={styles.description} wrap={false}>
              Memiliki buku harian dan mencatat isi pikiran atau perasaannya
              secara rutin, sering menyendiri dalam memikirkan dan memecahkan
              masalah, serta dapat memutuskan tujuan dan keinginannya dengan
              cara mandiri.
            </Text>
            <Text style={styles.subheading}>Karakter yang Kuat</Text>
            <View style={styles.list} wrap={false}>
              <Text style={styles.listItem}>
                • Mampu menganalisis kekuatan dan kelemahan diri dengan baik
              </Text>
              <Text style={styles.listItem}>
                • Memiliki kesadaran diri yang sangat baik
              </Text>
              <Text style={styles.listItem}>
                • Memahami dasar motivasi dan perasaannya sendiri
              </Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.heading}>Spasial</Text>
            <Text style={styles.description} wrap={false}>
              Mampu memperkirakan ukuran dan jarak, membaca peta, dan menentukan
              arah; mampu mengingat sesuatu seolah-olah memorinya menggunakan
              kamera; memiliki imajinasi hidup dan menikmati waktu saat
              menggambar atau mendesain sesuatu.
            </Text>
            <Text style={styles.subheading}>Karakter yang Kuat</Text>
            <View style={styles.list} wrap={false}>
              <Text style={styles.listItem}>• Hobi membaca dan menulis</Text>
              <Text style={styles.listItem}>• Pandai menyusun teka-teki</Text>
              <Text style={styles.listItem}>
                • Mampu memikirkan gambar, grafik, dan bagan dengan baik
              </Text>
              <Text style={styles.listItem}>
                • Menikmati menggambar, melukis, dan seni visual
              </Text>
              <Text style={styles.listItem}>
                • Mampu mengenali pola dengan mudah
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.heading}>Linguistik</Text>
              <Text style={styles.description} wrap={false}>
                Mampu menggunakan beragam jenis kata, dan menyusunnya menjadi
                kalimat yang mudah dipahami; menyukai beragam aktivitas yang
                bersinggungan dengan bahasa seperti membaca, menulis,
                mendengarkan, dan berbicara; dan memiliki kemampuan untuk
                menganalisis informasi yang disampaikan secara tertulis dan
                lisan.
              </Text>
              <Text style={styles.subheading}>Karakter yang Kuat</Text>
              <View style={styles.list} wrap={false}>
                <Text style={styles.listItem}>
                  • Berkomunikasi lisan & tulis
                </Text>
                <Text style={styles.listItem}>• Mengarang cerita</Text>
                <Text style={styles.listItem}>
                  • Diskusi & mengikuti debat suatu masalah
                </Text>
                <Text style={styles.listItem}>• Belajar bahasa asing</Text>
                <Text style={styles.listItem}>• Bermain "game" bahasa</Text>
                <Text style={styles.listItem}>
                  • Membaca dengan pemahaman tinggi
                </Text>
                <Text style={styles.listItem}>
                  • Mudah mengingat kutipan, ucapan ahli, pakar, ayat
                </Text>
                <Text style={styles.listItem}>
                  • Tidak mudah salah tulis atau salah eja
                </Text>
                <Text style={styles.listItem}>• Pandai membuat lelucon</Text>
                <Text style={styles.listItem}>• Pandai membuat puisi</Text>
                <Text style={styles.listItem}>• Tepat dalam tata bahasa</Text>
              </View>
            </View>
            <View style={styles.section}>
              <Text style={styles.heading}>Kinestetik</Text>
              <Text style={styles.description} wrap={false}>
                Tidak suka berdiam diri, lebih mudah menyerap pelajaran dengan
                cara praktik dibandingkan mendengarkan penjelasan teoritis;
                memiliki keterampilan untuk merakit benda dan bahan; membuat
                kerajinan tangan; menyukai kegiatan bergerak seperti berolahraga
                secara rutin, dan menghasilkan banyak waktu di luar ruangan.
              </Text>
              <Text style={styles.subheading}>Karakter yang Kuat</Text>
              <View style={styles.list} wrap={false}>
                <Text style={styles.listItem}>
                  • Terampil dalam menari dan olahraga
                </Text>
                <Text style={styles.listItem}>
                  • Memiliki koordinasi tangan dan sangat baik
                </Text>
                <Text style={styles.listItem}>
                  • Lebih bisa mengingat sesuatu lewat gerakan, bukan mendengar
                  atau melihat
                </Text>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.heading}>Matematis</Text>
              <Text style={styles.description}>
                Memiliki rasa ingin tahu yang tinggi terhadap cara kerja suatu
                benda; ingin mengetahui alasan untuk memahami hubungan
                sebab-akibat berbagai hal; merasa perlu membedakan antara fiksi
                dan opini; menikmati permainan yang melibatkan angka, berhitung,
                dan pemecahan masalah; mengingat sesuatu berdasarkan urutan, dan
                pandai melakukan perhitungan angka.
              </Text>
              <Text style={styles.subheading}>Karakter yang Kuat</Text>
              <View style={styles.list} wrap={false}>
                <Text style={styles.listItem}>• Memecahkan masalah</Text>
                <Text style={styles.listItem}>
                  • Mengidentifikasi fungsi dan hubungan
                </Text>
                <Text style={styles.listItem}>• Memperkirakan</Text>
                <Text style={styles.listItem}>• Menghitung</Text>
                <Text style={styles.listItem}>• Menggunakan simbol</Text>
                <Text style={styles.listItem}>• Menggunakan rumus</Text>
                <Text style={styles.listItem}>
                  • Menggunakan grafik dan tabel
                </Text>
                <Text style={styles.listItem}>• Menggunakan komputer</Text>
                <Text style={styles.listItem}>• Menggunakan logika</Text>
                <Text style={styles.listItem}>
                  • Menggunakan angka-angka besar
                </Text>
                <Text style={styles.listItem}>• Menggunakan statistik</Text>
                <Text style={styles.listItem}>
                  • Menggunakan konsep simbol abstrak
                </Text>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.heading}>Musikal</Text>
              <Text style={styles.description}>
                Menyukai musik, dapat memainkan alat musik, gemar bersenandung,
                mudah menghafal lagu, bisa mengenali nada, dan menikmati
                mendengarkan musik; dapat mengikuti irama, dan memperjelas
                ide-ide dalam bentuk musik atau lirik.
              </Text>
              <Text style={styles.subheading}>Karakter yang Kuat</Text>
              <View style={styles.list} wrap={false}>
                <Text style={styles.listItem}>• Menyanyi</Text>
                <Text style={styles.listItem}>• Bermain alat musik</Text>
                <Text style={styles.listItem}>• Mengarang lagu</Text>
                <Text style={styles.listItem}>• Menghafal nada</Text>
                <Text style={styles.listItem}>• Mengikuti irama</Text>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.heading}>Interpersonal</Text>
              <Text style={styles.description}>
                Mampu memahami dan mempengaruhi pendapat, perasaan, dan tindakan
                orang lain; solidaritas tinggi, peduli terhadap orang lain,
                dapat menjadi pemimpin, dan menyukai acara berkumpul bersama
                orang-orang.
              </Text>
              <Text style={styles.subheading}>Karakter yang Kuat</Text>
              <View style={styles.list} wrap={false}>
                <Text style={styles.listItem}>• Berkomunikasi dengan baik</Text>
                <Text style={styles.listItem}>• Menjadi pemimpin</Text>
                <Text style={styles.listItem}>• Bekerja sama dalam tim</Text>
                <Text style={styles.listItem}>• Menyelesaikan konflik</Text>
                <Text style={styles.listItem}>
                  • Memahami perasaan orang lain
                </Text>
                <Text style={styles.listItem}>• Menjalin hubungan sosial</Text>
              </View>
            </View>

            <View style={styles.section} break>
              <Text style={styles.heading}>Kecerdasan Majemuk</Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableHeaderCellNo}>No.</Text>
                  <Text style={styles.tableHeaderCellName}>Nama</Text>
                  <Text style={styles.tableHeaderCell}>Kines</Text>
                  <Text style={styles.tableHeaderCell}>Inter</Text>
                  <Text style={styles.tableHeaderCell}>Intra</Text>
                  <Text style={styles.tableHeaderCell}>Lin</Text>
                  <Text style={styles.tableHeaderCell}>Mat</Text>
                  <Text style={styles.tableHeaderCell}>Musk</Text>
                  <Text style={styles.tableHeaderCell}>Nat</Text>
                  <Text style={styles.tableHeaderCell}>Spas</Text>
                  <Text style={styles.tableHeaderCellResult}>Hasil</Text>
                </View>

                {worksheets &&
                  worksheets.map((worksheet, index) => {
                    const { profile, questionnaires } = worksheet;

                    const questionnaire = questionnaires.find(
                      (questionnaire) =>
                        questionnaire.assessment.id ===
                        AssessmentIdEnum.KECERDASAN_MAJEMUK
                    );

                    if (!questionnaire) return null;

                    const { result } = questionnaire;

                    let bodilyKinesthetic = "-1";
                    let interpersonal = "-1";
                    let intrapersonal = "-1";
                    let linguistic = "-1";
                    let logicalMathematic = "-1";
                    let musical = "-1";
                    let naturalistic = "-1";
                    let spatial = "-1";

                    result.attributes.forEach((attribute) => {
                      switch (attribute.id) {
                        case AttributeIdEnum_KecerdasanMajemuk.BODILY_KINESTHETIC: {
                          bodilyKinesthetic = (attribute.value * 100).toFixed(
                            0
                          );
                          break;
                        }
                        case AttributeIdEnum_KecerdasanMajemuk.INTERPERSONAL: {
                          interpersonal = (attribute.value * 100).toFixed(0);
                          break;
                        }
                        case AttributeIdEnum_KecerdasanMajemuk.INTRAPERSONAL: {
                          intrapersonal = (attribute.value * 100).toFixed(0);
                          break;
                        }
                        case AttributeIdEnum_KecerdasanMajemuk.LINGUISTIC: {
                          linguistic = (attribute.value * 100).toFixed(0);
                          break;
                        }
                        case AttributeIdEnum_KecerdasanMajemuk.LOGICAL_MATHEMATICAL: {
                          logicalMathematic = (attribute.value * 100).toFixed(
                            0
                          );
                          break;
                        }
                        case AttributeIdEnum_KecerdasanMajemuk.MUSICAL: {
                          musical = (attribute.value * 100).toFixed(0);
                          break;
                        }
                        case AttributeIdEnum_KecerdasanMajemuk.NATURALISTIC: {
                          naturalistic = (attribute.value * 100).toFixed(0);
                          break;
                        }
                        case AttributeIdEnum_KecerdasanMajemuk.SPATIAL: {
                          spatial = (attribute.value * 100).toFixed(0);
                          break;
                        }
                        default: {
                          return;
                        }
                      }
                    });

                    return (
                      <View
                        key={worksheet.id}
                        style={[
                          styles.tableRow,
                          index % 2 === 0 ? styles.evenRow : styles.oddRow,
                        ]}
                        wrap={false}
                      >
                        <Text style={styles.tableCellNo}>{index + 1}</Text>
                        <Text style={styles.tableCellName}>{profile.name}</Text>
                        <Text style={styles.tableCell}>
                          {bodilyKinesthetic}
                        </Text>
                        <Text style={styles.tableCell}>{interpersonal}</Text>
                        <Text style={styles.tableCell}>{intrapersonal}</Text>
                        <Text style={styles.tableCell}>{linguistic}</Text>
                        <Text style={styles.tableCell}>
                          {logicalMathematic}
                        </Text>
                        <Text style={styles.tableCell}>{musical}</Text>
                        <Text style={styles.tableCell}>{naturalistic}</Text>
                        <Text style={styles.tableCell}>{spatial}</Text>
                        <Text style={styles.tableCellResult}>
                          {getAttributeName(
                            getTopAttribute(questionnaire.result.attributes)
                          )}
                        </Text>
                      </View>
                    );
                  })}
              </View>
            </View>
            {/* <View style={styles.section}>
              <Text style={styles.heading}>Statistik Atribut Terbesar</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableHeaderCellName}>Atribut</Text>
                  <Text style={styles.tableHeaderCell}>Jumlah Murid</Text>
                </View>
                {Object.entries(attributeCounts).map(([attrId, count]) => (
                  <View key={attrId} style={styles.tableRow}>
                    <Text style={styles.tableCellName}>
                      {getAttributeName(attrId)}
                    </Text>
                    <Text style={styles.tableCell}>{count}</Text>
                  </View>
                ))}
              </View>
            </View> */}
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ResultPageKecerdasanMajemuk;
