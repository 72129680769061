// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import { ISubsetToken } from "../models/subset_token";

export const pattern: IPattern = {
  name: {
    minLength: 2,
    maxLength: 50,
    regex: /^[\w\s&,.():\-'"/]{2,50}$/,
  },
};

const validateSubsetToken = (
  subsetToken: ISubsetToken,
  propsToValidate = ["name"],
  extraConditionKeysToExclude: string[] = []
) => {
  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    subsetToken,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateSubsetToken;
