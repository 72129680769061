// ---------------------------------------------- modules import
import axios from "axios";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import voca from "voca";

import { AssessmentContext } from "../../../../contexts/assessment/context";
import { ModuleContext } from "../../../../contexts/module/context";
import { SessionContext } from "../../../../contexts/session/context";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import * as ROUTES from "../../../../constants/routes";
import { newCreateModuleDto } from "../../../../dtos/module";
import {
  EducationSubLevelEnum,
  EducationLevelEnum,
} from "../../../../enums/education_level";
import { emptyModule, IModule } from "../../../../models/module";
import validateModule from "../../../../validations/module";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";

// ---------------------------------------------- the hooks
export const useAddModuleForm = () => {
  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- consume context
  const {
    assessments,
    error: errAssessment,
    fetching: fetchingAssessment,
  } = useContext(AssessmentContext);
  const {
    participantGroup,
    error: errModule,
    fetching: fetchingModule,
  } = useContext(ModuleContext);
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [module, setModule] = useState<IModule>(emptyModule());
  const [tokenNumber, setTokenNumber] = useState(1);
  const [tokenNames, setTokenNames] = useState<string[]>([""]);

  const [educationSubLevels, setEducationSubLevels] = useState<
    EducationSubLevelEnum[]
  >([EducationSubLevelEnum.OTHERS]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [errRequest, setErrRequest] = useState<string | null>(null);

  const isModuleValid = () => validateModule(module);

  const isTokenNamesValid = () =>
    new Set(tokenNames).size === tokenNames.length;

  const educationLevelOptions = Object.values(EducationLevelEnum).map(
    (value) => ({
      value,
      label: value,
    })
  );

  const educationSubLevelOptions = educationSubLevels.map((value) => ({
    value,
    label: value,
  }));

  // ---------------------------------------------- handlers
  const handleChange = (prop: string, value: string) => {
    if (prop === "education.level") {
      let subLevel = EducationSubLevelEnum.OTHERS;

      switch (value) {
        case EducationLevelEnum.ELEMENTARY: {
          subLevel = EducationSubLevelEnum.I;
          break;
        }
        case EducationLevelEnum.JUNIOR_HIGH: {
          subLevel = EducationSubLevelEnum.VII;
          break;
        }
        case EducationLevelEnum.SENIOR_HIGH: {
          subLevel = EducationSubLevelEnum.X;
          break;
        }
        case EducationLevelEnum.UNIVERSITY: {
          subLevel = EducationSubLevelEnum.S1;
          break;
        }
        case EducationLevelEnum.EMPLOYEE: {
          subLevel = EducationSubLevelEnum.ENTRY_LEVEL;
          break;
        }
        default:
          break;
      }

      setModule({
        ...module,
        education: {
          ...module.education,
          level: value as EducationLevelEnum,
          subLevel,
        },
      });
    } else if (prop === "education.subLevel") {
      setModule({
        ...module,
        education: {
          ...module.education,
          subLevel: value as EducationSubLevelEnum,
        },
      });
    } else if (prop === "tokenNumber") {
      const newTokenNumber = parseFloat(value);

      if (newTokenNumber >= 1) {
        setTokenNumber(newTokenNumber);

        if (tokenNames.length > newTokenNumber)
          setTokenNames([...tokenNames].slice(0, newTokenNumber));

        if (tokenNames.length < newTokenNumber) {
          const newTokenNames = Array.from(
            { length: newTokenNumber - tokenNames.length },
            () => ""
          );

          setTokenNames([...tokenNames, ...newTokenNames]);
        }
      }
    } else {
      setModule({
        ...module,
        [prop]:
          prop === "assessmentIds"
            ? module.assessmentIds.includes(value as AssessmentIdEnum)
              ? [...module.assessmentIds].filter((id) => id !== value)
              : [...module.assessmentIds, value]
            : value,
      });
    }
  };

  const handleChangeTokenName = (index: string, value: string) => {
    setTokenNames(
      tokenNames.map((tokenName, i) =>
        i === parseInt(index) ? value : tokenName
      )
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const url = `${proxy}${API_ROUTES.MODULE}`;

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setLoading(true);

        axios
          .post(
            url,
            newCreateModuleDto(
              {
                ...module,
                name: voca.trim(module.name),
              },
              participantGroup.id,
              tokenNames
            ),
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            }
          )
          .then(() => {
            setLoading(false);
            setErrRequest(null);

            navigate(
              `${ROUTES.PARTICIPANT_GROUP}/details/${participantGroup.id}`,
              { replace: true }
            );

            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    switch (module.education.level) {
      case EducationLevelEnum.ELEMENTARY: {
        setEducationSubLevels([
          EducationSubLevelEnum.I,
          EducationSubLevelEnum.II,
          EducationSubLevelEnum.III,
          EducationSubLevelEnum.IV,
          EducationSubLevelEnum.V,
          EducationSubLevelEnum.VI,
        ]);
        break;
      }
      case EducationLevelEnum.JUNIOR_HIGH: {
        setEducationSubLevels([
          EducationSubLevelEnum.VII,
          EducationSubLevelEnum.VIII,
          EducationSubLevelEnum.IX,
        ]);
        break;
      }
      case EducationLevelEnum.SENIOR_HIGH: {
        setEducationSubLevels([
          EducationSubLevelEnum.X,
          EducationSubLevelEnum.XI,
          EducationSubLevelEnum.XII,
        ]);
        break;
      }
      case EducationLevelEnum.UNIVERSITY: {
        setEducationSubLevels([
          EducationSubLevelEnum.D3,
          EducationSubLevelEnum.S1,
          EducationSubLevelEnum.S2,
          EducationSubLevelEnum.S3,
        ]);
        break;
      }
      case EducationLevelEnum.EMPLOYEE: {
        setEducationSubLevels([
          EducationSubLevelEnum.ENTRY_LEVEL,
          EducationSubLevelEnum.MID_LEVEL,
          EducationSubLevelEnum.SENIOR_LEVEL,
          EducationSubLevelEnum.MANAGER,
          EducationSubLevelEnum.DIRECTOR,
          EducationSubLevelEnum.EXECUTIVE,
        ]);
        break;
      }
      default: {
        setEducationSubLevels([EducationSubLevelEnum.OTHERS]);
        break;
      }
    }
  }, [module]);

  useEffect(
    () => setError(errAssessment || errModule || errRequest || errSession),
    [errAssessment, errModule, errRequest, errSession]
  );

  // ---------------------------------------------- return value
  return {
    assessments,
    participantGroup,
    module,
    tokenNumber,
    tokenNames,
    educationLevelOptions,
    educationSubLevelOptions,
    error,
    fetching: fetchingAssessment || fetchingModule || fetchingSession,
    loading,
    isModuleValid,
    isTokenNamesValid,
    onChange: handleChange,
    onChangeTokenName: handleChangeTokenName,
    onSubmit: handleSubmit,
  };
};
