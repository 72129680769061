// ---------------------------------------------- modules import
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SessionContext } from "../../../../contexts/session/context";
import { WorksheetContext } from "../../../../contexts/worksheet/context";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import * as ROUTES from "../../../../constants/routes";

// ---------------------------------------------- the hooks
export const useDetails = () => {
  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- consume context
  const {
    participantGroup,
    module,
    subsetToken,
    worksheet,
    error: errWorksheet,
    fetching: fetchingWorksheet,
  } = useContext(WorksheetContext);
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [errRequest, setErrRequest] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // ---------------------------------------------- handlers
  const handleDelete = () => {
    const url = `${proxy}${API_ROUTES.WORKSHEET}`;

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setError(null);
        setLoading(true);

        axios
          .delete(
            `${url}/${worksheet.id}?participant_group_id=${participantGroup.id}&module_id=${module.id}&subset_token_id=${subsetToken.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setLoading(false);
            setErrRequest(null);

            navigate(`${ROUTES.REPORT}${ROUTES.WORKSHEET}`, { replace: true });
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(
    () => setError(errRequest || errSession || errWorksheet),
    [errRequest, errSession, errWorksheet]
  );

  // ---------------------------------------------- return value
  return {
    worksheet,
    error,
    fetching: fetchingSession || fetchingWorksheet,
    loading,
    onDelete: handleDelete,
  };
};
