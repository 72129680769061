// ---------------------------------------------- modules import
import {
  Page,
  StyleSheet,
  Text,
  View,
  Image,
  Svg,
  Rect,
} from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-kepribadian.png";

import { IResultPagePersonalityProps } from "./common";
import { IConclusion_Personality } from "../conclusion/personality/conclusion";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";
import { AttributeIdEnum_Personality } from "../../../../enums/attribute_id";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    fontWeight: "ultrabold",
    marginBottom: 10,
    color: "#3b1c78",
  },
  description: {
    marginBottom: 10,
    textAlign: "justify",
    fontSize: 9,
  },
  table: {
    display: "flex",
    width: "100%",
    borderColor: "#000000",
    borderCollapse: "collapse",
  },
  tableFrequency: {
    display: "flex",
    width: "50%",
    borderColor: "#000000",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 40,
    textAlign: "center",
  },
  tableHeaderCellResult: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 50,
    textAlign: "center",
  },
  tableCell: {
    fontSize: 8,
    padding: 5,
    width: 40,
    textAlign: "center",
  },
  tableCellResult: {
    fontSize: 9,
    padding: 5,
    width: 50,
    textAlign: "center",
  },
  tableHeaderCellName: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 130,
  },
  tableHeaderCellPhone: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 70,
  },
  tableCellName: {
    fontSize: 9,
    padding: 5,
    width: 130,
  },
  tableCellPhone: {
    fontSize: 9,
    padding: 5,
    width: 70,
  },
  tableHeaderCellNo: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  tableCellNo: {
    fontSize: 9,
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  evenRow: {
    backgroundColor: "#D9E1F2",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  chart: {
    width: "50%",
    marginTop: 10,
    alignSelf: "center",
  },
  bar: {
    height: 20,
    marginBottom: 5,
  },
  barLabel: {
    position: "absolute",
    right: "100%", // Memindahkan label ke sebelah kiri bar
    top: 3,
    fontSize: 10,
    color: "#000",
    marginRight: 2,
    textAlign: "right",
    width: 50,
  },
  barValue: {
    position: "absolute",
    right: 5,
    top: 3,
    fontSize: 10,
    color: "#000",
  },
  personalityDescription: {
    marginBottom: 20,
  },
  subheading: {
    fontSize: 12,
    fontWeight: "heavy",
    marginTop: 10,
    marginBottom: 5,
  },
  list: {
    marginLeft: 10,
  },
  listItem: {
    fontSize: 9,
    marginBottom: 2,
  },
});
// ---------------------------------------------- the component
const ResultPagePersonality: FunctionComponent<IResultPagePersonalityProps> = ({
  worksheets,
}) => {
  // Hitung frekuensi tipe kepribadian dan simpan informasi tambahan
  const personalityInfo: {
    [key: string]: IConclusion_Personality & { count: number };
  } = {};
  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.PERSONALITY
    );
    if (questionnaire) {
      const conclusion = JSON.parse(
        questionnaire.result.conclusion
      ) as IConclusion_Personality;
      if (!personalityInfo[conclusion.name]) {
        personalityInfo[conclusion.name] = { ...conclusion, count: 0 };
      }
      personalityInfo[conclusion.name].count++;
    }
  });

  // Urutkan tipe kepribadian berdasarkan frekuensi
  const sortedPersonalities = Object.values(personalityInfo)
    .sort((a, b) => b.count - a.count)
    .map((info) => ({
      ...info,
      percentage: ((info.count / worksheets.length) * 100).toFixed(0) + "%",
    }));

  // Ambil 10 tipe kepribadian terbesar
  const top10Personalities = sortedPersonalities.slice(0, 10);

  // Urutkan personalities berdasarkan count (jumlah peserta) secara descending
  const sortedPersonalitiesByCount = sortedPersonalities.sort(
    (a, b) => b.count - a.count
  );

  // Ambil nilai persentase tertinggi sebagai lebar maksimal bar
  const maxPercentage =
    sortedPersonalitiesByCount.length > 0
      ? parseFloat(sortedPersonalitiesByCount[0].percentage)
      : 100;

  const getBarColor = (index: number) => {
    const colors = [
      "#4472C4",
      "#ED7D31",
      "#A5A5A5",
      "#FFC000",
      "#5B9BD5",
      "#70AD47",
      "#264478",
      "#9E480E",
    ];
    return colors[index % colors.length];
  };

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableHeaderCellNo}>No.</Text>
            <Text style={styles.tableHeaderCellName}>Nama</Text>
            <Text style={styles.tableHeaderCellName}>Kode Unik</Text>
            <Text style={styles.tableHeaderCell}>E</Text>
            <Text style={styles.tableHeaderCell}>I</Text>
            <Text style={styles.tableHeaderCell}>S</Text>
            <Text style={styles.tableHeaderCell}>N</Text>
            <Text style={styles.tableHeaderCell}>T</Text>
            <Text style={styles.tableHeaderCell}>F</Text>
            <Text style={styles.tableHeaderCell}>J</Text>
            <Text style={styles.tableHeaderCell}>P</Text>
            <Text style={styles.tableHeaderCellResult}>Hasil</Text>
          </View>

          {worksheets &&
            worksheets.map((worksheet, index) => {
              const { profile, questionnaires } = worksheet;

              const questionnaire = questionnaires.find(
                (questionnaire) =>
                  questionnaire.assessment.id === AssessmentIdEnum.PERSONALITY
              );

              if (!questionnaire) return null;

              const { result } = questionnaire;

              const conclusion = JSON.parse(
                result.conclusion
              ) as IConclusion_Personality;

              let e = "-1";
              let i = "-1";
              let s = "-1";
              let n = "-1";
              let t = "-1";
              let f = "-1";
              let j = "-1";
              let p = "-1";

              result.attributes.forEach((attribute) => {
                switch (attribute.id) {
                  case AttributeIdEnum_Personality.E: {
                    e = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Personality.I: {
                    i = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Personality.S: {
                    s = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Personality.N: {
                    n = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Personality.T: {
                    t = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Personality.F: {
                    f = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Personality.J: {
                    j = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Personality.P: {
                    p = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  default: {
                    return;
                  }
                }
              });

              return (
                <View
                  key={worksheet.id}
                  style={[
                    styles.tableRow,
                    index % 2 === 0 ? styles.evenRow : styles.oddRow,
                  ]}
                >
                  <Text style={styles.tableCellNo}>{index + 1}</Text>
                  <Text style={styles.tableCellName} wrap={false}>
                    {profile.name}
                  </Text>
                  <Text style={styles.tableCellName}>{profile.phone}</Text>
                  <Text style={styles.tableCell}>{e}%</Text>
                  <Text style={styles.tableCell}>{i}%</Text>
                  <Text style={styles.tableCell}>{s}%</Text>
                  <Text style={styles.tableCell}>{n}%</Text>
                  <Text style={styles.tableCell}>{t}%</Text>
                  <Text style={styles.tableCell}>{f}%</Text>
                  <Text style={styles.tableCell}>{j}%</Text>
                  <Text style={styles.tableCell}>{p}%</Text>
                  <Text style={styles.tableCellResult}>{conclusion.name}</Text>
                </View>
              );
            })}
        </View>

        <View style={styles.section}>
          <Text style={styles.heading}>Distribusi Frekuensi</Text>
          <View style={styles.tableFrequency}>
            <View style={styles.tableRow}>
              <Text style={styles.tableHeaderCellName}>Tipe Kepribadian</Text>
              <Text style={styles.tableHeaderCellPhone}>Jumlah Siswa</Text>
              <Text style={styles.tableHeaderCellPhone}>Persentase</Text>
            </View>
            {sortedPersonalities.map((personality, index) => (
              <View
                key={personality.name}
                style={[
                  styles.tableRow,
                  index % 2 === 0 ? styles.evenRow : styles.oddRow,
                ]}
              >
                <Text style={[styles.tableCellName, { textAlign: "center" }]}>
                  {personality.name}
                </Text>
                <Text style={[styles.tableCellPhone, { textAlign: "center" }]}>
                  {personality.count}
                </Text>
                <Text style={[styles.tableCellPhone, { textAlign: "center" }]}>
                  {personality.percentage}
                </Text>
              </View>
            ))}
            <View style={[styles.tableRow, styles.evenRow]}>
              <Text style={[styles.tableCellName, { textAlign: "center" }]}>
                Total
              </Text>
              <Text style={[styles.tableCellPhone, { textAlign: "center" }]}>
                {worksheets.length}
              </Text>
              <Text style={[styles.tableCellPhone, { textAlign: "center" }]}>
                100%
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <Text style={styles.heading}>Grafik Distribusi Frekuensi</Text>
          <View style={styles.chart}>
            {sortedPersonalitiesByCount.map((personality, index) => (
              <View key={personality.name} style={styles.bar}>
                <Text style={styles.barLabel}>{personality.name}</Text>
                <Svg width="200%" height="20">
                  <Rect
                    x="0"
                    y="0"
                    width={`${
                      (parseFloat(personality.percentage) / maxPercentage) * 100
                    }%`}
                    height="20"
                    fill={getBarColor(index)}
                  />
                </Svg>
                <Text style={styles.barValue}>{personality.percentage}</Text>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.heading}>Deskripsi Tipe Kepribadian</Text>
          {top10Personalities.map((personality, index) => (
            <View key={personality.name} style={styles.personalityDescription}>
              <Text style={styles.subheading}>
                {personality.name} - {personality.personality}
              </Text>
              <Text style={styles.description}>{personality.description}</Text>
              <Text style={styles.subheading}>Karakter yang Kuat:</Text>
              <View style={styles.list}>
                {personality.traits.map((trait, i) => (
                  <Text key={i} style={styles.listItem}>
                    • {trait}
                  </Text>
                ))}
              </View>
              <Text style={styles.subheading}>Perlu Pengembangan:</Text>
              <View style={styles.list}>
                {personality.suggestedGrowth.map((growth, i) => (
                  <Text key={i} style={styles.listItem}>
                    • {growth}
                  </Text>
                ))}
              </View>
              <Text style={styles.subheading}>Rekomendasi Karier:</Text>
              <Text style={styles.description}>
                {personality.careerRecommendations.join(", ")}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  );
};

export default ResultPagePersonality;
