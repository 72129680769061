// ---------------------------------------------- modules import
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { ILinkButtonProps } from "./common";

// ---------------------------------------------- the component
const LinkButton: FunctionComponent<ILinkButtonProps> = ({
  children,
  className,
  style,
  to,
  valid = true,
  onClick,
}) => {
  const validClassname = "";
  const invalidClassname = "";

  // const validClassname =
  //   "text-white focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-blue-800";
  // const invalidClassname =
  //   "text-white bg-blue-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center";

  // ---------------------------------------------- content
  return (
    <Link
      to={to}
      className={`${className || ""} ${
        valid ? validClassname : invalidClassname
      }`}
      // className={valid ? validClassname : invalidClassname}
      onClick={onClick}
      style={{ ...style }}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
