// ---------------------------------------------- modules import
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import voca from "voca";

import { SessionContext } from "../../../../contexts/session/context";
import { SubsetTokenContext } from "../../../../contexts/subset_token/context";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import { newUpdateSubsetTokenDto } from "../../../../dtos/subset_token";
import { ISubsetToken } from "../../../../models/subset_token";

// ---------------------------------------------- the hooks
export const useSubsetTokenList = () => {
  // ---------------------------------------------- consume context
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);
  const {
    subsetTokens,
    module,
    participantGroup,
    error: errSubsetToken,
    fetching: fetchingSubsetToken,
  } = useContext(SubsetTokenContext);

  // ---------------------------------------------- local state
  const [copiedId, setCopiedId] = useState("");

  const [errRequest, setErrRequest] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // ---------------------------------------------- handlers
  const handleCopyId = (id: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(id)
        .then(() => {
          setCopiedId(id);
        })
        .catch((error) => {
          console.error("Failed to copy: ", error);
        });
    } else {
      const el = document.createElement("textarea");
      el.value = id;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);

      const selected = document.getSelection();
      let range: Range | null = null;

      if (selected) {
        const rangeCount = selected.rangeCount;
        range = rangeCount > 0 ? selected.getRangeAt(0) : null;
      }

      el.select();

      document.execCommand("copy");
      document.body.removeChild(el);

      if (selected && range) {
        selected.removeAllRanges();
        selected.addRange(range);
      }

      setCopiedId(id);
    }
  };

  const handleToggleStatus = (subsetToken: ISubsetToken) => {
    const url = `${proxy}${API_ROUTES.SUBSET_TOKEN}`;

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setLoading(true);

        axios
          .put(
            `${url}/${subsetToken.id}?participant_group_id=${participantGroup.id}&module_id=${module.id}`,
            newUpdateSubsetTokenDto({
              ...subsetToken,
              name: voca.trim(subsetToken.name),
              status: !subsetToken.status,
            }),
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            }
          )
          .then(() => {
            setLoading(false);
            setErrRequest(null);
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(
    () => setError(errRequest || errSession || errSubsetToken),
    [errRequest, errSession, errSubsetToken]
  );

  // ---------------------------------------------- content
  return {
    copiedId,
    error,
    fetching: fetchingSession || fetchingSubsetToken,
    loading,
    subsetTokens,
    onCopyId: handleCopyId,
    onToggleStatus: handleToggleStatus,
  };
};
