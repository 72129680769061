// ---------------------------------------------- modules import
import {
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Svg,
  Path,
} from "@react-pdf/renderer";
import { Fragment, FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-gayabelajar.png";

import { IResultPageGayaBelajarProps } from "./common";
import { IConclusion_GayaBelajar } from "../conclusion/gaya_belajar/conclusion";
import { colorTheme } from "../../../../constants/color_theme";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  section: {
    marginBottom: 8,
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
    width: "100%",
  },
  logo: {
    justifyContent: "center",
    width: 100,
  },
  headingPage: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
  },
  heading: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 18,
    marginBottom: 8,
  },
  attribute: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  attributeName: {
    fontSize: 14,
  },
  dominantAttribute: {
    marginTop: 20,
  },
  dominantName: {
    color: colorTheme.YELLOW_LIGHT,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  description: {
    fontSize: 9,
    marginBottom: 5,
  },
  traits: {
    fontSize: 9,
    marginBottom: 5,
  },
  learningStrategies: {
    fontSize: 9,
    marginBottom: 5,
  },
  bulletPoint: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 4,
  },
  bulletText: {
    fontSize: 9,
    marginRight: 4,
  },
  text: {
    marginBottom: 2,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    color: colorTheme.YELLOW_LIGHT,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
  },
  attributeValue: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    textAlign: "right",
  },
  credit: {
    bottom: 30,
    color: colorTheme.GRAY_DOFF,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },

  pieChartContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  pieChart: {
    width: 200,
    height: 200,
    alignSelf: "center",
  },
  chartLabel: {
    position: "absolute",
    fontSize: 12,
    textAlign: "center",
    width: "100%",
    color: "black", // Contoh: menetapkan warna teks
    fontFamily: "Helvetica", // Contoh: menetapkan jenis font
  },
});

// Fungsi untuk menghitung path dari doughnut chart
const describeArc = (
  x: number,
  y: number,
  outerRadius: number,
  innerRadius: number,
  startAngle: number,
  endAngle: number
) => {
  const startOuter = polarToCartesian(x, y, outerRadius, endAngle);
  const endOuter = polarToCartesian(x, y, outerRadius, startAngle);
  const startInner = polarToCartesian(x, y, innerRadius, endAngle);
  const endInner = polarToCartesian(x, y, innerRadius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  const d = [
    "M",
    startOuter.x,
    startOuter.y,
    "A",
    outerRadius,
    outerRadius,
    0,
    largeArcFlag,
    0,
    endOuter.x,
    endOuter.y,
    "L",
    endInner.x,
    endInner.y,
    "A",
    innerRadius,
    innerRadius,
    0,
    largeArcFlag,
    1,
    startInner.x,
    startInner.y,
    "Z",
  ].join(" ");

  return d;
};

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

// Fungsi untuk menghitung posisi tengah dari arc untuk label
const labelPosition = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  const middleAngle = (startAngle + endAngle) / 2;
  const innerRadius = 50;
  const labelRadius = radius - (radius - innerRadius) / 2; // Posisi label di tengah antara inner dan outer radius
  return polarToCartesian(x, y, labelRadius, middleAngle);
};

// Array warna untuk atribut
const colors = ["#8A2BE2", "#4682B4", "#D2691E", "#FF6347", "#20B2AA"];

// ---------------------------------------------- the component
const ResultPageGayaBelajar: FunctionComponent<IResultPageGayaBelajarProps> = ({
  questionnaire,
}) => {
  const { result } = questionnaire;

  // Menggunakan array warna untuk setiap atribut
  const data = result.attributes.map((attribute, index) => ({
    name: attribute.name,
    value: attribute.value * 100, // Mengubah skala nilai ke persentase
    color: colors[index % colors.length], // Menggunakan warna dari array, berulang jika lebih dari jumlah warna
  }));

  let cumulativePercent = 0;
  const outerRadius = 100;
  const innerRadius = 40;
  const svgSize = 300; // Ukuran SVG diperbesar untuk menghindari pemotongan teks

  const conclusion = JSON.parse(result.conclusion) as IConclusion_GayaBelajar;

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.logoContainer}></View>

      <View style={styles.section}>
        <Text style={styles.headingPage}></Text>

        <View style={styles.attributeTable}>
          {/* {result.attributes.map((attribute) => (
              <View key={attribute.id} style={styles.attributeRow}>
                <Text style={styles.attributeDescription}>{attribute.name}</Text>
  
                <Text style={styles.attributeValue}>
                  {(attribute.value * 100).toFixed(0)}
                </Text>
              </View>
            ))} */}

          <View style={styles.pieChartContainer}>
            <Svg
              style={{ width: svgSize, height: svgSize, alignSelf: "center" }}
            >
              {data.map((slice, index) => {
                const [startAngle, endAngle] = [
                  Math.round(cumulativePercent * 360),
                  Math.round((cumulativePercent + slice.value / 100) * 360),
                ];
                cumulativePercent += slice.value / 100;
                const { x, y } = labelPosition(
                  150,
                  150,
                  outerRadius,
                  startAngle,
                  endAngle
                );
                return (
                  <Fragment key={index}>
                    <Path
                      d={describeArc(
                        150,
                        150,
                        outerRadius,
                        innerRadius,
                        startAngle,
                        endAngle
                      )}
                      fill={slice.color}
                    />
                    <Text x={x} y={y} style={styles.chartLabel}>
                      {`${slice.name} (${Math.round(slice.value)}%)`}
                    </Text>
                  </Fragment>
                );
              })}
            </Svg>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.heading}>Tipe Gaya Belajar</Text>

        <Text style={styles.dominantName}>{conclusion.name}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.heading}>Deskripsi</Text>

        <Text style={styles.description}>
          {conclusion.descriptions.join(" ")}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.heading}>Ciri Gaya Belajar</Text>

        <View style={styles.traits}>
          {conclusion.traits.map((trait, index) => (
            <View key={index} style={styles.bulletPoint}>
              <Text style={styles.bulletText}>•</Text>

              <Text style={styles.text}>{trait}</Text>
            </View>
          ))}
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.heading}>
          Strategi Belajar Yang Dapat Diterapkan
        </Text>

        <View style={styles.learningStrategies}>
          {conclusion.learningStrategies.map((strategy, index) => (
            <View key={index} style={styles.bulletPoint}>
              <Text style={styles.bulletText}>•</Text>

              <Text style={styles.text}>{strategy}</Text>
            </View>
          ))}
        </View>
      </View>

      <Image src={BgPage} style={styles.backgroundImage} fixed />
    </Page>
  );
};

export default ResultPageGayaBelajar;
