// ---------------------------------------------- modules import
import { faBars, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { useSidebar } from "./hooks/use_sidebar";

// ---------------------------------------------- the component
const Sidebar: FunctionComponent = () => {
  // ---------------------------------------------- local state
  const {
    isSidebarOpen,
    menuItems,
    profile,
    onToggleSidebar,
    onClickMenu,
    onLogout,
  } = useSidebar();

  // ---------------------------------------------- content
  return profile ? (
    <>
      <button
        type="button"
        className="fixed z-50 inline-flex items-center p-2 mt-2 ml-3 text-sm rounded-lg text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600"
        onClick={onToggleSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <FontAwesomeIcon className="text-2xl" icon={faBars} />
      </button>

      {isSidebarOpen && (
        <>
          <div
            className="fixed top-0 left-0 z-40 w-full h-full bg-black bg-opacity-50"
            onClick={onToggleSidebar}
          ></div>
          <aside
            className="fixed top-0 left-0 z-50 w-64 h-screen bg-gray-900 transform translate-x-0 shadow-lg"
            aria-label="Sidebar"
          >
            <div className="h-full px-4 py-6 overflow-y-auto">
              <div className="flex items-center justify-between mb-6">
                <h1 className="text-3xl font-bold text-yellow-400">GANESA</h1>
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-200 focus:outline-none"
                  onClick={onToggleSidebar}
                >
                  <FontAwesomeIcon className="text-xl" icon={faBars} />
                </button>
              </div>
              <hr className="border-gray-600 mb-6" />
              <ul className="space-y-4">
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.route}
                      className="flex items-center p-3 rounded-lg text-white hover:bg-gray-700 transition-colors"
                      onClick={onClickMenu}
                    >
                      <span className="text-lg">{item.title}</span>
                    </Link>
                  </li>
                ))}
                <li>
                  <button
                    className="flex items-center p-3 w-full rounded-lg text-white hover:bg-gray-700 transition-colors"
                    onClick={onLogout}
                  >
                    <FontAwesomeIcon
                      className="mr-3 text-lg"
                      icon={faSignOutAlt}
                    />
                    <span className="text-lg">Sign Out</span>
                  </button>
                </li>
              </ul>
            </div>
          </aside>
        </>
      )}
    </>
  ) : null;
};

export default Sidebar;
