// ---------------------------------------------- modules import
import { WhereFilterOp } from "firebase/firestore";
import { FunctionComponent, createContext } from "react";

import {
  IConsolidatedWorksheetContextProviderState,
  IConsolidatedWorksheetContextProviderProps,
} from "./common";
import { useConsolidatedWorksheetContext } from "./hooks";

// ---------------------------------------------- create context
const initialState: IConsolidatedWorksheetContextProviderState = {
  consolidatedWorksheets: [],
  limit: 1,
  error: null,
  fetching: true,
  hasMore: true,
  onChangeLimit: (limit: number) => {
    return;
  },
  onNextPage: () => {
    return;
  },
  onFirstPage: () => {
    return;
  },
  onChangeFilters: (fieldPath: string, opStr: WhereFilterOp, value: any) => {
    return;
  },
};

export const ConsolidatedWorksheetContext = createContext(initialState);

// ---------------------------------------------- the component
const ConsolidatedWorksheetContextProvider: FunctionComponent<
  IConsolidatedWorksheetContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const {
    consolidatedWorksheets,
    limit,
    error,
    fetching,
    hasMore,
    onChangeLimit,
    onNextPage,
    onFirstPage,
    onChangeFilters,
  } = useConsolidatedWorksheetContext();

  // ---------------------------------------------- content
  return (
    <ConsolidatedWorksheetContext.Provider
      value={{
        consolidatedWorksheets,
        limit,
        error,
        fetching,
        hasMore,
        onChangeLimit,
        onNextPage,
        onFirstPage,
        onChangeFilters,
      }}
    >
      {children}
    </ConsolidatedWorksheetContext.Provider>
  );
};

export default ConsolidatedWorksheetContextProvider;
