// ---------------------------------------------- modules import
import { DocumentData, WhereFilterOp } from "firebase/firestore";
import { useCallback, useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import {
  IConsolidatedWorksheet,
  IConsolidatedWorksheetRecord,
} from "../../models/consolidated_worksheet";
import { IFilter } from "./common";

// ---------------------------------------------- the hooks
export const useConsolidatedWorksheetContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [consolidatedWorksheets, setConsolidatedWorksheets] = useState<
    IConsolidatedWorksheet[]
  >([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [limit, setLimit] = useState(150);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<IFilter[]>([]);

  const fetchData = useCallback(
    (startAfter: DocumentData | null = null) => {
      setFetching(true);

      let ref = firebase.firestore
        .collection(PATHS.CONSOLIDATED_WORKSHEET)
        .orderBy("dateCreated", "desc")
        .limit(limit);

      filters.forEach((filter) => {
        ref = ref.where(filter.fieldPath, filter.opStr, filter.value);
      });

      if (startAfter) ref = ref.startAfter(startAfter);

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          if (querySnapshot.size < limit) setHasMore(false);
          else setHasMore(true);

          const newWorksheets: IConsolidatedWorksheet[] =
            querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...(doc.data() as IConsolidatedWorksheetRecord),
            }));

          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

          setConsolidatedWorksheets([...newWorksheets]);

          firebase.firestore.collection(PATHS.CONSOLIDATED_WORKSHEET);

          setError(null);
          setFetching(false);
        },
        (error) => {
          console.log("error = ", error);
          console.log("error message = ", error.message);

          setError(error.message);
          setFetching(false);
        }
      );

      return unsubscribe;
    },
    [firebase, limit, filters]
  );

  // ---------------------------------------------- handlers
  const handleChangeLimit = (limit: number) => {
    if (limit) setLimit(limit);
  };

  const handleNextPage = () => {
    if (hasMore && !fetching) {
      setCurrentPage((prevPage) => prevPage + 1);
      fetchData(lastVisible);
    }
  };

  const handleFirstPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setConsolidatedWorksheets([]);
      setLastVisible(null);
      fetchData();
    }
  };

  const handleChangeFilters = (
    fieldPath: string,
    opStr: WhereFilterOp,
    value: any
  ) => {
    const found = filters.find((filter) => filter.fieldPath === fieldPath);

    if (found) {
      const filtered = filters.filter(
        (filter) => filter.fieldPath !== fieldPath
      );
      setFilters([...filtered, { fieldPath, opStr, value }]);
    } else setFilters([...filters, { fieldPath, opStr, value }]);

    switch (fieldPath) {
      case "status.devRoyaltyFee": {
        const filtered = [...filters].filter(
          (filter) => filter.fieldPath !== fieldPath
        );

        if (value === "all") setFilters([...filtered]);
        else
          setFilters([
            ...filtered,
            {
              fieldPath,
              opStr,
              value:
                value === "settled"
                  ? true
                  : value === "review"
                  ? "review"
                  : value === "pending"
                  ? "pending"
                  : false,
            },
          ]);

        break;
      }
      case "worksheet.participantGroup.id": {
        const filtered = [...filters].filter(
          (filter) => filter.fieldPath !== fieldPath
        );

        if (value === "all") setFilters([...filtered]);
        else setFilters([...filtered, { fieldPath, opStr, value }]);

        break;
      }
      default:
        break;
    }
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    const unsubscribeFetchData = fetchData();

    return () => unsubscribeFetchData();
  }, [firebase, limit, filters, fetchData]);

  // ---------------------------------------------- return value
  return {
    consolidatedWorksheets,
    limit,
    error,
    fetching,
    hasMore,
    onChangeLimit: handleChangeLimit,
    onNextPage: handleNextPage,
    onFirstPage: handleFirstPage,
    onChangeFilters: handleChangeFilters,
  };
};
