// ---------------------------------------------- document interfaces
/**
 * Represents the data fields of a regency document in Firestore.
 */
export interface IRegencyRecord {
  altName: string;
  latitude: number;
  longitude: number;
  name: string;
}

/**
 * Represents a regency returned to the frontend from Firestore.
 *
 * Extends the IRegencyRecord interface.
 */
export interface IRegency extends IRegencyRecord {
  id: string;
  [key: string]: any;
}

// ---------------------------------------------- empty document
export const emptyRegencyRecord = (): IRegencyRecord => ({
  altName: "",
  latitude: 0,
  longitude: 0,
  name: "",
});

export const emptyRegency = (): IRegency => ({
  ...emptyRegencyRecord(),
  id: "",
});
