// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import TagListItem from "./tag_list_item";

import { ITagListProps } from "./common";
import { useTagList } from "./hooks/use_tag_list";

// ---------------------------------------------- the component
const TagList: FunctionComponent<ITagListProps> = ({ isAuthorizedToWrite }) => {
  // ---------------------------------------------- local state
  const {
    // error,
    // fetching,
    // loading,
    tags,
    onChangeTag,
  } = useTagList();

  // ---------------------------------------------- content
  return (
    <div>
      <h2 className="font-bold text-xl mb-4">Tags</h2>
      {tags.length > 0 ? (
        <div className="space-y-4">
          {tags.map((tag) => (
            <TagListItem key={tag.id} tag={tag} onChangeTag={onChangeTag} />
          ))}
        </div>
      ) : (
        <p className="text-gray-400">
          Looks like there are no tags to display right now.
        </p>
      )}
    </div>
  );
};

export default TagList;
