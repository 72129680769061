// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faFilePdf,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FunctionComponent } from "react";
import voca from "voca";

import DetailsGayaBelajar from "./details/details_gaya_belajar";
import DetailsKebiasaanBelajar from "./details/details_kebiasaan_belajar";
import DetailsKecerdasanMajemuk from "./details/details_kecerdasan_majemuk";
import DetailsKesejahteraanPsikologiDanEmosional from "./details/details_kesejahteraan_psikologi_dan_emosional";
import DetailsKondisiKeluarga from "./details/details_kondisi_keluarga";
import DetailsPersonality from "./details/details_personality";
import DetailsRiasec from "./details/details_riasec";
import PdfPersonalReport from "./pdf_personal_report/pdf_personal_report";
import Button from "../../../components/ui/buttons/button";
import LinkButton from "../../../components/ui/buttons/link_button";
import DeleteModal from "../../../components/ui/modals/delete_modal";
import StatusBadge from "../../../components/ui/others/status_badge";

//v2
import DetailsAdversityQuotient from "./details/details_adversity_quotient";
import DetailsCfit from "./details/details_cfit";
import DetailsColouredProgressiveMatrices from "./details/details_coloured_progressive_matrices";
import DetailsDisc from "./details/details_disc";
import DetailsGayaBelajarKelas1Sampai3 from "./details/details_gaya_belajar_sd_kelas_1_sampai_3";
import DetailsGayaBelajarV2 from "./details/details_gaya_belajar_v2";
import DetailsKebiasaanBelajarV2 from "./details/details_kebiasaan_belajar_v2";
import DetailsKecerdasanMajemukV2 from "./details/details_kecerdasan_majemuk_v2";
import DetailsKesejahteraanPsikologiDanEmosionalV2 from "./details/details_kesejahteraan_psikologi_dan_emosional_v2";
import DetailsKondisiKeluargaV2 from "./details/details_kondisi_keluarga_v2";
import DetailsPersonalityV2 from "./details/details_personality_v2";
import DetailsRiasecV2 from "./details/details_riasec_v2";
import DetailsSpm from "./details/details_spm";

import { IDetailsProps } from "./common";
import { useDetails } from "./hooks/use_details";
import { AssessmentIdEnum } from "../../../enums/assessment_id";
import * as ROUTES from "../../../constants/routes";

// ---------------------------------------------- the component
const Details: FunctionComponent<IDetailsProps> = ({ isAuthorizedToWrite }) => {
  // ---------------------------------------------- local state
  const { worksheet, error, fetching, loading, onDelete } = useDetails();

  if (!worksheet) return null;

  const { module, profile, version } = worksheet;

  const profileName = voca.titleCase(profile.name);
  const replacedProfileName = profileName.replace(/\./g, " ");

  const educationLevel = version === "2" ? module.education?.level : "";
  const educationSubLevel = version === "2" ? module.education?.subLevel : "";

  // ---------------------------------------------- content
  return worksheet ? (
    <div className="p-8">
      <div className="flex flex-col items-center py-8 px-4 md:px-8 bg-gray-900 rounded-lg shadow-lg">
        <div className="w-full max-w-6xl">
          <div className="flex justify-between items-center mb-6">
            <h2 className="font-bold text-3xl text-yellow-400">REPORT</h2>
            <div className="flex space-x-2">
              <LinkButton
                className="flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full hover:bg-blue-600"
                to={`${ROUTES.REPORT}/worksheet`}
                valid>
                <FontAwesomeIcon icon={faArrowLeft} className="text-lg" />
              </LinkButton>

              <PDFDownloadLink
                document={<PdfPersonalReport worksheet={worksheet} />}
                fileName={`${worksheet.participantGroup.name}_${worksheet.module.name}_${replacedProfileName}`}>
                {({ blob, url, loading: loadingPdf, error }) => (
                  <Button
                    className={`flex items-center justify-center w-10 h-10 text-white rounded-full bg-blue-500 hover:bg-blue-600 ${
                      fetching || loading || loadingPdf
                        ? "bg-gray-500 pointer-events-none"
                        : error
                        ? "bg-red-500 pointer-events-none"
                        : ""
                    }`}
                    type="button"
                    valid={!fetching}>
                    {fetching || loading || loadingPdf ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="text-lg animate-spin"
                      />
                    ) : error ? (
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="text-lg"
                      />
                    ) : (
                      <FontAwesomeIcon icon={faFilePdf} className="text-lg" />
                    )}
                  </Button>
                )}
              </PDFDownloadLink>

              <DeleteModal
                id="participant-group-delete-modal"
                onDelete={onDelete}
                valid={!fetching && !loading}
                version="v2" // Apply the new design
                className="bg-red-500 hover:bg-red-600"
              />
            </div>
          </div>

          <StatusBadge error={error} fetching={fetching} loading={loading} />

          <div className="bg-gray-800 p-6 rounded-lg shadow-inner">
            <div>
              <p className="text-2xl md:text-3xl font-bold text-gray-200">
                {worksheet.profile.name.toUpperCase()}{" "}
                <span className="text-sm md:text-lg text-gray-400">
                  (ID: {worksheet.id})
                </span>
              </p>
            </div>
            {version === "2" && (
              <div className="mt-4 md:mt-0 md:text-right">
                <p className="text-lg md:text-xl text-gray-200">
                  <span className="text-yellow-400 font-semibold">
                    {educationLevel}
                  </span>
                  {" - "}
                  <span className="text-yellow-400 font-semibold">
                    {educationSubLevel}
                  </span>
                </p>
              </div>
            )}

            <hr className="h-px my-4 border-0 bg-gray-600" />

            <div className="max-h-screen overflow-auto space-y-6">
              {worksheet.questionnaires.map((questionnaire) => {
                switch (questionnaire.assessment.id) {
                  case AssessmentIdEnum.ADVERSITY_QUOTIENT:
                    return (
                      <DetailsAdversityQuotient
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.CFIT:
                    return (
                      <DetailsCfit
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.COLOURED_PROGRESSIVE_MATRICES:
                    return (
                      <DetailsColouredProgressiveMatrices
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.DISC:
                    return (
                      <DetailsDisc
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.GAYA_BELAJAR:
                    return version === "2" ? (
                      <DetailsGayaBelajarV2
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    ) : (
                      <DetailsGayaBelajar
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3:
                    return (
                      <DetailsGayaBelajarKelas1Sampai3
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.KEBIASAAN_BELAJAR:
                    return version === "2" ? (
                      <DetailsKebiasaanBelajarV2
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    ) : (
                      <DetailsKebiasaanBelajar
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.KECERDASAN_MAJEMUK:
                    return version === "2" ? (
                      <DetailsKecerdasanMajemukV2
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    ) : (
                      <DetailsKecerdasanMajemuk
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL:
                    return version === "2" ? (
                      <DetailsKesejahteraanPsikologiDanEmosionalV2
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    ) : (
                      <DetailsKesejahteraanPsikologiDanEmosional
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.KONDISI_KELUARGA:
                    return version === "2" ? (
                      <DetailsKondisiKeluargaV2
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    ) : (
                      <DetailsKondisiKeluarga
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.PERSONALITY:
                    return version === "2" ? (
                      <DetailsPersonalityV2
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    ) : (
                      <DetailsPersonality
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.RIASEC:
                    return version === "2" ? (
                      <DetailsRiasecV2
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    ) : (
                      <DetailsRiasec
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  case AssessmentIdEnum.SPM:
                    return (
                      <DetailsSpm
                        key={questionnaire.assessment.id}
                        questionnaire={questionnaire}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>

          {error && (
            <div className="mt-4 text-red-500">
              <p>{error}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default Details;
