// ---------------------------------------------- modules import
import dayjs from "dayjs";
import { FunctionComponent } from "react";

import { IAssessmentRowProps } from "./common";
import { useAssessmentRow } from "./hooks/use_assessment_row";

// ---------------------------------------------- the component
const AssessmentRow: FunctionComponent<IAssessmentRowProps> = ({
  assessment,
  onChangeAssessment,
}) => {
  const { lastModified, name } = assessment;

  // ---------------------------------------------- local state
  const { onClick } = useAssessmentRow(assessment, onChangeAssessment);

  // ---------------------------------------------- content
  return (
    <tr
      onClick={onClick}
      style={{ cursor: "pointer" }}
      className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600"
    >
      <td className="px-6 py-4">{name}</td>

      <td className="px-6 py-4">
        {dayjs(lastModified).locale("id").format("DD/MM/YYYY, HH:mm:ss")}
      </td>
    </tr>
  );
};

export default AssessmentRow;
