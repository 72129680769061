// ---------------------------------------------- modules import
import { useContext } from "react";

import { DashboardContext } from "../../../../contexts/dashboard/context";
import { SessionContext } from "../../../../contexts/session/context";

// ---------------------------------------------- the hooks
export const useOverview = () => {
  // ---------------------------------------------- consume context
  const { assessments, participantGroups, error, fetching } =
    useContext(DashboardContext);
  const { profile } = useContext(SessionContext);

  // ---------------------------------------------- return value
  return { assessments, participantGroups, profile, error, fetching };
};
