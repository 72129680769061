// ---------------------------------------------- modules import
import {
  faLayerGroup,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import ModuleListItem from "./module_list_item";
import StatusBadge from "../../../components/ui/others/status_badge";

import { IModuleListProps } from "./common";
import { useModuleList } from "./hooks/use_module_list";

// ---------------------------------------------- the component
const ModuleList: FunctionComponent<IModuleListProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    error,
    fetching,
    loading,
    modules,
    participantGroup,
    onChangeModule,
    onDelete,
  } = useModuleList();

  // ---------------------------------------------- content
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="bg-gray-700 p-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white flex items-center">
            <FontAwesomeIcon
              icon={faLayerGroup}
              className="mr-3 text-blue-400"
            />
            MODULES
          </h1>
          <span className="text-sm font-semibold text-gray-300 bg-gray-600 px-3 py-1 rounded-full">
            {!modules.length
              ? "NO MODULES REGISTERED"
              : modules.length === 1
              ? "1 MODULE REGISTERED"
              : `${modules.length} MODULES REGISTERED`}
          </span>
        </div>

        <div className="p-6">
          {modules.length > 0 ? (
            <div className="space-y-6">
              {modules.map((module) => (
                <ModuleListItem
                  key={module.id}
                  module={module}
                  participantGroup={participantGroup}
                  onChangeModule={onChangeModule}
                  onDelete={onDelete}
                />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-yellow-400 text-5xl mb-4"
              />
              <p className="text-gray-400 text-lg">
                Looks like there are no modules to display right now.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 flex justify-center">
        <StatusBadge fetching={fetching} loading={loading} error={error} />
      </div>
    </div>
  );
};

export default ModuleList;
