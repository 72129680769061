// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IDashboardContextProviderState,
  IDashboardContextProviderProps,
} from "./common";
import { useDashboardContext } from "./hooks";

// ---------------------------------------------- create context
const initialState: IDashboardContextProviderState = {
  assessments: [],
  participantGroups: [],
  error: null,
  fetching: true,
};

export const DashboardContext = createContext(initialState);

// ---------------------------------------------- the component
const DashboardContextProvider: FunctionComponent<
  IDashboardContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const { assessments, participantGroups, error, fetching } =
    useDashboardContext();

  // ---------------------------------------------- content
  return (
    <DashboardContext.Provider
      value={{ assessments, participantGroups, error, fetching }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
