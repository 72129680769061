// ---------------------------------------------- modules import
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import { IPdfConsolidationProps } from "./common";

// ---------------------------------------------- fonts
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: 300,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      fontWeight: 400,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 700,
    },
  ],
});

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: "Roboto",
    backgroundColor: "#ffffff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    fontSize: 24,
    fontWeight: 700,
    color: "#4a4a4a",
  },
  companyInfo: {
    fontSize: 10,
    color: "#4a4a4a",
    textAlign: "right",
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 20,
    textAlign: "center",
    color: "#2e2e2e",
  },
  infoSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  infoColumn: {
    flexDirection: "column",
  },
  infoLabel: {
    fontSize: 10,
    fontWeight: 500,
    color: "#6c757d",
    marginBottom: 4,
  },
  infoValue: {
    fontSize: 12,
    color: "#2e2e2e",
  },
  table: {
    display: "flex",
    width: "auto",
    marginTop: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e0e0e0",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
    alignItems: "center",
    minHeight: 24,
  },
  tableColHeader: {
    backgroundColor: "#f3f3f3",
    fontWeight: 500,
  },
  tableCol: {
    width: "25%",
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  tableCell: {
    fontSize: 10,
    color: "#4a4a4a",
  },
  summary: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  summaryText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#2e2e2e",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    fontSize: 10,
    color: "#6c757d",
    textAlign: "center",
    borderTopWidth: 1,
    borderTopColor: "#e0e0e0",
    paddingTop: 10,
  },
});

// ---------------------------------------------- the component
const PdfConsolidation: FunctionComponent<IPdfConsolidationProps> = ({
  consolidatedWorksheets,
}) => {
  const totalWorksheets = consolidatedWorksheets.length;
  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Sort worksheets by token name and then by profile name
  const sortedWorksheets = [...consolidatedWorksheets].sort((a, b) => {
    // Check if both worksheets are version 2 or above
    const aVersion = a.worksheet.version || "1";
    const bVersion = b.worksheet.version || "1";

    if (aVersion === bVersion && aVersion !== "1" && bVersion !== "1") {
      // Compare by token name first for version 2 or above
      const tokenNameComparison = a.worksheet.tokenName?.localeCompare(
        b.worksheet.tokenName
      );
      if (tokenNameComparison !== 0) {
        return tokenNameComparison;
      }
    }

    // If versions are different or both are version 1, or token names are the same,
    // compare by profile name
    return a.worksheet.profile.name.localeCompare(b.worksheet.profile.name);
  });

  // ---------------------------------------------- content
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Text style={styles.logo}>GANESA.ID</Text>
            <Text style={{ fontSize: 10, color: "#6c757d" }}>
              Gerbang Asesmen Indonesia
            </Text>
          </View>
          <View style={styles.companyInfo}>
            <Text>PT AKARA NATURA NUSANTARA</Text>
            <Text>Jalan Kanayakan Lama No 40a</Text>
            <Text>Jalan Sadang Serang No 18 Bandung</Text>
          </View>
        </View>

        <Text style={styles.title}>Consolidation Report</Text>

        <View style={styles.infoSection}>
          <View style={styles.infoColumn}>
            <Text style={styles.infoLabel}>Date Issued</Text>
            <Text style={styles.infoValue}>{currentDate}</Text>
          </View>
          <View style={styles.infoColumn}>
            <Text style={styles.infoLabel}>Total Worksheets</Text>
            <Text style={styles.infoValue}>{totalWorksheets}</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableColHeader]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Profile Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Token ID</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Token Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Group Name</Text>
            </View>
          </View>
          {sortedWorksheets.map((worksheet) => (
            <View style={styles.tableRow} key={worksheet.id}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {worksheet.worksheet.profile.name}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {worksheet.worksheet.tokenId}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {worksheet.worksheet.tokenName}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {worksheet.worksheet.participantGroup.name}
                </Text>
              </View>
            </View>
          ))}
        </View>

        <View style={styles.summary}>
          <Text style={styles.summaryText}>
            Total Worksheets: {totalWorksheets}
          </Text>
        </View>

        <Text style={styles.footer}>
          This document serves as an official record of worksheet counts. For
          verification or discrepancies, please contact records@ganesa.id
        </Text>
      </Page>
    </Document>
  );
};

export default PdfConsolidation;
