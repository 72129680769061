// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IQuestionListItemProps } from "./common";
import { QuestionTypeEnum } from "../../../enums/question_type";

// ---------------------------------------------- the component
const QuestionListItem: FunctionComponent<IQuestionListItemProps> = ({
  question,
}) => {
  const { id, options, text, type } = question;

  const questionTypeKey = (() => {
    return Object.keys(QuestionTypeEnum).find(
      (key) => QuestionTypeEnum[key as keyof typeof QuestionTypeEnum] === type
    );
  })();

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-700 p-4 rounded-lg shadow-sm">
      <p className="text-lg text-gray-300 mb-2">"{text}"</p>
      <ul className="list-disc pl-5 text-gray-400 mb-2">
        {options.map((option, index) => {
          const { text, value } = option;
          return (
            <li key={index} className="mb-2">
              <p>{text}</p>
              <p className="font-semibold">
                value: <span className="text-yellow-400">{value}</span>
              </p>
            </li>
          );
        })}
      </ul>
      <p className="text-gray-400 mb-2">Type: {questionTypeKey}</p>
      <p className="text-gray-500 text-sm">ID: {id}</p>
    </div>
  );
};

export default QuestionListItem;
