// ---------------------------------------------- modules import
import { useContext } from "react";

import { SessionContext } from "../../../contexts/session/context";

// ---------------------------------------------- the hooks
export const useTokenRoute = () => {
  // ---------------------------------------------- consume context
  const { fetching, token } = useContext(SessionContext);

  // ---------------------------------------------- return value
  return { fetching, token };
};
