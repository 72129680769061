// ---------------------------------------------- modules import
import { FormEvent, useContext, useState } from "react";

import { SessionContext } from "../../../../contexts/session/context";

// ---------------------------------------------- the hooks
export const useTokenSignIn = () => {
  // ---------------------------------------------- consume context
  const { error, fetching, token, onChangeTokenId } =
    useContext(SessionContext);

  // ---------------------------------------------- local state
  const [tokenId, setTokenId] = useState("");

  // ---------------------------------------------- handlers
  const handleChange = (prop: string, value: string) => setTokenId(value);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onChangeTokenId(tokenId);
  };

  // ---------------------------------------------- return value
  return {
    token,
    error,
    fetching,
    onChange: handleChange,
    onSubmit: handleSubmit,
  };
};
