// ---------------------------------------------- modules import
import { FormEvent } from "react";

import { IOption } from "../../../ui/inputs/common";

// ---------------------------------------------- the hook
export const useModuleSearchForm = () => {
  // ---------------------------------------------- consume context
  const findByOptions: IOption[] = [{ label: "Name", value: "name" }];

  // ---------------------------------------------- handlers
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();

  // ---------------------------------------------- return value
  return { findByOptions, onSubmit: handleSubmit };
};
