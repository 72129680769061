// ---------------------------------------------- modules import
import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

import NowLoading from "../fallbacks/now_loading";
import Unauthorized from "../fallbacks/unauthorized";
// import UnderMaintenance from "../../components/fallbacks/under_maintenance";

import * as ROUTES from "../../constants/routes";

import { IPrivateRouteProps } from "./common";
import { usePrivateRoute } from "./hooks/use_private_route";

// ---------------------------------------------- the component
const PrivateRoute: FunctionComponent<IPrivateRouteProps> = ({
  children,
  isAuthorized,
}) => {
  // ---------------------------------------------- local state
  const { fetching, profile } = usePrivateRoute();

  // ---------------------------------------------- content
  return (
    <>
      {fetching ? (
        <NowLoading />
      ) : !profile ? (
        <Navigate replace to={`${ROUTES.SIGN_IN}/admin`} />
      ) : !isAuthorized ? (
        <Unauthorized />
      ) : (
        children
      )}
      {/* <UnderMaintenance /> */}
    </>
  );
};

export default PrivateRoute;
