// ---------------------------------------------- modules import
import { FunctionComponent, useEffect } from "react";

import Button from "../../../components/ui/buttons/button";
import LinkButton from "../../../components/ui/buttons/link_button";

import { IIntroProps } from "./common";
import * as ROUTES from "../../../constants/routes";

// ---------------------------------------------- the component
const Intro: FunctionComponent<IIntroProps> = ({
  token,
  fetching,
  onResetToken,
}) => {
  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // ---------------------------------------------- content
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="rounded-lg py-6 px-8 w-full md:w-6/12 lg:w-2/5">
        <h1 className=" text-3xl lg:text-4xl text-teal-400 text-center font-bold mb-6 md:mb-8">
          Selamat Datang
        </h1>

        <div className="text-center mb-8">
          <p className="mb-2 sm:mb-5 text-gray-400">
            Halo, Grup{" "}
            <span className="font-semibold text-yellow-400">{token?.name}</span>{" "}
            dari{" "}
            <span className="font-semibold text-yellow-400">
              {token?.worksheet?.participantGroup?.name}
            </span>
            .
          </p>

          <p className="font-bold text-3xl my-8 text-teal-400">
            Terima kasih telah bergabung dalam kuis ini!
          </p>
        </div>

        <LinkButton to={`${ROUTES.QUIZ}/overview`} valid={!fetching}>
          <div
            className={`w-full px-20 py-2.5 text-center mb-2 md:mb-4 ${
              !fetching
                ? "text-white bg-blue-700 hover:bg-blue-800"
                : "bg-gray-400 text-gray-800"
            } focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-2XL bg-fuchsia-600`}
          >
            Mulai
          </div>
        </LinkButton>

        <div className="flex justify-end">
          <Button type="button" valid={!fetching} onClick={onResetToken}>
            <span className="text-blue-200 text-sm underline">
              or change your token
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
