// ---------------------------------------------- modules import
import firebaseApp from "firebase/compat/app";
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { IUserRecord } from "../../models/user";

import { IProfile } from "./common";
import { IToken, ITokenRecord } from "../../models/token";

// ---------------------------------------------- the hooks
export const useSessionContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [authUser, setAuthUser] = useState<firebaseApp.User | null>(null);
  const [profile, setProfile] = useState<IProfile | null>(null);

  const [tokenId, setTokenId] = useState<string | null>("");
  const [token, setToken] = useState<IToken | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(true);

  // ---------------------------------------------- handlers
  const handleSignIn = (email: string, password: string) => {
    setToken(null);
    setError(null);

    firebase
      .signIn(email, password)
      .then((userCredential) => {
        setError(null);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleSignOut = () => {
    firebase
      .signOut()
      .then(() => {
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleChangeTokenId = (tokenId: string) => {
    setProfile(null);
    setToken(null);
    setError(null);

    setTokenId(tokenId);
  };

  // ---------------------------------------------- effects
  // useEffect(() => {
  //   firebase.logFileUrls();
  // }, [firebase]);

  useEffect(() => {
    setFetching(true);

    const unsubscribe = firebase.auth.onAuthStateChanged(
      (authUser) => {
        setAuthUser(authUser);
        setFetching(false);
      },
      (error) => {
        setError(error.message);
        setFetching(false);
      }
    );

    return () => unsubscribe();
  }, [firebase]);

  useEffect(() => {
    setFetching(true);

    if (authUser) {
      const ref = firebase.firestore.collection(PATHS.USER).doc(authUser.uid);

      const unsubscribe = ref.onSnapshot(
        (documentSnapshot) => {
          if (documentSnapshot.exists) {
            const profile = documentSnapshot.data() as IUserRecord;

            setProfile(profile);
          }

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    } else {
      setProfile(null);
      setError(null);
      setFetching(false);
    }
  }, [firebase, authUser]);

  useEffect(() => {
    if (tokenId) {
      setFetching(true);

      const ref = firebase.firestore.collection(PATHS.TOKEN).doc(tokenId);

      const unsubscribe = ref.onSnapshot(
        (documentSnapshot) => {
          if (documentSnapshot.exists) {
            const tokenRecord = {
              ...(documentSnapshot.data() as ITokenRecord),
            };

            if (tokenRecord.status) {
              const token = {
                ...tokenRecord,
                id: documentSnapshot.id,
              };

              setToken(token);
              setError(null);
            } else {
              setError("Token had been disabled.");
            }
          } else {
            setError("Token doesn't exist.");
          }

          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, tokenId]);

  // ---------------------------------------------- return value
  return {
    authUser,
    profile,
    token,
    error,
    fetching,
    onSignIn: handleSignIn,
    onSignOut: handleSignOut,
    onChangeTokenId: handleChangeTokenId,
  };
};
