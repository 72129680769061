// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IParticipantGroupContextProviderState,
  IParticipantGroupContextProviderProps,
} from "./common";
import { useParticipantGroupContext } from "./hooks";

// ---------------------------------------------- create context
const initialState: IParticipantGroupContextProviderState = {
  participantGroups: [],
  error: null,
  fetching: true,
};

export const ParticipantGroupContext = createContext(initialState);

// ---------------------------------------------- the component
const ParticipantGroupContextProvider: FunctionComponent<
  IParticipantGroupContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const { participantGroups, error, fetching } = useParticipantGroupContext();

  // ---------------------------------------------- content
  return (
    <ParticipantGroupContext.Provider
      value={{ participantGroups, error, fetching }}
    >
      {children}
    </ParticipantGroupContext.Provider>
  );
};

export default ParticipantGroupContextProvider;
