// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { emptyModule, IModule, IModuleRecord } from "../../models/module";
import {
  emptyParticipantGroup,
  IParticipantGroup,
  IParticipantGroupRecord,
} from "../../models/participant_group";
import {
  emptySubsetToken,
  ISubsetToken,
  ISubsetTokenRecord,
} from "../../models/subset_token";
import {
  emptyWorksheet,
  IWorksheet,
  IWorksheetRecord,
} from "../../models/worksheet";

// ---------------------------------------------- the hooks
export const useWorksheetContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [participantGroup, setParticipantGroup] = useState<IParticipantGroup>(
    emptyParticipantGroup()
  );
  const [module, setModule] = useState<IModule>(emptyModule());
  const [subsetToken, setSubsetToken] = useState<ISubsetToken>(
    emptySubsetToken()
  );
  const [worksheet, setWorksheet] = useState<IWorksheet>(emptyWorksheet());

  const [participantGroups, setParticipantGroups] = useState<
    IParticipantGroup[]
  >([]);
  const [modules, setModules] = useState<IModule[]>([]);
  const [subsetTokens, setSubsetTokens] = useState<ISubsetToken[]>([]);
  const [worksheets, setWorksheets] = useState<IWorksheet[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- handlers
  const handleChangeModule = (module: IModule) => setModule(module);

  const handleChangeParticipantGroup = (participantGroup: IParticipantGroup) =>
    setParticipantGroup(participantGroup);

  const handleChangeSubsetToken = (subsetToken: ISubsetToken) =>
    setSubsetToken(subsetToken);

  const handleChangeWorksheet = (worksheet: IWorksheet) =>
    setWorksheet(worksheet);

  // ---------------------------------------------- effects
  useEffect(() => {
    setFetching(true);

    const ref = firebase.firestore
      .collection(PATHS.PARTICIPANT_GROUP)
      .orderBy("dateCreated", "desc");

    const unsubscribe = ref.onSnapshot(
      (querySnapshot) => {
        setParticipantGroups(
          querySnapshot.docs.reduce(
            (participantGroups, doc) => [
              ...participantGroups,
              {
                id: doc.id,
                ...(doc.data() as IParticipantGroupRecord),
              },
            ],
            [] as IParticipantGroup[]
          )
        );

        setError(null);
        setFetching(false);
      },
      (error) => {
        setError(error.message);
        setFetching(false);
      }
    );

    return () => unsubscribe();
  }, [firebase]);

  useEffect(() => {
    if (participantGroup) {
      setFetching(true);

      const ref = firebase.firestore
        .collection(PATHS.PARTICIPANT_GROUP)
        .doc(participantGroup.id.length ? participantGroup.id : "empty id")
        .collection(PATHS.MODULE)
        .orderBy("dateCreated", "desc");

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          setModules(
            querySnapshot.docs.reduce(
              (modules, doc) => [
                ...modules,
                {
                  id: doc.id,
                  ...(doc.data() as IModuleRecord),
                },
              ],
              [] as IModule[]
            )
          );

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, participantGroup]);

  useEffect(() => {
    if (participantGroup && module) {
      setFetching(true);

      const ref = firebase.firestore
        .collection(PATHS.PARTICIPANT_GROUP)
        .doc(participantGroup.id.length ? participantGroup.id : "empty id")
        .collection(PATHS.MODULE)
        .doc(module.id.length ? module.id : "empty id")
        .collection(PATHS.SUBSET_TOKEN)
        .orderBy("dateCreated", "desc");

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          setSubsetTokens(
            querySnapshot.docs.reduce(
              (subsetTokens, doc) => [
                ...subsetTokens,
                {
                  id: doc.id,
                  ...(doc.data() as ISubsetTokenRecord),
                },
              ],
              [] as ISubsetToken[]
            )
          );

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, participantGroup, module]);

  useEffect(() => {
    if (participantGroup && module && subsetToken) {
      setFetching(true);

      const ref = firebase.firestore
        .collection(PATHS.PARTICIPANT_GROUP)
        .doc(participantGroup.id.length ? participantGroup.id : "empty id")
        .collection(PATHS.MODULE)
        .doc(module.id.length ? module.id : "empty id")
        .collection(PATHS.SUBSET_TOKEN)
        .doc(subsetToken.id.length ? subsetToken.id : "empty id")
        .collection(PATHS.WORKSHEET)
        .orderBy("dateCreated", "desc");

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          setWorksheets(
            querySnapshot.docs.reduce(
              (worksheets, doc) => [
                ...worksheets,
                {
                  id: doc.id,
                  ...(doc.data() as IWorksheetRecord),
                },
              ],
              [] as IWorksheet[]
            )
          );

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, participantGroup, module, subsetToken]);

  // ---------------------------------------------- return value
  return {
    participantGroup,
    module,
    subsetToken,
    worksheet,
    participantGroups,
    modules,
    subsetTokens,
    worksheets,
    error,
    fetching,
    onChangeModule: handleChangeModule,
    onChangeParticipantGroup: handleChangeParticipantGroup,
    onChangeSubsetToken: handleChangeSubsetToken,
    onChangeWorksheet: handleChangeWorksheet,
  };
};
