// ---------------------------------------------- modules import
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import JsonSubmission from "./json_submission";
import SubmissionOptions from "./submission_options";
import NotFound from "../../../components/fallbacks/not_found";
import Footer from "../../../components/layouts/footer";
import PrivateRoute from "../../../components/route_guards/private_route";

import SheetContextProvider from "../../../contexts/sheet/context";
import WorksheetContextProvider from "../../../contexts/worksheet/context";

import * as ROUTES from "../../../constants/routes";

// ---------------------------------------------- the component
const Submission = () => {
  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // ---------------------------------------------- content
  return (
    <WorksheetContextProvider>
      <SheetContextProvider>
        <div className="min-h-screen flex flex-col justify-between bg-gray-900">
          <div className="flex-grow flex flex-col items-center justify-center p-8">
            <div className="w-full max-w-4xl bg-gray-400 rounded-lg shadow-lg p-6">
              <Routes>
                <Route
                  element={
                    <PrivateRoute isAuthorized={true}>
                      <SubmissionOptions isAuthorizedToWrite={true} />
                    </PrivateRoute>
                  }
                  path=""
                />

                <Route
                  element={
                    <PrivateRoute isAuthorized={true}>
                      <JsonSubmission isAuthorizedToWrite={true} />
                    </PrivateRoute>
                  }
                  path="json"
                />

                <Route element={<NotFound />} path={ROUTES.OTHERS} />
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </SheetContextProvider>
    </WorksheetContextProvider>
  );
};

export default Submission;
