// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import { IModule } from "../models/module";
import { AssessmentIdEnum } from "../enums/assessment_id";
import {
  EducationSubLevelEnum,
  EducationLevelEnum,
} from "../enums/education_level";

export const pattern: IPattern = {
  description: {
    minLength: 2,
    maxLength: 500,
    regex: /^[\w\s&,.():\-'"/]{2,500}$/,
  },
  name: {
    minLength: 2,
    maxLength: 50,
    regex: /^[\w\s&,.():\-'/]{2,50}$/,
  },
};

const validateModule = (
  module: IModule,
  propsToValidate = ["name"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { assessmentIds, description, education } = module;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  /**
   * ensure that module has at least one assessment id
   *
   * ensure no duplication inside `assessmentIds`
   *
   * each `assessmentId` should have been registered in the `AssessmentIdEnum`
   */
  extraConditions["assessmentIds"] =
    assessmentIds.length > 0 &&
    assessmentIds.length === new Set(assessmentIds).size &&
    assessmentIds.reduce(
      (registered, assesmentId) =>
        registered && assesmentId in AssessmentIdEnum,
      true
    );
  extraConditions["description"] = description
    ? pattern.description.regex.test(description)
    : true;
  extraConditions["education"] =
    Object.values(EducationSubLevelEnum).includes(education.subLevel) &&
    Object.values(EducationLevelEnum).includes(education.level);

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    module,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateModule;
