// ---------------------------------------------- modules import
import { MouseEvent, useContext, useEffect, useState } from "react";

import { SessionContext } from "../../../contexts/session/context";

import * as ROUTES from "../../../constants/routes";

// ---------------------------------------------- the hooks
export const useSidebar = () => {
  // ---------------------------------------------- consume context
  const { error, fetching, profile, onSignOut } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  // ---------------------------------------------- handlers
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickMenu = () => {
    if (window.innerWidth < 640) setIsSidebarOpen(false);
  };

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault();

    onSignOut();
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    if (profile)
      if (!profile.userPermission.isZuper) setMenuItems([...defaultMenuItems]);
      else
        setMenuItems([
          ...defaultMenuItems,
          {
            title: "Consolidation",
            route: ROUTES.CONSOLIDATION,
          },
        ]);
  }, [profile]);

  // ---------------------------------------------- return value
  return {
    isSidebarOpen,
    menuItems,
    profile,
    error,
    fetching,
    onToggleSidebar: handleToggleSidebar,
    onClickMenu: handleClickMenu,
    onLogout: handleLogout,
  };
};

const defaultMenuItems = [
  {
    title: "Dashboard",
    route: ROUTES.DASHBOARD,
  },
  {
    title: "Group",
    route: ROUTES.PARTICIPANT_GROUP,
  },
  {
    title: "Assessment",
    route: ROUTES.ASSESSMENT,
  },
  {
    title: "Report",
    route: ROUTES.REPORT,
  },
  {
    title: "Submission",
    route: ROUTES.SUBMISSION,
  },
  {
    title: "Setting",
    route: ROUTES.SETTINGS,
  },
];
