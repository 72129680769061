export interface IPattern {
  [key: string]: {
    minLength: number;
    maxLength: number;
    regex: RegExp;
  };
}

export const reduceExtraConditions = (extraConditions: {
  [key: string]: boolean;
}) => {
  let extraCondition = true;

  const entries = Object.entries(extraConditions);
  const length = entries.length;

  for (let i = 0; i < length; i++)
    extraCondition = extraCondition && entries[i][1];

  return extraCondition;
};

// ---------------------------------------------- user defined type guard
export const validateData = <T extends { [key: string]: any }>(
  data: T,
  pattern: IPattern,
  propsToValidate: string[],
  extraCondition: boolean = true
): boolean =>
  Object.keys(data)
    .map((prop) => data[prop])
    .reduce(
      (isValid: boolean, value) => isValid && value !== undefined,
      true
    ) &&
  propsToValidate.every((prop) => Object.keys(pattern).indexOf(prop) !== -1) &&
  propsToValidate.reduce(
    (isValid: boolean, prop) => isValid && pattern[prop].regex.test(data[prop]),
    true
  ) &&
  extraCondition;
