// ---------------------------------------------- modules import
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import Button from "../../../components/ui/buttons/button";

import { useConsolidationInfo } from "./hooks/use_consolidation_info";

// ---------------------------------------------- the component
const ConsolidationInfo: FunctionComponent = () => {
  const { isOpen, onToggleOpen } = useConsolidationInfo();

  // ---------------------------------------------- content
  return (
    <>
      <Button
        className="flex items-center justify-center w-10 h-10 mb-5 text-white rounded-full bg-green-500 hover:bg-green-600 mr-2"
        type="button"
        onClick={() => onToggleOpen(true)}
        valid
      >
        <FontAwesomeIcon icon={faInfoCircle} className="text-lg" />
      </Button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                Consolidation Feature Information
              </h2>
              <button
                onClick={() => onToggleOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faTimes} className="text-lg" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold mb-2">Instructions:</h3>
                <ul className="list-disc pl-5 space-y-1 text-sm text-gray-600">
                  <li>
                    Set <span className="font-semibold">Status</span> to filter
                    data by settlement status
                  </li>
                  <li>
                    Set <span className="font-semibold">Rows</span> to adjust
                    the number of data to show
                  </li>
                  <li>
                    Set <span className="font-semibold">Group</span> to filter
                    data by participant group
                  </li>
                  <li>
                    Set <span className="font-semibold">Find By</span> to set
                    which property to filter by keywords
                  </li>
                  <li>
                    Set <span className="font-semibold">Keywords</span> to
                    filter data
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-2">Rules:</h3>
                <ul className="list-disc pl-5 space-y-1 text-sm text-gray-600">
                  <li>
                    To settle (or unsettle) data, you need to select some first
                  </li>
                  <li>
                    To do the settlement, you can't have{" "}
                    <span className="text-green-500 font-semibold">
                      Settled
                    </span>{" "}
                    data selected
                  </li>
                  <li>
                    To do the unsettlement, you can't have{" "}
                    <span className="text-red-500 font-semibold">
                      Unsettled
                    </span>{" "}
                    data selected
                  </li>
                  <li>
                    To download consolidation pdf, make sure all the data are in
                    the same group or else the download button will be disabled
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsolidationInfo;
