// ---------------------------------------------- modules import
import { useNavigate } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";
import { IModule } from "../../../../models/module";

// ---------------------------------------------- the hooks
export const useModuleRow = (
  module: IModule,
  onChangeModule: (module: IModule) => void
) => {
  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- handlers
  const handleClick = () => {
    onChangeModule(module);

    navigate(`${ROUTES.REPORT}${ROUTES.SUBSET_TOKEN}`, {
      replace: true,
    });
  };

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
