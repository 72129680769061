// ---------------------------------------------- modules import
import { IUser } from "../models/user";

import { IPattern, reduceExtraConditions, validateData } from "./common";
import validateUserPermission from "./user_permission";

export const pattern: IPattern = {
  email: {
    minLength: 2,
    maxLength: 100,
    regex:
      /^[a-z]{1}([a-z\d._-]{2,25})@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
  },
  name: {
    minLength: 2,
    maxLength: 30,
    regex: /^[\w\s,-]{2,30}$/,
  },
  password: {
    minLength: 8,
    maxLength: 50,
    regex: /^[\w]{8,50}$/,
  },
};

const validateUser = (
  user: IUser,
  propsToValidate = ["email", "name"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { userPermission } = user;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["userPermission"] = validateUserPermission(userPermission);

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(user, pattern, propsToValidate, extraCondition);

  return isValid;
};

export default validateUser;
