// ---------------------------------------------- modules import
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import MainSettings from "./main_settings";
import NotFound from "../../../components/fallbacks/not_found";
import Footer from "../../../components/layouts/footer";
import PrivateRoute from "../../../components/route_guards/private_route";

import SettingsContextProvider from "../../../contexts/settings/context";

import * as ROUTES from "../../../constants/routes";

import { useSettings } from "./hooks/use_settings";

// ---------------------------------------------- the component
const Settings = () => {
  // ---------------------------------------------- local state
  const { profile } = useSettings();

  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // ---------------------------------------------- content
  return profile ? (
    <SettingsContextProvider>
      <Routes>
        <Route
          element={
            <PrivateRoute
              isAuthorized={profile !== null}
              children={<MainSettings />}
            />
          }
          path=""
        />

        <Route element={<NotFound />} path={ROUTES.OTHERS} />
      </Routes>

      <Footer />
    </SettingsContextProvider>
  ) : (
    <Navigate replace to={ROUTES.SIGN_IN} />
  );
};

export default Settings;
