// ---------------------------------------------- modules import
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FunctionComponent } from "react";

import { ITextInputProps } from "./common";

// ---------------------------------------------- the component
const TextInput: FunctionComponent<ITextInputProps> = ({
  className_input,
  className_label,
  className_root,
  id = "",
  label,
  name,
  pattern,
  placeholder,
  required,
  style,
  type,
  validate,
  value,
  onChange,
}) => {
  // ---------------------------------------------- handlers
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(e.currentTarget.name, e.currentTarget.value);

  // ---------------------------------------------- content
  return (
    <div className={`${className_root || ""}`} style={style}>
      {label && (
        <label
          className={`flex text-gray-100 items-center ${className_label}`}
          htmlFor={id}
        >
          <span className="flex-1">{label}</span>

          {validate && value?.length ? (
            pattern.regex.test(value) ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-blue-400 text-lg"
              />
            ) : (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="text-red-300 text-lg"
              />
            )
          ) : null}
        </label>
      )}

      <input
        className={
          className_input
            ? className_input
            : "w-full border text-sm rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
        }
        data-length={pattern.maxLength}
        id={id}
        name={name}
        maxLength={pattern.maxLength}
        minLength={pattern.minLength}
        // pattern={pattern.regex.toString().slice(1, -1)}
        placeholder={placeholder}
        required={required}
        type={type}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextInput;
