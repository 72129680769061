// ---------------------------------------------- modules import
import axios from "axios";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SessionContext } from "../../../../contexts/session/context";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import { newCreateWorksheetDto } from "../../../../dtos/worksheet";
import { QuestionTypeEnum } from "../../../../enums/question_type";
import validateWorksheet from "../../../../validations/worksheet";

import * as ROUTES from "../../../../constants/routes";
import { emptyWorksheet, IWorksheet } from "../../../../models/worksheet";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";

// ---------------------------------------------- the hooks
export const useQuiz = () => {
  // ---------------------------------------------- router state
  const navigate = useNavigate();

  // ---------------------------------------------- consume context
  const {
    token,
    error: errSession,
    fetching,
    onChangeTokenId,
  } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [worksheet, setWorksheet] = useState<IWorksheet>(emptyWorksheet());
  const [questionnaireStatuses, setQuestionnaireStatuses] = useState<
    {
      assessmentId: AssessmentIdEnum;
      isCompleted: boolean;
    }[]
  >([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [errRequest, setErrRequest] = useState<string | null>(null);

  const isWorksheetValid = () => validateWorksheet(worksheet);

  // ---------------------------------------------- handlers
  const handleChangeProfile = (prop: string, value: string) => {
    setWorksheet({
      ...worksheet,
      profile: {
        ...worksheet.profile,
        [prop]: prop === "age" ? parseFloat(value) : value,
      },
    });
  };

  const handleChangeSelectedOptions = (
    assessmentId: AssessmentIdEnum,
    tagId: string,
    questionId: string,
    value: string | number
  ) => {
    setWorksheet({
      ...worksheet,
      questionnaires: worksheet.questionnaires.map((questionnaire) => {
        if (questionnaire.assessment.id === assessmentId) {
          return {
            ...questionnaire,
            groups: questionnaire.groups.map((group) => {
              if (group.tag.id === tagId) {
                return {
                  ...group,
                  questions: group.questions.map((question) => {
                    if (question.id === questionId) {
                      const { options, selectedOptions, type } = question;

                      switch (type) {
                        case QuestionTypeEnum.PAIRED_CHOICE: {
                          const included = selectedOptions.reduce(
                            (included, selectedOption) =>
                              included || selectedOption.value === value,
                            false
                          );

                          if (!included) {
                            const option = options.find(
                              (option) => option.value === value
                            )!;

                            // if there are two option already selected, ignore
                            if (selectedOptions.length === 2) return question;

                            return {
                              ...question,
                              selectedOptions: [...selectedOptions, option],
                            };
                          }

                          return {
                            ...question,
                            selectedOptions: [...selectedOptions].filter(
                              (selectedOption) => selectedOption.value !== value
                            ),
                          };
                        }
                        case QuestionTypeEnum.MOST_LEAST_CHOICE: {
                          /*
                           * CHECK first if the current value is included in `selected options`
                           */
                          const included = selectedOptions.reduce(
                            (included, selectedOption) =>
                              included || selectedOption.value === value,
                            false
                          );

                          /*
                           * If YES, we simply remove it
                           */
                          if (included)
                            return {
                              ...question,
                              selectedOptions: [...selectedOptions].filter(
                                (selectedOption) =>
                                  selectedOption.value !== value
                              ),
                            };

                          /*
                           * If NO, we CHECK if the opposite value is included in `selected options`
                           *
                           * Opposite values are:
                           * MD >< LD
                           * MI >< LI
                           * MS >< LS
                           * MC >< LC
                           */
                          const [prefix, postfix] = (value as string).split("");

                          const oppositeValue =
                            prefix === "M"
                              ? `L${postfix}`
                              : prefix === "L"
                              ? `M${postfix}`
                              : "";

                          const oppositeIncluded = selectedOptions.reduce(
                            (included, selectedOption) =>
                              included ||
                              selectedOption.value === oppositeValue,
                            false
                          );

                          const option = options.find((option) =>
                            (option.value as string).includes(value as string)
                          )!;

                          /*
                           * If YES, we remove the opposite value and add the selected value
                           */
                          if (oppositeIncluded)
                            return {
                              ...question,
                              selectedOptions: [
                                ...[...selectedOptions].filter(
                                  (selectedOption) =>
                                    (selectedOption.value as string) !==
                                    oppositeValue
                                ),
                                { ...option, value },
                              ],
                            };

                          /*
                           * If NO, we remove the value with the same prefix and add the selected value
                           */
                          return {
                            ...question,
                            selectedOptions: [
                              ...[...selectedOptions].filter(
                                (selectedOption) =>
                                  !(selectedOption.value as string).startsWith(
                                    prefix
                                  )
                              ),
                              { ...option, value },
                            ],
                          };
                        }
                        case QuestionTypeEnum.MULTIPLE_CHOICE: {
                          const included = selectedOptions.reduce(
                            (included, selectedOption) =>
                              included || selectedOption.value === value,
                            false
                          );

                          if (!included) {
                            const option = options.find(
                              (option) => option.value === value
                            )!;

                            return {
                              ...question,
                              selectedOptions: [...selectedOptions, option],
                            };
                          }

                          return {
                            ...question,
                            selectedOptions: [...selectedOptions].filter(
                              (selectedOption) => selectedOption.value !== value
                            ),
                          };
                        }
                        case QuestionTypeEnum.SINGLE_CHOICE: {
                          const included = selectedOptions.reduce(
                            (included, selectedOption) =>
                              included || selectedOption.value === value,
                            false
                          );

                          if (!included) {
                            const option = options.find(
                              (option) => option.value === value
                            )!;

                            return {
                              ...question,
                              selectedOptions: [option],
                            };
                          }

                          return {
                            ...question,
                            selectedOptions: [],
                          };
                        }
                        default: {
                          return question;
                        }
                      }
                    }

                    return question;
                  }),
                };
              }

              return group;
            }),
          };
        }

        return questionnaire;
      }),
    });
  };

  const handleResetToken = () => onChangeTokenId("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const url = `${proxy}${API_ROUTES.WORKSHEET}`;

    axios
      .post(url, newCreateWorksheetDto(worksheet))
      .then(() => {
        setLoading(false);
        setErrRequest(null);

        navigate(`${ROUTES.QUIZ}/closure`, { replace: true });

        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        const message = error.response
          ? // ? `${error.response.data.message}: ${error.response.data.error}`
            'Maaf. Silahkan coba "Kirim" kembali beberapa saat lagi.'
          : error.request
          ? "request was made but no response was received."
          : "bad request setup.";

        // console.log("ERROR MESSAGE =", error.response.data.message);
        // console.log("ERROR =", error.response.data.error);

        setLoading(false);
        setErrRequest(message);
      });
  };

  const handleDownloadWorksheet = () => {
    const { participantGroup, profile, tokenName } = worksheet;

    // Convert the data to a JSON string
    const jsonString = JSON.stringify(worksheet, null, 2);

    // Create a Blob with the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = `${participantGroup.name} - ${tokenName} - ${profile.name}`;

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up by removing the link and revoking the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    if (token) {
      setWorksheet({
        ...token.worksheet,
        profile: {
          ...token.worksheet.profile,
          birthdate: new Date(
            new Date().setFullYear(new Date().getFullYear() - 15)
          ).toISOString(),
        },
        tokenName: token.name,
      });

      setQuestionnaireStatuses(
        token.worksheet.questionnaires.map((questionnaire) => ({
          assessmentId: questionnaire.assessment.id,
          isCompleted: false,
        }))
      );
    }
  }, [token]);

  useEffect(() => {
    if (worksheet) {
      // console.log("worksheet =", worksheet);

      setQuestionnaireStatuses(
        worksheet.questionnaires.map((questionnaire) => {
          const { assessment, groups } = questionnaire;

          const isCompleted = groups.reduce(
            (isCompleted, group) =>
              isCompleted &&
              group.questions.reduce(
                (isCompleted, question) =>
                  isCompleted && question.selectedOptions.length > 0,
                true
              ),
            true
          );

          return {
            assessmentId: assessment.id,
            isCompleted,
          };
        })
      );
    }
  }, [worksheet]);

  // ---------------------------------------------- effects
  useEffect(() => setError(errRequest || errSession), [errRequest, errSession]);

  // ---------------------------------------------- return value
  return {
    token,
    worksheet,
    questionnaireStatuses,
    error,
    fetching,
    loading,
    isWorksheetValid,
    onChangeProfile: handleChangeProfile,
    onChangeSelectedOptions: handleChangeSelectedOptions,
    onResetToken: handleResetToken,
    onSubmit: handleSubmit,
    onDownloadWorksheet: handleDownloadWorksheet,
  };
};
