// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IFirebaseContextProviderState,
  IFirebaseContextProviderProps,
} from "./common";
import Firebase from "./firebase";

// ---------------------------------------------- create context
const initialState: IFirebaseContextProviderState = {
  firebase: new Firebase(),
};

export const FirebaseContext =
  createContext<IFirebaseContextProviderState>(initialState);

// ---------------------------------------------- the component
const FirebaseContextProvider: FunctionComponent<
  IFirebaseContextProviderProps
> = ({ children }) => (
  <FirebaseContext.Provider value={{ ...initialState }}>
    {children}
  </FirebaseContext.Provider>
);

export default FirebaseContextProvider;
