// ---------------------------------------------- modules import
import { IParticipantGroup } from "../models/participant_group";

export const newCreateParticipantGroupDto = (
  participantGroup: IParticipantGroup
) => {
  const { description, name, location } = participantGroup;
  const { province, regency } = location;

  return {
    data: {
      description,
      name,
      province,
      regency,
    },
  };
};

export const newUpdateParticipantGroupDto = (
  participantGroup: IParticipantGroup
) => {
  const { description, name, location } = participantGroup;
  const { province, regency } = location;

  return {
    data: {
      description,
      name,
      province,
      regency,
    },
  };
};
