// ---------------------------------------------- modules import
import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import voca from "voca";

import Button from "../../ui/buttons/button";
import CheckboxInput from "../../ui/inputs/checkbox_input";

import bgQuiz from "../../../assets/images/bg-quiz.png";

import { IQuizGayaBelajarSdKelas1Sampai3Props } from "./common";
import { useQuizGayaBelajarSdKelas1Sampai3 } from "./hooks/use_quiz_gaya_belajar_sd_kelas_1_sampai_3";

// ---------------------------------------------- the component
const QuizGayaBelajarSdKelas1Sampai3: FunctionComponent<
  IQuizGayaBelajarSdKelas1Sampai3Props
> = ({ questionnaires, onChangeSelectedOptions }) => {
  // ---------------------------------------------- local state
  const { questionnaire, group, question, onChange, onNext, onPrevious } =
    useQuizGayaBelajarSdKelas1Sampai3(questionnaires, onChangeSelectedOptions);

  const anyOptionSelected = question
    ? question.options.some(
        (option) =>
          question.selectedOptions.findIndex(
            (selectedOption) => selectedOption.value === option.value
          ) !== -1
      )
    : false;

  // ---------------------------------------------- the content
  return (
    <div>
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-no-repeat bg-cover md:w-2/5 xl:w-1/4 h-full w-full"
        style={{ backgroundImage: `url(${bgQuiz})` }}
      >
        <form className="w-full max-w-md px-9 pt-8 pb-8 rounded-lg">
          <h1 className="md:text-2xl text-xl text-gray-100 md:py-10 py-5">
            {questionnaire && voca.upperCase(questionnaire.assessment.name)}
          </h1>
          <div>
            {questionnaire && group && question && (
              <>
                <div className=" sm:pt-13 md:pt-10 pt-14 pb-5 flex flex-wrap md:justify-between ">
                  {question.text.split("||").map((line, index) => {
                    const isImage =
                      index === 1 && line.trim().startsWith("https://");

                    return (
                      <div
                        key={index}
                        className={` ${
                          isImage ? "md:w-2/5 w-32 " : "md:w-2/5 w-full"
                        }`}
                      >
                        {isImage ? (
                          <img
                            src={line}
                            alt={question.id}
                            className="my-2 md:w-2/5 w-1/2"
                          />
                        ) : (
                          <p className="text-base text-gray-700 ">{line}</p>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-1 items-center object-center">
                  {question.options.map((option, index) => {
                    const { text, value } = option;

                    const included = question.selectedOptions.reduce(
                      (included, selectedOption) =>
                        included || selectedOption.value === value,
                      false
                    );

                    const isImage = text.trim().startsWith("https://");

                    return (
                      <div
                        key={value}
                        className={` overflow-y-auto ${
                          isImage ? " md:w-36 xl:1/2 w-32" : "w-full"
                        } `}
                      >
                        <CheckboxInput
                          checked={included}
                          className_input="hidden"
                          className_label={`flex-grow w-full text-base font-medium text-gray-100 inline-flex border rounded-lg cursor-pointer ${
                            included
                              ? "text-gray-900 bg-green-500 hover:bg-green-300"
                              : "text-gray-100 bg-blue-500 hover:bg-blue-300"
                          }`}
                          id={`${value}-${index}`}
                          label={
                            isImage ? (
                              <img
                                src={text}
                                alt={value.toString()}
                                className="w-full"
                              />
                            ) : (
                              text
                            )
                          }
                          name={question.id}
                          reversed
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <div className="fixed bottom-0 left-0 right-0 bg-transparent p-4">
              <div className="flex justify-between max-w-md mx-auto">
                <Button
                  className="flex items-center text-blue-500 hover:text-blue-700"
                  type="button"
                  valid
                  onClick={onPrevious}
                >
                  <FontAwesomeIcon icon={faCircleLeft} className="text-xl" />

                  <span className="text-lg font-bold mx-2">BACK</span>
                </Button>

                <Button
                  className={`flex items-center ${
                    anyOptionSelected
                      ? "text-blue-500 hover:text-blue-700"
                      : "text-gray-500"
                  }`}
                  type="button"
                  valid={anyOptionSelected}
                  onClick={onNext}
                >
                  <span className="text-lg font-bold mx-2">NEXT</span>

                  <FontAwesomeIcon icon={faCircleRight} className="text-xl" />
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuizGayaBelajarSdKelas1Sampai3;
