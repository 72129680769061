// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { emptyAssessment, IAssessment } from "../../models/assessment";
import { IQuestion, IQuestionRecord } from "../../models/question";
import { emptyTag, ITag } from "../../models/tag";

// ---------------------------------------------- the hooks
export const useQuestionContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [assessment, setAssessment] = useState<IAssessment>(emptyAssessment());
  const [tag, setTag] = useState<ITag>(emptyTag());
  const [questions, setQuestions] = useState<IQuestion[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- handlers
  const handleChangeAssessment = (assessment: IAssessment) =>
    setAssessment(assessment);

  const handleChangeTag = (tag: ITag) => setTag(tag);

  // ---------------------------------------------- effects
  useEffect(() => {
    if (assessment && tag) {
      setFetching(true);

      const ref = firebase.firestore
        .collection(PATHS.ASSESSMENT)
        .doc(assessment.id.length ? assessment.id : "empty id")
        .collection(PATHS.TAG)
        .doc(tag.id.length ? tag.id : "empty id")
        .collection(PATHS.QUESTION);

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          setQuestions(
            querySnapshot.docs.reduce(
              (questions, doc) => [
                ...questions,
                {
                  id: doc.id,
                  ...(doc.data() as IQuestionRecord),
                },
              ],
              [] as IQuestion[]
            )
          );

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, assessment, tag]);

  // ---------------------------------------------- return value
  return {
    assessment,
    tag,
    questions,
    error,
    fetching,
    onChangeTag: handleChangeTag,
    onChangeAssessment: handleChangeAssessment,
  };
};
