// ---------------------------------------------- modules import
import { faFaceLaughBeam } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import ParticipantGroupRow from "./participant_group_row";

import { IParticipantGroupTableProps } from "./common";

// ---------------------------------------------- the component
const ParticipantGroupTable: FunctionComponent<IParticipantGroupTableProps> = ({
  participantGroups,
  fetching,
  error,
}) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
    <p className="text-lg md:text-xl text-gray-100 mb-4">
      Yeay! We are proud to be partnered with{" "}
      <span className="text-yellow-400 font-semibold">
        {participantGroups.length} participant groups
      </span>{" "}
      who have entrusted us to conduct assessments. Together, we are fostering
      growth, nurturing talents, and empowering individuals to thrive!{" "}
      <FontAwesomeIcon className="text-yellow-400" icon={faFaceLaughBeam} />
    </p>

    <section className="shadow-lg overflow-x-auto max-h-screen mb-8 md:max-h-[calc(100vh-350px)]">
      <div className="relative overflow-x-auto shadow-md rounded-lg max-h-[calc(100vh-400px)]">
        <table className="w-full text-sm text-left text-gray-400">
          <thead className="text-xs uppercase bg-gray-700 text-gray-300 sticky top-0">
            <tr>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3 hidden md:table-cell">Province</th>
              <th className="px-6 py-3">Regency</th>
            </tr>
          </thead>

          <tbody>
            {!fetching
              ? participantGroups.map((participantGroup) => (
                  <ParticipantGroupRow
                    key={participantGroup.id}
                    participantGroup={participantGroup}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    </section>
  </div>
);

export default ParticipantGroupTable;
