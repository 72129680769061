// ---------------------------------------------- modules import
import { FunctionComponent, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Details from "./details";
import AssessmentTable from "./assessment_table";
import NotFound from "../../../components/fallbacks/not_found";
import Footer from "../../../components/layouts/footer";
import PrivateRoute from "../../../components/route_guards/private_route";

import AssessmentContextProvider from "../../../contexts/assessment/context";
import AttributeContextProvider from "../../../contexts/attribute/context";
import QuestionContextProvider from "../../../contexts/question/context";
import TagContextProvider from "../../../contexts/tag/context";

import * as ROUTES from "../../../constants/routes";

// ---------------------------------------------- the component
const Assessment: FunctionComponent = () => {
  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <AssessmentContextProvider>
      <AttributeContextProvider>
        <TagContextProvider>
          <QuestionContextProvider>
            <div
              style={{
                height: "100vh",
                backgroundColor: "",
              }}
            >
              <Routes>
                <Route
                  element={
                    <PrivateRoute isAuthorized={true}>
                      <AssessmentTable isAuthorizedToWrite={true} />
                    </PrivateRoute>
                  }
                  path=""
                />

                <Route
                  element={
                    <PrivateRoute isAuthorized={true}>
                      <Details isAuthorizedToWrite={true} />
                    </PrivateRoute>
                  }
                  path="details/:assessment_id"
                />

                <Route element={<NotFound />} path={ROUTES.OTHERS} />
              </Routes>

              <Footer />
            </div>
          </QuestionContextProvider>
        </TagContextProvider>
      </AttributeContextProvider>
    </AssessmentContextProvider>
  );
};

export default Assessment;
