// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import AssessmentTable from "./assessment_table";
import ParticipantGroupTable from "./participant_group_table";

import { useOverview } from "./hooks/use_overview";

// ---------------------------------------------- the component
const Overview: FunctionComponent = () => {
  // ---------------------------------------------- local state
  const { assessments, participantGroups, profile, error, fetching } =
    useOverview();

  // ---------------------------------------------- content
  return (
    <div className="space-y-8">
      <div className="bg-gray-800 p-6 rounded-lg shadow-md">
        <p className="text-2xl md:text-3xl text-gray-100">
          Welcome back,{" "}
          <span className="text-yellow-400 font-semibold">{profile?.name}</span>
          . Get ready to empower and inspire.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <AssessmentTable
            assessments={assessments}
            fetching={fetching}
            error={error}
          />
        </div>

        <div>
          <ParticipantGroupTable
            participantGroups={participantGroups}
            fetching={fetching}
            error={error}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
