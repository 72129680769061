// ---------------------------------------------- modules import
import { useContext } from "react";

import { AttributeContext } from "../../../../contexts/attribute/context";

// ---------------------------------------------- the hooks
export const useAttributeList = () => {
  // ---------------------------------------------- consume context
  const { attributes, error, fetching } = useContext(AttributeContext);

  // ---------------------------------------------- content
  return {
    error,
    fetching,
    attributes,
  };
};
