export enum EducationLevelEnum {
  ELEMENTARY = "SD",
  JUNIOR_HIGH = "SMP",
  SENIOR_HIGH = "SMA",
  UNIVERSITY = "Universitas",
  EMPLOYEE = "Pegawai",
  OTHERS = "Lainnya",
}

export enum EducationSubLevelEnum {
  // Elementary to Senior High
  I = "I",
  II = "II",
  III = "III",
  IV = "IV",
  V = "V",
  VI = "VI",
  VII = "VII",
  VIII = "VIII",
  IX = "IX",
  X = "X",
  XI = "XI",
  XII = "XII",

  // University levels
  D3 = "D3",
  S1 = "S1",
  S2 = "S2",
  S3 = "S3",

  // Employee levels (if applicable)
  ENTRY_LEVEL = "Entry Level",
  MID_LEVEL = "Mid Level",
  SENIOR_LEVEL = "Senior Level",
  MANAGER = "Manager",
  DIRECTOR = "Director",
  EXECUTIVE = "Executive",

  OTHERS = "Lainnya",
}
