// ---------------------------------------------- modules import
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import AttributeList from "./attribute_list";
import QuestionList from "./question_list";
import TagList from "./tag_list";
import LinkButton from "../../../components/ui/buttons/link_button";
import StatusBadge from "../../../components/ui/others/status_badge";

import * as ROUTES from "../../../constants/routes";

import { IDetailsProps } from "./common";
import { useDetails } from "./hooks/use_details";

// ---------------------------------------------- the component
const Details: FunctionComponent<IDetailsProps> = ({ isAuthorizedToWrite }) => {
  // ---------------------------------------------- local state
  const { error, fetching, assessment } = useDetails();
  const { description, name } = assessment;

  // ---------------------------------------------- content
  return (
    <div className="p-8 justify-center items-center max-w-none text-white">
      <div className="bg-gray-900 rounded-lg p-6 mb-6 shadow-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold text-yellow-400">
            {name.toUpperCase()}
          </h1>
          <LinkButton
            className="flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full hover:bg-blue-600"
            to={`${ROUTES.ASSESSMENT}`}
            valid
          >
            <FontAwesomeIcon icon={faArrowLeft} className="text-lg" />
          </LinkButton>
        </div>
        <hr className="my-4 border-gray-600" />
        <p className="text-base md:text-lg text-gray-300">
          {description ? description : "No description available"}
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <AttributeList isAuthorizedToWrite={isAuthorizedToWrite} />
        </div>
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <TagList isAuthorizedToWrite={isAuthorizedToWrite} />
        </div>
      </div>
      <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-6">
        <QuestionList isAuthorizedToWrite={isAuthorizedToWrite} />
      </div>

      <StatusBadge fetching={fetching} loading={false} error={error} />
    </div>
  );
};

export default Details;
