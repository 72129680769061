// ---------------------------------------------- modules import
import {
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Svg,
  Rect,
} from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-keluarga.png";

import { IResultPageKondisiKeluargaProps } from "./common";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";
import { AttributeIdEnum_KondisiKeluarga } from "../../../../enums/attribute_id";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#3b1c78",
  },
  description: {
    marginBottom: 10,
    textAlign: "justify",
    fontSize: 14,
  },
  table: {
    display: "flex",
    width: "70%",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    backgroundColor: "#87CEEB",
    color: "#000000",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    flex: 1,
    textAlign: "center",
  },
  tableCell: {
    fontSize: 10,
    padding: 5,
    flex: 1,
    textAlign: "center",
  },
  tableCellName: {
    fontSize: 10,
    padding: 5,
    flex: 1,
    textAlign: "left",
  },
  tableCellEven: {
    backgroundColor: "#E6F3FF",
  },
  tableCellOdd: {
    backgroundColor: "#FFFFFF",
  },
  tableTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#3b1c78",
  },

  tableCellNo: {
    fontSize: 10,
    padding: 5,
    flex: 0.5,
    textAlign: "center",
    backgroundColor: "#E6F3FF",
  },

  diagramContainer: {
    marginTop: 10,
    marginBottom: 10,
    width: "50%",
  },
  diagramRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  diagramLabel: {
    // width: "30%",
    fontSize: 10,
    textAlign: "right",
    marginRight: 2,
    flex: 5,
  },
  diagramBar: {
    // width: "10%",
    height: 20,
    flex: 5,
  },
  diagramPercentage: {
    // width: "5%",
    fontSize: 10,
    textAlign: "left",
    flex: 1,
  },

  categoryContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
  },
  categoryBox: {
    width: "48%",
    border: "1px solid #000",
    padding: 5,
    marginBottom: 10,
  },
  categoryTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  categoryDescription: {
    fontSize: 10,
    marginBottom: 5,
  },
  categoryPercentage: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
  },

  conclusionSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  conclusionLeft: {
    width: "80%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    marginRight: 4,
    padding: 4,
  },
  conclusionRight: {
    width: "20%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center", // Menambahkan text align untuk mengambang teks di tengah
    padding: 4,
  },
  // table
  table1: {
    display: "flex",
    width: "100%",
    borderColor: "#000000",
    borderCollapse: "collapse",
  },
  tableRow1: {
    flexDirection: "row",
  },
  tableHeaderCell1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 7,
    fontWeight: "bold",
    padding: 5,
    width: 30,
    textAlign: "center",
  },
  tableCell1: {
    fontSize: 6,
    padding: 5,
    width: 30,
    textAlign: "center",
  },
  tableHeaderCellName1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 130,
  },
  tableCellName1: {
    fontSize: 9,
    padding: 5,
    width: 130,
  },
  tableHeaderCellResult1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 55,
    textAlign: "center",
  },
  tableCellResult1: {
    fontSize: 9,
    padding: 5,
    width: 55,
    textAlign: "center",
  },
  tableHeaderCellNo1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  tableCellNo1: {
    fontSize: 9,
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  evenRow1: {
    backgroundColor: "#D9E1F2",
  },
  oddRow1: {
    backgroundColor: "#FFFFFF",
  },
});

// ---------------------------------------------- the table attribute
interface AttributeTableProps {
  title: string;
  data: {
    mulaiBerkembang: number;
    sedangBerkembang: number;
    berkembangBaik: number;
    sangatBerkembang: number;
  };
}

// ---------------------------------------------- the table attribute
const AttributeTable: React.FC<AttributeTableProps> = ({ title, data }) => (
  <View style={styles.table} wrap={false}>
    <Text style={styles.tableTitle}>{title}</Text>
    <View style={styles.tableRow}>
      <Text style={styles.tableHeaderCell}>Kategori</Text>
      <Text style={styles.tableHeaderCell}>Jumlah Siswa</Text>
      <Text style={styles.tableHeaderCell}>Persentase</Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>
        Mulai Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.mulaiBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {Math.round(
          (data.mulaiBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellEven]}>
        Sedang Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {data.sedangBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {Math.round(
          (data.sedangBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>
        Berkembang Sesuai Harapan
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.berkembangBaik}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {Math.round(
          (data.berkembangBaik /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellEven]}>
        Sangat Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {data.sangatBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {Math.round(
          (data.sangatBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>Total</Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.mulaiBerkembang +
          data.sedangBerkembang +
          data.berkembangBaik +
          data.sangatBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>100%</Text>
    </View>
  </View>
);

// ---------------------------------------------- the diagram attribute

const AttributeDiagram: React.FC<AttributeTableProps> = ({ title, data }) => {
  const total =
    data.mulaiBerkembang +
    data.sedangBerkembang +
    data.berkembangBaik +
    data.sangatBerkembang;
  const percentages = {
    mulaiBerkembang: Math.round((data.mulaiBerkembang / total) * 100),
    sedangBerkembang: Math.round((data.sedangBerkembang / total) * 100),
    berkembangBaik: Math.round((data.berkembangBaik / total) * 100),
    sangatBerkembang: Math.round((data.sangatBerkembang / total) * 100),
  };

  return (
    <View style={styles.diagramContainer} wrap={false}>
      <Text style={styles.tableTitle}>{title}</Text>
      <DiagramRow
        label="Mulai Berkembang"
        percentage={percentages.mulaiBerkembang}
        color="#F4A460"
      />
      <DiagramRow
        label="Sedang Berkembang"
        percentage={percentages.sedangBerkembang}
        color="#87CEEB"
      />
      <DiagramRow
        label="Berkembang Sesuai Harapan"
        percentage={percentages.berkembangBaik}
        color="#9370DB"
      />
      <DiagramRow
        label="Sangat Berkembang"
        percentage={percentages.sangatBerkembang}
        color="#90EE90"
      />
    </View>
  );
};

const DiagramRow: React.FC<{
  label: string;
  percentage: number;
  color: string;
}> = ({ label, percentage, color }) => (
  <View style={styles.diagramRow}>
    <Text style={styles.diagramLabel}>{label}</Text>
    <Svg width="100%" height={20} style={styles.diagramBar}>
      <Rect width={`${percentage}%`} height={20} fill={color} />
    </Svg>
    <Text style={styles.diagramPercentage}>{percentage}%</Text>
  </View>
);

// ---------------------------------------------- the component
const ResultPageKondisiKeluarga: FunctionComponent<
  IResultPageKondisiKeluargaProps
> = ({ worksheets }) => {
  // Menghitung jumlah siswa untuk setiap kategori pada atribut MANDIRI
  let CaraOrangTuaMendidikMulaiBerkembang = 0;
  let CaraOrangTuaMendidikSedangBerkembang = 0;
  let CaraOrangTuaMendidikBerkembangBaik = 0;
  let CaraOrangTuaMendidikSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KONDISI_KELUARGA
    );
    if (!questionnaire) return;

    const caraOrangTuaMendidikAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id === AttributeIdEnum_KondisiKeluarga.CARA_ORANG_TUA_MENDIDIK
    );
    if (!caraOrangTuaMendidikAttr) return;

    const caraOrangTuaMendidikValue = caraOrangTuaMendidikAttr.value * 100;
    if (caraOrangTuaMendidikValue < 53) {
      CaraOrangTuaMendidikMulaiBerkembang++;
    } else if (caraOrangTuaMendidikValue < 73) {
      CaraOrangTuaMendidikSedangBerkembang++;
    } else if (caraOrangTuaMendidikValue < 94) {
      CaraOrangTuaMendidikBerkembangBaik++;
    } else {
      CaraOrangTuaMendidikSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut RELASI_ANTARA_ANAK_DAN_ANGGOTA_KELUARGA
  let RelasiAntaraAnakDanAnggotaKeluargaMulaiBerkembang = 0;
  let RelasiAntaraAnakDanAnggotaKeluargaSedangBerkembang = 0;
  let RelasiAntaraAnakDanAnggotaKeluargaBerkembangBaik = 0;
  let RelasiAntaraAnakDanAnggotaKeluargaSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KONDISI_KELUARGA
    );
    if (!questionnaire) return;

    const relasiAntaraAnakDanAnggotaKeluargaAttr =
      questionnaire.result.attributes.find(
        (attr) =>
          attr.id ===
          AttributeIdEnum_KondisiKeluarga.RELASI_ANTARA_ANAK_DAN_ANGGOTA_KELUARGA
      );
    if (!relasiAntaraAnakDanAnggotaKeluargaAttr) return;

    const relasiAntaraAnakDanAnggotaKeluargaValue =
      relasiAntaraAnakDanAnggotaKeluargaAttr.value * 100;
    if (relasiAntaraAnakDanAnggotaKeluargaValue < 53) {
      RelasiAntaraAnakDanAnggotaKeluargaMulaiBerkembang++;
    } else if (relasiAntaraAnakDanAnggotaKeluargaValue < 73) {
      RelasiAntaraAnakDanAnggotaKeluargaSedangBerkembang++;
    } else if (relasiAntaraAnakDanAnggotaKeluargaValue < 83) {
      RelasiAntaraAnakDanAnggotaKeluargaBerkembangBaik++;
    } else {
      RelasiAntaraAnakDanAnggotaKeluargaSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut SUASANA_RUMAH
  let SuasanaRumahMulaiBerkembang = 0;
  let SuasanaRumahSedangBerkembang = 0;
  let SuasanaRumahBerkembangBaik = 0;
  let SuasanaRumahSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KONDISI_KELUARGA
    );
    if (!questionnaire) return;

    const suasanaRumahAttr = questionnaire.result.attributes.find(
      (attr) => attr.id === AttributeIdEnum_KondisiKeluarga.SUASANA_RUMAH
    );
    if (!suasanaRumahAttr) return;

    const suasanaRumahValue = suasanaRumahAttr.value * 100;
    if (suasanaRumahValue < 36) {
      SuasanaRumahMulaiBerkembang++;
    } else if (suasanaRumahValue < 59) {
      SuasanaRumahSedangBerkembang++;
    } else if (suasanaRumahValue < 83) {
      SuasanaRumahBerkembangBaik++;
    } else {
      SuasanaRumahSangatBerkembang++;
    }
  });

  //-------------------------------------------- the percentages

  const totalCaraOrangTuaMendidik =
    CaraOrangTuaMendidikMulaiBerkembang +
    CaraOrangTuaMendidikSedangBerkembang +
    CaraOrangTuaMendidikBerkembangBaik +
    CaraOrangTuaMendidikSangatBerkembang;

  const caraOrangTuaMendidikPercentages = {
    mulaiBerkembang: Math.round(
      (CaraOrangTuaMendidikMulaiBerkembang / totalCaraOrangTuaMendidik) * 100
    ),
    sedangBerkembang: Math.round(
      (CaraOrangTuaMendidikSedangBerkembang / totalCaraOrangTuaMendidik) * 100
    ),
    berkembangBaik: Math.round(
      (CaraOrangTuaMendidikBerkembangBaik / totalCaraOrangTuaMendidik) * 100
    ),
    sangatBerkembang: Math.round(
      (CaraOrangTuaMendidikSangatBerkembang / totalCaraOrangTuaMendidik) * 100
    ),
  };

  const totalRelasiAntaraAnakDanAnggotaKeluarga =
    RelasiAntaraAnakDanAnggotaKeluargaMulaiBerkembang +
    RelasiAntaraAnakDanAnggotaKeluargaSedangBerkembang +
    RelasiAntaraAnakDanAnggotaKeluargaBerkembangBaik +
    RelasiAntaraAnakDanAnggotaKeluargaSangatBerkembang;

  const relasiAntaraAnakDanAnggotaKeluargaPercentages = {
    mulaiBerkembang: Math.round(
      (RelasiAntaraAnakDanAnggotaKeluargaMulaiBerkembang /
        totalRelasiAntaraAnakDanAnggotaKeluarga) *
        100
    ),
    sedangBerkembang: Math.round(
      (RelasiAntaraAnakDanAnggotaKeluargaSedangBerkembang /
        totalRelasiAntaraAnakDanAnggotaKeluarga) *
        100
    ),
    berkembangBaik: Math.round(
      (RelasiAntaraAnakDanAnggotaKeluargaBerkembangBaik /
        totalRelasiAntaraAnakDanAnggotaKeluarga) *
        100
    ),
    sangatBerkembang: Math.round(
      (RelasiAntaraAnakDanAnggotaKeluargaSangatBerkembang /
        totalRelasiAntaraAnakDanAnggotaKeluarga) *
        100
    ),
  };

  const totalSuasanaRumah =
    SuasanaRumahMulaiBerkembang +
    SuasanaRumahSedangBerkembang +
    SuasanaRumahBerkembangBaik +
    SuasanaRumahSangatBerkembang;

  const suasanaRumahPercentages = {
    mulaiBerkembang: Math.round(
      (SuasanaRumahMulaiBerkembang / totalSuasanaRumah) * 100
    ),
    sedangBerkembang: Math.round(
      (SuasanaRumahSedangBerkembang / totalSuasanaRumah) * 100
    ),
    berkembangBaik: Math.round(
      (SuasanaRumahBerkembangBaik / totalSuasanaRumah) * 100
    ),
    sangatBerkembang: Math.round(
      (SuasanaRumahSangatBerkembang / totalSuasanaRumah) * 100
    ),
  };

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <Text style={styles.heading}>Kondisi Keluarga</Text>
        <View style={styles.table1}>
          <View style={styles.tableRow1}>
            <Text style={styles.tableHeaderCellNo1}>No.</Text>
            <Text style={styles.tableHeaderCellName1}>Nama</Text>
            <Text style={styles.tableHeaderCellResult1}>
              Cara Orang Tua Mendidik
            </Text>
            <Text style={styles.tableHeaderCellResult1}>Kategori</Text>
            <Text style={styles.tableHeaderCellResult1}>
              Relasi Anak dan Anggota Keluarga
            </Text>
            <Text style={styles.tableHeaderCellResult1}>Kategori</Text>
            <Text style={styles.tableHeaderCellResult1}>Suasana Rumah</Text>
            <Text style={styles.tableHeaderCellResult1}>Kategori</Text>
          </View>

          {worksheets &&
            worksheets.map((worksheet, index) => {
              const { profile, questionnaires } = worksheet;

              const questionnaire = questionnaires.find(
                (q) => q.assessment.id === AssessmentIdEnum.KONDISI_KELUARGA
              );

              if (!questionnaire) return null;

              const { result } = questionnaire;

              const caraOrangTuaMendidikAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KondisiKeluarga.CARA_ORANG_TUA_MENDIDIK
              );
              const relasiAntaraAnakDanAnggotaKeluargaAttr =
                result.attributes.find(
                  (attr) =>
                    attr.id ===
                    AttributeIdEnum_KondisiKeluarga.RELASI_ANTARA_ANAK_DAN_ANGGOTA_KELUARGA
                );
              const suasanaRumahAttr = result.attributes.find(
                (attr) =>
                  attr.id === AttributeIdEnum_KondisiKeluarga.SUASANA_RUMAH
              );

              const caraOrangTuaMendidikValue = caraOrangTuaMendidikAttr
                ? (caraOrangTuaMendidikAttr.value * 100).toFixed(0)
                : "-";
              const relasiAntaraAnakDanAnggotaKeluargaValue =
                relasiAntaraAnakDanAnggotaKeluargaAttr
                  ? (
                      relasiAntaraAnakDanAnggotaKeluargaAttr.value * 100
                    ).toFixed(0)
                  : "-";
              const suasanaRumahValue = suasanaRumahAttr
                ? (suasanaRumahAttr.value * 100).toFixed(0)
                : "-";

              return (
                <View
                  key={worksheet.id}
                  style={[
                    styles.tableRow,
                    index % 2 === 0 ? styles.evenRow1 : styles.oddRow1,
                  ]}
                  wrap={false}>
                  <Text style={styles.tableCellNo1}>{index + 1}</Text>
                  <Text style={styles.tableCellName1}>{profile.name}</Text>
                  <Text style={styles.tableCellResult1}>
                    {caraOrangTuaMendidikValue}
                  </Text>
                  <Text style={styles.tableCellResult1}>
                    {parseFloat(caraOrangTuaMendidikValue) < 53
                      ? "Mulai Berkembang"
                      : parseFloat(caraOrangTuaMendidikValue) < 73
                      ? "Sedang Berkembang"
                      : parseFloat(caraOrangTuaMendidikValue) < 94
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCellResult1}>
                    {relasiAntaraAnakDanAnggotaKeluargaValue}
                  </Text>
                  <Text style={styles.tableCellResult1}>
                    {parseFloat(relasiAntaraAnakDanAnggotaKeluargaValue) < 53
                      ? "Mulai Berkembang"
                      : parseFloat(relasiAntaraAnakDanAnggotaKeluargaValue) < 73
                      ? "Sedang Berkembang"
                      : parseFloat(relasiAntaraAnakDanAnggotaKeluargaValue) < 83
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCellResult1}>
                    {suasanaRumahValue}
                  </Text>
                  <Text style={styles.tableCellResult1}>
                    {parseFloat(suasanaRumahValue) < 36
                      ? "Mulai Berkembang"
                      : parseFloat(suasanaRumahValue) < 59
                      ? "Sedang Berkembang"
                      : parseFloat(suasanaRumahValue) < 83
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                </View>
              );
            })}
          <Text style={styles.tableCell1}></Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.section}>
          <AttributeTable
            title="Cara Orang Tua Mendidik"
            data={{
              mulaiBerkembang: CaraOrangTuaMendidikMulaiBerkembang,
              sedangBerkembang: CaraOrangTuaMendidikSedangBerkembang,
              berkembangBaik: CaraOrangTuaMendidikBerkembangBaik,
              sangatBerkembang: CaraOrangTuaMendidikSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Cara Orang Tua Mendidik"
            data={{
              mulaiBerkembang: CaraOrangTuaMendidikMulaiBerkembang,
              sedangBerkembang: CaraOrangTuaMendidikSedangBerkembang,
              berkembangBaik: CaraOrangTuaMendidikBerkembangBaik,
              sangatBerkembang: CaraOrangTuaMendidikSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran orang tua dalam memberikan contoh dan teladan yang baik
              kepada anak sebagai upaya orang tua dalam memberikan dukungan
              secara mental dan psikologis bagi anak dalam lingkungan keluarga.
              Memperhatikan pendidikan anaknya, memperhatikan
              kepentingan-kepentingan dan kebutuhan- kebutuhan anaknya dalam
              belajar, mampu mengatur waktu belajarnya. Mengetahui kemajuan
              belajar dan kesulitan-kesulitan yang dialami dalam belajar.
            </Text>

            <View style={styles.categoryContainer}>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran peran orang tua yang mulai berkembangdalam
                    memberikan contoh dan teladan yang baik kepada anak sebagai
                    upaya orang tua dalam memberikan dukungan secara mental dan
                    psikologis bagi anak dalam lingkungan keluarga.
                    Memperhatikan pendidikan anaknya, memperhatikan
                    kepentingan-kepentingan dan kebutuhan-kebutuhan anaknya
                    dalam belajar, mampu mengatur waktu belajarnya. Mengetahui
                    kemajuan belajar dan kesulitan-kesulitan yang dialami dalam
                    belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {caraOrangTuaMendidikPercentages.mulaiBerkembang}%
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran peran orang tua yang sedang berkembang dalam
                    memberikan contoh dan teladan yang baik kepada anak sebagai
                    upaya orang tua dalam memberikan dukungan secara mental dan
                    psikologis bagi anak dalam lingkungan keluarga.
                    Memperhatikan pendidikan anaknya, memperhatikan
                    kepentingan-kepentingan dan kebutuhan-kebutuhan anaknya
                    dalam belajar, mampu mengatur waktu belajarnya. Mengetahui
                    kemajuan belajar dan kesulitan-kesulitan yang dialami dalam
                    belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {caraOrangTuaMendidikPercentages.sedangBerkembang}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran peran orang tua yang berkembang sesuai harapan
                    dalam memberikan contoh dan teladan yang baik kepada anak
                    sebagai upaya orang tua dalam memberikan dukungan secara
                    mental dan psikologis bagi anak dalam lingkungan keluarga.
                    Memperhatikan pendidikan anaknya, memperhatikan kepentingan-
                    kepentingan dan kebutuhan-kebutuhan anaknya dalam belajar,
                    mampu mengatur waktu belajarnya. Mengetahui kemajuan belajar
                    dan kesulitan-kesulitan yang dialami dalam belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {caraOrangTuaMendidikPercentages.berkembangBaik}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran peran orang tua yang sangat berkembang dalam
                    memberikan contoh dan teladan yang baik kepada anak sebagai
                    upaya orang tua dalam memberikan dukungan secara mental dan
                    psikologis bagi anak dalam lingkungan keluarga.
                    Memperhatikan pendidikan anaknya, memperhatikan
                    kepentingan-kepentingan dan kebutuhan-kebutuhan anaknya
                    dalam belajar, mampu mengatur waktu belajarnya. Mengetahui
                    kemajuan belajar dan kesulitan-kesulitan yang dialami dalam
                    belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {caraOrangTuaMendidikPercentages.sangatBerkembang}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Relasi antara Anak dan Anggota Keluarga"
            data={{
              mulaiBerkembang:
                RelasiAntaraAnakDanAnggotaKeluargaMulaiBerkembang,
              sedangBerkembang:
                RelasiAntaraAnakDanAnggotaKeluargaSedangBerkembang,
              berkembangBaik: RelasiAntaraAnakDanAnggotaKeluargaBerkembangBaik,
              sangatBerkembang:
                RelasiAntaraAnakDanAnggotaKeluargaSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Relasi antara Anak dan Anggota Keluarga"
            data={{
              mulaiBerkembang:
                RelasiAntaraAnakDanAnggotaKeluargaMulaiBerkembang,
              sedangBerkembang:
                RelasiAntaraAnakDanAnggotaKeluargaSedangBerkembang,
              berkembangBaik: RelasiAntaraAnakDanAnggotaKeluargaBerkembangBaik,
              sangatBerkembang:
                RelasiAntaraAnakDanAnggotaKeluargaSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan mengenai kondisi
              keharmonisan, situasi atau kejadian- kejadian yang sering terjadi
              di dalam keluarga dimana anak berada dan belajar. Mendukung anak
              untuk menciptakan suasana belajar yang baik dan kondusif. Sehingga
              rumah bisa menjadi salah satu tempat belajar yang nyaman bagi
              anak.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran yang mulai berkembang mengenai hubungan anak dan
                    anggota keluarga yang penuh pengertian dan kasih sayang,
                    disertai dengan bimbingan dan bila perlu hukuman-hukuman
                    untuk mensukseskan belajar anak.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {
                      relasiAntaraAnakDanAnggotaKeluargaPercentages.mulaiBerkembang
                    }
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran yang sedang berkembang mengenai hubungan anak dan
                    anggota keluarga yang penuh pengertian dan kasih sayang,
                    disertai dengan bimbingan dan bila perlu hukuman-hukuman
                    untuk mensukseskan belajar anak.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {
                      relasiAntaraAnakDanAnggotaKeluargaPercentages.sedangBerkembang
                    }
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran yang berkembang sesuai harapan mengenai hubungan
                    anak dan anggota keluarga yang penuh pengertian dan kasih
                    sayang, disertai dengan bimbingan dan bila perlu
                    hukuman-hukuman untuk mensukseskan belajar anak.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {
                      relasiAntaraAnakDanAnggotaKeluargaPercentages.berkembangBaik
                    }
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran yang sangat berkembang mengenai hubungan anak dan
                    anggota keluarga yang penuh pengertian dan kasih sayang,
                    disertai dengan bimbingan dan bila perlu hukuman-hukuman
                    untuk mensukseskan belajar anak.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {
                      relasiAntaraAnakDanAnggotaKeluargaPercentages.sangatBerkembang
                    }
                    %
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Suasana Rumah"
            data={{
              mulaiBerkembang: SuasanaRumahMulaiBerkembang,
              sedangBerkembang: SuasanaRumahSedangBerkembang,
              berkembangBaik: SuasanaRumahBerkembangBaik,
              sangatBerkembang: SuasanaRumahSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Suasana Rumah"
            data={{
              mulaiBerkembang: SuasanaRumahMulaiBerkembang,
              sedangBerkembang: SuasanaRumahSedangBerkembang,
              berkembangBaik: SuasanaRumahBerkembangBaik,
              sangatBerkembang: SuasanaRumahSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan mengenai keharmonisan,
              situasi atau kejadian-kejadian yang sering terjadi di dalam
              keluarga dimana anak berada dan belajar. Mendukung anak untuk
              menciptakan suasana belajar yang baik dan kondusif. Sehingga rumah
              bisa menjadi salah satu tempat belajar yang nyaman bagi anak.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran kondisi yang mulai berkembang mengenai
                    keharmonisan, situasi atau kejadian-kejadian yang sering
                    terjadi di dalam keluarga dimana anak berada dan belajar.
                    Mendukung anak untuk menciptakan suasana belajar yang baik
                    dan kondusif. Sehingga rumah bisa menjadi salah satu tempat
                    belajar yang nyaman.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {suasanaRumahPercentages.mulaiBerkembang}%
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran kondisi yang sedang berkembang mengenai
                    keharmonisan, situasi atau kejadian-kejadian yang sering
                    terjadi di dalam keluarga dimana anak berada dan belajar.
                    Mendukung anak untuk menciptakan suasana belajar yang baik
                    dan kondusif. Sehingga rumah bisa menjadi salah satu tempat
                    belajar yang nyaman.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {suasanaRumahPercentages.sedangBerkembang}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran kondisi yang berkembang sesuai harapan mengenai
                    keharmonisan, situasi atau kejadian-kejadian yang sering
                    terjadi di dalam keluarga dimana anak berada dan belajar.
                    Mendukung anak untuk menciptakan suasana belajar yang baik
                    dan kondusif. Sehingga rumah bisa menjadi salah satu tempat
                    belajar yang nyaman.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {suasanaRumahPercentages.berkembangBaik}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Gambaran kondisi yang sangat berkembang mengenai
                    keharmonisan, situasi atau kejadian-kejadian yang sering
                    terjadi di dalam keluarga dimana anak berada dan belajar.
                    Mendukung anak untuk menciptakan suasana belajar yang baik
                    dan kondusif. Sehingga rumah bisa menjadi salah satu tempat
                    belajar yang nyaman.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {suasanaRumahPercentages.sangatBerkembang}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <View style={styles.section}>
            <Text style={styles.categoryTitle}>
              Saran untuk Pengembangan Orang Tua dan Anak{" "}
            </Text>
            <Text style={styles.categoryDescription}>
              Ketika usia anak mulai bertambah, anak-anak akan lebih mudah dalam
              mengekspresikan emosinya. Mereka mulai memiliki kesenangan dan
              kesibukkannya sendiri. terkadang anak-anak memilih untuk
              menghabiskan waktu bersama teman dibandingkan keluarga.
            </Text>
            <Text style={styles.categoryDescription}>
              Selain itu, orang tua akan disibukkan dengan rutinitas pekerjaan.
              sehingga perlu adanya upaya untuk menjaga hubungan yang baik
              antara anak dan orang tua dalam keluarga. diantaranya dengan
              memberikan kesempatan bagi anak untuk mengekspresikan perasaannya
              baik positif ataupun negative, orang tua diharapkan tidak
              menghakimi yang dilakukan oleh anak jika tidak sesuai dengan
              aturan yang diterapkan dalam keluarga karena akan menimbulkan
              upaya memberontak dari anak.
            </Text>
            <Text style={styles.categoryDescription}>
              Solusi agar menghindari menghakimi anak dengan memberikan ruang
              diskusi bersama, libatkan anak dalam setiap kegiatan keluarga,
              supaya anak dapat merasakan peran dan memiliki tanggung jawab
              dalam keluarga.
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ResultPageKondisiKeluarga;
