// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import QuestionnaireListItem from "./questionnaire_list_item";

import { IQuestionnaireListProps } from "./common";

// ---------------------------------------------- the component
const QuestionnaireList: FunctionComponent<IQuestionnaireListProps> = ({
  questionnaires,
  questionnaireStatuses,
}) => {
  // ---------------------------------------------- content
  return (
    <div className="relative shadow-md rounded-lg bg-gray-600">
      <div className="w-full text-sm text-left text-gray-400">
        <div className="max-h-96 md: xl:max-h-full sm:overflow-visible">
          <section>
            <h1 className="text-xs uppercase bg-gray-7000 text-gray-400 py-2 px-2">
              Assessments
            </h1>
            <div className=" overflow-auto md:h-64 h-32">
              {questionnaires.map((questionnaire) => {
                const isCompleted = questionnaireStatuses.find(
                  (status) =>
                    status.assessmentId === questionnaire.assessment.id
                )!.isCompleted;

                return (
                  <QuestionnaireListItem
                    key={questionnaire.assessment.id}
                    questionnaire={questionnaire}
                    isCompleted={isCompleted}
                  />
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireList;
