// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { IAssessment, IAssessmentRecord } from "../../models/assessment";
import {
  IParticipantGroup,
  IParticipantGroupRecord,
} from "../../models/participant_group";
import { AssessmentIdEnum } from "../../enums/assessment_id";

// ---------------------------------------------- the hooks
export const useDashboardContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [assessments, setAssessments] = useState<IAssessment[]>([]);
  const [participantGroups, setParticipantGroups] = useState<
    IParticipantGroup[]
  >([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- effects
  useEffect(() => {
    setFetching(true);

    const ref = firebase.firestore.collection(PATHS.ASSESSMENT).orderBy("name");

    const unsubscribe = ref.onSnapshot(
      (querySnapshot) => {
        setAssessments(
          querySnapshot.docs.reduce(
            (categories, doc) => [
              ...categories,
              {
                id: doc.id as AssessmentIdEnum,
                ...(doc.data() as IAssessmentRecord),
              },
            ],
            [] as IAssessment[]
          )
        );

        setError(null);
        setFetching(false);
      },
      (error) => {
        setError(error.message);
        setFetching(false);
      }
    );

    return () => unsubscribe();
  }, [firebase]);

  useEffect(() => {
    setFetching(true);

    const ref = firebase.firestore
      .collection(PATHS.PARTICIPANT_GROUP)
      .orderBy("dateCreated", "desc");

    const unsubscribe = ref.onSnapshot(
      (querySnapshot) => {
        setParticipantGroups(
          querySnapshot.docs.reduce(
            (participantGroups, doc) => [
              ...participantGroups,
              {
                id: doc.id,
                ...(doc.data() as IParticipantGroupRecord),
              },
            ],
            [] as IParticipantGroup[]
          )
        );

        setError(null);
        setFetching(false);
      },
      (error) => {
        setError(error.message);
        setFetching(false);
      }
    );

    return () => unsubscribe();
  }, [firebase]);

  // ---------------------------------------------- return value
  return { assessments, participantGroups, error, fetching };
};
