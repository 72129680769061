// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IModuleContextProviderState,
  IModuleContextProviderProps,
} from "./common";
import { useModuleContext } from "./hooks";
import { emptyParticipantGroup } from "../../models/participant_group";

// ---------------------------------------------- create context
const initialState: IModuleContextProviderState = {
  participantGroup: emptyParticipantGroup(),
  modules: [],
  error: null,
  fetching: true,
  onChangeParticipantGroup: () => {
    return;
  },
};

export const ModuleContext = createContext(initialState);

// ---------------------------------------------- the component
const ModuleContextProvider: FunctionComponent<IModuleContextProviderProps> = ({
  children,
}) => {
  // ---------------------------------------------- local state
  const {
    participantGroup,
    modules,
    error,
    fetching,
    onChangeParticipantGroup,
  } = useModuleContext();

  // ---------------------------------------------- content
  return (
    <ModuleContext.Provider
      value={{
        participantGroup,
        modules,
        error,
        fetching,
        onChangeParticipantGroup,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

export default ModuleContextProvider;
