// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { AssessmentIdEnum } from "../../enums/assessment_id";
import { IAssessment, IAssessmentRecord } from "../../models/assessment";

// ---------------------------------------------- the hooks
export const useAssessmentContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [assessments, setAssessments] = useState<IAssessment[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- effects
  useEffect(() => {
    setFetching(true);

    const ref = firebase.firestore.collection(PATHS.ASSESSMENT).orderBy("name");

    const unsubscribe = ref.onSnapshot(
      (querySnapshot) => {
        setAssessments(
          querySnapshot.docs.reduce(
            (assessments, doc) => [
              ...assessments,
              {
                id: doc.id as AssessmentIdEnum,
                ...(doc.data() as IAssessmentRecord),
              },
            ],
            [] as IAssessment[]
          )
        );

        setError(null);
        setFetching(false);
      },
      (error) => {
        setError(error.message);
        setFetching(false);
      }
    );

    return () => unsubscribe();
  }, [firebase]);

  // ---------------------------------------------- return value
  return { assessments, error, fetching };
};
