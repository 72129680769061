// ---------------------------------------------- modules import
import { useContext, useEffect, useState } from "react";

import { WorksheetContext } from "../../../../contexts/worksheet/context";
import { IModule } from "../../../../models/module";

// ---------------------------------------------- the hook
export const useModuleTable = () => {
  // ---------------------------------------------- consume context
  const { participantGroup, modules, error, fetching, onChangeModule } =
    useContext(WorksheetContext);

  // ---------------------------------------------- local state
  const [criteria, setCriteria] = useState({
    keywords: "",
    prop: "name",
  });
  const [filtered, setFiltered] = useState<IModule[]>([]);
  const [sortBy, setSortBy] = useState({
    asc: 1,
    prop: "name",
  });
  const [sorted, setSorted] = useState<IModule[]>([]);

  // ---------------------------------------------- handlers
  const handleFilter = (criterion: string, value: string) => {
    if (/^[^()[\\\]|]{0,}$/.test(value))
      setCriteria({
        ...criteria,
        [criterion]: value,
      });
  };

  const handleSort = (prop: string) =>
    setSortBy((prev) => ({
      ...prev,
      prop,
      asc: prev.asc * -1,
    }));

  // ---------------------------------------------- effects
  useEffect(() => {
    const { prop, asc } = sortBy;

    setSorted(
      [...modules].sort((a, b) => (a[prop] > b[prop] ? asc : asc * -1))
    );
  }, [sortBy, modules]);

  useEffect(() => {
    const { keywords, prop } = criteria;

    setFiltered(
      sorted.filter((module) => RegExp(`${keywords}`, "i").test(module[prop]))
    );
  }, [criteria, sorted]);

  // ---------------------------------------------- return value
  return {
    criteria,
    error,
    fetching,
    filtered,
    participantGroup,
    modules,
    sortBy,
    onChangeModule,
    onFilter: handleFilter,
    onSort: handleSort,
  };
};
