// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import { IOption } from "../models/question";

export const pattern: IPattern = {
  text: {
    minLength: 1,
    maxLength: 1800,
    regex: /^[\w\s&,.():\-'"/?!=%*|_]{1,1800}$/,
  },
};

const validateOption = (
  option: IOption,
  propsToValidate = ["text"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { value } = option;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["value"] =
    typeof value === "string"
      ? value.trim() !== ""
      : typeof value === "number"
      ? true
      : false;

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    option,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateOption;
