// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import { IProvince } from "../models/province";

export const pattern: IPattern = {
  altName: {
    minLength: 2,
    maxLength: 100,
    regex: /^[\w\s&,.():\-'"/]{2,100}$/,
  },
  name: {
    minLength: 2,
    maxLength: 50,
    regex: /^[\w\s&,.():\-'"/]{2,50}$/,
  },
};

const validateProvince = (
  province: IProvince,
  propsToValidate = ["name"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { altName } = province;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["altName"] = altName
    ? pattern.altName.regex.test(altName)
    : true;

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    province,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateProvince;
