// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import ModuleRow from "./module_row";
import ModuleSearchForm from "./module_search_form";
import LinkButton from "../../../components/ui/buttons/link_button";

import * as ROUTES from "../../../constants/routes";

import { IModuleTableProps } from "./common";
import { useModuleTable } from "./hooks/use_module_table";

// ---------------------------------------------- the component
const ModuleTable: FunctionComponent<IModuleTableProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    criteria,
    error,
    fetching,
    filtered,
    participantGroup,
    modules,
    sortBy,
    onChangeModule,
    onFilter,
    onSort,
  } = useModuleTable();

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col h-screen p-8">
      <div className="mb-6">
        <div className="flex flex-col">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-2">
            REPORT
          </h1>
          <div className="text-lg md:text-xl font-bold text-yellow-400">
            <p>Module Table of {participantGroup.name}</p>
            <p className="text-sm md:text-base text-gray-300">
              ID: {participantGroup.id}
            </p>
          </div>
        </div>
      </div>

      <ModuleSearchForm
        criteria={criteria}
        error={error}
        fetching={fetching}
        result={{
          filtered: filtered.length,
          total: modules.length,
        }}
        onFilter={onFilter}
      />

      <section className="shadow-lg overflow-x-auto max-h-screen">
        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-sm text-left text-gray-400">
            <thead className="text-xs uppercase bg-gray-500 text-gray-800">
              <tr>
                {[
                  {
                    prop: "name",
                    label: "Name",
                    className: "px-6 py-3",
                  },
                  {
                    prop: "",
                    label: "Assessments",
                    className: "px-6 py-3 hidden md:table-cell",
                  },
                  {
                    prop: "lastModified",
                    label: "Last Modified",
                    className: "px-6 py-3 hidden md:table-cell",
                  },
                ].map((header, index) => {
                  const { prop, label, className } = header;

                  return (
                    <th
                      className={className}
                      key={index}
                      style={{ cursor: prop ? "pointer" : "default" }}
                      onClick={() => {
                        if (!prop) return;

                        onSort(prop);
                      }}
                    >
                      <div className="flex items-center ">
                        {label}

                        {sortBy.prop === header.prop && (
                          <FontAwesomeIcon
                            icon={sortBy.asc > 0 ? faSortDown : faSortUp}
                            className="ml-1"
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody className="">
              {!fetching
                ? filtered.map((module) => (
                    <ModuleRow
                      key={module.id}
                      module={module}
                      onChangeModule={onChangeModule}
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </section>

      <div className="bottom-4 right-4 absolute">
        <LinkButton
          className="flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full hover:bg-blue-600"
          to={ROUTES.REPORT}
          valid
        >
          <FontAwesomeIcon icon={faArrowLeft} className="text-lg" />
        </LinkButton>
      </div>
    </div>
  );
};

export default ModuleTable;
