// ---------------------------------------------- modules import
import { Page, StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import Logo from "../../../../assets/images/logo_report.png";

import { IResultPageRiasecProps } from "./common";
import { IConclusion_Riasec } from "../conclusion/riasec/conclusion";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";
import { AttributeIdEnum_Riasec } from "../../../../enums/attribute_id";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#3b1c78",
  },
  description: {
    marginBottom: 10,
    textAlign: "justify",
    fontSize: 14,
  },
  table: {
    display: "flex",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    backgroundColor: "#FFCC00",
    color: "#000000",
    fontSize: 12,
    fontWeight: "bold",
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    width: 70,
  },
  tableCell: {
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    width: 70,
    textAlign: "center",
  },
  tableHeaderCellName: {
    backgroundColor: "#FFCC00",
    color: "#000000",
    fontSize: 12,
    fontWeight: "bold",
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    width: 100,
  },
  tableCellName: {
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    width: 100,
  },
  tableHeaderCellNo: {
    backgroundColor: "#FFCC00",
    color: "#000000",
    fontSize: 12,
    fontWeight: "bold",
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    width: 30,
  },
  tableCellNo: {
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    width: 30,
  },
  credit: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    width: 100,
    justifyContent: "center",
  },
});
// ---------------------------------------------- the component
const ResultPageRiasec: FunctionComponent<IResultPageRiasecProps> = ({
  worksheets,
}) => {
  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.logoContainer}>
        <Image source={Logo} style={styles.logo} />
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Kepribadian</Text>

        <Text style={styles.description}>
          Asesmen RIASEC digunakan untuk mengeksplorasi minat karier individu
          berdasarkan enam tipe kepribadian: Realistik, Investigatif, Artistik,
          Sosial, Enterprising, dan Konvensional. Asesmen ini membantu individu
          memahami preferensi mereka dalam lingkungan kerja, keahlian yang
          sesuai, dan bidang pekerjaan yang cocok dengan kepribadian mereka.
          Dengan memahami profil RIASEC mereka, individu dapat membuat pilihan
          karier yang lebih sesuai dengan minat dan potensi mereka.
        </Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableHeaderCellNo}>No.</Text>
            <Text style={styles.tableHeaderCellName}>Nama</Text>
            <Text style={styles.tableHeaderCellName}>Kode Unik</Text>
            <Text style={styles.tableHeaderCell}>Realistic</Text>
            <Text style={styles.tableHeaderCell}>Investigative</Text>
            <Text style={styles.tableHeaderCell}>Artistic</Text>
            <Text style={styles.tableHeaderCell}>Social</Text>
            <Text style={styles.tableHeaderCell}>Enterprising</Text>
            <Text style={styles.tableHeaderCell}>Conventional</Text>
            <Text style={styles.tableHeaderCell}>Atribut Primer</Text>
            <Text style={styles.tableHeaderCell}>Atribut Sekunder</Text>
          </View>

          {worksheets &&
            worksheets.map((worksheet, index) => {
              const { profile, questionnaires } = worksheet;

              const questionnaire = questionnaires.find(
                (questionnaire) =>
                  questionnaire.assessment.id === AssessmentIdEnum.RIASEC
              );

              if (!questionnaire) return null;

              const { result } = questionnaire;

              const conclusion = JSON.parse(
                result.conclusion
              ) as IConclusion_Riasec[];

              const [dominantConclusion, subdominantConclusion] = conclusion;

              let realistic = "-1";
              let investigative = "-1";
              let artisitic = "-1";
              let social = "-1";
              let enterprising = "-1";
              let conventional = "-1";

              result.attributes.forEach((attribute) => {
                switch (attribute.id) {
                  case AttributeIdEnum_Riasec.R: {
                    realistic = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Riasec.I: {
                    investigative = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Riasec.A: {
                    artisitic = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Riasec.S: {
                    social = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Riasec.E: {
                    enterprising = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  case AttributeIdEnum_Riasec.C: {
                    conventional = (attribute.value * 100).toFixed(0);
                    break;
                  }
                  default: {
                    return;
                  }
                }
              });

              return (
                <View key={worksheet.id} style={styles.tableRow}>
                  <Text style={styles.tableCellNo}>{index + 1}</Text>
                  <Text style={styles.tableCellName}>{profile.name}</Text>
                  <Text style={styles.tableCellName}>{profile.phone}</Text>
                  <Text style={styles.tableCell}>{realistic}</Text>
                  <Text style={styles.tableCell}>{investigative}</Text>
                  <Text style={styles.tableCell}>{artisitic}</Text>
                  <Text style={styles.tableCell}>{social}</Text>
                  <Text style={styles.tableCell}>{enterprising}</Text>
                  <Text style={styles.tableCell}>{conventional}</Text>
                  <Text style={styles.tableCell}>
                    {dominantConclusion.name}
                  </Text>
                  <Text style={styles.tableCell}>
                    {subdominantConclusion.name}
                  </Text>
                </View>
              );
            })}
        </View>
      </View>
      <Text style={styles.credit} fixed>
        ganesa.id
      </Text>
    </Page>
  );
};

export default ResultPageRiasec;
