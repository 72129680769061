// ---------------------------------------------- modules import
import { useNavigate } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";
import { IQuestionnaire } from "../../../../models/worksheet";

// ---------------------------------------------- the hooks
export const useQuestionnaireListItem = (questionnaire: IQuestionnaire) => {
  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- handlers
  const handleClick = () => {
    navigate(`${ROUTES.QUIZ}/${questionnaire.assessment.id}`, {
      replace: true,
    });
  };

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
