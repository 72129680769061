// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

// ---------------------------------------------- the component
const NowLoading: FunctionComponent = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "white",
    }}
  >
    <h2>Now loading...</h2>
  </div>
);

export default NowLoading;
