// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faEdit,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import LinkButton from "../../../components/ui/buttons/link_button";
import SelectInput from "../../../components/ui/inputs/select_input";
import TextInput from "../../../components/ui/inputs/text_input";
import UpdateModal from "../../../components/ui/modals/update_modal";
import StatusBadge from "../../../components/ui/others/status_badge";

import * as ROUTES from "../../../constants/routes";
import { emptyProvince } from "../../../models/province";
import { emptyRegency } from "../../../models/regency";
import { pattern } from "../../../validations/participant_group";

import { useUpdateParticipantGroupForm } from "./hooks/use_update_participant_group_form";

// ---------------------------------------------- the component
const UpdateParticipantGroupForm: FunctionComponent = () => {
  // ---------------------------------------------- local state
  const {
    error,
    fetching,
    loading,
    participantGroup,
    provinceOptions,
    regencyOptions,
    isParticipantGroupValid,
    onChange,
    onSubmit,
  } = useUpdateParticipantGroupForm();

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <div className="max-w-2xl mx-auto">
        <form
          onSubmit={onSubmit}
          className="bg-gray-800 rounded-lg shadow-lg overflow-hidden"
        >
          <div className="p-8">
            <h1 className="text-3xl font-bold text-white mb-2 flex items-center">
              <FontAwesomeIcon icon={faEdit} className="mr-3 text-blue-400" />
              UPDATE FORM
            </h1>
            <p className="text-gray-400 mb-6">
              Update group name, province, regency and description details
            </p>

            <div className="space-y-6">
              <TextInput
                className_root="mb-4"
                className_input="w-full bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                id="name"
                label="Name"
                name="name"
                pattern={pattern.name}
                required
                type="text"
                value={participantGroup.name}
                onChange={onChange}
              />

              <TextInput
                className_root="mb-4"
                className_input="w-full bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                id="description"
                label="Description"
                name="description"
                pattern={pattern.description}
                required={false}
                type="text"
                value={participantGroup.description}
                onChange={onChange}
              />

              <div className="grid md:grid-cols-2 gap-4">
                <SelectInput
                  className_root="mb-4"
                  className_select="block w-full p-2.5 bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  defaultOption={{
                    label: "set province",
                    value: emptyProvince().id,
                  }}
                  fallbackValue={emptyProvince().id}
                  id="participant-group-update-form-province-options"
                  label="Province"
                  name="province"
                  options={provinceOptions}
                  value={participantGroup.location.province.id}
                  onChange={onChange}
                />

                <SelectInput
                  className_root="mb-4"
                  className_select="block w-full p-2.5 bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  defaultOption={{
                    label: "set regency",
                    value: emptyRegency().id,
                  }}
                  fallbackValue={emptyRegency().id}
                  id="participant-group-update-form-regency-options"
                  label="Regency"
                  name="regency"
                  options={regencyOptions}
                  value={participantGroup.location.regency.id}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>

          <div className="bg-gray-700 px-8 py-4 flex justify-between items-center">
            <LinkButton
              className="flex items-center text-gray-300 hover:text-white transition duration-300 ease-in-out"
              to={ROUTES.PARTICIPANT_GROUP}
              valid={!fetching && !loading}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back
            </LinkButton>

            <UpdateModal
              id="participant-group-update-modal"
              valid={!fetching && !loading && isParticipantGroupValid()}
              className={`flex items-center px-4 py-2 rounded-lg transition duration-300 ease-in-out ${
                !fetching && !loading && isParticipantGroupValid()
                  ? "bg-blue-500 hover:bg-blue-600 text-white"
                  : "bg-gray-600 text-gray-400 cursor-not-allowed"
              }`}
            >
              <FontAwesomeIcon icon={faGraduationCap} className="mr-2" />
              Update Group
            </UpdateModal>
          </div>
        </form>

        <div className="mt-6 flex justify-center">
          <StatusBadge fetching={fetching} loading={loading} error={error} />
        </div>
      </div>
    </div>
    // <div className="flex justify-center items-center h-screen">
    //   <form
    //     onSubmit={onSubmit}
    //     className=" w-full max-w-md bg-gray-800 px-9 pt-8 pb-8 rounded-lg"
    //   >
    //     <div className="">
    //       <h1 className="text-3xl text-gray-100">UPDATE FORM</h1>

    //       <h2 className="text-lg text-yellow-400">UPDATE PARTICIPANT GROUP</h2>

    //       <hr className="h-px my-4 border-0 bg-gray-400"></hr>

    //       <TextInput
    //         active={false}
    //         characterCounter={false}
    //         className_root=" my-4"
    //         id="name"
    //         label="Name"
    //         name="name"
    //         pattern={pattern.name}
    //         required
    //         type="text"
    //         value={participantGroup.name}
    //         onChange={onChange}
    //       />

    //       <SelectInput
    //         className=""
    //         defaultOption={{
    //           label: "set province",
    //           value: emptyProvince().id,
    //         }}
    //         fallbackValue={emptyProvince().id}
    //         id="participant-group-update-form-province-options"
    //         label="Province"
    //         name="province"
    //         options={provinceOptions}
    //         value={participantGroup.location.province.id}
    //         onChange={onChange}
    //       />

    //       <SelectInput
    //         className=""
    //         defaultOption={{
    //           label: "set regency",
    //           value: emptyRegency().id,
    //         }}
    //         fallbackValue={emptyRegency().id}
    //         id="participant-group-update-form-regency-options"
    //         label="Regency"
    //         name="regency"
    //         options={regencyOptions}
    //         value={participantGroup.location.regency.id}
    //         onChange={onChange}
    //       />

    //       <TextInput
    //         active={false}
    //         characterCounter={false}
    //         className_root="my-4"
    //         id="description"
    //         label="Description"
    //         name="description"
    //         pattern={pattern.description}
    //         required={false}
    //         type="text"
    //         value={participantGroup.description}
    //         onChange={onChange}
    //       />
    //     </div>

    //     <hr className="h-px my-4 border-0 bg-gray-700"></hr>

    //     <div className="flex justify-between mt-4">
    //       <div>
    //         <LinkButton
    //           className="flex items-center text-blue-500 hover:text-blue-700 my-4"
    //           floated
    //           to={ROUTES.PARTICIPANT_GROUP}
    //           valid={!fetching && !loading}
    //         >
    //           <FontAwesomeIcon icon={faArrowLeft} />
    //         </LinkButton>
    //       </div>

    //       <div className="flex items-center text-blue-500 hover:text-blue-700">
    //         <UpdateModal
    //           id="participant-group-update-modal"
    //           valid={!fetching && !loading && isParticipantGroupValid()}
    //         />
    //       </div>
    //     </div>
    //   </form>

    //   <StatusBadge fetching={fetching} loading={loading} error={error} />
    // </div>
  );
};

export default UpdateParticipantGroupForm;
