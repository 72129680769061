// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsKondisiKeluargaProps } from "./common";
import { IConclusion_KondisiKeluarga } from "../conclusion/kondisi_keluarga/conclusion";

// ---------------------------------------------- the component
const DetailsKondisiKeluarga: FunctionComponent<
  IDetailsKondisiKeluargaProps
> = ({ questionnaire }) => {
  const { assessment, result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_KondisiKeluarga[];

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-gray-700 p-4 rounded-lg shadow-md mb-4 md:mb-0">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            Attributes
          </h3>
          <ul className="space-y-2">
            {result.attributes.map((attribute) => (
              <li
                key={attribute.id}
                className="flex items-center justify-between"
              >
                <span className="font-semibold text-gray-300">
                  {attribute.name}
                </span>
                <span className="text-yellow-400 font-bold">{`${(
                  attribute.value * 100
                ).toFixed(0)}%`}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="md:col-span-2">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            Conclusions
          </h3>
          <div className="space-y-4">
            {conclusion.map((c, index) => {
              const { descriptions, name, value } = c;

              return (
                <div
                  key={index}
                  className="bg-gray-700 p-4 rounded-lg shadow-md"
                >
                  <h4 className="text-xl font-semibold text-yellow-400 mb-2">
                    {name}
                  </h4>
                  <div className="flex items-center mb-2">
                    <div className="text-center p-4 bg-gray-800 text-yellow-400 font-bold rounded-lg mr-4">
                      {(value * 100).toFixed(0)}%
                    </div>
                    <div className="text-gray-300">
                      {descriptions.join(" ")}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsKondisiKeluarga;
