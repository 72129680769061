// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import SubsetTokenRow from "./subset_token_row";
import SubsetTokenSearchForm from "./subset_token_search_form";
import LinkButton from "../../../components/ui/buttons/link_button";

import * as ROUTES from "../../../constants/routes";

import { ISubsetTokenTableProps } from "./common";
import { useSubsetTokenTable } from "./hooks/use_subset_token_table";

// ---------------------------------------------- the component
const SubsetTokenTable: FunctionComponent<ISubsetTokenTableProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    criteria,
    error,
    fetching,
    filtered,
    module,
    subsetTokens,
    sortBy,
    onChangeSubsetToken,
    onFilter,
    onSort,
  } = useSubsetTokenTable();

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col h-screen p-8">
      <div className="mb-6">
        <div className="flex flex-col">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-2">
            REPORT
          </h1>
          <div className="text-lg md:text-xl font-bold text-yellow-400">
            <p>Token Table of {module.name}</p>
            <p className="text-sm md:text-base text-gray-300">
              ID: {module.id}
            </p>
          </div>
        </div>
      </div>

      <SubsetTokenSearchForm
        criteria={criteria}
        error={error}
        fetching={fetching}
        result={{
          filtered: filtered.length,
          total: subsetTokens.length,
        }}
        onFilter={onFilter}
      />

      <section className="shadow-lg overflow-x-auto max-h-screen">
        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-sm text-left text-gray-400">
            <thead className="text-xs uppercase bg-gray-500 text-gray-800">
              <tr>
                {[
                  {
                    prop: "name",
                    label: "Name",
                    className: "px-6 py-3",
                  },
                  {
                    prop: "id",
                    label: "Id",
                    className: "px-6 py-3 hidden md:block",
                  },
                  {
                    prop: "",
                    label: "Status",
                    className: "px-6 py-3",
                  },
                  {
                    prop: "lastModified",
                    label: "Last Modified",
                    className: "px-6 py-3",
                  },
                ].map((header, index) => {
                  const { prop, label, className } = header;

                  return (
                    <th
                      className={className}
                      key={index}
                      style={{ cursor: prop ? "pointer" : "default" }}
                      onClick={() => {
                        if (!prop) return;

                        onSort(prop);
                      }}
                    >
                      <div className="flex items-center ">
                        {label}

                        {sortBy.prop === header.prop && (
                          <FontAwesomeIcon
                            icon={sortBy.asc > 0 ? faSortDown : faSortUp}
                            className="ml-1"
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {!fetching
                ? filtered.map((subsetToken) => (
                    <SubsetTokenRow
                      key={subsetToken.id}
                      subsetToken={subsetToken}
                      onChangeSubsetToken={onChangeSubsetToken}
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </section>

      <div className="fixed bottom-4 right-4">
        <LinkButton
          className="flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full hover:bg-blue-600"
          to={`${ROUTES.REPORT}${ROUTES.MODULE}`}
          valid
        >
          <FontAwesomeIcon icon={faArrowLeft} className="text-lg" />
        </LinkButton>
      </div>
    </div>
  );
};

export default SubsetTokenTable;
