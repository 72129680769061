// ---------------------------------------------- modules import
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { FunctionComponent } from "react";

import { IModuleRowProps } from "./common";
import { useModuleRow } from "./hooks/use_module_row";

// ---------------------------------------------- the component
const ModuleRow: FunctionComponent<IModuleRowProps> = ({
  module,
  onChangeModule,
}) => {
  const { assessmentIds, lastModified, name } = module;

  // ---------------------------------------------- local state
  const { onClick } = useModuleRow(module, onChangeModule);

  // ---------------------------------------------- content
  return (
    <tr
      onClick={onClick}
      style={{ cursor: "pointer" }}
      className="bg-gray-800 border-b border-gray-700 hover:bg-gray-600"
    >
      <td className="p-3 md:px-6 md:py-4 flex flex-col items-start">
        <span className="flex-1 mb-2">{name}</span>

        <div className="text-xs block md:hidden">
          {assessmentIds.map((assessmentId) => (
            <span key={assessmentId} className="block truncate w-full mb-1">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-xs text-blue-500"
              />{" "}
              {assessmentId}
            </span>
          ))}
        </div>
      </td>

      <td className="p-3 md:px-6 md:py-4 hidden md:table-cell">
        {assessmentIds.map((assessmentId) => (
          <span key={assessmentId} className="block w-full">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-xs text-blue-500"
            />{" "}
            {assessmentId}
          </span>
        ))}
      </td>

      <td className="p-3 md:px-6 md:py-4 hidden md:table-cell align-top">
        {dayjs(lastModified).locale("id").format("DD/MM/YYYY, HH:mm:ss")}
      </td>
    </tr>
  );
};

export default ModuleRow;
