// ---------------------------------------------- modules import
import { IWorksheet } from "../models/worksheet";

export const newCreateWorksheetDto = (worksheet: IWorksheet) => {
  const { id, ...worksheetRecord } = worksheet;

  return {
    data: worksheetRecord,
  };
};
