// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  ISheetContextProviderState,
  ISheetContextProviderProps,
} from "./common";
import { useSheetContext } from "./hooks";
import { ISubsetToken } from "../../models/subset_token";
import { IWorksheet } from "../../models/worksheet";

// ---------------------------------------------- create context
const initialState: ISheetContextProviderState = {
  onDownloadReports: (subsetToken: ISubsetToken, worksheets: IWorksheet[]) => {
    return;
  },
};

export const SheetContext = createContext(initialState);

// ---------------------------------------------- the component
const SheetContextProvider: FunctionComponent<ISheetContextProviderProps> = ({
  children,
}) => {
  // ---------------------------------------------- local state
  const { onDownloadReports } = useSheetContext();

  // ---------------------------------------------- content
  return (
    <SheetContext.Provider value={{ onDownloadReports }}>
      {children}
    </SheetContext.Provider>
  );
};

export default SheetContextProvider;
