// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import {
  IParticipantGroup,
  IParticipantGroupRecord,
} from "../../models/participant_group";

// ---------------------------------------------- the hooks
export const useParticipantGroupContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [participantGroups, setParticipantGroups] = useState<
    IParticipantGroup[]
  >([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- effects
  useEffect(() => {
    setFetching(true);

    const ref = firebase.firestore
      .collection(PATHS.PARTICIPANT_GROUP)
      .orderBy("dateCreated", "desc");

    const unsubscribe = ref.onSnapshot(
      (querySnapshot) => {
        setParticipantGroups(
          querySnapshot.docs.reduce(
            (participantGroups, doc) => [
              ...participantGroups,
              {
                id: doc.id,
                ...(doc.data() as IParticipantGroupRecord),
              },
            ],
            [] as IParticipantGroup[]
          )
        );

        setError(null);
        setFetching(false);
      },
      (error) => {
        setError(error.message);
        setFetching(false);
      }
    );

    return () => unsubscribe();
  }, [firebase]);

  // ---------------------------------------------- return value
  return { participantGroups, error, fetching };
};
