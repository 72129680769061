// ---------------------------------------------- modules import
import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import voca from "voca";

import Button from "../../../components/ui/buttons/button";
import CheckboxInput from "../../../components/ui/inputs/checkbox_input";

import bgQuiz from "../../../assets/images/bg-overview.png";

import { IQuizColouredProgressiveMatricesProps } from "./common";
import { useQuizColouredProgressiveMatrices } from "./hooks/use_quiz_coloured_progressive_matrices";

// ---------------------------------------------- the component
const QuizColouredProgressiveMatrices: FunctionComponent<
  IQuizColouredProgressiveMatricesProps
> = ({ questionnaires, onChangeSelectedOptions }) => {
  // ---------------------------------------------- local state
  const { questionnaire, group, question, onChange, onNext, onPrevious } =
    useQuizColouredProgressiveMatrices(questionnaires, onChangeSelectedOptions);

  const anyOptionSelected = question
    ? question.options.some(
        (option) =>
          question.selectedOptions.findIndex(
            (selectedOption) => selectedOption.value === option.value
          ) !== -1
      )
    : false;

  // ---------------------------------------------- the content
  return (
    <div
      className="min-h-screen flex items-center justify-center p-4 bg-fixed bg-cover bg-center"
      style={{
        backgroundImage: `url(${bgQuiz})`,
      }}>
      <div className="bg-white bg-opacity-90 rounded-lg shadow-xl w-full max-w-2xl mx-auto my-8 relative overflow-hidden">
        <div className="relative bg-white rounded-lg shadow-xl p-8">
          <form className="w-full max-w-md px-9 pt-8 pb-8 rounded-lg">
            <h1 className="md:text-2xl text-xl text-gray-100 md:py-10 py-5">
              {questionnaire && voca.upperCase(questionnaire.assessment.name)}
            </h1>

            {questionnaire && group && question && (
              <>
                <div className=" sm:pt-8 md:pt-9 pt-11 xl:pt-14 pb-5 flex flex-wrap md:justify-between ">
                  {question.text.split("||").map((line, index) => {
                    const isImage = line.trim().startsWith("https://");

                    return (
                      <div
                        key={index}
                        className={`${
                          isImage ? "md:w-1/2 w-40" : "md:w-1/2 w-full"
                        }`}>
                        {isImage ? (
                          <img
                            src={line}
                            alt={question.id}
                            className=" w-full h-auto"
                          />
                        ) : (
                          <p className="text-base text-white max-h-32 overflow-auto">
                            {line}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="grid grid-cols-2 md:grid-cols-2 gap-2 items-center object-center">
                  {question.options.map((option, index) => {
                    const { text, value } = option;

                    const included = question.selectedOptions.reduce(
                      (included, selectedOption) =>
                        included || selectedOption.value === value,
                      false
                    );

                    const isImage = text.trim().startsWith("https://");

                    return (
                      <div
                        key={value}
                        className={`overflow-y-auto ${
                          isImage ? "md:w-4/5 xl:w-32 w-32" : "w-full"
                        }`}>
                        <CheckboxInput
                          checked={included}
                          className_input="hidden"
                          className_label={`flex-grow p-4 w-full text-base font-medium text-gray-100 inline-flex border rounded-lg cursor-pointer ${
                            included
                              ? "text-gray-900 bg-green-300 hover:bg-gray-300"
                              : "text-gray-100 bg-white hover:bg-gray-300"
                          }`}
                          id={`${value}-${index}`}
                          label={
                            isImage ? (
                              <img
                                src={text}
                                alt={value.toString()}
                                className="w-full h-auto"
                              />
                            ) : (
                              text
                            )
                          }
                          name={question.id}
                          reversed
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <div className="fixed bottom-0 left-0 right-0 bg-transparent p-4">
              <div className="flex justify-between max-w-md mx-auto">
                <Button
                  className="flex items-center text-blue-500 hover:text-blue-700"
                  type="button"
                  valid
                  onClick={onPrevious}>
                  <FontAwesomeIcon icon={faCircleLeft} className="text-xl" />

                  <span className="text-lg font-bold mx-2">BACK</span>
                </Button>

                <Button
                  className={`flex items-center ${
                    anyOptionSelected
                      ? "text-blue-500 hover:text-blue-700"
                      : "text-gray-500"
                  }`}
                  type="button"
                  valid={anyOptionSelected}
                  onClick={onNext}>
                  <span className="text-lg font-bold mx-2">NEXT</span>

                  <FontAwesomeIcon icon={faCircleRight} className="text-xl" />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuizColouredProgressiveMatrices;
