// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faPaperPlane,
  faLayerGroup,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import Button from "../../../components/ui/buttons/button";
import LinkButton from "../../../components/ui/buttons/link_button";
import CheckboxInput from "../../../components/ui/inputs/checkbox_input";
import NumberInput from "../../../components/ui/inputs/number_input";
import SelectInput from "../../../components/ui/inputs/select_input";
import TextInput from "../../../components/ui/inputs/text_input";
import StatusBadge from "../../../components/ui/others/status_badge";

import * as ROUTES from "../../../constants/routes";
import { emptyModule } from "../../../models/module";
import { pattern as modulePattern } from "../../../validations/module";
import { pattern as subsetTokenPattern } from "../../../validations/subset_token";

import { useAddModuleForm } from "./hooks/use_add_module_form";

// ---------------------------------------------- the component
const AddModuleForm: FunctionComponent = () => {
  // ---------------------------------------------- local state
  const {
    assessments,
    participantGroup,
    module,
    tokenNumber,
    tokenNames,
    educationLevelOptions,
    educationSubLevelOptions,
    error,
    fetching,
    loading,
    isModuleValid,
    isTokenNamesValid,
    onChange,
    onChangeTokenName,
    onSubmit,
  } = useAddModuleForm();

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <div className="max-w-4xl mx-auto">
        <form
          onSubmit={onSubmit}
          className="bg-gray-800 rounded-lg shadow-lg overflow-hidden"
        >
          <div className="p-8">
            <h1 className="text-3xl font-bold text-white mb-2">
              ADD NEW MODULE
            </h1>
            <p className="text-gray-400 mb-6">
              Fill in the details to add a new module
            </p>

            <div className="grid md:grid-cols-2 gap-6">
              <div className="col-span-2">
                <TextInput
                  className_root="mb-4"
                  id="name"
                  label="Name"
                  name="name"
                  pattern={modulePattern.name}
                  required
                  type="text"
                  onChange={onChange}
                />

                <TextInput
                  className_root="mb-4"
                  id="description"
                  label="Description"
                  name="description"
                  pattern={modulePattern.description}
                  required={false}
                  type="text"
                  onChange={onChange}
                />

                <div className="grid md:grid-cols-2 gap-4">
                  <SelectInput
                    className_root="mb-4"
                    className_select="block w-full p-2.5 bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    defaultOption={{
                      label: emptyModule().education.level,
                      value: emptyModule().education.level,
                    }}
                    fallbackValue={emptyModule().education.level}
                    id="education-level"
                    label="Education Level"
                    name="education.level"
                    options={educationLevelOptions}
                    value={module.education.level}
                    onChange={onChange}
                  />

                  <SelectInput
                    className_root="mb-4"
                    className_select="block w-full p-2.5 bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    defaultOption={{
                      label: emptyModule().education.subLevel,
                      value: emptyModule().education.subLevel,
                    }}
                    fallbackValue={emptyModule().education.subLevel}
                    id="education-sublevel"
                    label="Education Sub Level"
                    name="education.subLevel"
                    options={educationSubLevelOptions}
                    value={module.education.subLevel}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
                  <FontAwesomeIcon
                    icon={faLayerGroup}
                    className="mr-2 text-blue-400"
                  />
                  Assessments
                </h3>
                <div className="bg-gray-700 p-4 rounded-lg mb-4 max-h-60 overflow-y-auto">
                  {assessments.map((assessment) => {
                    const { id, name } = assessment;
                    return (
                      <CheckboxInput
                        key={id}
                        checked={module.assessmentIds.includes(id)}
                        className_input="w-4 h-4 text-blue-600 bg-gray-600 border-gray-500 rounded focus:ring-blue-600 ring-offset-gray-700 focus:ring-2"
                        className_label="ml-2 text-sm font-medium text-gray-300"
                        className_root="flex items-center mb-3"
                        id={id}
                        label={name}
                        name="assessmentIds"
                        value={id}
                        onChange={onChange}
                      />
                    );
                  })}
                </div>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
                  <FontAwesomeIcon
                    icon={faCoins}
                    className="mr-2 text-yellow-400"
                  />
                  Tokens
                </h3>
                <NumberInput
                  className_root="mb-4"
                  className_input="bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  disabled={false}
                  id="tokenNumber"
                  label="Token Number"
                  min={0}
                  name="tokenNumber"
                  required
                  value={tokenNumber}
                  onChange={onChange}
                />
                {tokenNames.map((tokenName, index) => (
                  <TextInput
                    key={index}
                    className_root="mb-3"
                    className_input="bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    id={`name-${index}`}
                    label={`Token ${index + 1} Name`}
                    name={index.toString()}
                    pattern={subsetTokenPattern.name}
                    required
                    type="text"
                    value={tokenName}
                    onChange={onChangeTokenName}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="bg-gray-700 px-8 py-4 flex justify-between items-center">
            <LinkButton
              className="flex items-center text-gray-300 hover:text-white transition duration-300 ease-in-out"
              to={`${ROUTES.PARTICIPANT_GROUP}/details/${participantGroup.id}`}
              valid={!fetching && !loading}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back
            </LinkButton>

            <Button
              className={`flex items-center px-4 py-2 rounded-lg transition duration-300 ease-in-out ${
                !fetching && !loading && isModuleValid() && isTokenNamesValid()
                  ? "bg-blue-500 hover:bg-blue-600 text-white"
                  : "bg-gray-600 text-gray-400 cursor-not-allowed"
              }`}
              type="submit"
              valid={
                !fetching && !loading && isModuleValid() && isTokenNamesValid()
              }
            >
              Submit
              <FontAwesomeIcon icon={faPaperPlane} className="ml-2" />
            </Button>
          </div>
        </form>

        <div className="mt-6 flex justify-center">
          <StatusBadge fetching={fetching} loading={loading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default AddModuleForm;
