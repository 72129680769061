// ---------------------------------------------- modules import
import { IConsolidatedWorksheet } from "../models/consolidated_worksheet";

export const newSettleConsolidatedWorksheetsDto = (
  consolidatedWorksheets: IConsolidatedWorksheet[]
) => ({
  data: {
    consolidatedWorksheetIDs: consolidatedWorksheets.map(
      (consolidatedWorksheet) => consolidatedWorksheet.id
    ),
  },
});

export const newUnsettleConsolidatedWorksheetDto = (
  consolidatedWorksheets: IConsolidatedWorksheet[]
) => ({
  data: {
    consolidatedWorksheetIDs: consolidatedWorksheets.map(
      (consolidatedWorksheet) => consolidatedWorksheet.id
    ),
  },
});
