// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IRegencyContextProviderState,
  IRegencyContextProviderProps,
} from "./common";
import { useRegencyContext } from "./hooks";
import { emptyProvince } from "../../models/province";

// ---------------------------------------------- create context
const initialState: IRegencyContextProviderState = {
  province: emptyProvince(),
  regencies: [],
  error: null,
  fetching: true,
  onChangeProvince: () => {
    return;
  },
};

export const RegencyContext = createContext(initialState);

// ---------------------------------------------- the component
const RegencyContextProvider: FunctionComponent<
  IRegencyContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const { province, regencies, error, fetching, onChangeProvince } =
    useRegencyContext();

  // ---------------------------------------------- content
  return (
    <RegencyContext.Provider
      value={{ province, regencies, error, fetching, onChangeProvince }}
    >
      {children}
    </RegencyContext.Provider>
  );
};

export default RegencyContextProvider;
