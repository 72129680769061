// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faFileExcel,
  faFilePdf,
  faFileZipper,
  faSortUp,
  faSortDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import WorksheetRow from "./worksheet_row";
import WorksheetSearchForm from "./worksheet_search_form";
import Button from "../../../components/ui/buttons/button";
import LinkButton from "../../../components/ui/buttons/link_button";

import * as ROUTES from "../../../constants/routes";

import { IWorksheetTableProps } from "./common";
import { useWorksheetTable } from "./hooks/use_worksheet_table";

// ---------------------------------------------- the component
const WorksheetTable: FunctionComponent<IWorksheetTableProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    criteria,
    error,
    fetching,
    filtered,
    loading,
    subsetToken,
    worksheets,
    sortBy,
    onChangeWorksheet,
    onFilter,
    onSort,
    onDownloadSheet,
    onDownloadZip,
  } = useWorksheetTable();

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col h-screen p-8">
      <div className="mb-6">
        <div className="flex flex-col">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-2">
            REPORT
          </h1>
          <div className="text-lg md:text-xl font-bold text-yellow-400">
            <p>Result Table of {subsetToken.name}</p>
            <p className="text-sm md:text-base text-gray-300">
              ID: {subsetToken.id}
            </p>
          </div>
        </div>
      </div>

      <WorksheetSearchForm
        criteria={criteria}
        error={error}
        fetching={fetching}
        result={{
          filtered: filtered.length,
          total: worksheets.length,
        }}
        onFilter={onFilter}
      />

      <section className="shadow-lg overflow-x-auto max-h-screen">
        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-sm text-left text-gray-400">
            <thead className="text-xs uppercase bg-gray-500 text-gray-800">
              <tr>
                {[
                  {
                    prop: "name",
                    label: "Name",
                    className: "px-6 py-3",
                  },
                  {
                    prop: "gender",
                    label: "Gender",
                    className: "px-6 py-3",
                  },
                  {
                    prop: "",
                    label: "Phone",
                    className: "px-6 py-3",
                  },
                  {
                    prop: "dateCreated",
                    label: "Date Created",
                    className: "px-6 py-3 hidden md:block",
                  },
                ].map((header, index) => {
                  const { prop, label, className } = header;

                  return (
                    <th
                      className={className}
                      key={index}
                      style={{ cursor: prop ? "pointer" : "default" }}
                      onClick={() => {
                        if (!prop) return;

                        onSort(prop);
                      }}
                    >
                      <div className="flex items-center ">
                        {label}

                        {sortBy.prop === header.prop && (
                          <FontAwesomeIcon
                            icon={sortBy.asc > 0 ? faSortDown : faSortUp}
                            className="ml-1"
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {!fetching && !loading
                ? filtered.map((worksheet) => (
                    <WorksheetRow
                      key={worksheet.id}
                      worksheet={worksheet}
                      onChangeWorksheet={onChangeWorksheet}
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </section>

      <div className="fixed bottom-4 right-4 z-50">
        <Button
          className={`flex items-center justify-center w-10 h-10 mb-5 text-white bg-blue-500 rounded-full hover:bg-blue-600 ${
            fetching || loading ? "bg-gray-500 pointer-events-none" : ""
          }`}
          type="button"
          valid={!fetching && !loading}
          onClick={onDownloadZip}
        >
          {fetching || loading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="text-lg animate-spin"
            />
          ) : (
            <FontAwesomeIcon icon={faFileZipper} className="text-lg" />
          )}
        </Button>

        <LinkButton
          className={`flex items-center justify-center w-10 h-10 mb-5 text-white bg-blue-500 rounded-full hover:bg-blue-600 ${
            fetching || loading ? "bg-gray-500 pointer-events-none" : ""
          }`}
          to={`${ROUTES.REPORT}/pdf-token-grouper-preview`}
          valid
        >
          {fetching || loading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="text-lg animate-spin"
            />
          ) : (
            <FontAwesomeIcon icon={faFilePdf} className="text-lg" />
          )}
        </LinkButton>

        <Button
          className={`flex items-center justify-center w-10 h-10 mb-5 text-white bg-blue-500 rounded-full hover:bg-blue-600 ${
            fetching || loading ? "bg-gray-500 pointer-events-none" : ""
          }`}
          type="button"
          valid={!fetching && !loading}
          onClick={onDownloadSheet}
        >
          {fetching || loading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="text-lg animate-spin"
            />
          ) : (
            <FontAwesomeIcon icon={faFileExcel} className="text-lg" />
          )}
        </Button>

        <LinkButton
          className={`flex items-center justify-center w-10 h-10 mb-5 text-white bg-blue-500 rounded-full hover:bg-blue-600 ${
            fetching || loading ? "bg-gray-500 pointer-events-none" : ""
          }`}
          to={`${ROUTES.REPORT}${ROUTES.SUBSET_TOKEN}`}
          valid
        >
          {fetching || loading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="text-lg animate-spin"
            />
          ) : (
            <FontAwesomeIcon icon={faArrowLeft} className="text-lg" />
          )}
        </LinkButton>
      </div>
    </div>
  );
};

export default WorksheetTable;
