// ---------------------------------------------- modules import
import { useLocation } from "react-router-dom";

// ---------------------------------------------- the hooks
export const useUnauthorized = () => {
  // ---------------------------------------------- router state
  let location = useLocation();

  // ---------------------------------------------- return value
  return { location };
};
