// ---------------------------------------------- modules import
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import SelectInput from "../../../components/ui/inputs/select_input";
import TextInput from "../../../components/ui/inputs/text_input";

import { ISearchFormProps } from "./common";
import { useSearchForm } from "./hooks/use_search_form";

// ---------------------------------------------- the component
const SearchForm: FunctionComponent<ISearchFormProps> = ({
  criteria,
  error,
  fetching,
  result,
  onFilter,
}) => {
  const { findByOptions, onSubmit } = useSearchForm();

  // ---------------------------------------------- content
  return (
    <div className="flex flex-row items-center flex-wrap bg-gray-800 rounded-xl mb-6">
      <div className="flex items-center md:basis-1/2">
        <FontAwesomeIcon
          icon={faSearch}
          className="text-gray-500 ml-6"
          style={{ fontSize: "1.2rem" }}
        />
        <span className="font-medium text-gray-800">FILTER</span>
        {fetching ? (
          <span className="ml-2 py-5 md:py-0 text-gray-100">Now fetching</span>
        ) : error ? (
          <span className="ml-2 py-5 md:py-0 text-red-100">Error</span>
        ) : (
          <span className="ml-2 py-5 md:py-0 text-gray-100">
            <b>{result.filtered}</b> out of <b>{result.total}</b> tokens
            displayed
          </span>
        )}
      </div>

      <div className="basis-1/2">
        <form
          onSubmit={onSubmit}
          className="flex items-center md:justify-end ml-4 flex-wrap justify-center"
        >
          <SelectInput
            className_internal_container="flex items-center whitespace-nowrap flex-auto text-gray-100 order-first"
            className_label="mr-5 flex-auto hidden md:block"
            className_root="w-72 rounded-md p-2 mr-2 mb-2 md:mb-0 md:mr-2"
            fallbackValue="name"
            id="assessment-search-form-find-by-options"
            label="Find by"
            name="prop"
            options={findByOptions}
            value={criteria.prop}
            onChange={onFilter}
          />

          <TextInput
            className_root=" w-72 rounded-md p-2 mr-2 mb-2 md:mb-0 md:mr-2"
            id="keywords"
            label=""
            name="keywords"
            pattern={{
              minLength: 0,
              maxLength: 100,
              regex: /.*/,
            }}
            required={false}
            type="text"
            value={criteria.keywords}
            onChange={onFilter}
          />
        </form>
      </div>
    </div>
  );
};

export default SearchForm;
