// ---------------------------------------------- modules import
import { ChangeEvent, FunctionComponent } from "react";

import { ICheckboxInputProps } from "./common";

// ---------------------------------------------- the component
const CheckboxInput: FunctionComponent<ICheckboxInputProps> = ({
  name,
  checked,
  className_input,
  className_label,
  className_root,
  disabled,
  id,
  label,
  reversed,
  value,
  onChange,
}) => {
  // ---------------------------------------------- handlers
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(e.currentTarget.name, e.currentTarget.value);

  // ---------------------------------------------- content
  return (
    <div className={className_root || ""}>
      {reversed && (
        <label htmlFor={id} className={className_label || ""}>
          {label}
        </label>
      )}

      <input
        checked={checked}
        className={className_input || ""}
        disabled={disabled}
        id={id}
        name={name}
        type="checkbox"
        value={value}
        onChange={handleChange}
      />

      {!reversed && (
        <label htmlFor={id} className={className_label || ""}>
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckboxInput;
