// ---------------------------------------------- modules import
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import { IQuestionnaireListItemProps } from "./common";
import { useQuestionnaireListItem } from "./hooks/use_questionnaire_list_item";

// ---------------------------------------------- the component
const QuestionnaireListItem: FunctionComponent<IQuestionnaireListItemProps> = ({
  questionnaire,
  isCompleted,
}) => {
  // ---------------------------------------------- local state
  const { onClick } = useQuestionnaireListItem(questionnaire);

  // ---------------------------------------------- content
  return (
    <div
      className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600 px-4 py-4 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex justify-between">
        <div>
          <h3 className="text-sm font-semibold">
            {questionnaire.assessment.name}
          </h3>
        </div>

        <div className="flex items-center justify-end">
          {isCompleted ? (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-blue-400 text-lg"
            />
          ) : (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-red-300 text-lg"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireListItem;
