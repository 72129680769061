// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { IModule, IModuleRecord } from "../../models/module";
import {
  IParticipantGroup,
  emptyParticipantGroup,
} from "../../models/participant_group";

// ---------------------------------------------- the hooks
export const useModuleContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [participantGroup, setParticipantGroup] = useState<IParticipantGroup>(
    emptyParticipantGroup()
  );
  const [modules, setModules] = useState<IModule[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- handlers
  const handleChangeParticipantGroup = (participantGroup: IParticipantGroup) =>
    setParticipantGroup(participantGroup);

  // ---------------------------------------------- effects
  useEffect(() => {
    if (participantGroup) {
      setFetching(true);

      const ref = firebase.firestore
        .collection(PATHS.PARTICIPANT_GROUP)
        .doc(participantGroup.id.length ? participantGroup.id : "empty id")
        .collection(PATHS.MODULE)
        .orderBy("dateCreated", "desc");

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          setModules(
            querySnapshot.docs.reduce(
              (modules, doc) => [
                ...modules,
                {
                  id: doc.id,
                  ...(doc.data() as IModuleRecord),
                },
              ],
              [] as IModule[]
            )
          );

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, participantGroup]);

  // ---------------------------------------------- return value
  return {
    participantGroup,
    modules,
    error,
    fetching,
    onChangeParticipantGroup: handleChangeParticipantGroup,
  };
};
