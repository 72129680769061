// ---------------------------------------------- modules import
import axios from "axios";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import voca from "voca";

import { ProvinceContext } from "../../../../contexts/province/context";
import { RegencyContext } from "../../../../contexts/regency/context";
import { SessionContext } from "../../../../contexts/session/context";
import { ParticipantGroupContext } from "../../../../contexts/participant_group/context";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import * as ROUTES from "../../../../constants/routes";
import { newUpdateParticipantGroupDto } from "../../../../dtos/participant_group";
import {
  emptyParticipantGroup,
  IParticipantGroup,
} from "../../../../models/participant_group";
import { emptyProvince } from "../../../../models/province";
import { emptyRegency } from "../../../../models/regency";
import validateParticipantGroup from "../../../../validations/participant_group";

// ---------------------------------------------- the hooks
export const useUpdateParticipantGroupForm = () => {
  // ---------------------------------------------- router state
  const { participant_group_id } = useParams();
  let navigate = useNavigate();

  // ---------------------------------------------- consume context
  const {
    error: errProvince,
    fetching: fetchingProvince,
    provinces,
  } = useContext(ProvinceContext);
  const {
    error: errRegency,
    fetching: fetchingRegency,
    regencies,
    onChangeProvince,
  } = useContext(RegencyContext);
  const {
    error: errParticipantGroup,
    fetching: fetchingParticipantGroup,
    participantGroups,
  } = useContext(ParticipantGroupContext);
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [participantGroup, setParticipantGroup] = useState<IParticipantGroup>(
    emptyParticipantGroup()
  );

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [errRequest, setErrRequest] = useState<string | null>(null);

  const findInProvinces = (id: string) => {
    const found = provinces.find((province) => province.id === id);

    if (found) return found;

    return emptyProvince();
  };

  const findInRegencies = (id: string) => {
    const found = regencies.find((regency) => regency.id === id);

    if (found) return found;

    return emptyRegency();
  };

  const isParticipantGroupValid = () =>
    validateParticipantGroup(participantGroup);

  const provinceOptions = provinces.map((province) => ({
    label: province.name,
    value: province.id,
  }));

  const regencyOptions = regencies.map((regency) => ({
    label: regency.name,
    value: regency.id,
  }));

  // ---------------------------------------------- handlers
  const handleChange = (prop: string, value: string) => {
    if (prop === "province")
      setParticipantGroup({
        ...participantGroup,
        location: {
          ...participantGroup.location,
          province: findInProvinces(value),
          regency: emptyRegency(),
        },
      });
    else if (prop === "regency")
      setParticipantGroup({
        ...participantGroup,
        location: {
          ...participantGroup.location,
          regency: findInRegencies(value),
        },
      });
    else
      setParticipantGroup({
        ...participantGroup,
        [prop]: value,
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const url = `${proxy}${API_ROUTES.PARTICIPANT_GROUP}`;

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setLoading(true);

        axios
          .put(
            `${url}/${participantGroup.id}`,
            newUpdateParticipantGroupDto({
              ...participantGroup,
              name: voca.trim(participantGroup.name),
            }),
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            }
          )
          .then(() => {
            setLoading(false);
            setErrRequest(null);

            navigate(ROUTES.PARTICIPANT_GROUP, { replace: true });

            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    if (participantGroups) {
      const currentParticipantGroup = participantGroups.find(
        (participantGroup) => participantGroup.id === participant_group_id
      );

      if (currentParticipantGroup) setParticipantGroup(currentParticipantGroup);
    }
  }, [participantGroups, participant_group_id]);

  useEffect(() => {
    if (participantGroup.location.province.id) {
      onChangeProvince(participantGroup.location.province);
    }
  }, [participantGroup, onChangeProvince]);

  useEffect(
    () =>
      setError(
        errProvince ||
          errRegency ||
          errParticipantGroup ||
          errRequest ||
          errSession
      ),
    [errProvince, errRegency, errParticipantGroup, errRequest, errSession]
  );

  // ---------------------------------------------- return value
  return {
    error,
    fetching:
      fetchingProvince ||
      fetchingRegency ||
      fetchingParticipantGroup ||
      fetchingSession,
    loading,
    participantGroup,
    provinceOptions,
    regencyOptions,
    isParticipantGroupValid,
    onChange: handleChange,
    onSubmit: handleSubmit,
  };
};
