// ---------------------------------------------- modules import
import { VERSION } from "../constants/version";
import { IAssessment } from "./assessment";
import { IAttribute } from "./attribute";
import { IModule, emptyModule } from "./module";
import { IParticipantGroup, emptyParticipantGroup } from "./participant_group";
import { IProfile, emptyProfile } from "./profile";
import { IQuestion } from "./question";
import { ITag } from "./tag";

// ---------------------------------------------- document interfaces
export interface IAssessmentResult {
  attributes: IAttribute[];
  conclusion: string;
}

export interface IQuestionGroup {
  questions: IQuestion[];
  tag: ITag;
}

export interface IQuestionnaire {
  assessment: IAssessment;
  groups: IQuestionGroup[];
  result: IAssessmentResult;
}

/**
 * Represents the data fields of a worksheet document in Firestore.
 *
 * Subcollections of each document:
 * - Tags
 */
export interface IWorksheetRecord {
  dateCreated: string;
  lastModified: string;
  module: IModule;
  participantGroup: IParticipantGroup;
  profile: IProfile;
  questionnaires: IQuestionnaire[];
  tokenId: string;
  tokenName: string;
  version: string;
}

/**
 * Represents a worksheet returned to the frontend from Firestore.
 *
 * Extends the IWorksheetRecord interface.
 */
export interface IWorksheet extends IWorksheetRecord {
  id: string;
  [key: string]: any;
}

// ---------------------------------------------- empty document
export const emptyWorksheetRecord = (): IWorksheetRecord => ({
  dateCreated: "",
  lastModified: "",
  module: emptyModule(),
  participantGroup: emptyParticipantGroup(),
  profile: emptyProfile(),
  questionnaires: [],
  tokenId: "",
  tokenName: "",
  version: VERSION,
});

export const emptyWorksheet = (): IWorksheet => ({
  ...emptyWorksheetRecord(),
  id: "",
});
