// ---------------------------------------------- modules import
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";
import { IQuestionnaire } from "../../../../models/worksheet";

// ---------------------------------------------- the hooks
export const useQuizGayaBelajarSdKelas1Sampai3 = (
  questionnaires: IQuestionnaire[],
  onChangeSelectedOptions: (
    assessmentId: AssessmentIdEnum,
    tagId: string,
    questionId: string,
    value: string | number
  ) => void
) => {
  const assessmentId = AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3;

  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- local state
  const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | null>(
    null
  );

  const [groupIndex, setGroupIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);

  // ---------------------------------------------- handlers
  const handleChange = (questionId: string, value: string | number) =>
    onChangeSelectedOptions(
      assessmentId,
      questionnaire!.groups[groupIndex].tag.id,
      questionId,
      value
    );

  const handleNext = () => {
    const group = questionnaire!.groups[groupIndex];

    const isLastGroupOnQuestionnaire =
      groupIndex === questionnaire!.groups.length - 1;
    const isLastQuestionOnGroup = questionIndex === group.questions.length - 1;

    if (!isLastQuestionOnGroup) {
      const nextQuestionIndex = questionIndex + 1;

      setQuestionIndex(nextQuestionIndex);
    } else if (isLastQuestionOnGroup && !isLastGroupOnQuestionnaire) {
      const nextGroupIndex = groupIndex + 1;
      const nextQuestionIndex = 0;

      setGroupIndex(nextGroupIndex);
      setQuestionIndex(nextQuestionIndex);
    } else {
      navigate(`${ROUTES.QUIZ}/overview`, { replace: true });
    }
  };

  const handlePrevious = () => {
    const isFirstGroupOnQuestionnaire = groupIndex === 0;
    const isFirstQuestionOnGroup = questionIndex === 0;

    if (!isFirstQuestionOnGroup) {
      const previousQuestionIndex = questionIndex - 1;

      setQuestionIndex(previousQuestionIndex);
    } else if (isFirstQuestionOnGroup && !isFirstGroupOnQuestionnaire) {
      const previousGroupIndex = groupIndex - 1;
      const previousGroup = questionnaire!.groups[previousGroupIndex];

      const previousQuestionIndex = previousGroup.questions.length - 1;

      setGroupIndex(previousGroupIndex);
      setQuestionIndex(previousQuestionIndex);
    } else {
      navigate(`${ROUTES.QUIZ}/overview`, { replace: true });
    }
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    const found = questionnaires.find(
      (questionnaire) => questionnaire.assessment.id === assessmentId
    );

    if (found) {
      setQuestionnaire(found);
    }
  }, [assessmentId, questionnaires]);

  // ---------------------------------------------- return value
  return {
    assessmentId,
    questionnaire,
    group: questionnaire ? questionnaire.groups[groupIndex] : null,
    question: questionnaire
      ? questionnaire.groups[groupIndex].questions[questionIndex]
      : null,
    onChange: handleChange,
    onNext: handleNext,
    onPrevious: handlePrevious,
  };
};
