// ---------------------------------------------- modules import
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Details from "./details";
import ModuleTable from "./module_table";
import ParticipantGroupTable from "./participant_group_table";
import PdfTokenGroupedPreview from "./pdf_token_grouped_preview";
import SubsetTokenTable from "./subset_token_table";
import WorksheetTable from "./worksheet_table";
import NotFound from "../../../components/fallbacks/not_found";
import Footer from "../../../components/layouts/footer";
import PrivateRoute from "../../../components/route_guards/private_route";

import SheetContextProvider from "../../../contexts/sheet/context";
import WorksheetContextProvider from "../../../contexts/worksheet/context";

import * as ROUTES from "../../../constants/routes";

// ---------------------------------------------- the component
const Report = () => {
  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // ---------------------------------------------- content
  return (
    <WorksheetContextProvider>
      <SheetContextProvider>
        <div style={{ height: "100vh", backgroundColor: "" }}>
          <Routes>
            <Route
              element={
                <PrivateRoute isAuthorized={true}>
                  <ParticipantGroupTable isAuthorizedToWrite={true} />
                </PrivateRoute>
              }
              path=""
            />

            <Route
              element={
                <PrivateRoute isAuthorized={true}>
                  <ModuleTable isAuthorizedToWrite={true} />
                </PrivateRoute>
              }
              path="module"
            />

            <Route
              element={
                <PrivateRoute isAuthorized={true}>
                  <SubsetTokenTable isAuthorizedToWrite={true} />
                </PrivateRoute>
              }
              path="subset-token"
            />

            <Route
              element={
                <PrivateRoute isAuthorized={true}>
                  <WorksheetTable isAuthorizedToWrite={true} />
                </PrivateRoute>
              }
              path="worksheet"
            />

            <Route
              element={
                <PrivateRoute isAuthorized={true}>
                  <Details isAuthorizedToWrite={true} />
                </PrivateRoute>
              }
              path="details"
            />

            <Route
              element={
                <PrivateRoute isAuthorized={true}>
                  <PdfTokenGroupedPreview isAuthorizedToWrite={true} />
                </PrivateRoute>
              }
              path="pdf-token-grouper-preview"
            />

            <Route element={<NotFound />} path={ROUTES.OTHERS} />
          </Routes>

          <Footer />
        </div>
      </SheetContextProvider>
    </WorksheetContextProvider>
  );
};

export default Report;
