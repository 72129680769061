// ---------------------------------------------- modules import
import { useState } from "react";

// ---------------------------------------------- the hooks
export const useConsolidationInfo = () => {
  // ---------------------------------------------- local state
  const [isOpen, setIsOpen] = useState(false);

  // ---------------------------------------------- handlers
  const handleToggleOpen = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  // ---------------------------------------------- return value
  return {
    isOpen,
    onToggleOpen: handleToggleOpen,
  };
};
