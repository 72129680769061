// ---------------------------------------------- modules import
import { useState } from "react";

// ---------------------------------------------- the hooks
export const useUpdateModal = () => {
  // ---------------------------------------------- local state
  const [isVisible, setIsVisible] = useState(false);

  // ---------------------------------------------- handlers
  const handleShowModal = () => {
    setIsVisible(true);
  };

  const handleHideModal = () => {
    setIsVisible(false);
  };

  // ---------------------------------------------- return value
  return {
    isVisible,
    onHideModal: handleHideModal,
    onShowModal: handleShowModal,
  };
};
