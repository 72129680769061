// ---------------------------------------------- modules import
import axios from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import { newCreateWorksheetDto } from "../../../../dtos/worksheet";
import validateWorksheet from "../../../../validations/worksheet";

import * as ROUTES from "../../../../constants/routes";

import { emptyWorksheet, IWorksheet } from "../../../../models/worksheet";

// ---------------------------------------------- the hooks
export const useJsonSubmission = () => {
  // ---------------------------------------------- router state
  const navigate = useNavigate();

  // ---------------------------------------------- local state
  const [file, setFile] = useState<File | null>(null);
  const [worksheet, setWorksheet] = useState<IWorksheet>(emptyWorksheet());
  const [previewContent, setPreviewContent] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const isWorksheetValid = () => validateWorksheet(worksheet);

  // ---------------------------------------------- handlers
  const handleSubmit = () => {
    setLoading(true);

    const url = `${proxy}${API_ROUTES.WORKSHEET}`;

    axios
      .post(url, newCreateWorksheetDto(worksheet))
      .then(() => {
        setFile(null);
        setPreviewContent("");

        setLoading(false);
        setError(null);

        navigate(ROUTES.SUBMISSION, { replace: true });

        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        const message = error.response
          ? // ? `${error.response.data.message}: ${error.response.data.error}`
            'Maaf. Silahkan coba "Kirim" kembali beberapa saat lagi.'
          : error.request
          ? "request was made but no response was received."
          : "bad request setup.";

        // console.log("ERROR MESSAGE =", error.response.data.message);
        // console.log("ERROR =", error.response.data.error);

        setLoading(false);
        setError(message);
      });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === "application/json") {
      setFile(selectedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);

          setWorksheet(json as IWorksheet);
          setPreviewContent(JSON.stringify(json, null, 2));
        } catch (error) {
          console.error("Error parsing JSON:", error);

          setPreviewContent("Error parsing JSON file");
        }
      };

      reader.readAsText(selectedFile);
    } else {
      setFile(null);
      setWorksheet(emptyWorksheet());
      setPreviewContent("Please select a valid JSON file");
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  // ---------------------------------------------- return value
  return {
    file,
    worksheet,
    previewContent,
    fileInputRef,
    error,
    loading,
    isWorksheetValid,
    onSubmit: handleSubmit,
    onFileChange: handleFileChange,
    onUploadClick: handleUploadClick,
  };
};
