// ---------------------------------------------- modules import
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { ModuleContext } from "../../../../contexts/module/context";
import { SessionContext } from "../../../../contexts/session/context";
import { SubsetTokenContext } from "../../../../contexts/subset_token/context";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";

// ---------------------------------------------- the hooks
export const useModuleList = () => {
  // ---------------------------------------------- consume context
  const {
    modules,
    participantGroup,
    error: errModule,
    fetching: fetchingModule,
  } = useContext(ModuleContext);
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);
  const { onChangeModule } = useContext(SubsetTokenContext);

  // ---------------------------------------------- local state
  const [errRequest, setErrRequest] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // ---------------------------------------------- handlers
  const handleDelete = (id: string) => {
    const url = `${proxy}${API_ROUTES.MODULE}`;

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setLoading(true);

        axios
          .delete(`${url}/${id}?participant_group_id=${participantGroup.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            setLoading(false);
            setErrRequest(null);
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(
    () => setError(errModule || errRequest || errSession),
    [errModule, errRequest, errSession]
  );

  // ---------------------------------------------- content
  return {
    error,
    fetching: fetchingModule || fetchingSession,
    loading,
    modules,
    participantGroup,
    onChangeModule,
    onDelete: handleDelete,
  };
};
