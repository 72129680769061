// ---------------------------------------------- modules import
import { FormEvent, useContext, useState } from "react";

import { SessionContext } from "../../../../contexts/session/context";

import { pattern } from "../../../../validations/user";

// ---------------------------------------------- the hooks
export const useAdminSignIn = () => {
  // ---------------------------------------------- consume context
  const { error, fetching, profile, onSignIn } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [signIn, setSignIn] = useState({
    email: "",
    password: "",
  });

  const isSignInValid = () => {
    const { email, password } = signIn;

    if (
      pattern.email.regex.test(email) &&
      pattern.password.regex.test(password)
    )
      return true;

    return false;
  };

  // ---------------------------------------------- handlers
  const handleChange = (prop: string, value: string) => {
    setSignIn({
      ...signIn,
      [prop]: value,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { email, password } = signIn;

    onSignIn(email, password);
  };

  // ---------------------------------------------- return value
  return {
    error,
    fetching,
    profile,
    isSignInValid,
    onChange: handleChange,
    onSubmit: handleSubmit,
  };
};
