// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import validateOption from "./option";
import { QuestionTypeEnum } from "../enums/question_type";
import { IQuestion } from "../models/question";

export const pattern: IPattern = {
  text: {
    minLength: 0,
    maxLength: 20000,
    regex: /^[\w\s&,.():\-'"/?!=%*|_]{0,2000}$/,
  },
};

const validateQuestion = (
  question: IQuestion,
  propsToValidate = ["text"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { options, selectedOptions, type } = question;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["options"] = options.length
    ? options.reduce(
        (isValidOptions, option) => isValidOptions && validateOption(option),
        true
      )
    : false;
  /**
   * each option inside `selectedOptions` should be a valid option
   * its length should comply its type (single choice, multiple choice, or else if any)
   */
  extraConditions["selectedOptions"] =
    (selectedOptions.length
      ? selectedOptions.reduce(
          (isValidSelectedOptions, selectedOption) =>
            isValidSelectedOptions && validateOption(selectedOption),
          true
        )
      : false) &&
    (type === QuestionTypeEnum.SINGLE_CHOICE
      ? selectedOptions.length === 1
      : type === QuestionTypeEnum.MULTIPLE_CHOICE
      ? selectedOptions.length >= 1
      : type === QuestionTypeEnum.MOST_LEAST_CHOICE
      ? selectedOptions.length === 2
      : type === QuestionTypeEnum.PAIRED_CHOICE
      ? selectedOptions.length === 2
      : false);
  /**
   * `type` should have been registered in the `QuestionTypeEnum`
   * if there is a need to add new kind of question type, the `QuestionTypeEnum` need to be updated first
   */
  extraConditions["type"] = type in QuestionTypeEnum;

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    question,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateQuestion;
