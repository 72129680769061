// ---------------------------------------------- modules import
import { AssessmentIdEnum } from "../enums/assessment_id";

// ---------------------------------------------- document interfaces
/**
 * Represents the data fields of an assessment document in Firestore.
 *
 * Subcollections of each document:
 * - Attributes
 * - Tags
 */
export interface IAssessmentRecord {
  dateCreated: string;
  description: string;
  lastModified: string;
  name: string;
}

/**
 * Represents an assessement returned to the frontend from Firestore.
 *
 * Extends the IAssessmentRecord interface.
 */
export interface IAssessment extends IAssessmentRecord {
  id: AssessmentIdEnum;
  [key: string]: any;
}

// ---------------------------------------------- empty document
export const emptyAssessmentRecord = (): IAssessmentRecord => ({
  dateCreated: "",
  description: "",
  lastModified: "",
  name: "",
});

export const emptyAssessment = (): IAssessment => ({
  ...emptyAssessmentRecord(),
  id: "" as AssessmentIdEnum,
});
