// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IQuestionContextProviderState,
  IQuestionContextProviderProps,
} from "./common";
import { useQuestionContext } from "./hooks";
import { emptyAssessment } from "../../models/assessment";
import { emptyTag } from "../../models/tag";

// ---------------------------------------------- create context
const initialState: IQuestionContextProviderState = {
  assessment: emptyAssessment(),
  tag: emptyTag(),
  questions: [],
  error: null,
  fetching: true,
  onChangeTag: () => {
    return;
  },
  onChangeAssessment: () => {
    return;
  },
};

export const QuestionContext = createContext(initialState);

// ---------------------------------------------- the component
const QuestionContextProvider: FunctionComponent<
  IQuestionContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const {
    assessment,
    tag,
    questions,
    error,
    fetching,
    onChangeTag,
    onChangeAssessment,
  } = useQuestionContext();

  // ---------------------------------------------- content
  return (
    <QuestionContext.Provider
      value={{
        assessment,
        tag,
        questions,
        error,
        fetching,
        onChangeTag,
        onChangeAssessment,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

export default QuestionContextProvider;
