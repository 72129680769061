// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IAssessmentRowProps } from "./common";

// ---------------------------------------------- the component
const AssessmentRow: FunctionComponent<IAssessmentRowProps> = ({
  assessment,
}) => (
  <tr className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600">
    <td className="px-6 py-4">{assessment.name}</td>
  </tr>
);

export default AssessmentRow;
