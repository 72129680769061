// ---------------------------------------------- modules import
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Closure from "./closure";
import Intro from "./intro";
import Overview from "./overview";
import QuizAdversityQuotient from "./quiz_adversity_quotient";
import QuizCfit from "./quiz_cfit";
import QuizColouredProgressiveMatrices from "./quiz_coloured_progressive_matrices";
import QuizDisc from "./quiz_disc";
import QuizGayaBelajarSdKelas1Sampai3 from "./quiz_gaya_belajar_sd_kelas_1_sampai_3";
import QuizGayaBelajar from "./quiz_gaya_belajar";
import QuizKebiasaanBelajar from "./quiz_kebiasaan_belajar";
import QuizKecerdasanMajemuk from "./quiz_kecerdasan_majemuk";
import QuizKesejahteraanPsikologiDanEmosional from "./quiz_kesejahteraan_psikologi_dan_emosional";
import QuizKondisiKeluarga from "./quiz_kondisi_keluarga";
import QuizPersonality from "./quiz_personality";
import QuizRiasec from "./quiz_riasec";
import QuizSpm from "./quiz_spm";
import NotFound from "../../../components/fallbacks/not_found";
import Footer from "../../../components/layouts/footer";
import TokenRoute from "../../../components/route_guards/token_route";

import * as ROUTES from "../../../constants/routes";
import { AssessmentIdEnum } from "../../../enums/assessment_id";

import { useQuiz } from "./hooks/use_quiz";

// ---------------------------------------------- the component
const Quiz = () => {
  // ---------------------------------------------- local state
  const {
    token,
    worksheet,
    questionnaireStatuses,
    error,
    fetching,
    loading,
    isWorksheetValid,
    onChangeProfile,
    onChangeSelectedOptions,
    onResetToken,
    onSubmit,
    onDownloadWorksheet,
  } = useQuiz();

  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.onbeforeunload = function () {
      return "Data yang belum disimpan akan hilang. Apakah Anda yakin ingin meninggalkan halaman ini?";
    };
  }, []);

  // ---------------------------------------------- content
  return (
    <div>
      <Routes>
        <Route
          element={
            <TokenRoute>
              {token && (
                <Intro
                  token={token}
                  fetching={fetching}
                  onResetToken={onResetToken}
                />
              )}
            </TokenRoute>
          }
          path=""
        />

        <Route
          element={
            <TokenRoute>
              <Closure fetching={fetching} onResetToken={onResetToken} />
            </TokenRoute>
          }
          path="closure"
        />

        <Route
          element={
            <TokenRoute>
              <Overview
                worksheet={worksheet}
                questionnaireStatuses={questionnaireStatuses}
                error={error}
                fetching={fetching}
                loading={loading}
                isWorksheetValid={isWorksheetValid}
                onChangeProfile={onChangeProfile}
                onSubmit={onSubmit}
                onDownloadWorksheet={onDownloadWorksheet}
              />
            </TokenRoute>
          }
          path="overview"
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizAdversityQuotient
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.ADVERSITY_QUOTIENT}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizCfit
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.CFIT}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizColouredProgressiveMatrices
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.COLOURED_PROGRESSIVE_MATRICES}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizDisc
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.DISC}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizGayaBelajar
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.GAYA_BELAJAR}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizGayaBelajarSdKelas1Sampai3
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizKebiasaanBelajar
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.KEBIASAAN_BELAJAR}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizKecerdasanMajemuk
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.KECERDASAN_MAJEMUK}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizKesejahteraanPsikologiDanEmosional
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizKondisiKeluarga
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.KONDISI_KELUARGA}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizPersonality
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.PERSONALITY}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizRiasec
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.RIASEC}
        />

        <Route
          element={
            <TokenRoute>
              {worksheet && (
                <QuizSpm
                  questionnaires={worksheet.questionnaires}
                  onChangeSelectedOptions={onChangeSelectedOptions}
                />
              )}
            </TokenRoute>
          }
          path={AssessmentIdEnum.SPM}
        />

        <Route element={<NotFound />} path={ROUTES.OTHERS} />
      </Routes>

      <Footer />
    </div>
  );
};

export default Quiz;
