// ---------------------------------------------- modules import
import { FunctionComponent, useEffect } from "react";

import { IClosureProps } from "./common";
import Button from "../../../components/ui/buttons/button";

// ---------------------------------------------- the component
const Closure: FunctionComponent<IClosureProps> = ({
  fetching,
  onResetToken,
}) => {
  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // ---------------------------------------------- content
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-gray-800 rounded-lg py-6 px-8 w-full md:w-6/12 lg:w-2/5">
        <h1 className=" text-3xl lg:text-4xl text-yellow-400 text-center font-bold mb-6 md:mb-8">
          Thank You
        </h1>

        <div className="text-center mb-8">
          <p className="mb-2 sm:mb-5 text-gray-400">
            Thank you for taking the test with us. Your participation is greatly
            appreciated.
          </p>

          <p className="mb-2 sm:mb-5 text-gray-400">
            We value your feedback and will use it to improve our services.
          </p>

          <p className="font-bold text-xl md:text-2xl my-8 text-gray-400">
            Have a great day!
          </p>
        </div>

        <Button
          className="w-full px-5 py-2.5 text-center mb-2 md:mb-4 text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
          type="button"
          valid={!fetching}
          onClick={onResetToken}
        >
          See You!
        </Button>
      </div>
    </div>
  );
};

export default Closure;
