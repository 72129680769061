// ---------------------------------------------- modules import
import {
  faExclamationTriangle,
  faPaperPlane,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import Button from "../../../components/ui/buttons/button";

import { IJsonSubmissionProps } from "./common";
import { useJsonSubmission } from "./hooks/use_json_submission";

// ---------------------------------------------- the component
const JsonSubmission: FunctionComponent<IJsonSubmissionProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    file,
    worksheet,
    previewContent,
    fileInputRef,
    error,
    loading,
    isWorksheetValid,
    onSubmit,
    onFileChange,
    onUploadClick,
  } = useJsonSubmission();

  // ---------------------------------------------- content
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <input
        type="file"
        accept=".json"
        onChange={onFileChange}
        ref={fileInputRef}
        className="hidden"
      />
      <Button
        type="button"
        onClick={onUploadClick}
        className="flex items-center justify-center mb-4 bg-blue-500 text-white"
        valid={true}
      >
        <FontAwesomeIcon icon={faUpload} className="mr-2" />
        Upload JSON File
      </Button>

      {file && (
        <div className="mb-4">
          <p className="font-bold">Selected file: {file.name}</p>
        </div>
      )}

      {previewContent && (
        <div className="mb-4">
          <h3 className="font-bold mb-2">Preview:</h3>
          <pre className="bg-gray-100 p-4 rounded-lg overflow-auto max-h-60">
            {previewContent}
          </pre>
        </div>
      )}

      {error && (
        <div className="mb-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
          <p className="flex items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <span>{error}</span>
          </p>
        </div>
      )}

      <Button
        onClick={onSubmit}
        className={`flex items-center justify-center ${
          !loading && worksheet && isWorksheetValid()
            ? "bg-green-500 hover:bg-green-600"
            : "bg-gray-400 cursor-not-allowed"
        } text-white`}
        type="button"
        valid={!loading && worksheet !== null && isWorksheetValid()}
      >
        <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
        Submit
      </Button>
    </div>
  );
};

export default JsonSubmission;
