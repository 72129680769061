// ---------------------------------------------- modules import
import { IModule } from "../../../../models/module";

// ---------------------------------------------- the hooks
export const useModuleListItem = (
  module: IModule,
  onChangeModule: (module: IModule) => void
) => {
  // ---------------------------------------------- handlers
  const handleClick = () => onChangeModule(module);

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
