// ---------------------------------------------- modules import
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import Button from "../buttons/button";

import { IUpdateModalProps } from "./common";
import { useUpdateModal } from "./hooks/use_update_modal";

// ---------------------------------------------- the component
const UpdateModal: FunctionComponent<IUpdateModalProps> = ({
  id,
  className,
  valid,
  children,
}) => {
  const { isVisible, onHideModal, onShowModal } = useUpdateModal();

  return (
    <>
      <Button
        type="button"
        valid={valid}
        className={className}
        onClick={onShowModal}
        dataModalTarget={id}
        dataModalToggle={id}
      >
        {children}
      </Button>
      {isVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-gray-800 w-full max-w-md p-6 rounded-lg shadow-xl">
            <div className="flex justify-between items-center mb-4">
              <h5 className="text-xl font-semibold text-white">
                Confirm Update
              </h5>
              <button
                onClick={onHideModal}
                className="text-gray-400 hover:text-white transition duration-150 ease-in-out"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <p className="text-gray-300 mb-6">
              Are you sure you want to continue with this update?
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                type="button"
                valid={false}
                onClick={onHideModal}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition duration-150 ease-in-out"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                valid
                dataModalToggle={id}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-150 ease-in-out"
              >
                Yes, Update
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateModal;
