// ---------------------------------------------- modules import
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import { SessionContext } from "../../../../contexts/session/context";
import { SheetContext } from "../../../../contexts/sheet/context";
import { WorksheetContext } from "../../../../contexts/worksheet/context";
import { ICriteria } from "../common";
import { IWorksheet } from "../../../../models/worksheet";

// ---------------------------------------------- the hooks
export const useWorksheetTable = () => {
  // ---------------------------------------------- consume context
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);
  const { onDownloadReports } = useContext(SheetContext);
  const {
    participantGroup,
    module,
    subsetToken,
    worksheets,
    error: errWorksheet,
    fetching: fetchingWorksheet,
    onChangeWorksheet,
  } = useContext(WorksheetContext);

  // ---------------------------------------------- local state
  const [criteria, setCriteria] = useState<ICriteria>({
    keywords: "",
    prop: "name",
  });
  const [filtered, setFiltered] = useState<IWorksheet[]>([]);
  const [sortBy, setSortBy] = useState({
    asc: 1,
    prop: "name",
  });
  const [sorted, setSorted] = useState<IWorksheet[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [errRequest, setErrRequest] = useState<string | null>(null);

  // ---------------------------------------------- handlers
  const handleFilter = (criterion: string, value: string) => {
    if (/^[^()[\\\]|]{0,}$/.test(value))
      setCriteria({
        ...criteria,
        [criterion]: value,
      });
  };

  const handleSort = (prop: string) =>
    setSortBy((prev) => ({
      ...prev,
      prop,
      asc: prev.asc * -1,
    }));

  const handleDownloadSheet = () => {
    onDownloadReports(subsetToken, filtered);

    return;
  };

  const handleDownloadZip = () => {
    const url = `${proxy}${API_ROUTES.WORKSHEET}`;

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setLoading(true);

        axios({
          method: "get",
          url: `${url}/download_many`,
          params: {
            participant_group_id: participantGroup.id,
            module_id: module.id,
            subset_token_id: subsetToken.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
          timeout: 3000000,
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `${subsetToken.id}.zip`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setLoading(false);
            setErrRequest(null);
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            console.log(error);

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    const { prop, asc } = sortBy;

    setSorted(
      [...worksheets].sort((a, b) =>
        prop === "name" || prop === "phone"
          ? a.profile[prop] > b.profile[prop]
            ? asc
            : asc * -1
          : a[prop] > b[prop]
          ? asc
          : asc * -1
      )
    );
  }, [sortBy, worksheets]);

  useEffect(() => {
    const { keywords, prop } = criteria;

    setFiltered(
      sorted.filter((worksheet) =>
        RegExp(`${keywords}`, "i").test(
          prop === "name" || prop === "phone"
            ? worksheet.profile[prop]
            : worksheet[prop]
        )
      )
    );
  }, [criteria, sorted]);

  useEffect(
    () => setError(errRequest || errSession || errWorksheet),
    [errRequest, errSession, errWorksheet]
  );

  // ---------------------------------------------- return value
  return {
    criteria,
    error,
    fetching: fetchingSession || fetchingWorksheet,
    filtered,
    loading,
    subsetToken,
    worksheets,
    sortBy,
    onChangeWorksheet,
    onFilter: handleFilter,
    onSort: handleSort,
    onDownloadSheet: handleDownloadSheet,
    onDownloadZip: handleDownloadZip,
  };
};
