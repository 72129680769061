// ---------------------------------------------- modules import
import { Document } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import CoverPage from "./cover_page";
import DisclaimerPage from "./disclaimer_page";
import ResultPageCfit from "./result_page_cfit";
import ResultPageDisc from "./result_page_disc";
import ResultPageAdversityQuotient from "./result_page_adversity_quotient";
import ResultPageSPM from "./result_page_spm";
import ResultPageColouredProgressiveMatrices from "./result_page_coloured_progressive_matrices";
import ResultPageGayaBelajarSDKelas1Sampai3 from "./result_page_gaya_belajar_sd_kelas_1_sampai_3";
import ResultPageGayaBelajar from "./result_page_gaya_belajar";
import ResultPageGayaBelajarV2 from "./result_page_gaya_belajar_v2";
import ResultPageKebiasaanBelajar from "./result_page_kebiasaan_belajar";
import ResultPageKebiasaanBelajarV2 from "./result_page_kebiasaan_belajar_v2";
import ResultPageKecerdasanMajemuk from "./result_page_kecerdasan_majemuk";
import ResultPageKecerdasanMajemukV2 from "./result_page_kecerdasan_majemuk_v2";
import ResultPageKesejahteraanPsikologiDanEmosional from "./result_page_kesejahteraan_psikologi_dan_emosional";
import ResultPageKesejahteraanPsikologiDanEmosionalV2 from "./result_page_kesejahteraan_psikologi_dan_emosional_v2";
import ResultPageKondisiKeluarga from "./result_page_kondisi_keluarga";
import ResultPageKondisiKeluargaV2 from "./result_page_kondisi_keluarga_v2";
import ResultPagePersonality from "./result_page_personality";
import ResultPagePersonalityV2 from "./result_page_personality_v2";
import ResultPageRiasec from "./result_page_riasec";
import ResultPageRiasecV2 from "./result_page_riasec_v2";

import { IPdfPersonalReportProps } from "./common";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";

// ---------------------------------------------- the component
const PdfPersonalReport: FunctionComponent<IPdfPersonalReportProps> = ({
  worksheet,
}) => {
  let gayaBelajarQuestionnaire = undefined;
  let kebiasaanBelajarQuestionnaire = undefined;
  let kecerdasanMajemukQuestionnaire = undefined;
  let kesejahteraanPsikologiDanEmosionalQuestionnaire = undefined;
  let kondisiKeluargaQuestionnaire = undefined;
  let personalityQuestionnaire = undefined;
  let riasecQuestionnaire = undefined;
  let gayaBelajarSDKelas1Sampai3Questionnaire = undefined;
  let colouredProgressiveMatricesQuestionnaire = undefined;
  let spmQuestionnaire = undefined;
  let adversityQuotientQuestionnaire = undefined;
  let cfitQuestionnaire = undefined;
  let discQuestionnaire = undefined;

  worksheet.questionnaires.forEach((questionnaire) => {
    switch (questionnaire.assessment.id) {
      case AssessmentIdEnum.GAYA_BELAJAR:
        gayaBelajarQuestionnaire = questionnaire;
        break;
      case AssessmentIdEnum.KEBIASAAN_BELAJAR:
        kebiasaanBelajarQuestionnaire = questionnaire;
        break;
      case AssessmentIdEnum.KECERDASAN_MAJEMUK:
        kecerdasanMajemukQuestionnaire = questionnaire;
        break;
      case AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL:
        kesejahteraanPsikologiDanEmosionalQuestionnaire = questionnaire;
        break;
      case AssessmentIdEnum.KONDISI_KELUARGA:
        kondisiKeluargaQuestionnaire = questionnaire;
        break;
      case AssessmentIdEnum.PERSONALITY:
        personalityQuestionnaire = questionnaire;
        break;
      case AssessmentIdEnum.RIASEC:
        riasecQuestionnaire = questionnaire;
        break;
      case AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3:
        gayaBelajarSDKelas1Sampai3Questionnaire = questionnaire;
        break;
      case AssessmentIdEnum.COLOURED_PROGRESSIVE_MATRICES:
        colouredProgressiveMatricesQuestionnaire = questionnaire;
        break;
      case AssessmentIdEnum.SPM:
        spmQuestionnaire = questionnaire;
        break;

      case AssessmentIdEnum.ADVERSITY_QUOTIENT:
        adversityQuotientQuestionnaire = questionnaire;
        break;

      case AssessmentIdEnum.DISC:
        discQuestionnaire = questionnaire;
        break;

      case AssessmentIdEnum.CFIT:
        cfitQuestionnaire = questionnaire;
        break;
      default:
        return;
    }
  });

  // ---------------------------------------------- content
  return (
    <Document>
      <CoverPage
        profile={worksheet.profile}
        participantGroup={worksheet.participantGroup}
        tokenName={worksheet.tokenName}
      />
      <DisclaimerPage />

      {personalityQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPagePersonalityV2 questionnaire={personalityQuestionnaire} />
        ) : (
          <ResultPagePersonality questionnaire={personalityQuestionnaire} />
        ))}

      {kecerdasanMajemukQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageKecerdasanMajemukV2
            questionnaire={kecerdasanMajemukQuestionnaire}
          />
        ) : (
          <ResultPageKecerdasanMajemuk
            questionnaire={kecerdasanMajemukQuestionnaire}
          />
        ))}

      {gayaBelajarQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageGayaBelajarV2 questionnaire={gayaBelajarQuestionnaire} />
        ) : (
          <ResultPageGayaBelajar questionnaire={gayaBelajarQuestionnaire} />
        ))}

      {kesejahteraanPsikologiDanEmosionalQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageKesejahteraanPsikologiDanEmosionalV2
            questionnaire={kesejahteraanPsikologiDanEmosionalQuestionnaire}
          />
        ) : (
          <ResultPageKesejahteraanPsikologiDanEmosional
            questionnaire={kesejahteraanPsikologiDanEmosionalQuestionnaire}
          />
        ))}

      {kebiasaanBelajarQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageKebiasaanBelajarV2
            questionnaire={kebiasaanBelajarQuestionnaire}
          />
        ) : (
          <ResultPageKebiasaanBelajar
            questionnaire={kebiasaanBelajarQuestionnaire}
          />
        ))}

      {kondisiKeluargaQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageKondisiKeluargaV2
            questionnaire={kondisiKeluargaQuestionnaire}
          />
        ) : (
          <ResultPageKondisiKeluarga
            questionnaire={kondisiKeluargaQuestionnaire}
          />
        ))}

      {riasecQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageRiasecV2 questionnaire={riasecQuestionnaire} />
        ) : (
          <ResultPageRiasec questionnaire={riasecQuestionnaire} />
        ))}

      {gayaBelajarSDKelas1Sampai3Questionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageGayaBelajarSDKelas1Sampai3
            questionnaire={gayaBelajarSDKelas1Sampai3Questionnaire}
          />
        ) : (
          <ResultPageGayaBelajarSDKelas1Sampai3
            questionnaire={gayaBelajarSDKelas1Sampai3Questionnaire}
          />
        ))}

      {colouredProgressiveMatricesQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageColouredProgressiveMatrices
            questionnaire={colouredProgressiveMatricesQuestionnaire}
          />
        ) : (
          <ResultPageColouredProgressiveMatrices
            questionnaire={colouredProgressiveMatricesQuestionnaire}
          />
        ))}

      {spmQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageSPM questionnaire={spmQuestionnaire} />
        ) : (
          <ResultPageSPM questionnaire={spmQuestionnaire} />
        ))}
      {adversityQuotientQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageAdversityQuotient
            questionnaire={adversityQuotientQuestionnaire}
          />
        ) : (
          <ResultPageAdversityQuotient
            questionnaire={adversityQuotientQuestionnaire}
          />
        ))}

      {discQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageDisc questionnaire={discQuestionnaire} />
        ) : (
          <ResultPageDisc questionnaire={discQuestionnaire} />
        ))}

      {cfitQuestionnaire &&
        (worksheet.version === "2" ? (
          <ResultPageCfit questionnaire={cfitQuestionnaire} />
        ) : (
          <ResultPageCfit questionnaire={cfitQuestionnaire} />
        ))}
    </Document>
  );
};

export default PdfPersonalReport;
