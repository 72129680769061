// ---------------------------------------------- modules import
import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import voca from "voca";

import Button from "../../../components/ui/buttons/button";
import CheckboxInput from "../../../components/ui/inputs/checkbox_input";

import bgQuiz from "../../../assets/images/bg-overview.png";

import { IQuizAdversityQuotientProps } from "./common";
import { useQuizAdversityQuotient } from "./hooks/use_quiz_adversity_quotient";

// ---------------------------------------------- the component
const QuizAdversityQuotient: FunctionComponent<IQuizAdversityQuotientProps> = ({
  questionnaires,
  onChangeSelectedOptions,
}) => {
  // ---------------------------------------------- local state
  const { questionnaire, group, question, onChange, onNext, onPrevious } =
    useQuizAdversityQuotient(questionnaires, onChangeSelectedOptions);

  const anyOptionSelected = question
    ? question.options.some(
        (option) =>
          question.selectedOptions.findIndex(
            (selectedOption) => selectedOption.value === option.value
          ) !== -1
      )
    : false;

  // ---------------------------------------------- the content
  return (
    <div
      className="min-h-screen flex items-center justify-center p-4 bg-fixed bg-cover bg-center"
      style={{
        backgroundImage: `url(${bgQuiz})`,
      }}>
      <div className="bg-white bg-opacity-90 rounded-lg shadow-xl w-full max-w-2xl mx-auto my-8 relative overflow-hidden">
        <div className="relative bg-white rounded-lg shadow-xl p-8">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">
            {questionnaire && voca.upperCase(questionnaire.assessment.name)}
          </h1>

          {questionnaire && group && question && (
            <>
              <p className="text-lg text-gray-700 mb-6">{question.text}</p>

              <div className="space-y-4">
                {question.options.map((option, index) => {
                  const { text, value } = option;

                  const included = question.selectedOptions.reduce(
                    (included, selectedOption) =>
                      included || selectedOption.value === value,
                    false
                  );

                  return (
                    <label
                      key={value}
                      className={`block w-full p-4 rounded-lg cursor-pointer transition-colors duration-200 ${
                        included
                          ? "bg-blue-100 border-blue-500"
                          : "bg-gray-100 border-gray-300"
                      } hover:bg-blue-50`}>
                      <CheckboxInput
                        key={value}
                        checked={included}
                        className_input="hidden"
                        // className_label={`ml-2 text-base ${
                        //   included ? "text-blue-700" : "text-gray-700"
                        // }`}
                        id={`${value}-${index}`}
                        name={question.id}
                        value={value}
                        onChange={onChange}
                      />
                      {text}
                    </label>
                  );
                })}
              </div>
            </>
          )}

          <div className="flex justify-between mt-8">
            <Button
              className="flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200"
              type="button"
              valid
              onClick={onPrevious}>
              <FontAwesomeIcon icon={faCircleLeft} className="text-xl mr-2" />
              <span className="text-lg font-bold">BACK</span>
            </Button>

            <Button
              className={`flex items-center transition-colors duration-200 ${
                anyOptionSelected
                  ? "text-blue-600 hover:text-blue-800"
                  : "text-gray-400 cursor-not-allowed"
              }`}
              type="button"
              valid={anyOptionSelected}
              onClick={onNext}>
              <span className="text-lg font-bold mr-2">NEXT</span>
              <FontAwesomeIcon icon={faCircleRight} className="text-xl" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizAdversityQuotient;
