// ---------------------------------------------- document interfaces
/**
 * Represents the data fields of a province document in Firestore.
 *
 * Subcollections of each document:
 * - Regencies
 */
export interface IProvinceRecord {
  altName: string;
  latitude: number;
  longitude: number;
  name: string;
}

/**
 * Represents a province returned to the frontend from Firestore.
 *
 * Extends the IProvinceRecord interface.
 */
export interface IProvince extends IProvinceRecord {
  id: string;
  [key: string]: any;
}

// ---------------------------------------------- empty document
export const emptyProvinceRecord = (): IProvinceRecord => ({
  altName: "",
  latitude: 0,
  longitude: 0,
  name: "",
});

export const emptyProvince = (): IProvince => ({
  ...emptyProvinceRecord(),
  id: "",
});
