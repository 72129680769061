// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgCover from "../../../../assets/images/bg-cover.png";

import { ICoverPageProps } from "./common";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center", // Center content vertically
    padding: 40,
    position: "relative", // Tambahkan style position: relative
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  logo: {
    marginBottom: 10,
    width: 200,
    zIndex: 1,
  },
  table: {
    display: "flex",
    margin: "0", // Mengubah margin menjadi 0 auto untuk memusatkan tabel
    width: "50%",
    borderStyle: undefined,
    marginLeft: 70,
  },
  tableRow: {
    flexDirection: "row",
    margin: "0",
  },
  tableColHeader: {
    width: "40%",
    padding: 5,
    fontWeight: "bold",
    fontSize: 12,
    wordWrap: "break-word", // Menambahkan wordWrap untuk membungkus teks yang terlalu panjang
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    width: "60%",
    padding: 5,
    fontSize: 12,
    wordWrap: "break-word", // Menambahkan wordWrap untuk membungkus teks yang terlalu panjang
  },
  credit: {
    bottom: 30,
    fontSize: 12,
    left: 0,
    right: 0,
    position: "absolute",
    textAlign: "center",
    zIndex: 1,
  },
});

// ---------------------------------------------- the component
const CoverPage: FunctionComponent<ICoverPageProps> = ({
  participantGroup,
  module,
  subsetToken,
}) => (
  <Page size="A4" style={styles.page}>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={styles.tableColHeader}>Grup</Text>
        <Text style={styles.tableCol}>: {participantGroup.name}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableColHeader}>Modul & Token</Text>
        <Text style={styles.tableCol}>
          :{module.name}, {subsetToken.name}
        </Text>
      </View>
    </View>
    <Image src={BgCover} style={styles.backgroundImage} />
  </Page>
);

export default CoverPage;
