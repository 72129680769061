// ---------------------------------------------- modules import
import { FormEvent } from "react";

import { IOption } from "../../../ui/inputs/common";

// ---------------------------------------------- the hooks
export const useParticipantGroupSearchForm = () => {
  // ---------------------------------------------- consume context
  const findByOptions: IOption[] = [
    { label: "Name", value: "name" },
    { label: "Province", value: "province" },
    { label: "Regency", value: "regency" },
  ];

  // ---------------------------------------------- handlers
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();

  // ---------------------------------------------- return value
  return { findByOptions, onSubmit: handleSubmit };
};
