// ---------------------------------------------- modules import
import { faHourglass, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate } from "react-router-dom";

import logo from "../../../assets/images/ganesa_new.png";

import Footer from "../../../components/layouts/footer";
import Button from "../../../components/ui/buttons/button";
import LinkButton from "../../../components/ui/buttons/link_button";
import TextInput from "../../../components/ui/inputs/text_input";

import * as ROUTES from "../../../constants/routes";

import { useTokenSignIn } from "./hooks/use_token_sign_in";

// ---------------------------------------------- the component
const TokenSignIn = () => {
  // ---------------------------------------------- local state
  const { token, error, fetching, onChange, onSubmit } = useTokenSignIn();

  // ---------------------------------------------- content
  return !token ? (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="bg-black rounded-lg py-6 px-8 w-full md:w-6/12 lg:w-4/12 mb-4">
        <img src={logo} alt="Logo" className="mx-auto" />

        <form onSubmit={onSubmit} className="flex flex-col items-center">
          <TextInput
            className_input="text-center bg-white rounded-full text-xl font-bold"
            className_root="mb-4"
            id="name"
            name="name"
            pattern={{
              minLength: 1,
              maxLength: 100,
              regex: /^[\w]{1,100}$/,
            }}
            placeholder="MASUKKAN TOKEN ANDA"
            required
            type="text"
            onChange={onChange}
          />

          <Button
            type="submit"
            valid={!fetching}
            className="w-full pt-2 pb-4 mb-2 md:mb-4"
          >
            <span className="text-teal-400 text-5xl font-extrabold text-center hover:text-fuchsia-600">
              Submit
            </span>
          </Button>

          {fetching && (
            <p className="text-blue-300">
              <FontAwesomeIcon icon={faHourglass} /> Now loading...
            </p>
          )}

          {error && (
            <p className="text-yellow-400">
              <FontAwesomeIcon icon={faWarning} /> {error}
            </p>
          )}

          <div className="self-end">
            <LinkButton
              to={`${ROUTES.SIGN_IN}/admin`}
              valid={!fetching}
              style={{
                color: "#fff",
              }}
            >
              <span className="text-blue-200 text-sm underline">
                or sign in as admin
              </span>
            </LinkButton>
          </div>
        </form>
      </div>

      <Footer />
    </div>
  ) : (
    <Navigate replace to={ROUTES.QUIZ} />
  );
};

export default TokenSignIn;
