// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { IModule, emptyModule } from "../../models/module";
import {
  IParticipantGroup,
  emptyParticipantGroup,
} from "../../models/participant_group";
import { ISubsetToken, ISubsetTokenRecord } from "../../models/subset_token";

// ---------------------------------------------- the hooks
export const useSubsetTokenContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [participantGroup, setParticipantGroup] = useState<IParticipantGroup>(
    emptyParticipantGroup()
  );
  const [module, setModule] = useState<IModule>(emptyModule());
  const [subsetTokens, setSubsetTokens] = useState<ISubsetToken[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- handlers
  const handleChangeParticipantGroup = (participantGroup: IParticipantGroup) =>
    setParticipantGroup(participantGroup);

  const handleChangeModule = (module: IModule) => setModule(module);

  // ---------------------------------------------- effects
  useEffect(() => {
    if (participantGroup && module) {
      setFetching(true);

      const ref = firebase.firestore
        .collection(PATHS.PARTICIPANT_GROUP)
        .doc(participantGroup.id.length ? participantGroup.id : "empty id")
        .collection(PATHS.MODULE)
        .doc(module.id.length ? module.id : "empty id")
        .collection(PATHS.SUBSET_TOKEN)
        .orderBy("name", "asc");

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          setSubsetTokens(
            querySnapshot.docs.reduce(
              (subsetTokens, doc) => [
                ...subsetTokens,
                {
                  id: doc.id,
                  ...(doc.data() as ISubsetTokenRecord),
                },
              ],
              [] as ISubsetToken[]
            )
          );

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, participantGroup, module]);

  // ---------------------------------------------- return value
  return {
    module,
    participantGroup,
    subsetTokens,
    error,
    fetching,
    onChangeModule: handleChangeModule,
    onChangeParticipantGroup: handleChangeParticipantGroup,
  };
};
