// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-cpm.png";

import { IResultPageColouredProgressiveMatricesProps } from "./common";
import { IConclusion_ColouredProgressiveMatrices } from "../conclusion/coloured_progressive_matrices/conclusion";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  section: {
    marginBottom: 8,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderWidth: 2,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10,
    overflow: "hidden",
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#cee9f0",
  },
  tableRowCheck: {
    flexDirection: "row",
  },
  tableHeader: {
    backgroundColor: "#66b8ce",
    color: "#ffffff",
  },
  tableColHeader: {
    height: 36,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeaderScore: {
    height: 60,
    width: "100%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeaderCategory: {
    height: 24,
    width: "100%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
  },
  tableCol: {
    height: 24,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
  },
  tableColCheck: {
    height: 24,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    backgroundColor: "#CEE9F0",
  },
  tableColCheckAlt: {
    height: 24,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    backgroundColor: "#E0C0C8",
  },
  tableColScore: {
    height: 48,
    width: "100%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCell: {
    fontSize: 10,
    fontWeight: "bold",
  },
  tableCellScore: {
    fontSize: 20,
    fontWeight: "bold",
  },
  heading: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  subHeading: {
    fontSize: 12,
    marginBottom: 2,
    fontFamily: "Helvetica-Bold",
  },
  text: {
    fontSize: 11,
    textAlign: "justify",
    marginBottom: 3,
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  tableSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  tableLeft: {
    width: "20%",
    padding: 4,
  },
  tableRight: {
    width: "80%",
    padding: 4,
  },
});

// ---------------------------------------------- the component
const ResultPageColouredProgressiveMatrices: FunctionComponent<
  IResultPageColouredProgressiveMatricesProps
> = ({ questionnaire }) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_ColouredProgressiveMatrices;

  const intScoreCategory = conclusion.scoreCategory;

  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <Text style={styles.heading}>PSIKOGRAM</Text>
        <View style={styles.tableSection}>
          <View style={styles.tableLeft}>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <View style={styles.tableColHeaderScore}>
                  <Text style={styles.tableCell}>{"Taraf\n Kecerdasan"}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColScore}>
                  <Text style={styles.tableCellScore}>
                    {conclusion.scoreCategory}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.tableRight}>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <View style={styles.tableColHeaderCategory}>
                  <Text style={styles.tableCell}>Kategori</Text>
                </View>
              </View>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Rendah</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>{"Rata-rata\n Bawah"}</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Rata-rata</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>{"Rata-rata\n Atas"}</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Tinggi</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{"< 5"}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>5 - 24</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>25 - 74</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>75 - 94</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{"> 95"}</Text>
                </View>
              </View>
              <View style={styles.tableRowCheck}>
                <View
                  style={
                    intScoreCategory < 5
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }
                >
                  <Text style={styles.tableCell}>
                    {intScoreCategory < 5 ? "X" : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 5 && intScoreCategory <= 24
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }
                >
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 5 && intScoreCategory <= 24 ? "X" : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 25 && intScoreCategory <= 74
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }
                >
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 25 && intScoreCategory <= 74
                      ? "X"
                      : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 75 && intScoreCategory <= 94
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }
                >
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 75 && intScoreCategory <= 94
                      ? "X"
                      : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 95
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }
                >
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 95 ? "X" : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Text style={styles.subHeading}>DESKRIPSI</Text>
      <View style={styles.section} wrap={false}>
        {conclusion.skills.map((skill, index) => (
          <View key={index} style={styles.section}>
            <Text style={styles.subHeading}>{skill.name}</Text>
            <Text style={styles.text}>{skill.description}</Text>
          </View>
        ))}
      </View>

      <View style={styles.section} wrap={false}>
        <Text style={styles.subHeading}>Saran Pengembangan:</Text>
        {conclusion.suggestedGrowth.map((suggestion, index) => (
          <Text key={index} style={styles.text}>
            {suggestion}
          </Text>
        ))}
      </View>
    </Page>
  );
};

export default ResultPageColouredProgressiveMatrices;
