// ---------------------------------------------- modules import
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Overview from "./overview";
import NotFound from "../../../components/fallbacks/not_found";
import Footer from "../../../components/layouts/footer";
import PrivateRoute from "../../../components/route_guards/private_route";

import logo from "../../../assets/images/ganesa_new.png";

import DashboardContextProvider from "../../../contexts/dashboard/context";

import * as ROUTES from "../../../constants/routes";

import { useDashboard } from "./hooks/use_dashboard";

// ---------------------------------------------- the component
const Dashboard = () => {
  // ---------------------------------------------- local state
  const { profile } = useDashboard();

  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // ---------------------------------------------- content
  return profile ? (
    <DashboardContextProvider>
      <div className="min-h-screen bg-gray-900 text-white flex flex-col">
        <header className="bg-gray-800 shadow-md py-4 px-6 flex items-center justify-between">
          <h1 className="text-2xl font-semibold pl-8">Dashboard</h1>
          <img src={logo} alt="Logo" className="h-8" />
        </header>
        <main className="flex-1 p-6">
          <Routes>
            <Route
              element={
                <PrivateRoute isAuthorized={profile !== null}>
                  <Overview />
                </PrivateRoute>
              }
              path=""
            />
            <Route element={<NotFound />} path={ROUTES.OTHERS} />
          </Routes>
        </main>
        <Footer />
      </div>
    </DashboardContextProvider>
  ) : (
    <Navigate replace to={`${ROUTES.SIGN_IN}/admin`} />
  );
};

export default Dashboard;
