// ---------------------------------------------- modules import
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { ConsolidatedWorksheetContext } from "../../../../contexts/consolidated_worksheets/context";
import { ParticipantGroupContext } from "../../../../contexts/participant_group/context";
import { SessionContext } from "../../../../contexts/session/context";

import { ICriteria } from "../common";
import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import {
  DevRoyaltyFeeStatus,
  IConsolidatedWorksheet,
} from "../../../../models/consolidated_worksheet";
import { newSettleConsolidatedWorksheetsDto } from "../../../../dtos/consolidated_worksheet";

// ---------------------------------------------- the hooks
export const useConsolidatedWorksheetTable = () => {
  // ---------------------------------------------- consume context
  const {
    participantGroups,
    error: errParticipantGroup,
    fetching: fetchingParticipantGroup,
  } = useContext(ParticipantGroupContext);
  const {
    consolidatedWorksheets,
    limit,
    error: errConsolidatedWorksheet,
    fetching: fetchingConsolidatedWorksheet,
    hasMore: hasMoreConsolidatedWorksheet,
    onChangeLimit: onChangeLimitFromConsolidatedWorksheetContext,
    onNextPage,
    onFirstPage,
    onChangeFilters,
  } = useContext(ConsolidatedWorksheetContext);
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [criteria, setCriteria] = useState<ICriteria>({
    settlementStatus: null,
    group: null,
    keywords: "",
    prop: "profile",
  });
  const [filtered, setFiltered] = useState<IConsolidatedWorksheet[]>([]);
  const [sortBy, setSortBy] = useState({
    asc: -1,
    prop: "dateCreated",
  });
  const [sorted, setSorted] = useState<IConsolidatedWorksheet[]>([]);

  const [selected, setSelected] = useState<IConsolidatedWorksheet[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [errRequest, setErrRequest] = useState<string | null>(null);

  // ---------------------------------------------- handlers
  const handleFilter = (criterion: string, value: string) => {
    setSelected([]);

    if (/^[^()[\\\]|]{0,}$/.test(value))
      setCriteria({
        ...criteria,
        [criterion]:
          criterion === "settlementStatus"
            ? value === "settled"
              ? true
              : value === "review"
              ? "review"
              : value === "pending"
              ? "pending"
              : value === "unsettled"
              ? false
              : null
            : criterion === "group"
            ? value === "all"
              ? null
              : value
            : value,
      });

    if (criterion === "settlementStatus")
      onChangeFilters("status.devRoyaltyFee", "==", value);

    if (criterion === "group")
      onChangeFilters("worksheet.participantGroup.id", "==", value);

    if (criterion === "settlementStatus" || criterion === "group")
      onFirstPage();
  };

  const handleSort = (prop: string) =>
    setSortBy((prev) => ({
      ...prev,
      prop,
      asc: prev.asc * -1,
    }));

  const handleChangeLimit = (prop: string, value: string) => {
    setSelected([]);

    onFirstPage();

    onChangeLimitFromConsolidatedWorksheetContext(parseFloat(value));
  };

  const handleSelect = (consolidatedWorksheet: IConsolidatedWorksheet) => {
    const included = selected.reduce(
      (included, s) => included || s.id === consolidatedWorksheet.id,
      false
    );

    if (!included) setSelected([...selected, consolidatedWorksheet]);
    else
      setSelected(
        [...selected].filter((s) => s.id !== consolidatedWorksheet.id)
      );
  };

  const handleSelectAll = () => {
    if (selected.length) setSelected([]);
    else setSelected(consolidatedWorksheets);
  };

  const handleSettlement = (settlementStatus: DevRoyaltyFeeStatus) => {
    const url = `${proxy}${API_ROUTES.CONSOLIDATED_WORKSHEET}`;

    const endpoint =
      settlementStatus === true
        ? "settle"
        : settlementStatus === "pending"
        ? "pending"
        : settlementStatus === "review"
        ? "review"
        : "unsettle";

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setLoading(true);

        axios
          .post(
            `${url}/${endpoint}`,
            newSettleConsolidatedWorksheetsDto(selected),
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            }
          )
          .then(() => {
            setLoading(false);
            setErrRequest(null);

            setSelected([]);

            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    const { prop, asc } = sortBy;

    setSorted(
      [...consolidatedWorksheets].sort((a, b) =>
        prop === "participantGroup" || prop === "module" || prop === "profile"
          ? a.worksheet[prop].name > b.worksheet[prop].name
            ? asc
            : asc * -1
          : prop === "tokenId"
          ? a.worksheet[prop] > b.worksheet[prop]
            ? asc
            : asc * -1
          : prop === "status"
          ? a.status.devRoyaltyFee > b.status.devRoyaltyFee
            ? asc
            : asc * -1
          : a[prop] > b[prop]
          ? asc
          : asc * -1
      )
    );
  }, [sortBy, consolidatedWorksheets]);

  useEffect(() => {
    const { settlementStatus, keywords, prop } = criteria;

    setFiltered(
      sorted
        .filter((consolidatedWorksheets) =>
          settlementStatus !== null
            ? consolidatedWorksheets.status.devRoyaltyFee === settlementStatus
            : consolidatedWorksheets
        )
        .filter((consolidatedWorksheet) =>
          RegExp(`${keywords}`, "i").test(
            prop === "participantGroup" ||
              prop === "module" ||
              prop === "profile"
              ? consolidatedWorksheet.worksheet[prop].name
              : prop === "tokenId"
              ? consolidatedWorksheet.worksheet[prop]
              : consolidatedWorksheet[prop]
          )
        )
    );
  }, [criteria, sorted]);

  useEffect(
    () =>
      setError(
        errParticipantGroup ||
          errConsolidatedWorksheet ||
          errRequest ||
          errSession
      ),
    [errParticipantGroup, errConsolidatedWorksheet, errRequest, errSession]
  );

  // ---------------------------------------------- content
  return {
    criteria,
    error,
    fetching:
      fetchingParticipantGroup ||
      fetchingConsolidatedWorksheet ||
      fetchingSession,
    loading,
    filtered,
    participantGroups,
    consolidatedWorksheets,
    selected,
    limit,
    sortBy,
    hasMoreConsolidatedWorksheet,
    onFilter: handleFilter,
    onSort: handleSort,
    onChangeLimit: handleChangeLimit,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    onNextPage: () => {
      onNextPage();
      setSelected([]);
    },
    onFirstPage: () => {
      onFirstPage();
      setSelected([]);
    },
    onSettlement: handleSettlement,
  };
};
