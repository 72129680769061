// ---------------------------------------------- modules import
import { FunctionComponent } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import NotFound from "./components/fallbacks/not_found";
import Assessment from "./components/modules/assessment/assessment";
import AdminSignIn from "./components/modules/auth/admin_sign_in";
import TokenSignIn from "./components/modules/auth/token_sign_in";
import Consolidation from "./components/modules/consolidation/consolidation";
import Dashboard from "./components/modules/dashboard/dashboard";
import ParticipantGroup from "./components/modules/participant_group/participant_group";
import Quiz from "./components/modules/quiz/quiz";
import Report from "./components/modules/report/report";
import Settings from "./components/modules/settings/settings";
import Submission from "./components/modules/submission/submission";

import Sidebar from "./components/layouts/sidebar";

import FirebaseContextProvider from "./contexts/firebase/context";
import SessionContextProvider from "./contexts/session/context";

import * as ROUTES from "./constants/routes";

// ---------------------------------------------- the component
const App: FunctionComponent = () => (
  <FirebaseContextProvider>
    <SessionContextProvider>
      <BrowserRouter>
        <div className="bg-black overflow-auto">
          <Sidebar />

          <Routes>
            <Route path={ROUTES.SIGN_IN} element={<TokenSignIn />} />

            <Route path={`${ROUTES.SIGN_IN}/admin`} element={<AdminSignIn />} />

            <Route path={`${ROUTES.DASHBOARD}/*`} element={<Dashboard />} />

            <Route path={`${ROUTES.ASSESSMENT}/*`} element={<Assessment />} />

            <Route
              path={`${ROUTES.CONSOLIDATION}/*`}
              element={<Consolidation />}
            />

            <Route
              path={`${ROUTES.PARTICIPANT_GROUP}/*`}
              element={<ParticipantGroup />}
            />

            <Route path={`${ROUTES.QUIZ}/*`} element={<Quiz />} />

            <Route path={`${ROUTES.REPORT}/*`} element={<Report />} />

            <Route path={`${ROUTES.SETTINGS}/*`} element={<Settings />} />

            <Route path={`${ROUTES.SUBMISSION}/*`} element={<Submission />} />

            <Route path={ROUTES.OTHERS} element={<NotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </SessionContextProvider>
  </FirebaseContextProvider>
);

export default App;
