// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faFilePdf,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import PdfTokenGroupedReport from "./pdf_token_grouped_report/pdf_token_grouped_report";
import Button from "../../../components/ui/buttons/button";
import LinkButton from "../../../components/ui/buttons/link_button";

import * as ROUTES from "../../../constants/routes";

import { IPdfTokenGroupedPreviewProps } from "./common";
import { usePdfTokenGroupedPreview } from "./hooks/use_pdf_token_grouped_preview";

// ---------------------------------------------- the component
const PdfTokenGroupedPreview: FunctionComponent<
  IPdfTokenGroupedPreviewProps
> = ({ isAuthorizedToWrite }) => {
  // ---------------------------------------------- local state
  const {
    error: errorFetching,
    fetching,
    filtered,
    participantGroup,
    module,
    subsetToken,
  } = usePdfTokenGroupedPreview();

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col h-screen p-8">
      <div className="flex items-center justify-between mb-4">
        <div className="py-4 px-6">
          <h1 className="md:text-3xl text-xl font-bold text-gray-100">
            PDF PREVIEW{" "}
            <span className="text-lg md:text-xl font-bold text-yellow-400">
              {`of ${subsetToken.name}`}
            </span>
          </h1>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <PDFViewer className="w-full max-h-screen" height={700}>
          <PdfTokenGroupedReport
            participantGroup={participantGroup}
            module={module}
            subsetToken={subsetToken}
            worksheets={filtered}
          />
        </PDFViewer>
      </div>

      <div className="fixed bottom-4 right-4 z-50">
        <PDFDownloadLink
          className="items-center"
          document={
            <PdfTokenGroupedReport
              participantGroup={participantGroup}
              module={module}
              subsetToken={subsetToken}
              worksheets={filtered}
            />
          }
          fileName={`${participantGroup.name}_${module.name}_${subsetToken.name}`}
        >
          {({ blob, url, loading, error }) => (
            <Button
              className={`flex items-center justify-center w-10 h-10 mb-5 text-white bg-blue-500 rounded-full hover:bg-blue-600 ${
                fetching || loading
                  ? "bg-gray-500 pointer-events-none"
                  : error
                  ? "bg-red-500 pointer-events-none"
                  : ""
              }`}
              type="button"
              valid={!fetching}
            >
              {fetching || loading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="text-lg animate-spin"
                />
              ) : error || errorFetching ? (
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="text-lg"
                />
              ) : (
                <FontAwesomeIcon icon={faFilePdf} className="text-lg" />
              )}
            </Button>
          )}
        </PDFDownloadLink>

        <LinkButton
          className="flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full hover:bg-blue-600"
          to={`${ROUTES.REPORT}${ROUTES.WORKSHEET}`}
          valid
        >
          <FontAwesomeIcon icon={faArrowLeft} className="text-lg" />
        </LinkButton>
      </div>
    </div>
  );
};

export default PdfTokenGroupedPreview;
