// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { IProvince, IProvinceRecord } from "../../models/province";

// ---------------------------------------------- the hooks
export const useProvinceContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [provinces, setProvinces] = useState<IProvince[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- effects
  useEffect(() => {
    setFetching(true);

    const ref = firebase.firestore.collection(PATHS.PROVINCE).orderBy("name");

    const unsubscribe = ref.onSnapshot(
      (querySnapshot) => {
        setProvinces(
          querySnapshot.docs.reduce(
            (provinces, doc) => [
              ...provinces,
              {
                id: doc.id,
                ...(doc.data() as IProvinceRecord),
              },
            ],
            [] as IProvince[]
          )
        );

        setError(null);
        setFetching(false);
      },
      (error) => {
        setError(error.message);
        setFetching(false);
      }
    );

    return () => unsubscribe();
  }, [firebase]);

  // ---------------------------------------------- return value
  return { provinces, error, fetching };
};
