// ---------------------------------------------- modules import
import { faHourglass, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate } from "react-router-dom";

import logo from "../../../assets/images/ganesa_new.png";

import Footer from "../../../components/layouts/footer";
import Button from "../../../components/ui/buttons/button";
import LinkButton from "../../../components/ui/buttons/link_button";
import TextInput from "../../../components/ui/inputs/text_input";

import * as ROUTES from "../../../constants/routes";
import { pattern } from "../../../validations/user";

import { useAdminSignIn } from "./hooks/use_admin_sign_in";

// ---------------------------------------------- the component
const AdminSignIn = () => {
  // ---------------------------------------------- local state
  const { error, fetching, profile, isSignInValid, onChange, onSubmit } =
    useAdminSignIn();

  // ---------------------------------------------- content
  return !profile ? (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-900">
      <div className="bg-gray-800 rounded-lg py-8 px-10 w-full md:w-6/12 lg:w-4/12 mb-4 shadow-lg">
        <img src={logo} alt="Logo" className="mx-auto mb-6" />

        <form onSubmit={onSubmit} className="flex flex-col items-center">
          <TextInput
            className_input="text-center w-full bg-white rounded-full text-xl font-bold"
            className_root="mb-4 w-full"
            id="email"
            name="email"
            pattern={pattern.email}
            placeholder="EMAIL"
            required
            type="email"
            onChange={onChange}
          />

          <TextInput
            className_input="text-center w-full bg-white rounded-full text-xl font-bold"
            className_root="mb-4 w-full"
            id="password"
            name="password"
            pattern={pattern.password}
            placeholder="PASSWORD"
            required
            type="password"
            onChange={onChange}
          />

          <Button
            type="submit"
            valid={!fetching && isSignInValid()}
            className="w-full pt-2 pb-4 mb-2 md:mb-4 bg-teal-600 rounded-full hover:bg-teal-700 text-white"
          >
            <span className="text-2xl font-extrabold">Sign In</span>
          </Button>

          {fetching && (
            <p className="text-blue-300 mt-2">
              <FontAwesomeIcon icon={faHourglass} /> Now loading...
            </p>
          )}

          {error && (
            <p className="text-yellow-400 mt-2">
              <FontAwesomeIcon icon={faWarning} /> <span>{`${error}`}</span>
            </p>
          )}

          <div className="self-end mt-4">
            <LinkButton
              to={`${ROUTES.SIGN_IN}`}
              valid={!fetching}
              className="text-blue-200 text-sm underline"
            >
              or take your quiz
            </LinkButton>
          </div>
        </form>
      </div>

      <Footer />
    </div>
  ) : (
    <Navigate replace to={ROUTES.DASHBOARD} />
  );
};

export default AdminSignIn;
