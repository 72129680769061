// ---------------------------------------------- modules import
import {
  faClipboard,
  faClipboardCheck,
  faToggleOn,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import { ISubsetTokenListItemProps } from "./common";
import Button from "../../../components/ui/buttons/button";

// ---------------------------------------------- the component
const SubsetTokenListItem: FunctionComponent<ISubsetTokenListItemProps> = ({
  copiedId,
  subsetToken,
  onCopyId,
  onToggleStatus,
}) => {
  const { name, status, id } = subsetToken;

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-700 rounded-lg p-4 flex items-center justify-between hover:bg-gray-600 transition duration-300 ease-in-out">
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-white">{name}</h3>
        <p className="text-sm text-gray-400 mt-1">{id}</p>
      </div>
      <div className="flex items-center space-x-4">
        <button
          className={`flex items-center space-x-2 px-3 py-1 rounded-md text-sm font-medium transition duration-300 ease-in-out ${
            status
              ? "bg-green-500 hover:bg-green-600 text-white"
              : "bg-red-500 hover:bg-red-600 text-white"
          }`}
          onClick={() => onToggleStatus(subsetToken)}
        >
          <FontAwesomeIcon icon={status ? faToggleOn : faToggleOff} />
          <span>{status ? "Enabled" : "Disabled"}</span>
        </button>
        <Button
          className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md transition duration-300 ease-in-out"
          type="button"
          valid
          onClick={() => onCopyId(id)}
        >
          <FontAwesomeIcon
            icon={copiedId === id ? faClipboardCheck : faClipboard}
          />
        </Button>
      </div>
    </div>
  );
};

export default SubsetTokenListItem;
