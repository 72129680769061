// ---------------------------------------------- modules import\
import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

import NowLoading from "../../components/fallbacks/now_loading";
// import UnderMaintenance from "../../components/fallbacks/under_maintenance";

import * as ROUTES from "../../constants/routes";

import { ITokenRouteProps } from "./common";
import { useTokenRoute } from "./hooks/use_token_route";

// ---------------------------------------------- the component
const TokenRoute: FunctionComponent<ITokenRouteProps> = ({ children }) => {
  // ---------------------------------------------- local state
  const { fetching, token } = useTokenRoute();

  // ---------------------------------------------- content
  return (
    <>
      {fetching ? (
        <NowLoading />
      ) : !token ? (
        <Navigate replace to={ROUTES.SIGN_IN} />
      ) : (
        children
      )}
      {/* <UnderMaintenance/> */}
    </>
  );
};

export default TokenRoute;
