// ---------------------------------------------- modules import
import { IModule } from "../models/module";

export const newCreateModuleDto = (
  module: IModule,
  participantGroupId: string,
  tokenNames: string[]
) => {
  const { assessmentIds, description, education, name } = module;

  return {
    data: {
      assessmentIds,
      description,
      education,
      name,
      participantGroupId,
      tokenNames,
    },
  };
};

export const newUpdateModuleDto = (module: IModule) => {
  const { description, education, name } = module;

  return {
    data: {
      description,
      education,
      name,
    },
  };
};
