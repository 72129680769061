// ---------------------------------------------- modules import
import { useContext, useEffect, useState } from "react";

import { AttributeContext } from "../../../../contexts/attribute/context";
import { AssessmentContext } from "../../../../contexts/assessment/context";
import { QuestionContext } from "../../../../contexts/question/context";
import { TagContext } from "../../../../contexts/tag/context";
import { ICriteria } from "../common";
import { IAssessment } from "../../../../models/assessment";

// ---------------------------------------------- the hooks
export const useAssessmentTable = () => {
  // ---------------------------------------------- consume context
  const { assessments, error, fetching } = useContext(AssessmentContext);
  const { onChangeAssessment: onChangeAssessmentFromAttributeContext } =
    useContext(AttributeContext);
  const { onChangeAssessment: onChangeAssessmentFromTagContext } =
    useContext(TagContext);
  const { onChangeAssessment: onChangeAssessmentFromQuestionContext } =
    useContext(QuestionContext);

  // ---------------------------------------------- local state
  const [criteria, setCriteria] = useState<ICriteria>({
    keywords: "",
    prop: "name",
  });
  const [filtered, setFiltered] = useState<IAssessment[]>([]);
  const [sortBy, setSortBy] = useState({
    asc: 1,
    prop: "name",
  });
  const [sorted, setSorted] = useState<IAssessment[]>([]);

  // ---------------------------------------------- handlers
  const handleChangeAssessment = (assessment: IAssessment) => {
    onChangeAssessmentFromAttributeContext(assessment);
    onChangeAssessmentFromTagContext(assessment);
    onChangeAssessmentFromQuestionContext(assessment);
  };

  const handleFilter = (criterion: string, value: string) => {
    if (/^[^()[\\\]|]{0,}$/.test(value))
      setCriteria({
        ...criteria,
        [criterion]: value,
      });
  };

  const handleSort = (prop: string) =>
    setSortBy((prev) => ({
      ...prev,
      prop,
      asc: prev.asc * -1,
    }));

  // ---------------------------------------------- effects
  useEffect(() => {
    const { prop, asc } = sortBy;

    setSorted(
      [...assessments].sort((a, b) => (a[prop] > b[prop] ? asc : asc * -1))
    );
  }, [sortBy, assessments]);

  useEffect(() => {
    const { keywords, prop } = criteria;

    setFiltered(
      sorted.filter((assessment) =>
        RegExp(`${keywords}`, "i").test(assessment[prop])
      )
    );
  }, [criteria, sorted]);

  // ---------------------------------------------- content
  return {
    assessments,
    criteria,
    error,
    fetching,
    filtered,
    sortBy,
    onChangeAssessment: handleChangeAssessment,
    onFilter: handleFilter,
    onSort: handleSort,
  };
};
