// ---------------------------------------------- modules import
import {
  faBan,
  faFilePdf,
  faSortDown,
  faSortUp,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import ConsolidatedWorksheetRow from "./consolidated_worksheet_row";
import ConsolidationInfo from "./consolidation_info";
import PdfConsolidation from "./pdf_consolodation";
import SearchForm from "./search_form";
import Button from "../../../components/ui/buttons/button";

import { IConsolidatedWorksheetTableProps } from "./common";
import { useConsolidatedWorksheetTable } from "./hooks/use_consolidated_worksheet_table";

// ---------------------------------------------- the component
const ConsolidatedWorksheetTable: FunctionComponent<
  IConsolidatedWorksheetTableProps
> = ({ isAuthorizedToWrite }) => {
  // ---------------------------------------------- local state
  const {
    criteria,
    error,
    fetching,
    loading,
    filtered,
    participantGroups,
    consolidatedWorksheets,
    selected,
    sortBy,
    limit,
    hasMoreConsolidatedWorksheet,
    onFilter,
    onSort,
    onChangeLimit,
    onSelect,
    onSelectAll,
    onNextPage,
    onFirstPage,
    onSettlement: onSettle,
  } = useConsolidatedWorksheetTable();

  const allSettled = selected.reduce(
    (reduced, consolidatedWorksheet) =>
      reduced && consolidatedWorksheet.status.devRoyaltyFee === true,
    true
  );

  const allInReview = selected.reduce((reduced, consolidatedWorksheet) => {
    return reduced && consolidatedWorksheet.status.devRoyaltyFee === "review";
  }, true);

  const allUnsettled = selected.reduce((reduced, consolidatedWorksheet) => {
    return reduced && consolidatedWorksheet.status.devRoyaltyFee === false;
  }, true);

  const sameGroup = filtered.reduce(
    (reduced, consolidatedWorksheet, index, consolidatedWorksheets) =>
      index
        ? reduced &&
          consolidatedWorksheet.worksheet.participantGroup.id ===
            consolidatedWorksheets[index - 1].worksheet.participantGroup.id
        : true,
    true
  );

  const groupName = (id: string | null) =>
    participantGroups.find((participantGroup) => participantGroup.id === id)
      ?.name;

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col h-screen p-8 md:m-8">
      <div className="flex items-center justify-between mb-4">
        <div className="py-4 px-6">
          <h1 className="md:text-3xl text-xl font-bold text-gray-100">
            CONSOLIDATION
          </h1>
        </div>
      </div>

      <SearchForm
        criteria={criteria}
        limit={limit}
        error={error}
        fetching={fetching}
        participantGroups={participantGroups}
        result={{
          filtered: filtered.length,
          total: consolidatedWorksheets.length,
        }}
        onFilter={onFilter}
        onChangeLimit={onChangeLimit}
      />

      <div id="controls" className="flex justify-between items-center mb-4">
        <div className="flex space-x-2">
          <Button
            onClick={onFirstPage}
            className={`font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none focus:ring-4 ${
              !fetching && !loading
                ? "text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                : "text-gray-400 bg-gray-600 cursor-not-allowed"
            }`}
            type="button"
            valid={!fetching && !loading}
          >
            First Page
          </Button>
          <Button
            onClick={onNextPage}
            className={`font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none focus:ring-4 ${
              !fetching && !loading && hasMoreConsolidatedWorksheet
                ? "text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                : "text-gray-400 bg-gray-600 cursor-not-allowed"
            }`}
            type="button"
            valid={!fetching && !loading && hasMoreConsolidatedWorksheet}
          >
            Next Page
          </Button>
        </div>
        <div className="flex items-center space-x-2">
          <Button
            onClick={onSelectAll}
            className={`font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none focus:ring-4 ${
              !fetching && !loading
                ? "text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-600"
                : "text-gray-400 bg-gray-600 cursor-not-allowed"
            }`}
            type="button"
            valid={!fetching && !loading}
          >
            {selected.length ? `${selected.length} Selected` : "Select All"}
          </Button>
          <Button
            onClick={() => onSettle(true)}
            className={`font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none focus:ring-4 ${
              selected.length > 0 &&
              (allUnsettled || allInReview) &&
              !fetching &&
              !loading
                ? "text-white bg-green-600 hover:bg-green-700 focus:ring-green-800"
                : "text-gray-400 bg-gray-600 cursor-not-allowed"
            }`}
            type="button"
            valid={
              selected.length > 0 &&
              (allUnsettled || allInReview) &&
              !fetching &&
              !loading
            }
          >
            Settle
          </Button>
          <Button
            onClick={() => onSettle("review")}
            className={`font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none focus:ring-4 ${
              selected.length > 0 && allUnsettled && !fetching && !loading
                ? "text-white bg-green-600 hover:bg-green-700 focus:ring-green-800"
                : "text-gray-400 bg-gray-600 cursor-not-allowed"
            }`}
            type="button"
            valid={selected.length > 0 && allUnsettled && !fetching && !loading}
          >
            Review
          </Button>
          <Button
            onClick={() => onSettle("pending")}
            className={`font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none focus:ring-4 ${
              selected.length > 0 && allInReview && !fetching && !loading
                ? "text-white bg-green-600 hover:bg-green-700 focus:ring-green-800"
                : "text-gray-400 bg-gray-600 cursor-not-allowed"
            }`}
            type="button"
            valid={selected.length > 0 && allInReview && !fetching && !loading}
          >
            Pending
          </Button>
          <Button
            onClick={() => onSettle(false)}
            className={`font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none focus:ring-4 ${
              selected.length > 0 && allSettled && !fetching && !loading
                ? "text-white bg-green-600 hover:bg-green-700 focus:ring-green-800"
                : "text-gray-400 bg-gray-600 cursor-not-allowed"
            }`}
            type="button"
            valid={selected.length > 0 && allSettled && !fetching && !loading}
          >
            Unsettle
          </Button>
        </div>
      </div>

      <section className="shadow-lg overflow-x-auto max-h-screen">
        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <div className="shadow-lg overflow-x-auto h-[calc(100vh-256px)]">
            <table className="w-full text-sm text-left text-gray-400">
              <thead className="text-xs uppercase bg-gray-500 text-gray-800 sticky top-0 z-10">
                <tr>
                  {[
                    {
                      prop: "participantGroup",
                      label: "Group",
                      className: "px-6 py-3 hidden md:table-cell",
                    },
                    {
                      prop: "module",
                      label: "Module",
                      className: "px-6 py-3 hidden md:table-cell",
                    },
                    {
                      prop: "id",
                      label: "ID",
                      className: "px-6 py-3 hidden md:table-cell",
                    },
                    {
                      prop: "profile",
                      label: "Name",
                      className: "px-6 py-3",
                    },
                    {
                      prop: "tokenId",
                      label: "Token",
                      className: "px-6 py-3",
                    },
                    {
                      prop: "status",
                      label: "Status",
                      className: "px-6 py-3",
                    },
                    {
                      prop: "dateCreated",
                      label: "Date Created",
                      className: "px-6 py-3 hidden md:table-cell",
                    },
                  ].map((header, index) => {
                    const { prop, label, className } = header;

                    return (
                      <th
                        className={className}
                        key={index}
                        style={{ cursor: prop ? "pointer" : "default" }}
                        onClick={() => {
                          if (!prop) return;

                          onSort(prop);
                        }}
                      >
                        <div className="flex items-center ">
                          {label}

                          {sortBy.prop === header.prop && (
                            <FontAwesomeIcon
                              icon={sortBy.asc > 0 ? faSortDown : faSortUp}
                              className="ml-1"
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className="overflow-x-auto max-h-96">
                {!fetching
                  ? filtered.map((consolidatedWorksheet) => (
                      <ConsolidatedWorksheetRow
                        key={consolidatedWorksheet.id}
                        consolidatedWorksheet={consolidatedWorksheet}
                        selected={selected.reduce(
                          (included, s) =>
                            included || s.id === consolidatedWorksheet.id,
                          false
                        )}
                        onSelect={onSelect}
                      />
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <div
        id="consolidation-buttons-container"
        className="fixed bottom-4 right-4 z-50"
      >
        <ConsolidationInfo />

        {sameGroup && filtered.length > 0 ? (
          <PDFDownloadLink
            document={<PdfConsolidation consolidatedWorksheets={filtered} />}
            fileName={`[ TOTAL = ${filtered.length} ] ${groupName(
              criteria.group
            )}`}
          >
            {({ blob, url, loading: loadingPdf, error }) => (
              <Button
                className={`flex items-center justify-center w-10 h-10 text-white rounded-full ${
                  fetching || loading || loadingPdf
                    ? "bg-gray-500 pointer-events-none"
                    : error
                    ? "bg-red-500 pointer-events-none"
                    : !sameGroup
                    ? "bg-yellow-400 pointer-events-none"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
                type="button"
                valid={!fetching && sameGroup && filtered.length > 0}
              >
                {fetching || loading || loadingPdf ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="text-lg animate-spin"
                  />
                ) : error ? (
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="text-lg"
                  />
                ) : !sameGroup ? (
                  <FontAwesomeIcon icon={faBan} className="text-lg" />
                ) : (
                  <FontAwesomeIcon icon={faFilePdf} className="text-lg" />
                )}
              </Button>
            )}
          </PDFDownloadLink>
        ) : (
          <Button
            className={`flex items-center justify-center w-10 h-10 text-white rounded-full ${
              fetching || loading
                ? "bg-gray-500 pointer-events-none"
                : error
                ? "bg-red-500 pointer-events-none"
                : !sameGroup || !filtered.length
                ? "bg-yellow-400 pointer-events-none"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
            type="button"
            valid={!fetching && sameGroup && filtered.length > 0}
          >
            {fetching || loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="text-lg animate-spin"
              />
            ) : error ? (
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="text-lg"
              />
            ) : !sameGroup || !filtered.length ? (
              <FontAwesomeIcon icon={faBan} className="text-lg" />
            ) : (
              <FontAwesomeIcon icon={faFilePdf} className="text-lg" />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConsolidatedWorksheetTable;
