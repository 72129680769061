// ---------------------------------------------- modules import
import {
  Page,
  StyleSheet,
  Text,
  View,
  Svg,
  Rect,
  Image,
} from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-psikologi.png";

import { IResultPageKesejahteraanPsikologiDanEmosionalProps } from "./common";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";
import { AttributeIdEnum_KesejahteraanPsikologiDanEmosional } from "../../../../enums/attribute_id";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#3b1c78",
  },
  description: {
    marginBottom: 10,
    textAlign: "justify",
    fontSize: 14,
  },
  table: {
    display: "flex",
    width: "70%",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    backgroundColor: "#87CEEB",
    color: "#000000",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    flex: 1,
    textAlign: "center",
  },
  tableCell: {
    fontSize: 10,
    padding: 5,
    flex: 1,
    textAlign: "center",
  },
  tableCellName: {
    fontSize: 10,
    padding: 5,
    flex: 1,
    textAlign: "left",
  },
  tableCellEven: {
    backgroundColor: "#E6F3FF",
  },
  tableCellOdd: {
    backgroundColor: "#FFFFFF",
  },
  tableTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#3b1c78",
  },

  tableCellNo: {
    fontSize: 10,
    padding: 5,
    flex: 0.5,
    textAlign: "center",
    backgroundColor: "#E6F3FF",
  },

  // table
  table1: {
    display: "flex",
    width: "100%",
    borderColor: "#000000",
    borderCollapse: "collapse",
  },
  tableRow1: {
    flexDirection: "row",
  },
  tableHeaderCell1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 7,
    fontWeight: "bold",
    padding: 5,
    width: 30,
    textAlign: "center",
  },
  tableCell1: {
    fontSize: 6,
    padding: 5,
    width: 30,
    textAlign: "center",
  },
  tableHeaderCellName1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 130,
  },
  tableCellName1: {
    fontSize: 9,
    padding: 5,
    width: 130,
  },
  tableHeaderCellResult1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 7,
    fontWeight: "bold",
    padding: 5,
    width: 80,
    textAlign: "center",
  },
  tableCellResult1: {
    fontSize: 6,
    padding: 5,
    width: 80,
    textAlign: "center",
  },
  tableHeaderCellNo1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  tableCellNo1: {
    fontSize: 9,
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  evenRow1: {
    backgroundColor: "#D9E1F2",
  },
  oddRow1: {
    backgroundColor: "#FFFFFF",
  },

  diagramContainer: {
    marginTop: 10,
    marginBottom: 10,
    width: "50%",
  },
  diagramRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  diagramLabel: {
    // width: "30%",
    fontSize: 10,
    textAlign: "right",
    marginRight: 2,
    flex: 5,
  },
  diagramBar: {
    // width: "10%",
    height: 20,
    flex: 5,
  },
  diagramPercentage: {
    // width: "5%",
    fontSize: 10,
    textAlign: "left",
    flex: 1,
  },

  categoryContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
  },
  categoryBox: {
    width: "48%",
    border: "1px solid #000",
    padding: 5,
    marginBottom: 10,
  },
  categoryTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  categoryDescription: {
    fontSize: 10,
    marginBottom: 5,
  },
  categoryPercentage: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
  },

  conclusionSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  conclusionLeft: {
    width: "80%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    marginRight: 4,
    padding: 4,
  },
  conclusionRight: {
    width: "20%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center", // Menambahkan text align untuk mengambang teks di tengah
    padding: 4,
  },
});

// ---------------------------------------------- the table attribute
interface AttributeTableProps {
  title: string;
  data: {
    mulaiBerkembang: number;
    sedangBerkembang: number;
    berkembangBaik: number;
    sangatBerkembang: number;
  };
}

// ---------------------------------------------- the table attribute
const AttributeTable: React.FC<AttributeTableProps> = ({ title, data }) => (
  <View style={styles.table} wrap={false}>
    <Text style={styles.tableTitle}>{title}</Text>
    <View style={styles.tableRow}>
      <Text style={styles.tableHeaderCell}>Kategori</Text>
      <Text style={styles.tableHeaderCell}>Jumlah Siswa</Text>
      <Text style={styles.tableHeaderCell}>Persentase</Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>
        Mulai Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.mulaiBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {Math.round(
          (data.mulaiBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellEven]}>
        Sedang Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {data.sedangBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {Math.round(
          (data.sedangBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>
        Berkembang Sesuai Harapan
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.berkembangBaik}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {Math.round(
          (data.berkembangBaik /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellEven]}>
        Sangat Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {data.sangatBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {Math.round(
          (data.sangatBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>Total</Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.mulaiBerkembang +
          data.sedangBerkembang +
          data.berkembangBaik +
          data.sangatBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>100%</Text>
    </View>
  </View>
);

// ---------------------------------------------- the diagram attribute

const AttributeDiagram: React.FC<AttributeTableProps> = ({ title, data }) => {
  const total =
    data.mulaiBerkembang +
    data.sedangBerkembang +
    data.berkembangBaik +
    data.sangatBerkembang;
  const percentages = {
    mulaiBerkembang: Math.round((data.mulaiBerkembang / total) * 100),
    sedangBerkembang: Math.round((data.sedangBerkembang / total) * 100),
    berkembangBaik: Math.round((data.berkembangBaik / total) * 100),
    sangatBerkembang: Math.round((data.sangatBerkembang / total) * 100),
  };

  return (
    <View style={styles.diagramContainer} wrap={false}>
      <Text style={styles.tableTitle}>{title}</Text>
      <DiagramRow
        label="Mulai Berkembang"
        percentage={percentages.mulaiBerkembang}
        color="#F4A460"
      />
      <DiagramRow
        label="Sedang Berkembang"
        percentage={percentages.sedangBerkembang}
        color="#87CEEB"
      />
      <DiagramRow
        label="Berkembang Sesuai Harapan"
        percentage={percentages.berkembangBaik}
        color="#9370DB"
      />
      <DiagramRow
        label="Sangat Berkembang"
        percentage={percentages.sangatBerkembang}
        color="#90EE90"
      />
    </View>
  );
};

const DiagramRow: React.FC<{
  label: string;
  percentage: number;
  color: string;
}> = ({ label, percentage, color }) => (
  <View style={styles.diagramRow}>
    <Text style={styles.diagramLabel}>{label}</Text>
    <Svg width="100%" height={20} style={styles.diagramBar}>
      <Rect width={`${percentage}%`} height={20} fill={color} />
    </Svg>
    <Text style={styles.diagramPercentage}>{percentage}%</Text>
  </View>
);

// ---------------------------------------------- the component
const ResultPageKesejahteraanPsikologiDanEmosional: FunctionComponent<
  IResultPageKesejahteraanPsikologiDanEmosionalProps
> = ({ worksheets }) => {
  // Menghitung jumlah siswa untuk setiap kategori pada atribut MANDIRI
  let MandiriMulaiBerkembang = 0;
  let MandiriSedangBerkembang = 0;
  let MandiriBerkembangBaik = 0;
  let MandiriSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id ===
        AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
    );
    if (!questionnaire) return;

    const mandiriAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id === AttributeIdEnum_KesejahteraanPsikologiDanEmosional.MANDIRI
    );
    if (!mandiriAttr) return;

    const mandiriValue = mandiriAttr.value * 100;
    if (mandiriValue < 47) {
      MandiriMulaiBerkembang++;
    } else if (mandiriValue < 66) {
      MandiriSedangBerkembang++;
    } else if (mandiriValue < 84) {
      MandiriBerkembangBaik++;
    } else {
      MandiriSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut PENGUASAAN_LINGKUNGAN
  let PenguasaanLingkunganMulaiBerkembang = 0;
  let PenguasaanLingkunganSedangBerkembang = 0;
  let PenguasaanLingkunganBerkembangBaik = 0;
  let PenguasaanLingkunganSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id ===
        AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
    );
    if (!questionnaire) return;

    const penguasaanLingkunganAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id ===
        AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENGUASAAN_LINGKUNGAN
    );
    if (!penguasaanLingkunganAttr) return;

    const penguasaanLingkunganValue = penguasaanLingkunganAttr.value * 100;
    if (penguasaanLingkunganValue < 49) {
      PenguasaanLingkunganMulaiBerkembang++;
    } else if (penguasaanLingkunganValue < 68) {
      PenguasaanLingkunganSedangBerkembang++;
    } else if (penguasaanLingkunganValue < 88) {
      PenguasaanLingkunganBerkembangBaik++;
    } else {
      PenguasaanLingkunganSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut PERTUMBUHAN_PRIBADI
  let PertumbuhanPribadiMulaiBerkembang = 0;
  let PertumbuhanPribadiSedangBerkembang = 0;
  let PertumbuhanPribadiBerkembangBaik = 0;
  let PertumbuhanPribadiSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id ===
        AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
    );
    if (!questionnaire) return;

    const pertumbuhanPribadiAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id ===
        AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PERTUMBUHAN_PRIBADI
    );
    if (!pertumbuhanPribadiAttr) return;

    const pertumbuhanPribadiValue = pertumbuhanPribadiAttr.value * 100;
    if (pertumbuhanPribadiValue < 65) {
      PertumbuhanPribadiMulaiBerkembang++;
    } else if (pertumbuhanPribadiValue < 82) {
      PertumbuhanPribadiSedangBerkembang++;
    } else if (pertumbuhanPribadiValue < 97) {
      PertumbuhanPribadiBerkembangBaik++;
    } else {
      PertumbuhanPribadiSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN
  let HubunganPositifMulaiBerkembang = 0;
  let HubunganPositifSedangBerkembang = 0;
  let HubunganPositifBerkembangBaik = 0;
  let HubunganPositifSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id ===
        AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
    );
    if (!questionnaire) return;

    const hubunganPositifAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id ===
        AttributeIdEnum_KesejahteraanPsikologiDanEmosional.HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN
    );
    if (!hubunganPositifAttr) return;

    const hubunganPositifValue = hubunganPositifAttr.value * 100;
    if (hubunganPositifValue < 57) {
      HubunganPositifMulaiBerkembang++;
    } else if (hubunganPositifValue < 75) {
      HubunganPositifSedangBerkembang++;
    } else if (hubunganPositifValue < 92) {
      HubunganPositifBerkembangBaik++;
    } else {
      HubunganPositifSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut TUJUAN_HIDUP
  let TujuanHidupMulaiBerkembang = 0;
  let TujuanHidupSedangBerkembang = 0;
  let TujuanHidupBerkembangBaik = 0;
  let TujuanHidupSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id ===
        AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
    );
    if (!questionnaire) return;

    const tujuanHidupAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id ===
        AttributeIdEnum_KesejahteraanPsikologiDanEmosional.TUJUAN_HIDUP
    );
    if (!tujuanHidupAttr) return;

    const tujuanHidupValue = tujuanHidupAttr.value * 100;
    if (tujuanHidupValue < 61) {
      TujuanHidupMulaiBerkembang++;
    } else if (tujuanHidupValue < 80) {
      TujuanHidupSedangBerkembang++;
    } else if (tujuanHidupValue < 97) {
      TujuanHidupBerkembangBaik++;
    } else {
      TujuanHidupSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut PENERIMAAN_DIRI
  let PenerimaanDiriMulaiBerkembang = 0;
  let PenerimaanDiriSedangBerkembang = 0;
  let PenerimaanDiriBerkembangBaik = 0;
  let PenerimaanDiriSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id ===
        AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
    );
    if (!questionnaire) return;

    const penerimaanDiriAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id ===
        AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENERIMAAN_DIRI
    );
    if (!penerimaanDiriAttr) return;

    const penerimaanDiriValue = penerimaanDiriAttr.value * 100;
    if (penerimaanDiriValue < 54) {
      PenerimaanDiriMulaiBerkembang++;
    } else if (penerimaanDiriValue < 74) {
      PenerimaanDiriSedangBerkembang++;
    } else if (penerimaanDiriValue < 93) {
      PenerimaanDiriBerkembangBaik++;
    } else {
      PenerimaanDiriSangatBerkembang++;
    }
  });

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <Text style={styles.heading}>
          Kesejahteraan Psikologi dan Emosional
        </Text>
        <View style={styles.table1}>
          <View style={styles.tableRow1}>
            <Text style={styles.tableHeaderCellNo1}>No.</Text>
            <Text style={styles.tableHeaderCellName1}>Nama</Text>
            <Text style={styles.tableHeaderCell1}>Mandiri</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>Penguasaan Diri</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>Pertumbuhan</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>Hubungan</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>Tujuan Hidup</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>Penerimaan Diri</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
          </View>

          {worksheets &&
            worksheets.map((worksheet, index) => {
              const { profile, questionnaires } = worksheet;

              const questionnaire = questionnaires.find(
                (q) =>
                  q.assessment.id ===
                  AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
              );

              if (!questionnaire) return null;

              const { result } = questionnaire;

              const mandiriAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KesejahteraanPsikologiDanEmosional.MANDIRI
              );
              const penguasaanLingkunganAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENGUASAAN_LINGKUNGAN
              );
              const pertumbuhanAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PERTUMBUHAN_PRIBADI
              );
              const hubunganAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KesejahteraanPsikologiDanEmosional.HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN
              );
              const tujuanHidupAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KesejahteraanPsikologiDanEmosional.TUJUAN_HIDUP
              );
              const penerimaanDiriAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENERIMAAN_DIRI
              );

              const mandiriValue = mandiriAttr
                ? (mandiriAttr.value * 100).toFixed(0)
                : "-";
              const penguasaanLingkunganValue = penguasaanLingkunganAttr
                ? (penguasaanLingkunganAttr.value * 100).toFixed(0)
                : "-";
              const pertumbuhanValue = pertumbuhanAttr
                ? (pertumbuhanAttr.value * 100).toFixed(0)
                : "-";
              const hubunganValue = hubunganAttr
                ? (hubunganAttr.value * 100).toFixed(0)
                : "-";
              const tujuanHidupValue = tujuanHidupAttr
                ? (tujuanHidupAttr.value * 100).toFixed(0)
                : "-";
              const penerimaanDiriValue = penerimaanDiriAttr
                ? (penerimaanDiriAttr.value * 100).toFixed(0)
                : "-";

              return (
                <View
                  key={worksheet.id}
                  style={[
                    styles.tableRow,
                    index % 2 === 0 ? styles.evenRow1 : styles.oddRow1,
                  ]}
                  wrap={false}>
                  <Text style={styles.tableCellNo1}>{index + 1}</Text>
                  <Text style={styles.tableCellName1}>{profile.name}</Text>
                  <Text style={styles.tableCell1}>{mandiriValue}</Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(mandiriValue) < 47
                      ? "Mulai Berkembang"
                      : parseFloat(mandiriValue) < 66
                      ? "Sedang Berkembang"
                      : parseFloat(mandiriValue) < 84
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>
                    {penguasaanLingkunganValue}
                  </Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(penguasaanLingkunganValue) < 49
                      ? "Mulai Berkembang"
                      : parseFloat(penguasaanLingkunganValue) < 68
                      ? "Sedang Berkembang"
                      : parseFloat(penguasaanLingkunganValue) < 88
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>{pertumbuhanValue}</Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(pertumbuhanValue) < 65
                      ? "Mulai Berkembang"
                      : parseFloat(pertumbuhanValue) < 81
                      ? "Sedang Berkembang"
                      : parseFloat(pertumbuhanValue) < 97
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>{hubunganValue}</Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(hubunganValue) < 57
                      ? "Mulai Berkembang"
                      : parseFloat(hubunganValue) < 75
                      ? "Sedang Berkembang"
                      : parseFloat(hubunganValue) < 92
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>{tujuanHidupValue}</Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(tujuanHidupValue) < 61
                      ? "Mulai Berkembang"
                      : parseFloat(tujuanHidupValue) < 80
                      ? "Sedang Berkembang"
                      : parseFloat(tujuanHidupValue) < 97
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>{penerimaanDiriValue}</Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(penerimaanDiriValue) < 54
                      ? "Mulai Berkembang"
                      : parseFloat(penerimaanDiriValue) < 74
                      ? "Sedang Berkembang"
                      : parseFloat(penerimaanDiriValue) < 93
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                </View>
              );
            })}
          <Text style={styles.tableCell1}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Mandiri"
            data={{
              mulaiBerkembang: MandiriMulaiBerkembang,
              sedangBerkembang: MandiriSedangBerkembang,
              berkembangBaik: MandiriBerkembangBaik,
              sangatBerkembang: MandiriSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Mandiri"
            data={{
              mulaiBerkembang: MandiriMulaiBerkembang,
              sedangBerkembang: MandiriSedangBerkembang,
              berkembangBaik: MandiriBerkembangBaik,
              sangatBerkembang: MandiriSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang dalam meyakini nilai internal (diri sendiri).
              Mampu menentukan tujuan pribadi secara mandiri. Dan memiliki
              kemampuan untuk mengatur serta mengevaluasi diri dalam berperilaku
              sesuai dengan standar priadi.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang mulai berkembang dalam meyakini
                    nilai internal. Dapat menentukan tujuan pribadi secara
                    mandiri. Memiliki kemampuan untuk mengatur serta
                    mengevaluasi diri dalam berperilaku sesuai dengan standar
                    priadi.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (MandiriMulaiBerkembang /
                        (MandiriMulaiBerkembang +
                          MandiriSedangBerkembang +
                          MandiriBerkembangBaik +
                          MandiriSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sedang berkembang dalam meyakini
                    nilai internal. Dapat menentukan tujuan pribadi secara
                    mandiri. Memiliki kemampuan untuk mengatur serta
                    mengevaluasi diri dalam berperilaku sesuai dengan standar
                    priadi.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (MandiriSedangBerkembang /
                        (MandiriMulaiBerkembang +
                          MandiriSedangBerkembang +
                          MandiriBerkembangBaik +
                          MandiriSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang mulai berkembang dalam meyakini
                    nilai internal. Dapat menentukan tujuan pribadi secara
                    mandiri. Memiliki kemampuan untuk mengatur serta
                    mengevaluasi diri dalam berperilaku sesuai dengan standar
                    priadi.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (MandiriBerkembangBaik /
                        (MandiriMulaiBerkembang +
                          MandiriSedangBerkembang +
                          MandiriBerkembangBaik +
                          MandiriSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sangat berkembang dalam meyakini
                    nilai internal. Dapat menentukan tujuan pribadi secara
                    mandiri. Memiliki kemampuan untuk mengatur serta
                    mengevaluasi diri dalam berperilaku sesuai dengan standar
                    priadi.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (MandiriSangatBerkembang /
                        (MandiriMulaiBerkembang +
                          MandiriSedangBerkembang +
                          MandiriBerkembangBaik +
                          MandiriSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Penguasaan Lingkungan"
            data={{
              mulaiBerkembang: PenguasaanLingkunganMulaiBerkembang,
              sedangBerkembang: PenguasaanLingkunganSedangBerkembang,
              berkembangBaik: PenguasaanLingkunganBerkembangBaik,
              sangatBerkembang: PenguasaanLingkunganSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Penguasaan Lingkungan"
            data={{
              mulaiBerkembang: PenguasaanLingkunganMulaiBerkembang,
              sedangBerkembang: PenguasaanLingkunganSedangBerkembang,
              berkembangBaik: PenguasaanLingkunganBerkembangBaik,
              sangatBerkembang: PenguasaanLingkunganSangatBerkembang,
            }}
          />
          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan dalam mengelola dan
              mengendalikan segala aktivitas eksternal yang sesuai dengan
              kebutuhan pribadi.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang mulai berkembang dalam mengelola
                    dan mengendalikan segala aktivitas eksternal yang sesuai
                    dengan kebutuhan pribadi.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PenguasaanLingkunganMulaiBerkembang /
                        (PenguasaanLingkunganMulaiBerkembang +
                          PenguasaanLingkunganSedangBerkembang +
                          PenguasaanLingkunganBerkembangBaik +
                          PenguasaanLingkunganSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sedang berkembang dalam
                    mengelola dan mengendalikan segala aktivitas eksternal yang
                    sesuai dengan kebutuhan pribadi.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PenguasaanLingkunganSedangBerkembang /
                        (PenguasaanLingkunganMulaiBerkembang +
                          PenguasaanLingkunganSedangBerkembang +
                          PenguasaanLingkunganBerkembangBaik +
                          PenguasaanLingkunganSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang berkembang sesuai harapan dalam
                    mengelola dan mengendalikan segala aktivitas eksternal yang
                    sesuai dengan kebutuhan pribadi.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PenguasaanLingkunganBerkembangBaik /
                        (PenguasaanLingkunganMulaiBerkembang +
                          PenguasaanLingkunganSedangBerkembang +
                          PenguasaanLingkunganBerkembangBaik +
                          PenguasaanLingkunganSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sangat berkembang dalam
                    mengelola dan mengendalikan segala aktivitas eksternal yang
                    sesuai dengan kebutuhan pribadi.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PenguasaanLingkunganSangatBerkembang /
                        (PenguasaanLingkunganMulaiBerkembang +
                          PenguasaanLingkunganSedangBerkembang +
                          PenguasaanLingkunganBerkembangBaik +
                          PenguasaanLingkunganSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Pertumbuhan Pribadi"
            data={{
              mulaiBerkembang: PertumbuhanPribadiMulaiBerkembang,
              sedangBerkembang: PertumbuhanPribadiSedangBerkembang,
              berkembangBaik: PertumbuhanPribadiBerkembangBaik,
              sangatBerkembang: PertumbuhanPribadiSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Pertumbuhan Pribadi"
            data={{
              mulaiBerkembang: PertumbuhanPribadiMulaiBerkembang,
              sedangBerkembang: PertumbuhanPribadiSedangBerkembang,
              berkembangBaik: PertumbuhanPribadiBerkembangBaik,
              sangatBerkembang: PertumbuhanPribadiSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan dalam menyadari potensi
              serta bakat yang ada dan mampu untuk merealisasikan hal tersebut.
              Dan mampu melakukan evaluasi serta perbaikan didalam hidupnya
              setiap saat.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang mulai berkembang dalam menyadari
                    potensi serta bakat yang ada dan mampu untuk merealisasikan
                    hal tersebut. Mampu melakukan evaluasi serta perbaikan
                    didalam hidupnya setiap saat.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PertumbuhanPribadiMulaiBerkembang /
                        (PertumbuhanPribadiMulaiBerkembang +
                          PertumbuhanPribadiSedangBerkembang +
                          PertumbuhanPribadiBerkembangBaik +
                          PertumbuhanPribadiSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sedang berkembang dalam
                    menyadari potensi serta bakat yang ada dan mampu untuk
                    merealisasikan hal tersebut. Mampu melakukan evaluasi serta
                    perbaikan didalam hidupnya setiap saat.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PertumbuhanPribadiSedangBerkembang /
                        (PertumbuhanPribadiMulaiBerkembang +
                          PertumbuhanPribadiSedangBerkembang +
                          PertumbuhanPribadiBerkembangBaik +
                          PertumbuhanPribadiSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang berkembang sesuai harapan dalam
                    menyadari potensi serta bakat yang ada dan mampu untuk
                    merealisasikan hal tersebut. Mampu melakukan evaluasi serta
                    perbaikan didalam hidupnya setiap saat.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PertumbuhanPribadiBerkembangBaik /
                        (PertumbuhanPribadiMulaiBerkembang +
                          PertumbuhanPribadiSedangBerkembang +
                          PertumbuhanPribadiBerkembangBaik +
                          PertumbuhanPribadiSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sangat berkembang dalam
                    menyadari potensi serta bakat yang ada dan mampu untuk
                    merealisasikan hal tersebut. Mampu melakukan evaluasi serta
                    perbaikan didalam hidupnya setiap saat.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PertumbuhanPribadiSangatBerkembang /
                        (PertumbuhanPribadiMulaiBerkembang +
                          PertumbuhanPribadiSedangBerkembang +
                          PertumbuhanPribadiBerkembangBaik +
                          PertumbuhanPribadiSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Hubungan Positif dengan Orang Lain"
            data={{
              mulaiBerkembang: HubunganPositifMulaiBerkembang,
              sedangBerkembang: HubunganPositifSedangBerkembang,
              berkembangBaik: HubunganPositifBerkembangBaik,
              sangatBerkembang: HubunganPositifSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Hubungan Positif dengan Orang Lain"
            data={{
              mulaiBerkembang: HubunganPositifMulaiBerkembang,
              sedangBerkembang: HubunganPositifSedangBerkembang,
              berkembangBaik: HubunganPositifBerkembangBaik,
              sangatBerkembang: HubunganPositifSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan dalam hubungan yang positif
              dengan orang lain. Memiliki sikap empati, serta memahami arti
              memberi dan menerima dalam sebuah hubungan.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang mulai berkembang dalam hubungan
                    yang hangat dengan orang lain. Memiliki sikap empati, serta
                    memahami arti memberi dan menerima dalam sebuah hubungan.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (HubunganPositifMulaiBerkembang /
                        (HubunganPositifMulaiBerkembang +
                          HubunganPositifSedangBerkembang +
                          HubunganPositifBerkembangBaik +
                          HubunganPositifSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sedang berkembang dalam hubungan
                    yang hangat dengan orang lain. Memiliki sikap empati, serta
                    memahami arti memberi dan menerima dalam sebuah hubungan.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (HubunganPositifSedangBerkembang /
                        (HubunganPositifMulaiBerkembang +
                          HubunganPositifSedangBerkembang +
                          HubunganPositifBerkembangBaik +
                          HubunganPositifSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang berkembang sesuai harapan dalam
                    hubungan yang hangat dengan orang lain. Memiliki sikap
                    empati, serta memahami arti memberi dan menerima dalam
                    sebuah hubungan.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (HubunganPositifBerkembangBaik /
                        (HubunganPositifMulaiBerkembang +
                          HubunganPositifSedangBerkembang +
                          HubunganPositifBerkembangBaik +
                          HubunganPositifSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sangat berkembang dalam hubungan
                    yang hangat dengan orang lain. Memiliki sikap empati, serta
                    memahami arti memberi dan menerima dalam sebuah hubungan.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (HubunganPositifSangatBerkembang /
                        (HubunganPositifMulaiBerkembang +
                          HubunganPositifSedangBerkembang +
                          HubunganPositifBerkembangBaik +
                          HubunganPositifSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Tujuan Hidup"
            data={{
              mulaiBerkembang: TujuanHidupMulaiBerkembang,
              sedangBerkembang: TujuanHidupSedangBerkembang,
              berkembangBaik: TujuanHidupBerkembangBaik,
              sangatBerkembang: TujuanHidupSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Tujuan Hidup"
            data={{
              mulaiBerkembang: TujuanHidupMulaiBerkembang,
              sedangBerkembang: TujuanHidupSedangBerkembang,
              berkembangBaik: TujuanHidupBerkembangBaik,
              sangatBerkembang: TujuanHidupSangatBerkembang,
            }}
          />
          <Text style={styles.categoryTitle}>Deskripsi :</Text>
          <Text style={styles.categoryDescription}>
            Gambaran seseorang memiliki kemampuan dalam memiliki misi, tujuan
            dan arah hidup yang digunakan untuk memaknai pengalaman hidup masa
            kini dan masa lalunya.
          </Text>

          <View style={styles.categoryContainer} wrap={false}>
            <View style={styles.conclusionSection}>
              <View style={styles.conclusionLeft}>
                <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                <Text style={styles.categoryDescription}>
                  Memiliki kecenderungan mulai berkembang baik dalam memiliki
                  misi, tujuan dan arah hidup yang digunakan untuk memaknai
                  pengalaman hidup masa kini dan masa lalunya.
                </Text>
              </View>
              <View style={styles.conclusionRight}>
                <Text style={styles.categoryPercentage}>
                  {Math.round(
                    (TujuanHidupMulaiBerkembang /
                      (TujuanHidupMulaiBerkembang +
                        TujuanHidupSedangBerkembang +
                        TujuanHidupBerkembangBaik +
                        TujuanHidupSangatBerkembang)) *
                      100
                  ) || "-"}
                  %
                </Text>
              </View>
            </View>

            <View style={styles.conclusionSection}>
              <View style={styles.conclusionLeft}>
                <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                <Text style={styles.categoryDescription}>
                  Memiliki kecenderungan yang sedang berkembang dalam memiliki
                  misi, tujuan dan arah hidup yang digunakan untuk memaknai
                  pengalaman hidup masa kini dan masa lalunya.
                </Text>
              </View>
              <View style={styles.conclusionRight}>
                <Text style={styles.categoryPercentage}>
                  {Math.round(
                    (TujuanHidupSedangBerkembang /
                      (TujuanHidupMulaiBerkembang +
                        TujuanHidupSedangBerkembang +
                        TujuanHidupBerkembangBaik +
                        TujuanHidupSangatBerkembang)) *
                      100
                  )}
                  %
                </Text>
              </View>
            </View>

            <View style={styles.conclusionSection}>
              <View style={styles.conclusionLeft}>
                <Text style={styles.categoryTitle}>
                  Berkembang Sesuai Harapan
                </Text>
                <Text style={styles.categoryDescription}>
                  Memiliki kecenderungan berkembang sesuai harapan baik dalam
                  memiliki misi, tujuan dan arah hidup yang digunakan untuk
                  memaknai pengalaman hidup masa kini dan masa lalunya.
                </Text>
              </View>
              <View style={styles.conclusionRight}>
                <Text style={styles.categoryPercentage}>
                  {Math.round(
                    (TujuanHidupBerkembangBaik /
                      (TujuanHidupMulaiBerkembang +
                        TujuanHidupSedangBerkembang +
                        TujuanHidupBerkembangBaik +
                        TujuanHidupSangatBerkembang)) *
                      100
                  )}
                  %
                </Text>
              </View>
            </View>

            <View style={styles.conclusionSection}>
              <View style={styles.conclusionLeft}>
                <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                <Text style={styles.categoryDescription}>
                  Memiliki kecenderungan yang sangat berkembang dalam memiliki
                  misi, tujuan dan arah hidup yang digunakan untuk memaknai
                  pengalaman hidup masa kini dan masa lalunya.
                </Text>
              </View>
              <View style={styles.conclusionRight}>
                <Text style={styles.categoryPercentage}>
                  {Math.round(
                    (TujuanHidupSangatBerkembang /
                      (TujuanHidupMulaiBerkembang +
                        TujuanHidupSedangBerkembang +
                        TujuanHidupBerkembangBaik +
                        TujuanHidupSangatBerkembang)) *
                      100
                  )}
                  %
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Penerimaan Diri"
            data={{
              mulaiBerkembang: PenerimaanDiriMulaiBerkembang,
              sedangBerkembang: PenerimaanDiriSedangBerkembang,
              berkembangBaik: PenerimaanDiriBerkembangBaik,
              sangatBerkembang: PenerimaanDiriSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Penerimaan Diri"
            data={{
              mulaiBerkembang: PenerimaanDiriMulaiBerkembang,
              sedangBerkembang: PenerimaanDiriSedangBerkembang,
              berkembangBaik: PenerimaanDiriBerkembangBaik,
              sangatBerkembang: PenerimaanDiriSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan dalam sikap dan penerimaan
              yang positif terhadap dirinya sendiri. Mengakui adanya aspek
              negatif dan positif di dalam dirinya. Menghadapi masa lalu dengan
              sikap dan perasaan positif.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang mulai berkembang dalam sikap dan
                    penerimaan yang positif terhadap dirinya sendiri. Mengakui
                    adanya aspek negatif dan positif di dalam dirinya.
                    Menghadapi masa lalu dengan sikap dan perasaan positif.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PenerimaanDiriMulaiBerkembang /
                        (PenerimaanDiriMulaiBerkembang +
                          PenerimaanDiriSedangBerkembang +
                          PenerimaanDiriBerkembangBaik +
                          PenerimaanDiriSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sedang berkembang dalam sikap
                    dan penerimaan yang positif terhadap dirinya sendiri.
                    Mengakui adanya aspek negatif dan positif di dalam dirinya.
                    Menghadapi masa lalu dengan sikap dan perasaan positif.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PenerimaanDiriSedangBerkembang /
                        (PenerimaanDiriMulaiBerkembang +
                          PenerimaanDiriSedangBerkembang +
                          PenerimaanDiriBerkembangBaik +
                          PenerimaanDiriSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang berkembang sesuai harapan dalam
                    sikap dan penerimaan yang positif terhadap dirinya sendiri.
                    Mengakui adanya aspek negatif dan positif di dalam dirinya.
                    Menghadapi masa lalu dengan sikap dan perasaan positif.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PenerimaanDiriBerkembangBaik /
                        (PenerimaanDiriMulaiBerkembang +
                          PenerimaanDiriSedangBerkembang +
                          PenerimaanDiriBerkembangBaik +
                          PenerimaanDiriSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Memiliki kecenderungan yang sangat berkembang dalam sikap
                    dan penerimaan yang positif terhadap dirinya sendiri.
                    Mengakui adanya aspek negatif dan positif di dalam dirinya.
                    Menghadapi masa lalu dengan sikap dan perasaan positif.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {Math.round(
                      (PenerimaanDiriSangatBerkembang /
                        (PenerimaanDiriMulaiBerkembang +
                          PenerimaanDiriSedangBerkembang +
                          PenerimaanDiriBerkembangBaik +
                          PenerimaanDiriSangatBerkembang)) *
                        100
                    )}
                    %
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ResultPageKesejahteraanPsikologiDanEmosional;
