// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-psikologi.png";

import { IResultPageKesejahteraanPsikologiDanEmosionalV2Props } from "./common";
import { IConclusion_KesejahteraanPsikologiDanEmosionalV2 } from "../conclusion/kesejahteraan_psikologi_dan_emosional_v2/conclusion";
import { colorTheme } from "../../../../constants/color_theme";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  section: {
    marginBottom: 10,
  },
  headingPage: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
    marginTop: 30,
  },
  heading: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    marginBottom: 2,
  },
  disclaimer: {
    marginBottom: 10,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    color: colorTheme.PURPLE_DARK,
  },
  attribute: {
    flexDirection: "row",
    fontSize: 14,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  attributeName: {
    fontSize: 14,
  },
  descriptionsContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 4,
  },
  attributeTable: {
    marginBottom: 8,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    flex: 1,
    fontSize: 10,
  },

  credit: {
    bottom: 30,
    color: colorTheme.GRAY_DOFF,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },

  barContainer: {
    height: 15,
    backgroundColor: "#555",
    width: "60%", // Adjusted width to 90% for a better fit
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
  },
  bar: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 5,
  },
  barText: {
    position: "absolute",
    right: 10,
    top: 2,
    color: "white",
    fontSize: 10,
  },
  table: {
    display: "flex",
    width: "auto",
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCellHeader: {
    backgroundColor: colorTheme.PURPLE_DARK,
    color: "white",
    padding: 5,
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    flex: 1,
    textAlign: "center",
  },
  tableCellText: {
    padding: 5,
    fontSize: 10,
    flex: 1,
    textAlign: "left",
  },
  tableCell: {
    padding: 5,
    fontSize: 10,
    flex: 1,
    textAlign: "center",
  },
  tableCellAlt: {
    backgroundColor: "#f0f0f0",
  },
  conclusion: {
    padding: 4,
  },
  conclusionSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  conclusionLeft: {
    width: "80%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    marginRight: 4,
    padding: 4,
  },
  conclusionRight: {
    width: "20%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center", // Menambahkan text align untuk mengambang teks di tengah
    padding: 4,
  },
  descriptionValue: {
    marginTop: 17,
    fontSize: 10,
    flex: 1,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  descriptionText: {
    fontSize: 11,
  },
});

// ---------------------------------------------- the component
const ResultPageKesejahteraanPsikologiDanEmosionalV2: FunctionComponent<
  IResultPageKesejahteraanPsikologiDanEmosionalV2Props
> = ({ questionnaire }) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_KesejahteraanPsikologiDanEmosionalV2[];

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <View style={styles.attributeTable}>
          {result.attributes.map((attribute) => (
            <View key={attribute.id} style={styles.attributeRow}>
              <Text style={styles.attributeDescription}>{attribute.name}</Text>
              <View style={styles.barContainer}>
                <View
                  style={[
                    styles.bar,
                    {
                      width: `${attribute.value * 100}%`,
                      backgroundColor: attribute.color || "#4682b4",
                    },
                  ]}
                />
                <Text style={styles.barText}>
                  {(attribute.value * 100).toFixed(0)}%
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Tabel Psikogram</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader} wrap={true}>
              Kesejahteraan Psikologi
            </Text>
            <Text style={styles.tableCellHeader} wrap={true}>
              {"Mulai\n Berkembang"}
            </Text>
            <Text style={styles.tableCellHeader} wrap={true}>
              {"Sedang\n Berkembang"}
            </Text>
            <Text style={styles.tableCellHeader} wrap={true}>
              {"Berkembang\n Sesuai Harapan"}
            </Text>
            <Text style={styles.tableCellHeader} wrap={true}>
              {"Sangat\n Berkembang"}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellText}>Mandiri</Text>
            <Text style={styles.tableCell}>{"< 47%"}</Text>
            <Text style={styles.tableCell}>47% - 65%</Text>
            <Text style={styles.tableCell}>65% - 84%</Text>
            <Text style={styles.tableCell}>{"> 84%"}</Text>
          </View>
          <View style={[styles.tableRow, styles.tableCellAlt]}>
            <Text style={styles.tableCellText}>Penguasaan Lingkungan</Text>
            <Text style={styles.tableCell}>49%</Text>
            <Text style={styles.tableCell}>49% - 67%</Text>
            <Text style={styles.tableCell}>68% - 84%</Text>
            <Text style={styles.tableCell}>{"> 84%"}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellText}>Keterampilan Sosial</Text>
            <Text style={styles.tableCell}>{"< 53%"}</Text>
            <Text style={styles.tableCell}>53% - 73%</Text>
            <Text style={styles.tableCell}>74% - 94%</Text>
            <Text style={styles.tableCell}>{"> 94%"}</Text>
          </View>
          <View style={[styles.tableRow, styles.tableCellAlt]}>
            <Text style={styles.tableCellText}>Keterampilan Emosional</Text>
            <Text style={styles.tableCell}>{"< 53%"}</Text>
            <Text style={styles.tableCell}>53% - 73%</Text>
            <Text style={styles.tableCell}>74% - 94%</Text>
            <Text style={styles.tableCell}>{"> 94%"}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        {conclusion.map((c, index) => (
          <View key={index} style={styles.conclusion}>
            <View style={styles.conclusionSection} wrap={false}>
              <View style={styles.conclusionLeft}>
                <Text style={styles.heading}>{c.name}</Text>
                <Text style={styles.descriptionText}>{c.description}</Text>
              </View>
              <View style={styles.conclusionRight}>
                <Text style={styles.descriptionValue} wrap={true}>
                  {c.newLinedDevLevel}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </Page>
  );
};

export default ResultPageKesejahteraanPsikologiDanEmosionalV2;
