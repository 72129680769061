// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsGayaBelajarProps } from "./common";
import { IConclusion_GayaBelajar } from "../conclusion/gaya_belajar/conclusion";

// ---------------------------------------------- the component
const DetailsGayaBelajar: FunctionComponent<IDetailsGayaBelajarProps> = ({
  questionnaire,
}) => {
  const { assessment, result } = questionnaire;

  const conclusion = JSON.parse(result.conclusion) as IConclusion_GayaBelajar;

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {result.attributes.map((attribute) => (
          <div
            key={attribute.id}
            className="bg-gray-700 p-4 rounded-lg shadow-md"
          >
            <div className="flex items-center justify-between">
              <span className="font-semibold text-gray-300">
                {attribute.name}
              </span>
              <span className="text-yellow-400 font-bold">{`${(
                attribute.value * 100
              ).toFixed(0)}%`}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
          Dominant Attribute
        </h3>
        <span className="font-semibold text-4xl md:text-5xl text-yellow-400">
          {conclusion.name}
        </span>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 text-gray-300">
        <div>
          <h3 className="text-xl font-semibold bg-gray-600 p-2 rounded-t-lg">
            Description
          </h3>
          <div className="bg-gray-700 p-4 rounded-b-lg shadow-md">
            <p>{conclusion.descriptions.join(" ")}</p>
          </div>
        </div>
        <div>
          <h3 className="text-xl font-semibold bg-gray-600 p-2 rounded-t-lg">
            Traits
          </h3>
          <div className="bg-gray-700 p-4 rounded-b-lg shadow-md">
            <ul className="list-disc list-inside">
              {conclusion.traits.map((trait, index) => (
                <li key={index}>{trait}</li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-xl font-semibold bg-gray-600 p-2 rounded-t-lg">
            Learning Strategies
          </h3>
          <div className="bg-gray-700 p-4 rounded-b-lg shadow-md">
            <ul className="list-disc list-inside">
              {conclusion.learningStrategies.map((strategy, index) => (
                <li key={index}>{strategy}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsGayaBelajar;
