// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsRiasecV2Props } from "./common";
import { IConclusion_RiasecV2 } from "../conclusion/riasec_v2/conclusion";

// ---------------------------------------------- the component
const DetailsRiasecV2: FunctionComponent<IDetailsRiasecV2Props> = ({
  questionnaire,
}) => {
  const { assessment, result } = questionnaire;

  const conclusion = JSON.parse(result.conclusion) as IConclusion_RiasecV2[];

  const [dominantConclusion, subdominantConclusion] = conclusion;

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 max-h-96 overflow-auto">
        <div className="bg-gray-700 p-4 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            Attributes
          </h3>
          <ul className="space-y-2">
            {result.attributes.map((attribute) => (
              <li
                key={attribute.id}
                className="flex items-center justify-between"
              >
                <span className="font-semibold text-gray-300">
                  {attribute.description}
                </span>
                <span className="text-yellow-400 font-bold">{`${(
                  attribute.value * 100
                ).toFixed(0)}%`}</span>
              </li>
            ))}
          </ul>

          <div className="mt-4">
            <h3 className="font-light text-gray-300">Dominant Attribute</h3>
            <span className="font-semibold text-2xl md:text-5xl text-yellow-400">
              {dominantConclusion.name}
            </span>
          </div>

          <div className="mt-4">
            <h3 className="font-light text-gray-300">Subdominant Attribute</h3>
            <span className="font-semibold text-2xl md:text-5xl text-yellow-400">
              {subdominantConclusion.name}
            </span>
          </div>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            Descriptions
          </h3>
          <div className="pb-4">
            <h4 className="font-bold text-yellow-300 bg-slate-800 p-2 rounded-md mb-2">
              {dominantConclusion.name}
            </h4>
            <p className="text-gray-300">
              {dominantConclusion.descriptions.join(" ")}
            </p>
          </div>

          <div className="">
            <h4 className="font-bold text-yellow-300 bg-slate-800 p-2 rounded-md mb-2">
              {subdominantConclusion.name}
            </h4>
            <p className="text-gray-300">
              {subdominantConclusion.descriptions.join(" ")}
            </p>
          </div>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            Suggestions
          </h3>
          <div className="mb-4">
            <h4 className="font-bold text-yellow-300 bg-slate-800 p-2 rounded-md mb-2">
              Suggested Majors
            </h4>
            <ul className="list-disc list-inside text-gray-300">
              {dominantConclusion.suggestedMajors.map((major, index) => (
                <li key={index}>{major}</li>
              ))}
            </ul>
          </div>

          <div className="">
            <h4 className="font-bold text-yellow-300 bg-slate-800 p-2 rounded-md mb-2">
              Supporting Subjects
            </h4>
            <ul className="list-disc list-inside text-gray-300">
              {dominantConclusion.supportingSubjects.map((subject, index) => (
                <li key={index}>{subject}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsRiasecV2;
