// ---------------------------------------------- modules import
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { TagContext } from "../../../../contexts/tag/context";
import { AttributeContext } from "../../../../contexts/attribute/context";
import { AssessmentContext } from "../../../../contexts/assessment/context";

import { emptyAssessment } from "../../../../models/assessment";

// ---------------------------------------------- the component
export const useDetails = () => {
  // ---------------------------------------------- router state
  const { assessment_id } = useParams();

  // ---------------------------------------------- consume context
  const {
    error: errAssessment,
    fetching: fetchingAssessment,
    assessments,
  } = useContext(AssessmentContext);
  const {
    error: errAttribute,
    fetching: fetchingAttribute,
    attributes,
  } = useContext(AttributeContext);
  const { error: errTag, fetching: fetchingTag, tags } = useContext(TagContext);

  // ---------------------------------------------- local state
  const [assessment, setAssessment] = useState(emptyAssessment());

  const [error, setError] = useState(errAssessment || errTag || null);

  // ---------------------------------------------- effects
  useEffect(() => {
    if (assessments) {
      const currentAssessment = assessments.find(
        (assessment) => assessment.id === assessment_id
      );

      if (currentAssessment) setAssessment(currentAssessment);
    }
  }, [assessments, assessment_id]);

  useEffect(
    () => setError(errAssessment || errAttribute || errTag),
    [errAssessment, errAttribute, errTag]
  );

  // ---------------------------------------------- return value
  return {
    assessment,
    tags,
    attributes,
    error,
    fetching: fetchingAssessment || fetchingAttribute || fetchingTag,
  };
};
