// ---------------------------------------------- modules import
import dayjs from "dayjs";
import { FunctionComponent } from "react";

import { ITagListItemProps } from "./common";
import { useTagListItem } from "./hooks/use_tag_list_item";

// ---------------------------------------------- the component
const TagListItem: FunctionComponent<ITagListItemProps> = ({
  tag,
  onChangeTag,
}) => {
  const { lastModified, name, subset } = tag;

  // ---------------------------------------------- local state
  const { onClick } = useTagListItem(tag, onChangeTag);

  // ---------------------------------------------- content
  return (
    <div
      onClick={onClick}
      style={{ cursor: "pointer" }}
      className="bg-gray-700 p-4 rounded-lg shadow-sm hover:bg-gray-600 transition"
    >
      <h3 className="text-yellow-400 text-lg font-semibold">{name}</h3>
      <p className="text-gray-300">Subset: {subset}</p>
      <p className="text-gray-400 text-sm">
        Last Modified:{" "}
        {dayjs(lastModified).locale("id").format("DD/MM/YYYY, HH:mm:ss")}
      </p>
    </div>
  );
};

export default TagListItem;
