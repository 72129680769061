// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-majemuk.png";

import { IResultPageKecerdasanMajemukV2Props } from "./common";
import { IConclusion_KecerdasanMajemukV2 } from "../conclusion/kecerdasan_majemuk_v2/conclusion";
import { colorTheme } from "../../../../constants/color_theme";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  section: {
    marginBottom: 4,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  logo: {
    justifyContent: "center",
    width: 100,
  },
  headingPage: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
  },
  heading: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 18,
    marginBottom: 8,
  },
  attribute: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  attributeName: {
    fontSize: 14,
  },
  dominantAttribute: {
    marginTop: 20,
  },
  dominantName: {
    color: colorTheme.YELLOW_LIGHT,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  description: {
    fontSize: 12,
    marginBottom: 5,
  },
  traits: {
    fontSize: 12,
    marginBottom: 5,
  },
  learningStrategies: {
    fontSize: 12,
    marginBottom: 5,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  attributeValue: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    textAlign: "right",
  },
  credit: {
    bottom: 30,
    color: colorTheme.GRAY_DOFF,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },

  title: {
    fontSize: 14,
    marginBottom: 10,
    fontFamily: "Helvetica-Bold",
  },
  subtitle: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    color: "black",
    fontFamily: "Helvetica-Bold",
  },
  list: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
  },
  listItem: {
    fontSize: 12,
  },
  descriptionsContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 4,
  },

  barContainer: {
    height: 12,
    backgroundColor: "#555",
    width: "70%", // Adjusted width to 90% for a better fit
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
  },
  bar: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 5,
  },
  barText: {
    position: "absolute",
    right: 10,
    top: 2,
    color: "white",
    fontSize: 10,
  },
  bulletPoint: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 5,
  },
  bulletText: {
    fontSize: 11,
    textAlign: "justify",
  },
  text: {
    fontSize: 11,
    textAlign: "justify",
  },
});

// ---------------------------------------------- the component
const ResultPageKecerdasanMajemukV2: FunctionComponent<
  IResultPageKecerdasanMajemukV2Props
> = ({ questionnaire }) => {
  const { result } = questionnaire;

  const conclusions = JSON.parse(
    result.conclusion
  ) as IConclusion_KecerdasanMajemukV2[];

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <View style={styles.attributeTable}>
          {result.attributes.map((attribute) => (
            <View key={attribute.id} style={styles.attributeRow}>
              <Text style={styles.attributeDescription}>{attribute.name}</Text>
              <View style={styles.barContainer}>
                <View
                  style={[
                    styles.bar,
                    {
                      width: `${attribute.value * 100}%`,
                      backgroundColor: attribute.color || "#4682b4",
                    },
                  ]}
                />
                <Text style={styles.barText}>
                  {(attribute.value * 100).toFixed(0)}%
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      <View style={styles.section}>
        {conclusions.map((conclusion, index) => (
          <View key={index} wrap={false}>
            <Text style={styles.title}>{conclusion.name}</Text>
            <Text style={styles.subtitle}>Deskripsi:</Text>
            {conclusion.descriptions.map((description, idx) => (
              <Text key={idx} style={styles.text}>
                {description}
              </Text>
            ))}
            <Text style={styles.subtitle}>Karakter yang Kuat:</Text>
            <View style={styles.list}>
              {conclusion.traits.map((trait, idx) => (
                <View key={idx} style={styles.bulletPoint}>
                  <Text style={styles.bulletText}>•</Text>
                  <Text style={styles.text}>{trait}</Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </Page>
  );
};

export default ResultPageKecerdasanMajemukV2;
