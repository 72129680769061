// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsDiscProps } from "./common";
import { IConclusion_Disc } from "../conclusion/disc/conclusion";

// ---------------------------------------------- the component
const DetailsDisc: FunctionComponent<IDetailsDiscProps> = ({
  questionnaire,
}) => {
  const { assessment, result } = questionnaire;

  console.log(questionnaire);

  const conclusion = JSON.parse(result.conclusion) as IConclusion_Disc;
  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>
      <div className="grid grid-cols-1 gap-6">
        <div className="bg-gray-700 p-4 rounded-lg shadow-md">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
            <div>
              <p className="font-semibold text-xl md:text-3xl text-yellow-400">
                {conclusion.category.toUpperCase()}
              </p>
              <p className="font-semibold text-base md:text-xl text-yellow-200">
                {conclusion.personalityType}
              </p>
            </div>
            <div className="mt-4 md:mt-0 md:ml-6 flex-grow">
              <p className="text-gray-300">
                {conclusion.descriptions.join(". ")}
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-700 p-4 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Strengths
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.strengths.map((strength, index) => (
                <li key={index}>{strength}</li>
              ))}
            </ul>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Weaknesses
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.weaknesses.map((weakness, index) => (
                <li key={index}>{weakness}</li>
              ))}
            </ul>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg shadow-md md:col-span-2">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Career Recommendations
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.careerRecommendations.map(
                (careerRecommendation, index) => (
                  <li key={index}>{careerRecommendation}</li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsDisc;
