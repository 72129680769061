// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsPersonalityV2Props } from "./common";
import { IConclusion_PersonalityV2 } from "../conclusion/personality_v2/conclusion";
import { IAttribute } from "../../../../models/attribute";

// ---------------------------------------------- the component
const DetailsPersonalityV2: FunctionComponent<IDetailsPersonalityV2Props> = ({
  questionnaire,
}) => {
  const { assessment, result } = questionnaire;

  const conclusion = JSON.parse(result.conclusion) as IConclusion_PersonalityV2;

  const reduced = result.attributes.reduce(
    (reduced, attribute) => ({
      ...reduced,
      [attribute.id]: attribute,
    }),
    {} as { [key: string]: IAttribute }
  );

  const sorted = [
    reduced["E"],
    reduced["I"],
    reduced["S"],
    reduced["N"],
    reduced["T"],
    reduced["F"],
    reduced["J"],
    reduced["P"],
  ];

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="col-span-1 bg-gray-700 p-4 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            Attributes
          </h3>
          <ul className="space-y-2">
            {sorted.map((attribute) => (
              <li
                key={attribute.id}
                className="flex items-center justify-between"
              >
                <span className="font-semibold text-gray-300">
                  {attribute.description}
                </span>
                <span className="text-yellow-400 font-bold">{`${(
                  attribute.value * 100
                ).toFixed(0)}%`}</span>
              </li>
            ))}
          </ul>

          <div className="mt-4">
            <h3 className="font-light text-gray-300">Dominant Attribute</h3>
            <p className="font-semibold text-2xl md:text-5xl text-yellow-400">
              {conclusion.name}
            </p>
            <span className="text-xl text-gray-300">
              {conclusion.personality}
            </span>
          </div>
        </div>

        <div className="lg:col-span-3 grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-gray-700 p-4 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Description
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.descriptions.map((description, index) => (
                <li key={index}>{description}</li>
              ))}
            </ul>
          </div>

          <div className="bg-gray-700 p-4 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Traits
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.traits.map((trait, index) => (
                <li key={index}>{trait}</li>
              ))}
            </ul>
          </div>

          <div className="bg-gray-700 p-4 rounded-lg shadow-md lg:col-span-2">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Suggested Growth
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.suggestedGrowth.map((growth, index) => (
                <li key={index}>{growth}</li>
              ))}
            </ul>
          </div>

          <div className="bg-gray-700 p-4 rounded-lg shadow-md lg:col-span-2">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Suggested Careers
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.careerRecommendations.map((career, index) => (
                <li key={index}>{career}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-6 bg-gray-700 p-4 rounded-lg shadow-md">
        <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
          Personality Description
        </h3>
        <p className="text-gray-300">{conclusion.description}</p>
      </div>
    </div>
  );
};

export default DetailsPersonalityV2;
