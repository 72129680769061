// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import AttributeListItem from "./attribute_list_item";

import { IAttributeListProps } from "./common";
import { useAttributeList } from "./hooks/use_attribute_list";

// ---------------------------------------------- the component
const AttributeList: FunctionComponent<IAttributeListProps> = ({
  isAuthorizedToWrite,
}) => {
  const {
    // error,
    // fetching,
    attributes,
  } = useAttributeList();

  return (
    <div>
      <h2 className="font-bold text-xl mb-4">Attributes</h2>
      {attributes.length > 0 ? (
        <div className="space-y-4">
          {attributes.map((attribute) => (
            <AttributeListItem key={attribute.id} attribute={attribute} />
          ))}
        </div>
      ) : (
        <p className="text-gray-400">
          Looks like there are no attributes to display right now.
        </p>
      )}
    </div>
  );
};

export default AttributeList;
