// ---------------------------------------------- modules import
import { useContext } from "react";

import { SessionContext } from "../../../../contexts/session/context";

// ---------------------------------------------- the hooks
export const useSettings = () => {
  // ---------------------------------------------- consume context
  const { profile } = useContext(SessionContext);

  // ---------------------------------------------- return value
  return { profile };
};
