// ---------------------------------------------- modules import
import {
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Svg,
  Path,
} from "@react-pdf/renderer";
import { Fragment, FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-gayabelajar.png";

import { IResultPageDiscProps } from "./common";
import { IConclusion_Disc } from "../conclusion/disc/conclusion";
import { colorTheme } from "../../../../constants/color_theme";

// Tambahkan ini sebelum definisi komponen ResultPageDisc

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  section: {
    marginBottom: 4,
    marginTop: 4,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  logo: {
    justifyContent: "center",
    width: 100,
  },
  headingPage: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
  },
  heading: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 18,
    marginBottom: 8,
  },
  attribute: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  attributeName: {
    fontSize: 14,
  },
  dominantAttribute: {
    marginTop: 20,
  },
  dominantName: {
    color: colorTheme.YELLOW_LIGHT,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  description: {
    fontSize: 12,
    marginBottom: 5,
  },
  traits: {
    fontSize: 12,
    marginBottom: 5,
  },
  learningStrategies: {
    fontSize: 12,
    marginBottom: 5,
  },
  bulletPoint: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 5,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    color: colorTheme.YELLOW_LIGHT,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
  },
  attributeValue: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    textAlign: "right",
  },
  credit: {
    bottom: 30,
    color: colorTheme.GRAY_DOFF,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },

  pieChartContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
  },
  pieChart: {
    width: 150,
    height: 150,
    alignSelf: "center",
  },
  chartLabel: {
    position: "absolute",
    fontSize: 12,
    textAlign: "center",
    width: "100%",
    color: "black",
    fontFamily: "Helvetica-Bold",
  },
  legend: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  legendColor: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  legendText: {
    fontSize: 10,
  },
  title: {
    fontSize: 14,
    marginTop: 5,
    fontFamily: "Helvetica-Bold",
  },
  subtitle: {
    fontSize: 12,
    margin: 3,
    color: "black",
    textAlign: "justify",
  },
  text: {
    fontSize: 11,
    textAlign: "justify",
  },
  list: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 11,
  },
  listItem: {
    fontSize: 12,
  },
  bulletText: {
    fontSize: 12,
    marginRight: 5,
    textAlign: "justify",
  },
  bulletPointList: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 5,
  },
  textList: {
    fontSize: 11,
  },
});

// Fungsi untuk menghitung path dari doughnut chart

const orderedAttributes = ["MD", "MI", "MS", "MC"];

const getLabel = (id: string) => {
  switch (id) {
    case "MD":
      return "Dominance";
    case "MI":
      return "Influence";
    case "MS":
      return "Steadiness";
    case "MC":
      return "Conscientiousness";
    default:
      return id;
  }
};

// Array warna untuk atribut DISC (sesuai urutan)
const colors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700"];
const describeArc = (
  x: number,
  y: number,
  outerRadius: number,
  innerRadius: number,
  startAngle: number,
  endAngle: number
) => {
  const startOuter = polarToCartesian(x, y, outerRadius, endAngle);
  const endOuter = polarToCartesian(x, y, outerRadius, startAngle);
  const startInner = polarToCartesian(x, y, innerRadius, endAngle);
  const endInner = polarToCartesian(x, y, innerRadius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  const d = [
    "M",
    startOuter.x,
    startOuter.y,
    "A",
    outerRadius,
    outerRadius,
    0,
    largeArcFlag,
    0,
    endOuter.x,
    endOuter.y,
    "L",
    endInner.x,
    endInner.y,
    "A",
    innerRadius,
    innerRadius,
    0,
    largeArcFlag,
    1,
    startInner.x,
    startInner.y,
    "Z",
  ].join(" ");

  return d;
};

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

// Fungsi untuk menghitung posisi tengah dari arc untuk label
const labelPosition = (
  x: number,
  y: number,
  innerRadius: number,
  outerRadius: number,
  startAngle: number,
  endAngle: number
) => {
  const middleAngle = (startAngle + endAngle) / 2;
  const labelRadius = (innerRadius + outerRadius) / 2; // Posisi label di tengah antara inner dan outer radius
  const position = polarToCartesian(x, y, labelRadius, middleAngle);
  position.x -= 17; // Menggeser label ke kiri
  return position;
};

// ---------------------------------------------- the component
const ResultPageDisc: FunctionComponent<IResultPageDiscProps> = ({
  questionnaire,
}) => {
  const { result } = questionnaire;

  // Filter, urutkan, dan proses atribut MD, MI, MS, MC
  const data = orderedAttributes
    .map((id) => result.attributes.find((attr) => attr.id === id))
    .filter((attribute) => attribute !== undefined)
    .map((attribute, index) => ({
      name: attribute!.id,
      value: (attribute!.value / 24) * 100, // Mengubah skala nilai ke persentase
      color: colors[index],
    }));

  let cumulativePercent = 0;
  const outerRadius = 80;
  const innerRadius = 30;
  const svgSizeHeight = 180;
  const svgSizeWidth = 180; // Ukuran SVG disesuaikan agar lebih proporsional

  const conclusion: IConclusion_Disc = JSON.parse(result.conclusion);
  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <View style={styles.section}>
          <Svg
            style={{
              width: svgSizeWidth,
              height: svgSizeHeight,
              alignSelf: "center",
            }}>
            {data.map((slice, index) => {
              const [startAngle, endAngle] = [
                Math.round(cumulativePercent * 360),
                Math.round((cumulativePercent + slice.value / 100) * 360),
              ];
              cumulativePercent += slice.value / 100;
              const { x, y } = labelPosition(
                80,
                80,
                innerRadius,
                outerRadius,
                startAngle,
                endAngle
              );
              return (
                <Fragment key={index}>
                  <Path
                    d={describeArc(
                      80,
                      80,
                      outerRadius,
                      innerRadius,
                      startAngle,
                      endAngle
                    )}
                    fill={slice.color}
                  />
                  <Text x={x} y={y} style={styles.chartLabel}>
                    {`${Math.round(slice.value)}%`}
                  </Text>
                </Fragment>
              );
            })}
          </Svg>
          <View style={styles.legend}>
            {data.map((slice, index) => (
              <View key={index} style={styles.legendItem}>
                <View
                  style={[styles.legendColor, { backgroundColor: slice.color }]}
                />
                <Text style={styles.legendText}>
                  {getLabel(slice.name)} - {`${Math.round(slice.value)}%`}
                </Text>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.section}>
          <View wrap={false}>
            <Text style={styles.text}>Tipe Kepribadian :</Text>
            <Text style={styles.title}>{conclusion.category}</Text>
            <Text style={styles.title}>{conclusion.personalityType}</Text>
          </View>
          <View wrap={false}>
            <Text style={styles.title}>Deskripsi:</Text>
            {conclusion.descriptions.map((description, idx) => (
              <Text key={idx} style={styles.text}>
                {description}
              </Text>
            ))}
          </View>
          <View wrap={false}>
            <Text style={styles.title}>Karakter Yang Kuat:</Text>
            <View style={styles.list}>
              {conclusion.strengths.map((strength, idx) => (
                <View key={idx} style={styles.bulletPointList}>
                  <Text style={styles.bulletText}>•</Text>
                  <Text style={styles.textList}>{strength}</Text>
                </View>
              ))}
            </View>
          </View>

          <View wrap={false}>
            <Text style={styles.title}>Butuh Pengembangan:</Text>
            <View style={styles.list}>
              {conclusion.weaknesses.map((weakness, idx) => (
                <View key={idx} style={styles.bulletPointList}>
                  <Text style={styles.bulletText}>•</Text>
                  <Text style={styles.textList}>{weakness}</Text>
                </View>
              ))}
            </View>
          </View>

          <View wrap={false}>
            <Text style={styles.title}>Karir Rekomendasi:</Text>
            <Text style={styles.text}>
              {conclusion.careerRecommendations.join(", ")}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ResultPageDisc;
