export enum AttributeIdEnum_AdversityQuotient {
  CAMPER = "CAMPER",
  CLIMBER = "CLIMBER",
  QUITTER = "QUITTER",
}

export enum AttributeIdEnum_Cfit {
  SISTEMATIKA_BERPIKIR = "SISTEMATIKA_BERPIKIR",
  KETAJAMAN_DIFERENSIASI = "KETAJAMAN_DIFERENSIASI",
  KEMAMPUAN_ASOSIASI = "KEMAMPUAN_ASOSIASI",
  PEMAHAMAN_KONSEP = "PEMAHAMAN_KONSEP",
}

export enum AttributeIdEnum_ColouredProgressiveMatrices {
  CPM = "CPM",
}

export enum AttributeIdEnum_Disc {
  L_DOMINANCE = "LD",
  L_INFLUENCE = "LI",
  L_STEADINESS = "LS",
  L_CONSCIENTIOUSNESS = "LC",
  M_DOMINANCE = "MD",
  M_INFLUENCE = "MI",
  M_STEADINESS = "MS",
  M_CONSCIENTIOUSNESS = "MC",
}

export enum AttributeIdEnum_GayaBelajar {
  VISUAL = "VISUAL",
  AUDITORY = "AUDITORY",
  KINESTHETIC = "KINESTHETIC",
}

export enum AttributeIdEnum_GayaBelajarSdKelas1Sampai3 {
  VISUAL = "VISUAL",
  AUDITORY = "AUDITORY",
  KINESTHETIC = "KINESTHETIC",
}

export enum AttributeIdEnum_KecerdasanMajemuk {
  BODILY_KINESTHETIC = "BODILY-KINESTHETIC",
  INTERPERSONAL = "INTERPERSONAL",
  INTRAPERSONAL = "INTRAPERSONAL",
  LINGUISTIC = "LINGUISTIC",
  LOGICAL_MATHEMATICAL = "LOGICAL-MATHEMATICAL",
  MUSICAL = "MUSICAL",
  NATURALISTIC = "NATURALISTIC",
  SPATIAL = "SPATIAL",
}

export enum AttributeIdEnum_KesejahteraanPsikologiDanEmosional {
  HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN = "HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN",
  MANDIRI = "MANDIRI",
  PENERIMAAN_DIRI = "PENERIMAAN_DIRI",
  PENGUASAAN_LINGKUNGAN = "PENGUASAAN_LINGKUNGAN",
  PERTUMBUHAN_PRIBADI = "PERTUMBUHAN_PRIBADI",
  TUJUAN_HIDUP = "TUJUAN_HIDUP",
}

export enum AttributeIdEnum_KebiasaanBelajar {
  HOMEWORK_AND_ASSIGNMENTS = "HOMEWORK_AND_ASSIGNMENTS",
  TIME_ALLOCATION = "TIME_ALLOCATION",
  READING_AND_NOTE_TAKING = "READING_AND_NOTE_TAKING",
  STUDY_PERIOD_PROCEDURES = "STUDY_PERIOD_PROCEDURES",
  CONCENTRATION = "CONCENTRATION",
}

export enum AttributeIdEnum_KondisiKeluarga {
  CARA_ORANG_TUA_MENDIDIK = "CARA_ORANG_TUA_MENDIDIK",
  RELASI_ANTARA_ANAK_DAN_ANGGOTA_KELUARGA = "RELASI_ANTARA_ANAK_DAN_ANGGOTA_KELUARGA",
  SUASANA_RUMAH = "SUASANA_RUMAH",
}

export enum AttributeIdEnum_Personality {
  // `I` stands for INTROVERT
  I = "I",
  // `E` stands for EXTROVERT
  E = "E",
  // `S` stands for SENSING
  S = "S",
  // `N` stands for INTUITION
  N = "N",
  // `T` stands for THINKING
  T = "T",
  // `F` stands for FEELING
  F = "F",
  // `J` stands for JUDGING
  J = "J",
  // `P` stands for PERCEIVING
  P = "P",
}

export enum AttributeIdEnum_Riasec {
  // `R` stands for REALISTIC
  R = "R",
  // `I` stands for INVESTIGATIVE
  I = "I",
  // `A` stands for ARTISTIC
  A = "A",
  // `S` stands for SOCIAL
  S = "S",
  // `E` stands for ENTERPRISING
  E = "E",
  // `C` stands for CONVENTIONAL
  C = "C",
}

export enum AttributeIdEnum_SPM {
  SPM = "SPM",
}
