// ---------------------------------------------- modules import
import {
  faCheckCircle,
  faClock,
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { FunctionComponent } from "react";

import { IConsolidatedWorksheetRowProps } from "./common";
import { useConsolidatedWorksheetRow } from "./hooks/use_consolidated_worksheet_row";

// ---------------------------------------------- the component
const ConsolidatedWorksheetRow: FunctionComponent<
  IConsolidatedWorksheetRowProps
> = ({ consolidatedWorksheet, selected, onSelect }) => {
  const { dateCreated, id, status, worksheet } = consolidatedWorksheet;
  const { module, participantGroup, profile, tokenId } = worksheet;

  // ---------------------------------------------- local state
  const { onClick } = useConsolidatedWorksheetRow(
    consolidatedWorksheet,
    onSelect
  );

  // ---------------------------------------------- content
  return (
    <tr
      onClick={onClick}
      style={{ cursor: "pointer" }}
      className={`border-b ${
        selected ? "bg-sky-800" : "bg-gray-800"
      } border-gray-700 hover:bg-gray-600`}
    >
      <td className="px-6 py-4 hidden md:table-cell">
        {participantGroup.name}
      </td>

      <td className="px-6 py-4 hidden md:table-cell">{module.name}</td>

      <td className="px-6 py-4 hidden md:table-cell">{id}</td>

      <td className="px-6 py-4">{profile.name}</td>

      <td className="px-6 py-4">{tokenId}</td>

      <td className="px-6 py-4">
        {/* {status.devRoyaltyFee ? "Settled" : "Unsettled"} */}
        {status.devRoyaltyFee === true ? (
          <span className="text-green-400 flex items-center">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> Settled
          </span>
        ) : status.devRoyaltyFee === "review" ? (
          <span className="text-orange-400 flex items-center">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> In
            Review
          </span>
        ) : status.devRoyaltyFee === "pending" ? (
          <span className="text-yellow-400 flex items-center">
            <FontAwesomeIcon icon={faClock} className="mr-2" /> Pending
          </span>
        ) : (
          <span className="text-red-400 flex items-center">
            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> Unsettled
          </span>
        )}
      </td>

      <td className="px-6 py-4 hidden md:table-cell">
        {dayjs(dateCreated).locale("id").format("DD/MM/YYYY, HH:mm:ss")}
      </td>
    </tr>
  );
};

export default ConsolidatedWorksheetRow;
