// ---------------------------------------------- modules import
import { GenderEnum } from "../enums/gender";

export interface IProfile {
  age: number;
  birthdate: string;
  gender: GenderEnum;
  name: string;
  phone: string;
}

export const emptyProfile = (): IProfile => ({
  age: 10,
  birthdate: "",
  gender: GenderEnum.MALE,
  name: "",
  phone: "",
});
