// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-bakat.png";

import { IConclusion_RiasecV2 } from "../conclusion/riasec_v2/conclusion";
import { IResultPageRiasecV2Props } from "./common";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  section: {
    marginBottom: 8,
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
    width: "100%",
  },
  logo: {
    justifyContent: "center",
    width: 100,
  },
  headingPage: {
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
    marginTop: 20,
  },
  heading: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    marginBottom: 8,
  },
  attribute: {
    flexDirection: "row",
    fontSize: 10,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  descriptionsContainer: {
    marginBottom: 8,
  },
  dominantName: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  description: {
    marginBottom: 5,
    fontSize: 11,
    textAlign: "justify",
  },
  suggestedMajorsContainer: {
    marginTop: 5,
    fontSize: 12,
  },
  suggestedMajor: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 12,
  },
  supportingSubjectsContainer: {
    marginTop: 10,
    fontSize: 12,
  },
  supportingSubject: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 12,
  },
  bulletPoint: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 4,
  },
  bulletText: {
    fontSize: 11,
    marginRight: 4,
    fontFamily: "Helvetica-Bold",
  },
  text: {
    marginBottom: 4,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  attributeValue: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    textAlign: "right",
  },

  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  barContainer: {
    height: 15,
    backgroundColor: "#ccc",
    width: "70%", // Adjusted width to 90% for a better fit
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
  },
  bar: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 5,
  },
  barText: {
    position: "absolute",
    right: 10,
    top: 2,
    color: "white",
    fontSize: 10,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
});

// ---------------------------------------------- the component
const ResultPageRiasecV2: FunctionComponent<IResultPageRiasecV2Props> = ({
  questionnaire,
}) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(result.conclusion) as IConclusion_RiasecV2[];

  let outstandingSkills: {
    description: string;
    name: string;
  }[] = [];

  conclusion.forEach((conclusion) => {
    outstandingSkills = [...outstandingSkills, ...conclusion.outstandingSkills];
  });

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <View style={styles.attributeTable}>
          <View style={styles.attributeRow}></View>
          {result.attributes.map((attribute) => (
            <View key={attribute.id} style={styles.attributeRow}>
              <Text style={styles.attributeDescription}>
                {attribute.description}
              </Text>
              <View style={styles.barContainer}>
                <View
                  style={[
                    styles.bar,
                    {
                      width: `${attribute.value * 100}%`,
                      backgroundColor: attribute.color || "#4682b4",
                    },
                  ]}
                />
                <Text style={styles.barText}>
                  {(attribute.value * 100).toFixed(0)}%
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      <View style={styles.section} wrap={false}>
        {conclusion.map((conclusion, index) => (
          <View key={index} style={styles.section}>
            <Text style={styles.dominantName}>{conclusion.name}</Text>
            <Text style={styles.description}>
              {conclusion.descriptions.join(" ")}
            </Text>
          </View>
        ))}
      </View>

      <View style={styles.section} wrap={false}>
        <Text style={styles.dominantName}>Saran Jurusan Kuliah:</Text>
        <Text style={styles.description}>
          {conclusion[0].suggestedMajors
            ? conclusion[0].suggestedMajors.join(", ")
            : "Tidak tersedia"}
        </Text>
      </View>

      <View style={styles.section} wrap={false}>
        <Text style={styles.dominantName}>Mata Pelajaran Yang Mendukung:</Text>
        <Text style={styles.description}>
          {conclusion[0].supportingSubjects.join(", ")}
        </Text>
      </View>
      <View style={styles.section} wrap={false}>
        <Text style={styles.dominantName}>Kemampuan Yang Kuat:</Text>

        {outstandingSkills.length > 0 && (
          <View key={0} style={styles.section}>
            {conclusion[0].outstandingSkills.map((skill, index) => (
              <View key={index}>
                <Text style={styles.bulletText}>• {skill.name}</Text>
                <Text style={styles.description}>{skill.description}</Text>
              </View>
            ))}
          </View>
        )}
      </View>
    </Page>
  );
};

export default ResultPageRiasecV2;
