// ---------------------------------------------- modules import
import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import voca from "voca";

import Button from "../../../components/ui/buttons/button";
import CheckboxInput from "../../../components/ui/inputs/checkbox_input";

import bgQuiz from "../../../assets/images/bg-overview.png";

import { IQuizCfitProps } from "./common";
import { useQuizCfit } from "./hooks/use_quiz_cfit";
import { QuestionTypeEnum } from "../../../enums/question_type";

// ---------------------------------------------- the component
const QuizCfit: FunctionComponent<IQuizCfitProps> = ({
  questionnaires,
  onChangeSelectedOptions,
}) => {
  // ---------------------------------------------- local state
  const { questionnaire, group, question, onChange, onNext, onPrevious } =
    useQuizCfit(questionnaires, onChangeSelectedOptions);

  const selectedOptionsCount = question?.selectedOptions.length || 0;
  const isPairedChoice = question?.type === QuestionTypeEnum.PAIRED_CHOICE;
  const isValidSelection = isPairedChoice
    ? selectedOptionsCount === 2
    : selectedOptionsCount > 0;

  const isQuestionImage = question?.text.trim().startsWith("https://");

  // ---------------------------------------------- the content
  return (
    <div
      className="min-h-screen flex items-center justify-center p-4 bg-fixed bg-cover bg-center"
      style={{
        backgroundImage: `url(${bgQuiz})`,
      }}>
      <div className="bg-white bg-opacity-90 rounded-lg shadow-xl w-full max-w-6xl mx-auto my-8 relative overflow-hidden">
        <form className="p-4 sm:p-8">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-6 text-center">
            {questionnaire && voca.upperCase(questionnaire.assessment.name)}
          </h1>

          {questionnaire && group && question && (
            <div className="lg:flex lg:space-x-8">
              <div className="lg:w-1/2 mb-6 lg:mb-0">
                {isQuestionImage ? (
                  <img
                    src={question.text}
                    alt="Question"
                    className="w-full max-w-md mx-auto h-auto rounded-lg shadow-md"
                  />
                ) : (
                  <p className="text-base sm:text-lg text-gray-700 font-medium">
                    {question.text}
                  </p>
                )}
                {isPairedChoice && (
                  <p className="text-xs sm:text-sm text-blue-600 mt-2">
                    Pilihlah tepat dua pilihan yang dirasa mempunyai kesamaan. (
                    {selectedOptionsCount}/2 dipilih)
                  </p>
                )}
              </div>

              <div className="lg:w-1/2 lg:flex lg:items-center lg:justify-center">
                <div className="grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-3 sm:gap-4">
                  {question.options.map((option, index) => {
                    const { text, value } = option;
                    const included = question.selectedOptions.some(
                      (selectedOption) => selectedOption.value === value
                    );

                    return (
                      <div key={value} className="flex flex-col items-center">
                        <CheckboxInput
                          checked={included}
                          className_input="hidden"
                          className_label={`flex flex-col items-center justify-center p-2 w-full h-full text-center text-sm sm:text-xl font-medium border-2 rounded-lg cursor-pointer transition-all duration-200 ${
                            included
                              ? "border-green-500 bg-green-100 text-green-700 shadow-lg"
                              : "border-gray-300 bg-white bg-opacity-50 text-gray-700 hover:bg-gray-100 hover:shadow-md"
                          }`}
                          id={`${value}-${index}`}
                          label={
                            <span className="px-2 py-1 sm:px-4 sm:py-2">
                              {text}
                            </span>
                          }
                          name={question.id}
                          reversed
                          value={value}
                          onChange={(name, value) => {
                            if (
                              isPairedChoice &&
                              selectedOptionsCount === 2 &&
                              !included
                            ) {
                              return; // Prevent selecting more than 2 options
                            }
                            onChange(name, value);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-between items-center mt-8">
            <Button
              className="flex items-center text-blue-600 hover:text-blue-800 text-sm sm:text-base bg-white bg-opacity-50 hover:bg-opacity-70 px-4 py-2 rounded-lg transition-all duration-200"
              type="button"
              valid
              onClick={onPrevious}>
              <FontAwesomeIcon icon={faCircleLeft} className="mr-1 sm:mr-2" />
              <span className="font-bold">BACK</span>
            </Button>

            <Button
              className={`flex items-center ${
                isValidSelection
                  ? "text-blue-600 hover:text-blue-800"
                  : "text-gray-400 cursor-not-allowed"
              }`}
              type="button"
              valid={isValidSelection}
              onClick={onNext}>
              <span className="font-bold">NEXT</span>
              <FontAwesomeIcon icon={faCircleRight} className="ml-1 sm:ml-2" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuizCfit;
