// ---------------------------------------------- modules import
import { AssessmentIdEnum } from "../enums/assessment_id";
import {
  EducationSubLevelEnum,
  EducationLevelEnum,
} from "../enums/education_level";

// ---------------------------------------------- document interfaces
/**
 * Represents the data fields of a module document in Firestore.
 *
 * Subcollections of each document:
 * - SubsetTokens
 */
export interface IModuleRecord {
  assessmentIds: AssessmentIdEnum[];
  dateCreated: string;
  description: string;
  education: {
    level: EducationLevelEnum;
    subLevel: EducationSubLevelEnum;
  };
  lastModified: string;
  name: string;
}

/**
 * Represents a module returned to the frontend from Firestore.
 *
 * Extends the IModuleRecord interface.
 */
export interface IModule extends IModuleRecord {
  id: string;
  [key: string]: any;
}

// ---------------------------------------------- empty document
export const emptyModuleRecord = (): IModuleRecord => ({
  assessmentIds: [],
  dateCreated: "",
  description: "",
  education: {
    level: EducationLevelEnum.OTHERS,
    subLevel: EducationSubLevelEnum.OTHERS,
  },
  lastModified: "",
  name: "",
});

export const emptyModule = (): IModule => ({
  ...emptyModuleRecord(),
  id: "",
});
