// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import QuestionListItem from "./question_list_item";

import { IQuestionListProps } from "./common";
import { useQuestionList } from "./hooks/use_question_list";

// ---------------------------------------------- the component
const QuestionList: FunctionComponent<IQuestionListProps> = ({
  isAuthorizedToWrite,
}) => {
  const {
    // error,
    // fetching,
    questions,
  } = useQuestionList();

  // ---------------------------------------------- content
  return (
    <div>
      <h2 className="font-bold text-xl mb-4">Questions</h2>
      {questions.length > 0 ? (
        <div className="space-y-4">
          {questions.map((question) => (
            <QuestionListItem key={question.id} question={question} />
          ))}
        </div>
      ) : (
        <p className="text-gray-400">
          Looks like there are no questions to display right now.
        </p>
      )}
    </div>
  );
};

export default QuestionList;
