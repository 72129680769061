// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import { AssessmentIdEnum } from "../enums/assessment_id";
import { IAssessment } from "../models/assessment";

export const pattern: IPattern = {
  description: {
    minLength: 2,
    maxLength: 500,
    regex: /^[\w\s&,.():\-'"/]{2,500}$/,
  },
  name: {
    minLength: 2,
    maxLength: 50,
    regex: /^[\w\s&,.():\-'"/]{2,50}$/,
  },
};

const validateAssessment = (
  assessment: IAssessment,
  propsToValidate = ["name"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { description, id } = assessment;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["description"] = description
    ? pattern.description.regex.test(description)
    : true;
  /**
   * `id` should have been registered in the `AssessmentIdEnum`
   * if there is a need to add new kind of assessment, the `AssessmentIdEnum` need to be updated first
   */
  extraConditions["id"] = id in AssessmentIdEnum;

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    assessment,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateAssessment;
