export enum AssessmentIdEnum {
  ADVERSITY_QUOTIENT = "ADVERSITY_QUOTIENT",
  CFIT = "CFIT",
  COLOURED_PROGRESSIVE_MATRICES = "COLOURED_PROGRESSIVE_MATRICES",
  DISC = "DISC",
  GAYA_BELAJAR = "GAYA_BELAJAR",
  GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3 = "GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3",
  KECERDASAN_MAJEMUK = "KECERDASAN_MAJEMUK",
  KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL = "KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL",
  KEBIASAAN_BELAJAR = "KEBIASAAN_BELAJAR",
  KONDISI_KELUARGA = "KONDISI_KELUARGA",
  PERSONALITY = "PERSONALITY",
  RIASEC = "RIASEC",
  SPM = "SPM",
}
