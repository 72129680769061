// ---------------------------------------------- modules import
import { IConsolidatedWorksheet } from "../../../../models/consolidated_worksheet";

// ---------------------------------------------- the hooks
export const useConsolidatedWorksheetRow = (
  consolidatedWorksheet: IConsolidatedWorksheet,
  onSelect: (consolidatedWorksheet: IConsolidatedWorksheet) => void
) => {
  // ---------------------------------------------- handlers
  const handleClick = () => {
    onSelect(consolidatedWorksheet);
  };

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
