// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import { GenderEnum } from "../enums/gender";
import { IProfile } from "../models/profile";

export const pattern: IPattern = {
  name: {
    minLength: 2,
    maxLength: 70,
    regex: /^[\w\s-_.’'",`]{2,70}$/,
  },
  phone: {
    minLength: 8,
    maxLength: 20,
    regex: /^[\d\s+-_.()]{8,20}$/,
  },
};

const validateProfile = (
  profile: IProfile,
  propsToValidate = ["name", "phone"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { age, gender } = profile;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["age"] = age > 0;
  // extraConditions["birthdate"] = !isNaN(Date.parse(birthdate));
  extraConditions["gender"] = Object.values(GenderEnum).includes(gender);

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    profile,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateProfile;
