// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  ISubsetTokenContextProviderState,
  ISubsetTokenContextProviderProps,
} from "./common";
import { useSubsetTokenContext } from "./hooks";
import { emptyModule } from "../../models/module";
import { emptyParticipantGroup } from "../../models/participant_group";

// ---------------------------------------------- create context
const initialState: ISubsetTokenContextProviderState = {
  module: emptyModule(),
  participantGroup: emptyParticipantGroup(),
  subsetTokens: [],
  error: null,
  fetching: true,
  onChangeModule: () => {
    return;
  },
  onChangeParticipantGroup: () => {
    return;
  },
};

export const SubsetTokenContext = createContext(initialState);

// ---------------------------------------------- the component
const SubsetTokenContextProvider: FunctionComponent<
  ISubsetTokenContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const {
    module,
    participantGroup,
    subsetTokens,
    error,
    fetching,
    onChangeModule,
    onChangeParticipantGroup,
  } = useSubsetTokenContext();

  // ---------------------------------------------- content
  return (
    <SubsetTokenContext.Provider
      value={{
        module,
        participantGroup,
        subsetTokens,
        error,
        fetching,
        onChangeModule,
        onChangeParticipantGroup,
      }}
    >
      {children}
    </SubsetTokenContext.Provider>
  );
};

export default SubsetTokenContextProvider;
