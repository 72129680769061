// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsKecerdasanMajemukV2Props } from "./common";
import { IConclusion_KecerdasanMajemukV2 } from "../conclusion/kecerdasan_majemuk_v2/conclusion";

// ---------------------------------------------- the component
const DetailsKecerdasanMajemukV2: FunctionComponent<
  IDetailsKecerdasanMajemukV2Props
> = ({ questionnaire }) => {
  const { assessment, result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_KecerdasanMajemukV2[];

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="col-span-1 bg-gray-700 p-4 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            Attributes
          </h3>
          <ul className="space-y-2">
            {result.attributes.map((attribute) => (
              <li
                key={attribute.id}
                className="flex items-center justify-between"
              >
                <span className="font-semibold text-gray-300">
                  {attribute.name}
                </span>
                <span className="text-yellow-400 font-bold">{`${(
                  attribute.value * 100
                ).toFixed(0)}%`}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="col-span-3 bg-gray-700 p-5 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-5 text-yellow-400">
            TIPE KECERDASAN MAJEMUK
          </h3>
          {conclusion.map((item, index) => (
            <div key={index} className="mb-6">
              <h4 className="text-lg font-semibold text-yellow-300">
                {item.name}
              </h4>
              {item.traits && (
                <>
                  <h5 className="text-sm font-semibold text-white mt-2">
                    Karakter yang Kuat
                  </h5>
                  <ul className="text-sm text-gray-300 mb-2 list-disc list-inside ml-4">
                    {item.traits.map((trait, idx) => (
                      <li key={idx}>{trait}</li>
                    ))}
                  </ul>
                </>
              )}
              {item.descriptions && (
                <>
                  <h5 className="text-sm font-semibold text-white mt-2">
                    Deskripsi
                  </h5>
                  <p className="text-sm text-gray-300">
                    {item.descriptions.join(", ")}
                  </p>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailsKecerdasanMajemukV2;
