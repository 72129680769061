// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-cpm.png";

import { IResultPageCfitProps } from "./common";
import { IConclusion_Cfit } from "../conclusion/cfit/conclusion";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  section: {
    marginBottom: 8,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderWidth: 2,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10,
    overflow: "hidden",
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#cee9f0",
  },
  tableRowCheck: {
    flexDirection: "row",
  },
  tableHeader: {
    backgroundColor: "#66b8ce",
    color: "#ffffff",
  },
  tableColHeader: {
    height: 36,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeaderScore: {
    height: 60,
    width: "100%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeaderCategory: {
    height: 24,
    width: "100%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
  },
  tableCol: {
    height: 24,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
  },
  tableCol2: {
    height: "auto",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "left",
  },
  tableColCheck: {
    height: 24,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    backgroundColor: "#CEE9F0",
  },
  tableColCheckAlt: {
    height: 24,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    backgroundColor: "#E0C0C8",
  },
  tableColScore: {
    height: 48,
    width: "100%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCell: {
    fontSize: 10,
    fontWeight: "bold",
  },
  tableCellScore: {
    fontSize: 20,
    fontWeight: "bold",
  },
  heading: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  subHeading: {
    fontSize: 12,
    marginBottom: 2,
    fontFamily: "Helvetica-Bold",
  },
  text: {
    fontSize: 11,
    textAlign: "justify",
    marginBottom: 3,
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  tableSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  tableLeft: {
    width: "20%",
    padding: 4,
  },
  tableRight: {
    width: "80%",
    padding: 4,
  },
});

// ---------------------------------------------- the component
const ResultPageCfit: FunctionComponent<IResultPageCfitProps> = ({
  questionnaire,
}) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(result.conclusion) as IConclusion_Cfit;

  const intScoreCategory = conclusion.scoreCategory;

  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <Text style={styles.heading}>PSIKOGRAM</Text>
        <View style={styles.tableSection}>
          <View style={styles.tableLeft}>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <View style={styles.tableColHeaderScore}>
                  <Text style={styles.tableCell}>{"Taraf\n Kecerdasan"}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColScore}>
                  <Text style={styles.tableCellScore}>
                    {conclusion.scoreCategory}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.tableRight}>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <View style={styles.tableColHeaderCategory}>
                  <Text style={styles.tableCell}>Kategori</Text>
                </View>
              </View>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Rendah</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>{"Rata-rata\n Bawah"}</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Rata-rata</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>{"Rata-rata\n Atas"}</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Tinggi</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Tinggi Sekali</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Jenius</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>70 - 79</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>80 - 89</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>90 - 109</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>110 - 119</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>120 - 139</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>140 - 169</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{"> 170"}</Text>
                </View>
              </View>
              <View style={styles.tableRowCheck}>
                <View
                  style={
                    intScoreCategory >= 70 && intScoreCategory <= 79
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }>
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 70 && intScoreCategory <= 79
                      ? "X"
                      : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 80 && intScoreCategory <= 89
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }>
                  <Text style={styles.tableCell}>
                    {intScoreCategory > 80 && intScoreCategory <= 89 ? "X" : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 90 && intScoreCategory <= 109
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }>
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 90 && intScoreCategory <= 109
                      ? "X"
                      : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 110 && intScoreCategory <= 109
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }>
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 110 && intScoreCategory <= 109
                      ? "X"
                      : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 120 && intScoreCategory <= 139
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }>
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 120 && intScoreCategory <= 139
                      ? "X"
                      : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 140 && intScoreCategory <= 169
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }>
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 140 && intScoreCategory <= 169
                      ? "X"
                      : ""}
                  </Text>
                </View>
                <View
                  style={
                    intScoreCategory >= 170
                      ? styles.tableColCheckAlt
                      : styles.tableColCheck
                  }>
                  <Text style={styles.tableCell}>
                    {intScoreCategory >= 170 ? "X" : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.tableSection}>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View
                style={{
                  ...styles.tableColHeader,
                  width: "30%",
                  height: "auto",
                }}>
                <Text style={styles.tableCell}>Aspek Yang Di Ukur</Text>
              </View>
              <View
                style={{
                  ...styles.tableColHeader,
                  width: "45%",
                  height: "auto",
                }}>
                <Text style={styles.tableCell}>Uraian</Text>
              </View>
              <View
                style={{
                  ...styles.tableColHeader,
                  width: "25%",
                  height: "auto",
                }}>
                <Text style={styles.tableCell}>Kategori</Text>
              </View>
            </View>
            {conclusion.details.map((detail, index) => (
              <View key={index} style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol2,
                    width: "30%",
                    justifyContent: "center",
                  }}>
                  <Text style={styles.tableCell}>{detail.name}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol2,
                    width: "45%",
                    height: "auto",
                  }}>
                  <Text style={styles.tableCell}>{detail.description}</Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol2,
                    width: "25%",
                    justifyContent: "center",
                    textAlign: "center",
                  }}>
                  <Text style={styles.tableCell}>{detail.category}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      <View wrap={false} style={styles.section}>
        <Text style={styles.subHeading}>Deskripsi:</Text>
        {conclusion.descriptions.map((description, idx) => (
          <Text key={idx} style={styles.text}>
            {description}
          </Text>
        ))}
      </View>
    </Page>
  );
};

export default ResultPageCfit;
