// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IAttributeListItemProps } from "./common";

// ---------------------------------------------- the component
const AttributeListItem: FunctionComponent<IAttributeListItemProps> = ({
  attribute,
}) => {
  const { description, name } = attribute;

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-700 p-4 rounded-lg shadow-sm">
      <h3 className="text-yellow-400 text-lg font-semibold">{name}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  );
};

export default AttributeListItem;
