// ---------------------------------------------- modules import
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import ParticipantGroupRow from "./participant_group_row";
import ParticipantGroupSearchForm from "./participant_group_search_form";

import { IParticipantGroupTableProps } from "./common";
import { useParticipantGroupTable } from "./hooks/use_participant_group_table";

// ---------------------------------------------- the component
const ParticipantGroupTable: FunctionComponent<IParticipantGroupTableProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    criteria,
    error,
    fetching,
    filtered,
    participantGroups,
    sortBy,
    onChangeParticipantGroup,
    onFilter,
    onSort,
  } = useParticipantGroupTable();

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col h-screen p-8">
      <div className="flex items-center justify-between mb-4">
        <div className="py-4 px-6">
          <h1 className="md:text-3xl text-xl font-bold text-gray-100">
            REPORT{" "}
            <span className="text-lg md:text-xl font-bold text-yellow-400">
              Participant Group Table
            </span>
          </h1>
        </div>
      </div>

      <ParticipantGroupSearchForm
        criteria={criteria}
        error={error}
        fetching={fetching}
        result={{
          filtered: filtered.length,
          total: participantGroups.length,
        }}
        onFilter={onFilter}
      />

      <section className="shadow-lg overflow-x-auto max-h-screen">
        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-sm text-left text-gray-400">
            <thead className="text-xs uppercase bg-gray-500 text-gray-800">
              <tr>
                {[
                  {
                    prop: "name",
                    label: "Name",
                    className: "px-6 py-3",
                  },
                  {
                    prop: "province",
                    label: "Province",
                    className: "px-6 py-3 hidden md:table-cell",
                  },
                  {
                    prop: "regency",
                    label: "Regency",
                    className: "px-6 py-3 hidden md:table-cell",
                  },
                  {
                    prop: "lastModified",
                    label: "Last modified",
                    className: "px-6 py-3",
                  },
                ].map((header, index) => {
                  const { prop, label, className } = header;

                  return (
                    <th
                      className={className}
                      key={index}
                      style={{ cursor: prop ? "pointer" : "default" }}
                      onClick={() => {
                        if (!prop) return;

                        onSort(prop);
                      }}>
                      <div className="flex items-center ">
                        {label}

                        {sortBy.prop === header.prop && (
                          <FontAwesomeIcon
                            icon={sortBy.asc > 0 ? faSortDown : faSortUp}
                            className="ml-1"
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {!fetching
                ? filtered.map((participantGroup) => (
                    <ParticipantGroupRow
                      key={participantGroup.id}
                      participantGroup={participantGroup}
                      onChangeParticipantGroup={onChangeParticipantGroup}
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ParticipantGroupTable;
