// ---------------------------------------------- modules import
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import { ISubmissionOptionsProps } from "./common";
import { useSubmissionOptions } from "./hooks/use_submission_options";

// ---------------------------------------------- the component
const SubmissionOptions: FunctionComponent<ISubmissionOptionsProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const { options, onClick } = useSubmissionOptions();

  // ---------------------------------------------- content
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">
        Manual Worksheet Submission
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {options.map((option) => (
          <div
            key={option.name}
            onClick={() => onClick(option.route)}
            className={`
            flex flex-col items-center justify-center p-6 rounded-lg
            ${
              option.implemented
                ? "bg-blue-100 hover:bg-blue-200 cursor-pointer"
                : "bg-gray-100 cursor-not-allowed"
            }
            transition duration-300 ease-in-out
          `}
          >
            <FontAwesomeIcon
              icon={option.icon}
              className={`text-4xl mb-4 ${
                option.implemented ? "text-blue-500" : "text-gray-400"
              }`}
            />
            <span className="text-lg font-semibold">{option.name}</span>
            {!option.implemented && (
              <div className="flex items-center mt-2 text-gray-500">
                <FontAwesomeIcon icon={faLock} className="mr-2" />
                <span>Coming Soon</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubmissionOptions;
