// ---------------------------------------------- modules import
import {
  faCircleCheck,
  faEdit,
  faClock,
  faGraduationCap,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { FunctionComponent } from "react";

import LinkButton from "../../../components/ui/buttons/link_button";
import DeleteModal from "../../../components/ui/modals/delete_modal";

import * as ROUTES from "../../../constants/routes";

import { IModuleListItemProps } from "./common";
import { useModuleListItem } from "./hooks/use_module_list_item";

// ---------------------------------------------- the component
const ModuleListItem: FunctionComponent<IModuleListItemProps> = ({
  module,
  participantGroup,
  onChangeModule,
  onDelete,
}) => {
  const { assessmentIds, description, education, id, lastModified, name } =
    module;

  // ---------------------------------------------- local state
  const { onClick } = useModuleListItem(module, onChangeModule);

  // ---------------------------------------------- content
  return (
    <div
      onClick={onClick}
      className="bg-gray-800 border border-gray-700 rounded-lg shadow-md hover:bg-gray-700 transition duration-300 ease-in-out cursor-pointer my-4 overflow-hidden"
    >
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div className="flex-1">
            <h3 className="text-2xl font-semibold text-white mb-2">{name}</h3>
            <div className="space-y-2 text-gray-300">
              <p className="flex items-center">
                <FontAwesomeIcon
                  icon={faIdCard}
                  className="w-5 h-5 mr-2 text-blue-400"
                />
                ID: {id}
              </p>
              {education?.level && (
                <p className="flex items-center">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="w-5 h-5 mr-2 text-green-400"
                  />
                  Education: {education.level} - {education.subLevel}
                </p>
              )}
              <p className="flex items-center">
                <FontAwesomeIcon
                  icon={faClock}
                  className="w-5 h-5 mr-2 text-yellow-400"
                />
                Last Modified:{" "}
                {dayjs(lastModified)
                  .locale("id")
                  .format("DD/MM/YYYY, HH:mm:ss")}
              </p>
            </div>
          </div>
          <div className="flex space-x-2">
            <LinkButton
              className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md transition duration-300 ease-in-out"
              to={`${ROUTES.PARTICIPANT_GROUP}/update-module/${id}?participant_group_id=${participantGroup.id}`}
              valid={true}
            >
              <FontAwesomeIcon icon={faEdit} />
            </LinkButton>
            <DeleteModal
              className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-md transition duration-300 ease-in-out"
              id="module-delete-modal"
              version="v2"
              onDelete={() => onDelete(id)}
              valid={true}
            />
          </div>
        </div>

        <div className="border-t border-gray-600 pt-4 mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h4 className="text-lg font-semibold text-white mb-2">
                Description
              </h4>
              <p className="text-gray-300">
                {description || "No description available"}
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold text-white mb-2">
                Assessments
              </h4>
              <ul className="space-y-1">
                {assessmentIds.map((assessmentId) => (
                  <li
                    key={assessmentId}
                    className="flex items-center text-gray-300"
                  >
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="w-4 h-4 mr-2 text-blue-400"
                    />
                    {assessmentId.slice(0, 20)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleListItem;
