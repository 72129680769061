// ---------------------------------------------- modules import
import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import voca from "voca";

import Button from "../../../components/ui/buttons/button";
import CheckboxInput from "../../../components/ui/inputs/checkbox_input";

import bgQuiz from "../../../assets/images/bg-quiz.png";

import { IQuizDisc } from "./common";
import { useQuizDisc } from "./hooks/use_quiz_disc";

// ---------------------------------------------- the component
const QuizDisc: FunctionComponent<IQuizDisc> = ({
  questionnaires,
  onChangeSelectedOptions,
}) => {
  // ---------------------------------------------- local state
  const { questionnaire, group, question, onChange, onNext, onPrevious } =
    useQuizDisc(questionnaires, onChangeSelectedOptions);

  const optionsSelected = question
    ? question.selectedOptions.filter((selectedOption) =>
        (selectedOption.value as string).startsWith("M")
      ).length === 1 &&
      question.selectedOptions.filter((selectedOption) =>
        (selectedOption.value as string).startsWith("L")
      ).length === 1
    : false;

  // ---------------------------------------------- the content
  return (
    <div className="bg-blue-900">
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-no-repeat bg-cover md:w-2/5 xl:w-1/4 h-full w-full"
        style={{ backgroundImage: `url(${bgQuiz})` }}
      >
        <form className="w-full max-w-md px-9 pt-8 pb-8 rounded-lg">
          <h1 className="md:text-2xl text-xl text-gray-100 md:py-10 py-5">
            {questionnaire && voca.upperCase(questionnaire.assessment.name)}
          </h1>

          {questionnaire && group && question && (
            <>
              <p className="sm:pt-12 md:pt-10 xl:pt-14 pt-16 pb-10 text-base text-gray-700">
                {question.text}
              </p>

              <div className="pb-40 space-y-6">
                {question.options.map((option, index) => {
                  const { text, value } = option;
                  const [l_value, m_value] = (value as string).split("|");

                  const l_included = question.selectedOptions.some(
                    (selectedOption) => selectedOption.value === l_value
                  );
                  const m_included = question.selectedOptions.some(
                    (selectedOption) => selectedOption.value === m_value
                  );

                  return (
                    <div key={value} className="flex items-center space-x-4">
                      <div className="flex items-center space-x-2">
                        <CheckboxInput
                          checked={m_included}
                          className_input="hidden"
                          className_label={`flex items-center justify-center w-10 h-10 rounded-full cursor-pointer border-2 font-semibold transition-colors duration-200 ${
                            m_included
                              ? "bg-amber-500 text-white border-amber-500"
                              : "bg-white text-amber-500 border-amber-500 hover:bg-amber-100"
                          }`}
                          className_root="flex items-center"
                          id={`m-${value}-${index}`}
                          name={question.id}
                          value={m_value}
                          onChange={onChange}
                          label="P"
                        />
                        <CheckboxInput
                          checked={l_included}
                          className_input="hidden"
                          className_label={`flex items-center justify-center w-10 h-10 rounded-full cursor-pointer border-2 font-semibold transition-colors duration-200 ${
                            l_included
                              ? "bg-sky-500 text-white border-sky-500"
                              : "bg-white text-sky-500 border-sky-500 hover:bg-sky-100"
                          }`}
                          className_root="flex items-center"
                          id={`l-${value}-${index}`}
                          name={question.id}
                          value={l_value}
                          onChange={onChange}
                          label="K"
                        />
                      </div>
                      <span className="flex-grow text-base text-gray-700">
                        {text}
                      </span>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <div className="fixed bottom-0 left-0 right-0 bg-transparent p-4">
            <div className="flex justify-between max-w-md mx-auto">
              <Button
                className="flex items-center text-blue-500 hover:text-blue-700"
                type="button"
                valid
                onClick={onPrevious}
              >
                <FontAwesomeIcon icon={faCircleLeft} className="text-xl" />

                <span className="text-lg font-bold mx-2">BACK</span>
              </Button>

              <Button
                className={`flex items-center ${
                  optionsSelected
                    ? "text-blue-500 hover:text-blue-700"
                    : "text-gray-500"
                }`}
                type="button"
                valid={optionsSelected}
                onClick={onNext}
              >
                <span className="text-lg font-bold mx-2">NEXT</span>

                <FontAwesomeIcon icon={faCircleRight} className="text-xl" />
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuizDisc;
