// ---------------------------------------------- modules import
import { faArrowLeft, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../../ui/buttons/button";
import LinkButton from "../../ui/buttons/link_button";
import SelectInput from "../../ui/inputs/select_input";
import TextInput from "../../ui/inputs/text_input";
import StatusBadge from "../../ui/others/status_badge";

import * as ROUTES from "../../../constants/routes";
import { emptyProvince } from "../../../models/province";
import { emptyRegency } from "../../../models/regency";
import { pattern } from "../../../validations/participant_group";

import { useAddParticipantGroupForm } from "./hooks/use_add_participant_group_form";

// ---------------------------------------------- the component
const AddParticipantGroupForm = () => {
  // ---------------------------------------------- local state
  const {
    participantGroup,
    error,
    fetching,
    loading,
    provinceOptions,
    regencyOptions,
    isParticipantGroupValid,
    onChange,
    onSubmit,
  } = useAddParticipantGroupForm();

  // ---------------------------------------------- content
  return (
    <div className="flex justify-center items-center md:h-screen">
      <div className="w-auto lg:w-1/2 bg-gray-800 p-6 rounded-lg over">
        <form onSubmit={onSubmit}>
          <div>
            <h1 className="text-3xl text-gray-100">ADD FORM</h1>

            <h2 className=" text-lg text-yellow-400">
              ADD NEW PARTICIPANT GROUP
            </h2>

            <hr className="h-px my-4 border-0 bg-gray-400"></hr>

            <TextInput
              className_root="mb-4"
              id="name"
              label="Name"
              name="name"
              pattern={pattern.name}
              required
              type="text"
              onChange={onChange}
            />

            <SelectInput
              className_root="mb-5"
              className_select="block p-2.5"
              defaultOption={{
                label: "set province",
                value: emptyProvince().id,
              }}
              fallbackValue={emptyProvince().id}
              id="participant-group-add-form-province-options"
              label="Province"
              name="province"
              options={provinceOptions}
              value={participantGroup.location.province.id}
              onChange={onChange}
            />

            <SelectInput
              className_root="mb-5"
              className_select="block p-2.5"
              defaultOption={{
                label: "set regency",
                value: emptyRegency().id,
              }}
              fallbackValue={emptyRegency().id}
              id="participant-group-add-form-regency-options"
              label="Regency"
              name="regency"
              options={regencyOptions}
              value={participantGroup.location.regency.id}
              onChange={onChange}
            />

            <TextInput
              id="description"
              label="Description"
              name="description"
              pattern={pattern.description}
              required={false}
              type="text"
              onChange={onChange}
            />
          </div>

          <hr className="h-px my-4 border-0 bg-gray-700"></hr>

          <div className="flex justify-between mt-4">
            <div>
              <LinkButton
                className="flex items-center text-blue-500 hover:text-blue-700"
                to={ROUTES.PARTICIPANT_GROUP}
                valid={!fetching && !loading}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
                Back
              </LinkButton>
            </div>

            <div>
              <Button
                className={`flex items-center text-blue-500 hover:text-blue-700 ${
                  !fetching && !loading && isParticipantGroupValid()
                    ? ""
                    : "text-gray-500 pointer-events-none"
                }`}
                type="submit"
                valid={!fetching && !loading && isParticipantGroupValid()}
              >
                Submit
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </div>
          </div>
        </form>
      </div>

      <StatusBadge fetching={fetching} loading={loading} error={error} />
    </div>
  );
};

export default AddParticipantGroupForm;
