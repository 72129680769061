// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faDownload,
  faHourglass,
  faPaperPlane,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, useEffect } from "react";

import QuestionnaireList from "./questionnaire_list";
import Button from "../../../components/ui/buttons/button";
import LinkButton from "../../../components/ui/buttons/link_button";
import NumberInput from "../../../components/ui/inputs/number_input";
import TextInput from "../../../components/ui/inputs/text_input";
import SelectInput from "../../../components/ui/inputs/select_input";

import logo from "../../../assets/images/ganesa_new.png";
import bgOverview from "../../../assets/images/bg-overview.png";

import { IOverviewProps } from "./common";
import * as ROUTES from "../../../constants/routes";
import { pattern } from "../../../validations/profile";

// ---------------------------------------------- the component
const Overview: FunctionComponent<IOverviewProps> = ({
  worksheet,
  questionnaireStatuses,
  error,
  fetching,
  loading,
  isWorksheetValid,
  onChangeProfile,
  onSubmit,
  onDownloadWorksheet,
}) => {
  // ---------------------------------------------- effects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // ---------------------------------------------- content
  return (
    <div
      className="bg-blue-900 min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${bgOverview})` }}>
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto bg-white bg-opacity-90 rounded-lg shadow-lg">
          <form onSubmit={onSubmit} className="p-4 md:p-8">
            <div className="flex items-center mb-6">
              <img src={logo} alt="Logo" className="w-10 md:w-12 mr-4" />
              <h1 className="text-xl md:text-2xl text-blue-900 font-bold">
                Ringkasan Test
              </h1>
            </div>

            <div
              className="overflow-y-auto max-h-[calc(100vh-300px)] md:max-h-none"
              style={{
                maxHeight:
                  window.innerWidth < 768 ? "calc(100vh - 200px)" : "none",
              }}>
              {worksheet && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 mb-1">
                        Nama
                      </label>
                      <TextInput
                        className_input="bg-white rounded-lg w-full border-gray-300"
                        id="name"
                        name="name"
                        pattern={pattern.name}
                        required
                        type="text"
                        validate
                        value={worksheet.profile.name}
                        onChange={onChangeProfile}
                      />
                      <label
                        htmlFor="gender"
                        className="block text-sm font-medium text-gray-700 mb-1">
                        Jenis Kelamin
                      </label>
                      <SelectInput
                        className_label="mb-2 text-black"
                        className_root="mb-2"
                        id="gender"
                        name="gender"
                        options={[
                          { value: "male", label: "Laki-laki" },
                          { value: "female", label: "Perempuan" },
                        ]}
                        fallbackValue={"male"}
                        value={worksheet.profile.gender}
                        onChange={onChangeProfile}
                      />
                      <label
                        htmlFor="age"
                        className="block text-sm font-medium text-gray-700 mb-1">
                        Umur
                      </label>
                      <NumberInput
                        className_input="bg-white rounded-xl w-full"
                        className_root="mb-2"
                        disabled={false}
                        id="age"
                        min={1}
                        name="age"
                        required
                        value={worksheet.profile.age}
                        onChange={onChangeProfile}
                      />
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 mb-1">
                        Nomor Unik
                      </label>
                      <TextInput
                        className_input="bg-white rounded-xl w-full"
                        className_root="mb-2"
                        id="phone"
                        name="phone"
                        pattern={pattern.phone}
                        required
                        type="text"
                        validate
                        value={worksheet.profile.phone}
                        placeholder="08123456789"
                        onChange={onChangeProfile}
                      />
                    </div>
                  </div>
                  <div>
                    <QuestionnaireList
                      questionnaires={worksheet.questionnaires}
                      questionnaireStatuses={questionnaireStatuses}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="mt-8">
              <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
                <LinkButton
                  className="flex items-center text-blue-600 hover:text-blue-800"
                  to={ROUTES.QUIZ}
                  valid={!fetching && !loading}>
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                  <span className="font-semibold">Kembali</span>
                </LinkButton>

                <Button
                  className={`w-full md:w-auto flex items-center justify-center px-4 py-2 rounded-lg ${
                    !fetching && !loading && isWorksheetValid()
                      ? "bg-blue-500 text-white hover:bg-blue-600"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                  type="button"
                  onClick={onDownloadWorksheet}
                  valid={!fetching && !loading && isWorksheetValid()}>
                  <span className="font-semibold mr-2">Download</span>
                  <FontAwesomeIcon icon={faDownload} />
                </Button>

                <Button
                  className={`w-full md:w-auto flex items-center justify-center px-4 py-2 rounded-lg ${
                    !fetching && !loading && isWorksheetValid()
                      ? "bg-green-500 text-white hover:bg-green-600"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                  type="submit"
                  valid={!fetching && !loading && isWorksheetValid()}>
                  <span className="font-semibold mr-2">Kirim</span>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </div>
            </div>
          </form>
        </div>

        {(fetching || loading) && (
          <p className="text-blue-300 text-center">
            <FontAwesomeIcon icon={faHourglass} /> Now loading...
          </p>
        )}

        {error && (
          <p className="text-yellow-400 text-center">
            <FontAwesomeIcon icon={faWarning} /> {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Overview;
