// ---------------------------------------------- modules import
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import NumberInput from "../../ui/inputs/number_input";
import SelectInput from "../../../components/ui/inputs/select_input";
import TextInput from "../../../components/ui/inputs/text_input";

import { ISearchFormProps } from "./common";
import { useSearchForm } from "./hooks/use_search_form";

// ---------------------------------------------- the component
const SearchForm: FunctionComponent<ISearchFormProps> = ({
  criteria,
  limit,
  error,
  fetching,
  participantGroups,
  result,
  onFilter,
  onChangeLimit,
}) => {
  const { groupOptions, findByOptions, settlementStatusOptions, onSubmit } =
    useSearchForm(participantGroups);

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col md:flex-row items-center mb-6 bg-gray-800 rounded-xl p-4 space-y-4 md:space-y-0 md:space-x-4 justify-between">
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={faSearch}
          className="text-gray-500 ml-6 mr-4"
          style={{ fontSize: "1.2rem" }}
        />
        {fetching ? (
          <span className="ml-2 py-5 md:py-0 text-gray-100">Now fetching</span>
        ) : error ? (
          <span className="ml-2 py-5 md:py-0 text-red-100">Error</span>
        ) : (
          <span className="ml-2 py-5 md:py-0 text-gray-100">
            <p className="hidden xl:block">
              <b>{result.filtered}</b> out of <b>{result.total}</b> worksheets
              displayed
            </p>
            <p className=" xl:hidden">
              <b>{result.filtered}</b> out of <b>{result.total}</b> displayed
            </p>
          </span>
        )}
      </div>

      <form
        onSubmit={onSubmit}
        className="flex flex-col md:flex-row items-center md:justify-end ml-4 flex-wrap lg:flex-nowrap justify-center space-y-4 md:space-y-0 md:space-x-4"
      >
        <div className="flex flex-col">
          <label
            htmlFor="consolidation-search-form-is-settled-options"
            className="text-gray-400 ml-2 text-sm"
          >
            Status
          </label>
          <SelectInput
            className_internal_container="flex items-center whitespace-nowrap flex-auto text-gray-100 order-first"
            className_root="w-64 rounded-md p-2"
            fallbackValue="settled"
            id="consolidation-search-form-is-settled-options"
            name="settlementStatus"
            options={settlementStatusOptions}
            value={
              criteria.settlementStatus === true
                ? "settled"
                : criteria.settlementStatus === "review"
                ? "review"
                : criteria.settlementStatus === "pending"
                ? "pending"
                : criteria.settlementStatus === false
                ? "unsettled"
                : "all"
            }
            onChange={onFilter}
          />
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="consolidation-search-form-limit"
            className="text-gray-400 ml-2 text-sm"
          >
            Rows
          </label>
          <NumberInput
            className_root="w-24 rounded-md p-2"
            disabled={false}
            id="consolidation-search-form-limit"
            min={1}
            name="limit"
            required
            value={limit}
            onChange={onChangeLimit}
          />
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="consolidation-search-form-group-options"
            className="text-gray-400 ml-2 text-sm"
          >
            Group
          </label>
          <SelectInput
            className_internal_container="flex items-center whitespace-nowrap flex-auto text-gray-100 order-first"
            className_root="w-64 rounded-md p-2"
            fallbackValue="settled"
            id="consolidation-search-form-group-options"
            name="group"
            options={groupOptions}
            value={criteria.group ? criteria.group : "all"}
            onChange={onFilter}
          />
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="consolidation-search-form-find-by-options"
            className="text-gray-400 ml-2 text-sm"
          >
            Find By
          </label>
          <SelectInput
            className_internal_container="flex items-center whitespace-nowrap flex-auto text-gray-100 order-first"
            className_root="w-64 rounded-md p-2"
            fallbackValue="name"
            id="consolidation-search-form-find-by-options"
            name="prop"
            options={findByOptions}
            value={criteria.prop}
            onChange={onFilter}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="keywords" className="text-gray-400 ml-2 text-sm">
            Keywords
          </label>
          <TextInput
            className_root="w-64 rounded-md p-2"
            id="keywords"
            label=""
            name="keywords"
            pattern={{
              minLength: 0,
              maxLength: 100,
              regex: /.*/,
            }}
            required={false}
            type="text"
            value={criteria.keywords}
            onChange={onFilter}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
