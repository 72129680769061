// ---------------------------------------------- modules import
import { useState } from "react";

// ---------------------------------------------- the hooks
export const useDeleteModal = (onDelete: () => void) => {
  // ---------------------------------------------- local state
  const [isVisible, setIsVisible] = useState(false);

  // ---------------------------------------------- handlers
  const handleConfirm = () => {
    onDelete();
    setIsVisible(false);
  };

  const handleShowModal = () => {
    setIsVisible(true);
  };

  const handleHideModal = () => {
    setIsVisible(false);
  };

  // ---------------------------------------------- return value
  return {
    isVisible,
    onConfirm: handleConfirm,
    onHideModal: handleHideModal,
    onShowModal: handleShowModal,
  };
};
