// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IWorksheetContextProviderState,
  IWorksheetContextProviderProps,
} from "./common";
import { useWorksheetContext } from "./hooks";
import { emptyModule } from "../../models/module";
import { emptyParticipantGroup } from "../../models/participant_group";
import { emptySubsetToken } from "../../models/subset_token";
import { emptyWorksheet } from "../../models/worksheet";

// ---------------------------------------------- create context
const initialState: IWorksheetContextProviderState = {
  participantGroup: emptyParticipantGroup(),
  module: emptyModule(),
  subsetToken: emptySubsetToken(),
  worksheet: emptyWorksheet(),
  participantGroups: [],
  modules: [],
  subsetTokens: [],
  worksheets: [],
  error: null,
  fetching: true,
  onChangeModule: () => {
    return;
  },
  onChangeParticipantGroup: () => {
    return;
  },
  onChangeSubsetToken: () => {
    return;
  },
  onChangeWorksheet: () => {
    return;
  },
};

export const WorksheetContext = createContext(initialState);

// ---------------------------------------------- the component
const WorksheetContextProvider: FunctionComponent<
  IWorksheetContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const {
    participantGroup,
    module,
    subsetToken,
    worksheet,
    participantGroups,
    modules,
    subsetTokens,
    worksheets,
    error,
    fetching,
    onChangeModule,
    onChangeParticipantGroup,
    onChangeSubsetToken,
    onChangeWorksheet,
  } = useWorksheetContext();

  // ---------------------------------------------- content
  return (
    <WorksheetContext.Provider
      value={{
        participantGroup,
        module,
        subsetToken,
        worksheet,
        participantGroups,
        modules,
        subsetTokens,
        worksheets,
        error,
        fetching,
        onChangeModule,
        onChangeParticipantGroup,
        onChangeSubsetToken,
        onChangeWorksheet,
      }}
    >
      {children}
    </WorksheetContext.Provider>
  );
};

export default WorksheetContextProvider;
