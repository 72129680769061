// ---------------------------------------------- modules import
import { useContext, useEffect, useState } from "react";

import { WorksheetContext } from "../../../../contexts/worksheet/context";
import { IWorksheet } from "../../../../models/worksheet";

// ---------------------------------------------- the hooks
export const usePdfTokenGroupedPreview = () => {
  // ---------------------------------------------- consume context
  const { participantGroup, module, subsetToken, worksheets, error, fetching } =
    useContext(WorksheetContext);

  // ---------------------------------------------- local state
  const [filtered, setFiltered] = useState<IWorksheet[]>([]);
  const [sorted, setSorted] = useState<IWorksheet[]>([]);

  // ---------------------------------------------- effects
  useEffect(() => {
    const asc = 1;
    const prop = "name";

    setSorted(
      [...worksheets].sort((a, b) =>
        prop === "name" || prop === "phone"
          ? a.profile[prop] > b.profile[prop]
            ? asc
            : asc * -1
          : a[prop] > b[prop]
          ? asc
          : asc * -1
      )
    );
  }, [worksheets]);

  useEffect(() => {
    const keywords = "";
    const prop = "name";

    setFiltered(
      sorted.filter((worksheet) =>
        RegExp(`${keywords}`, "i").test(
          prop === "name" || prop === "phone"
            ? worksheet.profile[prop]
            : worksheet[prop]
        )
      )
    );
  }, [sorted]);

  // ---------------------------------------------- return value
  return {
    error,
    fetching,
    filtered,
    participantGroup,
    module,
    subsetToken,
  };
};
