// ---------------------------------------------- modules import
import { useNavigate } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";
import { ISubsetToken } from "../../../../models/subset_token";

// ---------------------------------------------- the hooks
export const useSubsetTokenRow = (
  subsetToken: ISubsetToken,
  onChangeSubsetToken: (subsetToken: ISubsetToken) => void
) => {
  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- handlers
  const handleClick = () => {
    onChangeSubsetToken(subsetToken);

    navigate(`${ROUTES.REPORT}${ROUTES.WORKSHEET}`, {
      replace: true,
    });
  };

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
