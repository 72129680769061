// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  ISessionContextProviderState,
  ISessionContextProviderProps,
} from "./common";
import { useSessionContext } from "./hooks";

// ---------------------------------------------- create context
const initialState: ISessionContextProviderState = {
  authUser: null,
  profile: null,
  token: null,
  error: null,
  fetching: true,
  onSignIn: () => {},
  onSignOut: () => {},
  onChangeTokenId: () => {},
};

export const SessionContext =
  createContext<ISessionContextProviderState>(initialState);

// ---------------------------------------------- the component
const SessionContextProvider: FunctionComponent<
  ISessionContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const {
    authUser,
    profile,
    token,
    error,
    fetching,
    onSignIn,
    onSignOut,
    onChangeTokenId,
  } = useSessionContext();

  // ---------------------------------------------- content
  return (
    <SessionContext.Provider
      value={{
        authUser,
        profile,
        token,
        error,
        fetching,
        onSignIn,
        onSignOut,
        onChangeTokenId,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
