// ---------------------------------------------- modules import
import { useContext, useState, useEffect } from "react";

import { FirebaseContext } from "../../contexts/firebase/context";

import * as PATHS from "../../constants/collection_paths";
import { IProvince, emptyProvince } from "../../models/province";
import { IRegency, IRegencyRecord } from "../../models/regency";

// ---------------------------------------------- the hooks
export const useRegencyContext = () => {
  // ---------------------------------------------- consume context
  const { firebase } = useContext(FirebaseContext);

  // ---------------------------------------------- local state
  const [province, setProvince] = useState<IProvince>(emptyProvince());
  const [regencies, setRegencies] = useState<IRegency[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);

  // ---------------------------------------------- handlers
  const handleChangeProvince = (province: IProvince) => setProvince(province);

  // ---------------------------------------------- effects
  useEffect(() => {
    if (province) {
      setFetching(true);

      const ref = firebase.firestore
        .collection(PATHS.PROVINCE)
        .doc(province.id.length ? province.id : "empty id")
        .collection(PATHS.REGENCY);

      const unsubscribe = ref.onSnapshot(
        (querySnapshot) => {
          setRegencies(
            querySnapshot.docs.reduce(
              (regencies, doc) => [
                ...regencies,
                {
                  id: doc.id,
                  ...(doc.data() as IRegencyRecord),
                },
              ],
              [] as IRegency[]
            )
          );

          setError(null);
          setFetching(false);
        },
        (error) => {
          setError(error.message);
          setFetching(false);
        }
      );

      return () => unsubscribe();
    }
  }, [firebase, province]);

  // ---------------------------------------------- return value
  return {
    province,
    regencies,
    error,
    fetching,
    onChangeProvince: handleChangeProvince,
  };
};
