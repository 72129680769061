// ---------------------------------------------- modules import
import { faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import voca from "voca";

import Button from "../../../components/ui/buttons/button";
import CheckboxInput from "../../../components/ui/inputs/checkbox_input";

import bgQuiz from "../../../assets/images/bg-quiz.png";

import { IQuizKesejahteraanPsikologiDanEmosionalProps } from "./common";
import { useQuizKesejahteraanPsikologiDanEmosional } from "./hooks/use_quiz_kesejahteraan_psikologi_dan_emosional";

// ---------------------------------------------- the component
const QuizKesejahteraanPsikologiDanEmosional: FunctionComponent<
  IQuizKesejahteraanPsikologiDanEmosionalProps
> = ({ questionnaires, onChangeSelectedOptions }) => {
  // ---------------------------------------------- local state
  const { questionnaire, group, question, onChange, onNext, onPrevious } =
    useQuizKesejahteraanPsikologiDanEmosional(
      questionnaires,
      onChangeSelectedOptions
    );
  const anyOptionSelected = question
    ? question.options.some(
        (option) =>
          question.selectedOptions.findIndex(
            (selectedOption) => selectedOption.value === option.value
          ) !== -1
      )
    : false;
  // ---------------------------------------------- the content
  return (
    <div className="bg-blue-900">
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-no-repeat bg-cover md:w-2/5 xl:w-1/4 h-full w-full"
        style={{ backgroundImage: `url(${bgQuiz})` }}
      >
        <div className="">
          <form className="w-full max-w-md px-9 pt-8 sm:pb-8 rounded-lg">
            <h1 className="md:text-2xl text-xl text-gray-100 md:py-10 py-5">
              {questionnaire && voca.upperCase(questionnaire.assessment.name)}
            </h1>

            {questionnaire && group && question && (
              <>
                <p className=" sm:pt-12 md:pt-10 xl:pt-14 pt-16 pb-10 text-base text-black">
                  {question.text}
                </p>

                <div className="pb-40">
                  {question.options.map((option, index) => {
                    const { text, value } = option;

                    const included = question.selectedOptions.reduce(
                      (included, selectedOption) =>
                        included || selectedOption.value === value,
                      false
                    );

                    return (
                      <label
                        key={value}
                        className={`mb-3 inline-flex items-center justify-start w-full p-5 text-white border rounded-lg cursor-pointer border-gray-700 ${
                          included
                            ? "bg-gray-400 hover:bg-gray-400"
                            : "bg-gray-800 hover:bg-gray-400"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <CheckboxInput
                          key={value}
                          checked={included}
                          className_input="hidden"
                          className_label={`flex-grow ml-2 text-base font-medium ${
                            included ? "text-gray-900" : "text-gray-100"
                          }`}
                          id={`${value}-${index}`}
                          name={question.id}
                          value={value}
                          onChange={onChange}
                        />
                        {text}
                      </label>
                    );
                  })}
                </div>
              </>
            )}

            <div className="fixed bottom-0 left-0 right-0 bg-transparent p-4">
              <div className="flex justify-between max-w-md mx-auto">
                <Button
                  className="flex items-center text-blue-500 hover:text-blue-700"
                  type="button"
                  valid
                  onClick={onPrevious}
                >
                  <FontAwesomeIcon icon={faCircleLeft} className="text-xl" />

                  <span className="text-lg font-bold mx-2">BACK</span>
                </Button>

                <Button
                  className={`flex items-center ${
                    anyOptionSelected
                      ? "text-blue-500 hover:text-blue-700"
                      : "text-gray-500"
                  }`}
                  type="button"
                  valid={anyOptionSelected}
                  onClick={onNext}
                >
                  <span className="text-lg font-bold mx-2">NEXT</span>

                  <FontAwesomeIcon icon={faCircleRight} className="text-xl" />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuizKesejahteraanPsikologiDanEmosional;
