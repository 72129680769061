// ---------------------------------------------- modules import
import { faFaceLaughBeam } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import AssessmentRow from "./assessment_row";

import { IAssessmentTableProps } from "./common";

// ---------------------------------------------- the component
const AssessmentTable: FunctionComponent<IAssessmentTableProps> = ({
  assessments,
  fetching,
  error,
}) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
    <p className="text-lg md:text-xl text-gray-100 mb-4">
      We have an exciting collection of{" "}
      <span className="text-yellow-400 font-semibold">
        {assessments.length} assessments
      </span>{" "}
      available, ready to unlock the potential and strengths of individuals on
      their unique journeys of personal growth.{" "}
      <FontAwesomeIcon className="text-yellow-400" icon={faFaceLaughBeam} />
    </p>

    <section className="shadow-lg overflow-x-auto max-h-screen mb-8 md:max-h-[calc(100vh-350px)]">
      <div className="relative overflow-x-auto shadow-md rounded-lg max-h-[calc(100vh-400px)]">
        <table className="w-full text-sm text-left text-gray-400">
          <thead className="text-xs uppercase bg-gray-700 text-gray-300 sticky top-0">
            <tr>
              <th className="px-6 py-3">Name</th>
            </tr>
          </thead>

          <tbody>
            {!fetching
              ? assessments.map((assessment) => (
                  <AssessmentRow key={assessment.id} assessment={assessment} />
                ))
              : null}
          </tbody>
        </table>
      </div>
    </section>
  </div>
);

export default AssessmentTable;
