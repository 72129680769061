import {
  Page,
  StyleSheet,
  Text,
  View,
  Svg,
  Rect,
  Image,
} from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-belajar.png";

import { IResultPageKebiasaanBelajarProps } from "./common";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";
import { AttributeIdEnum_KebiasaanBelajar } from "../../../../enums/attribute_id";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#3b1c78",
  },
  description: {
    marginBottom: 10,
    textAlign: "justify",
    fontSize: 14,
  },
  table: {
    display: "flex",
    width: "70%",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    backgroundColor: "#87CEEB",
    color: "#000000",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    flex: 1,
    textAlign: "center",
  },
  tableCell: {
    fontSize: 10,
    padding: 5,
    flex: 1,
    textAlign: "center",
  },
  tableCellName: {
    fontSize: 10,
    padding: 5,
    flex: 1,
    textAlign: "left",
  },
  tableCellEven: {
    backgroundColor: "#E6F3FF",
  },
  tableCellOdd: {
    backgroundColor: "#FFFFFF",
  },
  tableTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#3b1c78",
  },

  tableCellNo: {
    fontSize: 10,
    padding: 5,
    flex: 0.5,
    textAlign: "center",
    backgroundColor: "#E6F3FF",
  },

  diagramContainer: {
    marginTop: 10,
    marginBottom: 10,
    width: "50%",
  },
  diagramRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  diagramLabel: {
    // width: "30%",
    fontSize: 10,
    textAlign: "right",
    marginRight: 2,
    flex: 5,
  },
  diagramBar: {
    // width: "10%",
    height: 20,
    flex: 5,
  },
  diagramPercentage: {
    // width: "5%",
    fontSize: 10,
    textAlign: "left",
    flex: 1,
  },

  categoryContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
  },
  categoryBox: {
    width: "48%",
    border: "1px solid #000",
    padding: 5,
    marginBottom: 10,
  },
  categoryTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  categoryDescription: {
    fontSize: 10,
    marginBottom: 5,
  },
  categoryPercentage: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
  },

  conclusionSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  conclusionLeft: {
    width: "80%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    marginRight: 4,
    padding: 4,
  },
  conclusionRight: {
    width: "20%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center", // Menambahkan text align untuk mengambang teks di tengah
    padding: 4,
  },
  // table
  table1: {
    display: "flex",
    width: "100%",
    borderColor: "#000000",
    borderCollapse: "collapse",
  },
  tableRow1: {
    flexDirection: "row",
  },
  tableHeaderCell1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 7,
    fontWeight: "bold",
    padding: 5,
    width: 45,
    textAlign: "center",
  },
  tableCell1: {
    fontSize: 6,
    padding: 5,
    width: 45,
    textAlign: "center",
  },
  tableHeaderCellName1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 130,
  },
  tableCellName1: {
    fontSize: 9,
    padding: 5,
    width: 130,
  },
  tableHeaderCellResult1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 7,
    fontWeight: "bold",
    padding: 5,
    width: 80,
    textAlign: "center",
  },
  tableCellResult1: {
    fontSize: 6,
    padding: 5,
    width: 80,
    textAlign: "center",
  },
  tableHeaderCellNo1: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  tableCellNo1: {
    fontSize: 9,
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  evenRow1: {
    backgroundColor: "#D9E1F2",
  },
  oddRow1: {
    backgroundColor: "#FFFFFF",
  },
});

// ---------------------------------------------- the table attribute
interface AttributeTableProps {
  title: string;
  data: {
    mulaiBerkembang: number;
    sedangBerkembang: number;
    berkembangBaik: number;
    sangatBerkembang: number;
  };
}

// ---------------------------------------------- the table attribute
const AttributeTable: React.FC<AttributeTableProps> = ({ title, data }) => (
  <View style={styles.table} wrap={false}>
    <Text style={styles.tableTitle}>{title}</Text>
    <View style={styles.tableRow}>
      <Text style={styles.tableHeaderCell}>Kategori</Text>
      <Text style={styles.tableHeaderCell}>Jumlah Siswa</Text>
      <Text style={styles.tableHeaderCell}>Persentase</Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>
        Mulai Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.mulaiBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {Math.round(
          (data.mulaiBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellEven]}>
        Sedang Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {data.sedangBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {Math.round(
          (data.sedangBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>
        Berkembang Sesuai Harapan
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.berkembangBaik}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {Math.round(
          (data.berkembangBaik /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellEven]}>
        Sangat Berkembang
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {data.sangatBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellEven]}>
        {Math.round(
          (data.sangatBerkembang /
            (data.mulaiBerkembang +
              data.sedangBerkembang +
              data.berkembangBaik +
              data.sangatBerkembang)) *
            100
        )}
        %
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCellName, styles.tableCellOdd]}>Total</Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>
        {data.mulaiBerkembang +
          data.sedangBerkembang +
          data.berkembangBaik +
          data.sangatBerkembang}
      </Text>
      <Text style={[styles.tableCell, styles.tableCellOdd]}>100%</Text>
    </View>
  </View>
);

// ---------------------------------------------- the diagram attribute

const AttributeDiagram: React.FC<AttributeTableProps> = ({ title, data }) => {
  const total =
    data.mulaiBerkembang +
    data.sedangBerkembang +
    data.berkembangBaik +
    data.sangatBerkembang;
  const percentages = {
    mulaiBerkembang: Math.round((data.mulaiBerkembang / total) * 100),
    sedangBerkembang: Math.round((data.sedangBerkembang / total) * 100),
    berkembangBaik: Math.round((data.berkembangBaik / total) * 100),
    sangatBerkembang: Math.round((data.sangatBerkembang / total) * 100),
  };

  return (
    <View style={styles.diagramContainer} wrap={false}>
      <Text style={styles.tableTitle}>{title}</Text>
      <DiagramRow
        label="Mulai Berkembang"
        percentage={percentages.mulaiBerkembang}
        color="#F4A460"
      />
      <DiagramRow
        label="Sedang Berkembang"
        percentage={percentages.sedangBerkembang}
        color="#87CEEB"
      />
      <DiagramRow
        label="Berkembang Sesuai Harapan"
        percentage={percentages.berkembangBaik}
        color="#9370DB"
      />
      <DiagramRow
        label="Sangat Berkembang"
        percentage={percentages.sangatBerkembang}
        color="#90EE90"
      />
    </View>
  );
};

const DiagramRow: React.FC<{
  label: string;
  percentage: number;
  color: string;
}> = ({ label, percentage, color }) => (
  <View style={styles.diagramRow}>
    <Text style={styles.diagramLabel}>{label}</Text>
    <Svg width="100%" height={20} style={styles.diagramBar}>
      <Rect width={`${percentage}%`} height={20} fill={color} />
    </Svg>
    <Text style={styles.diagramPercentage}>{percentage}%</Text>
  </View>
);

// ---------------------------------------------- the component
const ResultPageKebiasaanBelajar: FunctionComponent<
  IResultPageKebiasaanBelajarProps
> = ({ worksheets }) => {
  // Menghitung jumlah siswa untuk setiap kategori pada atribut MANDIRI
  let HomeworkAndAssignmentsMulaiBerkembang = 0;
  let HomeworkAndAssignmentsSedangBerkembang = 0;
  let HomeworkAndAssignmentsBerkembangBaik = 0;
  let HomeworkAndAssignmentsSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KEBIASAAN_BELAJAR
    );
    if (!questionnaire) return;

    const homeworkAndAssignmentsAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id === AttributeIdEnum_KebiasaanBelajar.HOMEWORK_AND_ASSIGNMENTS
    );
    if (!homeworkAndAssignmentsAttr) return;

    const homeworkAndAssignmentsValue = homeworkAndAssignmentsAttr.value * 100;
    if (homeworkAndAssignmentsValue < 55) {
      HomeworkAndAssignmentsMulaiBerkembang++;
    } else if (homeworkAndAssignmentsValue < 74) {
      HomeworkAndAssignmentsSedangBerkembang++;
    } else if (homeworkAndAssignmentsValue < 91) {
      HomeworkAndAssignmentsBerkembangBaik++;
    } else {
      HomeworkAndAssignmentsSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut PENGUASAAN_LINGKUNGAN
  let ReadingAndNoteTakingMulaiBerkembang = 0;
  let ReadingAndNoteTakingSedangBerkembang = 0;
  let ReadingAndNoteTakingBerkembangBaik = 0;
  let ReadingAndNoteTakingSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KEBIASAAN_BELAJAR
    );
    if (!questionnaire) return;

    const readingAndNoteTakingAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id === AttributeIdEnum_KebiasaanBelajar.READING_AND_NOTE_TAKING
    );
    if (!readingAndNoteTakingAttr) return;

    const readingAndNoteTakingValue = readingAndNoteTakingAttr.value * 100;
    if (readingAndNoteTakingValue < 50) {
      ReadingAndNoteTakingMulaiBerkembang++;
    } else if (readingAndNoteTakingValue < 70) {
      ReadingAndNoteTakingSedangBerkembang++;
    } else if (readingAndNoteTakingValue < 88) {
      ReadingAndNoteTakingBerkembangBaik++;
    } else {
      ReadingAndNoteTakingSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut PERTUMBUHAN_PRIBADI
  let StudyPeriodProceduresMulaiBerkembang = 0;
  let StudyPeriodProceduresSedangBerkembang = 0;
  let StudyPeriodProceduresBerkembangBaik = 0;
  let StudyPeriodProceduresSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KEBIASAAN_BELAJAR
    );
    if (!questionnaire) return;

    const studyPeriodProceduresAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id === AttributeIdEnum_KebiasaanBelajar.STUDY_PERIOD_PROCEDURES
    );
    if (!studyPeriodProceduresAttr) return;

    const studyPeriodProceduresValue = studyPeriodProceduresAttr.value * 100;
    if (studyPeriodProceduresValue < 44) {
      StudyPeriodProceduresMulaiBerkembang++;
    } else if (studyPeriodProceduresValue < 62) {
      StudyPeriodProceduresSedangBerkembang++;
    } else if (studyPeriodProceduresValue < 78) {
      StudyPeriodProceduresBerkembangBaik++;
    } else {
      StudyPeriodProceduresSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN
  let ConcentrationMulaiBerkembang = 0;
  let ConcentrationSedangBerkembang = 0;
  let ConcentrationBerkembangBaik = 0;
  let ConcentrationSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KEBIASAAN_BELAJAR
    );
    if (!questionnaire) return;

    const concentrationAttr = questionnaire.result.attributes.find(
      (attr) => attr.id === AttributeIdEnum_KebiasaanBelajar.CONCENTRATION
    );
    if (!concentrationAttr) return;

    const concentrationValue = concentrationAttr.value * 100;
    if (concentrationValue < 44) {
      ConcentrationMulaiBerkembang++;
    } else if (concentrationValue < 62) {
      ConcentrationSedangBerkembang++;
    } else if (concentrationValue < 78) {
      ConcentrationBerkembangBaik++;
    } else {
      ConcentrationSangatBerkembang++;
    }
  });

  // Menghitung jumlah siswa untuk setiap kategori pada atribut TUJUAN_HIDUP
  let TimeAllocationMulaiBerkembang = 0;
  let TimeAllocationSedangBerkembang = 0;
  let TimeAllocationBerkembangBaik = 0;
  let TimeAllocationSangatBerkembang = 0;

  worksheets.forEach((worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) => q.assessment.id === AssessmentIdEnum.KEBIASAAN_BELAJAR
    );
    if (!questionnaire) return;

    const timeAllocationAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id === AttributeIdEnum_KebiasaanBelajar.STUDY_PERIOD_PROCEDURES
    );
    if (!timeAllocationAttr) return;

    const timeAllocationValue = timeAllocationAttr.value * 100;
    if (timeAllocationValue < 27) {
      TimeAllocationMulaiBerkembang++;
    } else if (timeAllocationValue < 49) {
      StudyPeriodProceduresSedangBerkembang++;
    } else if (timeAllocationValue < 69) {
      TimeAllocationBerkembangBaik++;
    } else {
      TimeAllocationSangatBerkembang++;
    }
  });

  //-------------------------------------------- the percentages

  const totalTimeAllocation =
    TimeAllocationMulaiBerkembang +
    TimeAllocationSedangBerkembang +
    TimeAllocationBerkembangBaik +
    TimeAllocationSangatBerkembang;

  const timeAllocationPercentages = {
    mulaiBerkembang: Math.round(
      (TimeAllocationMulaiBerkembang / totalTimeAllocation) * 100
    ),
    sedangBerkembang: Math.round(
      (TimeAllocationSedangBerkembang / totalTimeAllocation) * 100
    ),
    berkembangBaik: Math.round(
      (TimeAllocationBerkembangBaik / totalTimeAllocation) * 100
    ),
    sangatBerkembang: Math.round(
      (TimeAllocationSangatBerkembang / totalTimeAllocation) * 100
    ),
  };

  const totalHomeworkAndAssignments =
    HomeworkAndAssignmentsMulaiBerkembang +
    HomeworkAndAssignmentsSedangBerkembang +
    HomeworkAndAssignmentsBerkembangBaik +
    HomeworkAndAssignmentsSangatBerkembang;

  const homeworkAndAssignmentsPercentages = {
    mulaiBerkembang: Math.round(
      (HomeworkAndAssignmentsMulaiBerkembang / totalHomeworkAndAssignments) *
        100
    ),
    sedangBerkembang: Math.round(
      (HomeworkAndAssignmentsSedangBerkembang / totalHomeworkAndAssignments) *
        100
    ),
    berkembangBaik: Math.round(
      (HomeworkAndAssignmentsBerkembangBaik / totalHomeworkAndAssignments) * 100
    ),
    sangatBerkembang: Math.round(
      (HomeworkAndAssignmentsSangatBerkembang / totalHomeworkAndAssignments) *
        100
    ),
  };

  const totalReadingAndNoteTaking =
    ReadingAndNoteTakingMulaiBerkembang +
    ReadingAndNoteTakingSedangBerkembang +
    ReadingAndNoteTakingBerkembangBaik +
    ReadingAndNoteTakingSangatBerkembang;

  const readingAndNoteTakingPercentages = {
    mulaiBerkembang: Math.round(
      (ReadingAndNoteTakingMulaiBerkembang / totalReadingAndNoteTaking) * 100
    ),
    sedangBerkembang: Math.round(
      (ReadingAndNoteTakingSedangBerkembang / totalReadingAndNoteTaking) * 100
    ),
    berkembangBaik: Math.round(
      (ReadingAndNoteTakingBerkembangBaik / totalReadingAndNoteTaking) * 100
    ),
    sangatBerkembang: Math.round(
      (ReadingAndNoteTakingSangatBerkembang / totalReadingAndNoteTaking) * 100
    ),
  };

  const totalStudyPeriodProcedures =
    StudyPeriodProceduresMulaiBerkembang +
    StudyPeriodProceduresSedangBerkembang +
    StudyPeriodProceduresBerkembangBaik +
    StudyPeriodProceduresSangatBerkembang;

  const studyPeriodProceduresPercentages = {
    mulaiBerkembang: Math.round(
      (StudyPeriodProceduresMulaiBerkembang / totalStudyPeriodProcedures) * 100
    ),
    sedangBerkembang: Math.round(
      (StudyPeriodProceduresSedangBerkembang / totalStudyPeriodProcedures) * 100
    ),
    berkembangBaik: Math.round(
      (StudyPeriodProceduresBerkembangBaik / totalStudyPeriodProcedures) * 100
    ),
    sangatBerkembang: Math.round(
      (StudyPeriodProceduresSangatBerkembang / totalStudyPeriodProcedures) * 100
    ),
  };

  const totalConcentration =
    ConcentrationMulaiBerkembang +
    ConcentrationSedangBerkembang +
    ConcentrationBerkembangBaik +
    ConcentrationSangatBerkembang;

  const concentrationPercentages = {
    mulaiBerkembang: Math.round(
      (ConcentrationMulaiBerkembang / totalConcentration) * 100
    ),
    sedangBerkembang: Math.round(
      (ConcentrationSedangBerkembang / totalConcentration) * 100
    ),
    berkembangBaik: Math.round(
      (ConcentrationBerkembangBaik / totalConcentration) * 100
    ),
    sangatBerkembang: Math.round(
      (ConcentrationSangatBerkembang / totalConcentration) * 100
    ),
  };

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}>
        <Text style={styles.heading}>Kebiasaan Belajar</Text>
        <View style={styles.table1}>
          <View style={styles.tableRow1}>
            <Text style={styles.tableHeaderCellNo1}>No.</Text>
            <Text style={styles.tableHeaderCellName1}>Nama</Text>
            <Text style={styles.tableHeaderCell1}>
              Pekerjaan Rumah dan Tugas
            </Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>Alokasi Waktu</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>Membaca dan Mencatat</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>
              Prosedur Periode Belajar
            </Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
            <Text style={styles.tableHeaderCell1}>Konsentrasi</Text>
            <Text style={styles.tableHeaderCell1}>Kategori</Text>
          </View>

          {worksheets &&
            worksheets.map((worksheet, index) => {
              const { profile, questionnaires } = worksheet;

              const questionnaire = questionnaires.find(
                (q) => q.assessment.id === AssessmentIdEnum.KEBIASAAN_BELAJAR
              );
              if (!questionnaire) return null;

              const { result } = questionnaire;

              const homeworkAndAssignmentsAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KebiasaanBelajar.HOMEWORK_AND_ASSIGNMENTS
              );
              const timeAllocationAttr = result.attributes.find(
                (attr) =>
                  attr.id === AttributeIdEnum_KebiasaanBelajar.TIME_ALLOCATION
              );
              const readingAndNoteTakingAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KebiasaanBelajar.READING_AND_NOTE_TAKING
              );
              const studyPeriodProceduresAttr = result.attributes.find(
                (attr) =>
                  attr.id ===
                  AttributeIdEnum_KebiasaanBelajar.STUDY_PERIOD_PROCEDURES
              );
              const concentrationAttr = result.attributes.find(
                (attr) =>
                  attr.id === AttributeIdEnum_KebiasaanBelajar.CONCENTRATION
              );

              const homeworkAndAssignmentsValue = homeworkAndAssignmentsAttr
                ? (homeworkAndAssignmentsAttr.value * 100).toFixed(0)
                : "-";
              const timeAllocationValue = timeAllocationAttr
                ? (timeAllocationAttr.value * 100).toFixed(0)
                : "-";
              const readingAndNoteTakingValue = readingAndNoteTakingAttr
                ? (readingAndNoteTakingAttr.value * 100).toFixed(0)
                : "-";
              const studyPeriodProceduresValue = studyPeriodProceduresAttr
                ? (studyPeriodProceduresAttr.value * 100).toFixed(0)
                : "-";
              const concentrationValue = concentrationAttr
                ? (concentrationAttr.value * 100).toFixed(0)
                : "-";

              return (
                <View
                  key={worksheet.id}
                  style={[
                    styles.tableRow,
                    index % 2 === 0 ? styles.evenRow1 : styles.oddRow1,
                  ]}
                  wrap={false}>
                  <Text style={styles.tableCellNo1}>{index + 1}</Text>
                  <Text style={styles.tableCellName1}>{profile.name}</Text>
                  <Text style={styles.tableCell1}>
                    {homeworkAndAssignmentsValue}
                  </Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(homeworkAndAssignmentsValue) < 55
                      ? "Mulai Berkembang"
                      : parseFloat(homeworkAndAssignmentsValue) < 73
                      ? "Sedang Berkembang"
                      : parseFloat(homeworkAndAssignmentsValue) < 91
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>{timeAllocationValue}</Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(timeAllocationValue) < 27
                      ? "Mulai Berkembang"
                      : parseFloat(timeAllocationValue) < 48
                      ? "Sedang Berkembang"
                      : parseFloat(timeAllocationValue) < 69
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>
                    {readingAndNoteTakingValue}
                  </Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(readingAndNoteTakingValue) < 50
                      ? "Mulai Berkembang"
                      : parseFloat(readingAndNoteTakingValue) < 69
                      ? "Sedang Berkembang"
                      : parseFloat(readingAndNoteTakingValue) < 88
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>
                    {studyPeriodProceduresValue}
                  </Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(studyPeriodProceduresValue) < 44
                      ? "Mulai Berkembang"
                      : parseFloat(studyPeriodProceduresValue) < 61
                      ? "Sedang Berkembang"
                      : parseFloat(studyPeriodProceduresValue) < 78
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                  <Text style={styles.tableCell1}>{concentrationValue}</Text>
                  <Text style={styles.tableCell1}>
                    {parseFloat(concentrationValue) < 44
                      ? "Mulai Berkembang"
                      : parseFloat(concentrationValue) < 61
                      ? "Sedang Berkembang"
                      : parseFloat(concentrationValue) < 78
                      ? "Berkembang Baik"
                      : "Sangat Berkembang"}
                  </Text>
                </View>
              );
            })}
          <Text style={styles.tableCell1}></Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Pekerjaan Rumah dan Tugas"
            data={{
              mulaiBerkembang: HomeworkAndAssignmentsMulaiBerkembang,
              sedangBerkembang: HomeworkAndAssignmentsSedangBerkembang,
              berkembangBaik: HomeworkAndAssignmentsBerkembangBaik,
              sangatBerkembang: HomeworkAndAssignmentsSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Pekerjaan Rumah dan Tugas"
            data={{
              mulaiBerkembang: HomeworkAndAssignmentsMulaiBerkembang,
              sedangBerkembang: HomeworkAndAssignmentsSedangBerkembang,
              berkembangBaik: HomeworkAndAssignmentsBerkembangBaik,
              sangatBerkembang: HomeworkAndAssignmentsSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang dalam dalam mengadopsi pembelajaran di luar jam
              pelajaran sekolah. Mengatur jadwal pelajaran untuk memudahkan
              pembelajaran. Selain itu juga untuk mengevaluasi pekerjaan rumah
              serta pengaturan buku, catatan, dan latihan yang akan dibawa ke
              sekolah.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang mulai berkembang dalam mengadopsi
                    pembelajaran di luar jam pelajaran sekolah. Mengatur jadwal
                    pelajaran untuk memudahkan pembelajaran. Selain itu juga
                    untuk mengevaluasi pekerjaan rumah serta pengaturan buku,
                    catatan, dan latihan yang akan dibawa ke sekolah.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {homeworkAndAssignmentsPercentages.mulaiBerkembang}%
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang sedang berkembang dalam mengadopsi
                    pembelajaran di luar jam pelajaran sekolah. Mengatur jadwal
                    pelajaran untuk memudahkan pembelajaran. Selain itu juga
                    untuk mengevaluasi pekerjaan rumah serta pengaturan buku,
                    catatan, dan latihan yang akan dibawa ke sekolah.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {homeworkAndAssignmentsPercentages.sedangBerkembang}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang berkembang sesuai harapan dalam
                    mengadopsi pembelajaran di luar jam pelajaran sekolah.
                    Mengatur jadwal pelajaran untuk memudahkan pembelajaran.
                    Selain itu juga untuk mengevaluasi pekerjaan rumah serta
                    pengaturan buku, catatan, dan latihan yang akan dibawa ke
                    sekolah.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {homeworkAndAssignmentsPercentages.berkembangBaik}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang sangat berkembang dalam mengadopsi
                    pembelajaran di luar jam pelajaran sekolah. Mengatur jadwal
                    pelajaran untuk memudahkan pembelajaran. Selain itu juga
                    untuk mengevaluasi pekerjaan rumah serta pengaturan buku,
                    catatan, dan latihan yang akan dibawa ke sekolah.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {homeworkAndAssignmentsPercentages.sangatBerkembang}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} break>
          <AttributeTable
            title="Alokasi Waktu"
            data={{
              mulaiBerkembang: TimeAllocationMulaiBerkembang,
              sedangBerkembang: TimeAllocationSedangBerkembang,
              berkembangBaik: TimeAllocationBerkembangBaik,
              sangatBerkembang: TimeAllocationSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Alokasi Waktu"
            data={{
              mulaiBerkembang: TimeAllocationMulaiBerkembang,
              sedangBerkembang: TimeAllocationSedangBerkembang,
              berkembangBaik: TimeAllocationBerkembangBaik,
              sangatBerkembang: TimeAllocationSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan dalam memanfaatkan waktu
              untuk belajar. Menjauhi faktor- faktor yang dapat mengganggu
              perhatian dalam belajar, jika dihadapkan pada dua pilihan yaitu
              belajar atau aktivitas lain, maka akan memilih aktivitas belajar.
              Menilai alokasi waktu belajar dan lamanya jam belajar.
            </Text>

            <View style={styles.categoryContainer}>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang mulai berkembang dalam memanfaatkan
                    waktu untuk belajar. Menjauhi faktor-faktor yang dapat
                    mengganggu perhatian dalam belajar, jika dihadapkan pada dua
                    pilihan yaitu belajar atau aktivitas lain, maka akan memilih
                    aktivitas belajar. Menilai alokasi waktu belajar dan lamanya
                    jam belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {timeAllocationPercentages.mulaiBerkembang}%
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang sedang berkembang dalam memanfaatkan
                    waktu untuk belajar. Menjauhi faktor-faktor yang dapat
                    mengganggu perhatian dalam belajar, jika dihadapkan pada dua
                    pilihan yaitu belajar atau aktivitas lain, maka akan memilih
                    aktivitas belajar. Menilai alokasi waktu belajar dan lamanya
                    jam belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {timeAllocationPercentages.sedangBerkembang}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang berkembang sesuai harapan dalam
                    memanfaatkan waktu untuk belajar. Menjauhi faktor-faktor
                    yang dapat mengganggu perhatian dalam belajar, jika
                    dihadapkan pada dua pilihan yaitu belajar atau aktivitas
                    lain, maka akan memilih aktivitas belajar. Menilai alokasi
                    waktu belajar dan lamanya jam belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {timeAllocationPercentages.berkembangBaik}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang sangat berkembang dalam memanfaatkan
                    waktu untuk belajar. Menjauhi faktor-faktor yang dapat
                    mengganggu perhatian dalam belajar, jika dihadapkan pada dua
                    pilihan yaitu belajar atau aktivitas lain, maka akan memilih
                    aktivitas belajar. Menilai alokasi waktu belajar dan lamanya
                    jam belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {timeAllocationPercentages.sangatBerkembang}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <AttributeTable
            title="Membaca dan Mencatat"
            data={{
              mulaiBerkembang: ReadingAndNoteTakingMulaiBerkembang,
              sedangBerkembang: ReadingAndNoteTakingSedangBerkembang,
              berkembangBaik: ReadingAndNoteTakingBerkembangBaik,
              sangatBerkembang: ReadingAndNoteTakingSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Membaca dan Mencatat"
            data={{
              mulaiBerkembang: ReadingAndNoteTakingMulaiBerkembang,
              sedangBerkembang: ReadingAndNoteTakingSedangBerkembang,
              berkembangBaik: ReadingAndNoteTakingBerkembangBaik,
              sangatBerkembang: ReadingAndNoteTakingSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan dalam membaca buku serta
              membuat catatan yang rapi dan mudah dipahami, meliputi kerapian,
              kejelasan dan keterbacaan catatan yang ditulis dan penggunaan kata
              kunci serta beberapa kebiasaan pendukung yang dapat mempengaruhi
              hasil belajar. Kebiasaan membaca seperti menghapal, menentukan ide
              utama, memahami konsep, dan mereview catatan secara berkala.
            </Text>

            <View style={styles.categoryContainer}>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan intensitas yang mulai berkembang dalam membaca
                    buku serta membuat catatan yang rapi dan mudah dipahami,
                    meliputi kerapian, kejelasan dan keterbacaan catatan yang
                    ditulis dan penggunaan kata kunci serta beberapa kebiasaan
                    pendukung yang dapat mempengaruhi hasil belajar. Kebiasaan
                    membaca seperti menghapal, menentukan ide utama, memahami
                    konsep, dan mereview catatan secara berkala.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {readingAndNoteTakingPercentages.mulaiBerkembang}%
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan intensitas yang sedang berkembang dalam membaca
                    buku serta membuat catatan yang rapi dan mudah dipahami,
                    meliputi kerapian, kejelasan dan keterbacaan catatan yang
                    ditulis dan penggunaan kata kunci serta beberapa kebiasaan
                    pendukung yang dapat mempengaruhi hasil belajar. Kebiasaan
                    membaca seperti menghapal, menentukan ide utama, memahami
                    konsep, dan mereview catatan secara berkala.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {readingAndNoteTakingPercentages.sedangBerkembang}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan intensitas yang berkembang sesuai harapan dalam
                    membaca buku serta membuat catatan yang rapi dan mudah
                    dipahami, meliputi kerapian, kejelasan dan keterbacaan
                    catatan yang ditulis dan penggunaan kata kunci serta
                    beberapa kebiasaan pendukung yang dapat mempengaruhi hasil
                    belajar. Kebiasaan membaca seperti menghapal, menentukan ide
                    utama, memahami konsep, dan mereview catatan secara berkala.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {readingAndNoteTakingPercentages.berkembangBaik}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan intensitas yang sangat berkembang dalam membaca
                    buku serta membuat catatan yang rapi dan mudah dipahami,
                    meliputi kerapian, kejelasan dan keterbacaan catatan yang
                    ditulis dan penggunaan kata kunci serta beberapa kebiasaan
                    pendukung yang dapat mempengaruhi hasil belajar. Kebiasaan
                    membaca seperti menghapal, menentukan ide utama, memahami
                    konsep, dan mereview catatan secara berkala.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {readingAndNoteTakingPercentages.sangatBerkembang}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Prosedur Periode Belajar"
            data={{
              mulaiBerkembang: StudyPeriodProceduresMulaiBerkembang,
              sedangBerkembang: StudyPeriodProceduresSedangBerkembang,
              berkembangBaik: StudyPeriodProceduresBerkembangBaik,
              sangatBerkembang: StudyPeriodProceduresSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Prosedur Periode Belajar"
            data={{
              mulaiBerkembang: StudyPeriodProceduresMulaiBerkembang,
              sedangBerkembang: StudyPeriodProceduresSedangBerkembang,
              berkembangBaik: StudyPeriodProceduresBerkembangBaik,
              sangatBerkembang: StudyPeriodProceduresSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan dalam belajar dan
              mempersiapkan hal-hal yang diperlukan dalam belajar. Intensitas
              dan faktor yang mempengaruhi tercapainya pembelajaran yang
              efisien. Dan kebiasaan yang terkait pengaturan jarak belajar.
            </Text>

            <View style={styles.categoryContainer}>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang mulai berkembang saat belajar.
                    Mempersiapkan hal-hal yang diperlukan dalam belajar.
                    Intensitas dan faktor yang mempengaruhi tercapainya
                    pembelajaran yang efisien. Dan kebiasaan yang terkait
                    pengaturan jarak belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {studyPeriodProceduresPercentages.mulaiBerkembang}%
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang sedang berkembang saat belajar.
                    Mempersiapkan hal-hal yang diperlukan dalam belajar.
                    Intensitas dan faktor yang mempengaruhi tercapainya
                    pembelajaran yang efisien. Dan kebiasaan yang terkait
                    pengaturan jarak belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {studyPeriodProceduresPercentages.sedangBerkembang}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang berkembang sesuai harapan saat
                    belajar. Mempersiapkan hal-hal yang diperlukan dalam
                    belajar. Intensitas dan faktor yang mempengaruhi tercapainya
                    pembelajaran yang efisien. Dan kebiasaan yang terkait
                    pengaturan jarak belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {studyPeriodProceduresPercentages.berkembangBaik}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan sikap yang sangat berkembang saat belajar.
                    Mempersiapkan hal-hal yang diperlukan dalam belajar.
                    Intensitas dan faktor yang mempengaruhi tercapainya
                    pembelajaran yang efisien. Dan kebiasaan yang terkait
                    pengaturan jarak belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {studyPeriodProceduresPercentages.sangatBerkembang}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <AttributeTable
            title="Konsentrasi"
            data={{
              mulaiBerkembang: ConcentrationMulaiBerkembang,
              sedangBerkembang: ConcentrationSedangBerkembang,
              berkembangBaik: ConcentrationBerkembangBaik,
              sangatBerkembang: ConcentrationSangatBerkembang,
            }}
          />

          <AttributeDiagram
            title="Grafik Konsentrasi"
            data={{
              mulaiBerkembang: ConcentrationMulaiBerkembang,
              sedangBerkembang: ConcentrationSedangBerkembang,
              berkembangBaik: ConcentrationBerkembangBaik,
              sangatBerkembang: ConcentrationSangatBerkembang,
            }}
          />

          <View style={styles.section}>
            <Text style={styles.categoryTitle}>Deskripsi :</Text>
            <Text style={styles.categoryDescription}>
              Gambaran seseorang memiliki kemampuan dalam dalam berkonsentrasi
              dan hal-hal yang mempengaruhi konsentrasi dalam belajar dan
              menghindari gangguan-gangguan saat belajar.
            </Text>

            <View style={styles.categoryContainer} wrap={false}>
              <View style={styles.conclusionSection}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Mulai Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan kemampuan yang mulai berkembang dalam
                    berkonsentrasi dan hal-hal yang mempengaruhi konsentrasi
                    dalam belajar dan menghindari gangguan- gangguan saat
                    belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {concentrationPercentages.mulaiBerkembang}%
                  </Text>
                </View>
              </View>

              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sedang Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan kemampuan yang sedang berkembang dalam
                    berkonsentrasi dan hal- hal yang mempengaruhi konsentrasi
                    dalam belajar dan menghindari gangguan- gangguan saat
                    belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {concentrationPercentages.sedangBerkembang}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>
                    Berkembang Sesuai Harapan
                  </Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan kemampuan yang berkembang sesuai harapandalam
                    berkonsentrasi dan hal-hal yang mempengaruhi konsentrasi
                    dalam belajar dan menghindari gangguan-gangguan saat
                    belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {concentrationPercentages.berkembangBaik}%
                  </Text>
                </View>
              </View>
              <View style={styles.conclusionSection} wrap={false}>
                <View style={styles.conclusionLeft}>
                  <Text style={styles.categoryTitle}>Sangat Berkembang</Text>
                  <Text style={styles.categoryDescription}>
                    Menunjukan kemampuan yang sangat berkembang dalam
                    berkonsentrasi dan hal- hal yang mempengaruhi konsentrasi
                    dalam belajar dan menghindari gangguan- gangguan saat
                    belajar.
                  </Text>
                </View>
                <View style={styles.conclusionRight}>
                  <Text style={styles.categoryPercentage}>
                    {concentrationPercentages.sangatBerkembang}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ResultPageKebiasaanBelajar;
