// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import validateAssessment from "./assessment";
import validateModule from "./module";
import validateParticipantGroup from "./participant_group";
import validateProfile from "./profile";
import validateQuestion from "./question";
import validateTag from "./tag";
import { IWorksheet } from "../models/worksheet";

export const pattern: IPattern = {
  description: {
    minLength: 2,
    maxLength: 100,
    regex: /^[\w\s&,.():\-'"/]{2,100}$/,
  },
  name: {
    minLength: 2,
    maxLength: 50,
    regex: /^[\w\s&,.():\-'"/]{2,50}$/,
  },
};

const validateWorksheet = (
  worksheet: IWorksheet,
  propsToValidate = ["name"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { module, participantGroup, profile, questionnaires } = worksheet;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["module"] = validateModule(module);
  extraConditions["participantGroup"] =
    validateParticipantGroup(participantGroup);
  extraConditions["profile"] = validateProfile(profile);
  extraConditions["questionnaires"] = questionnaires.reduce(
    (isValid, questionnaires) => {
      const { assessment, groups } = questionnaires;

      return (
        isValid &&
        validateAssessment(assessment) &&
        groups.reduce((isValid, group) => {
          const { tag, questions } = group;

          return (
            isValid &&
            validateTag(tag) &&
            questions.reduce(
              (isValid, question) => isValid && validateQuestion(question),
              true
            )
          );
        }, true)
      );
    },
    true
  );

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(
    worksheet,
    pattern,
    propsToValidate,
    extraCondition
  );

  return isValid;
};

export default validateWorksheet;
