// ---------------------------------------------- modules import
import { Image, Page, StyleSheet } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgCover from "../../../../assets/images/disclaimer-page.png";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center", // Center content vertically
    padding: 40,
    position: "relative", // Tambahkan style position: relative
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
});

// ---------------------------------------------- the component
const DisclaimerPage: FunctionComponent = () => (
  <Page size="A4" style={styles.page}>
    <Image src={BgCover} style={styles.backgroundImage} />
  </Page>
);

export default DisclaimerPage;
