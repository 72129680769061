// ---------------------------------------------- modules import
import { useNavigate } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";
import { IParticipantGroup } from "../../../../models/participant_group";

// ---------------------------------------------- the hooks
export const useParticipantGroupRow = (
  participantGroup: IParticipantGroup,
  onChangeParticipantGroup: (participantGroup: IParticipantGroup) => void
) => {
  // ---------------------------------------------- router state
  let navigate = useNavigate();

  // ---------------------------------------------- handlers
  const handleClick = () => {
    onChangeParticipantGroup(participantGroup);

    navigate(`${ROUTES.REPORT}${ROUTES.MODULE}`, {
      replace: true,
    });
  };

  // ---------------------------------------------- return value
  return { onClick: handleClick };
};
