// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsAdversityQuotientProps } from "./common";
import { IConclusion_AdversityQuotient } from "../conclusion/adversity_quotient/conclusion";

// ---------------------------------------------- the component
const DetailsAdversityQuotient: FunctionComponent<
  IDetailsAdversityQuotientProps
> = ({ questionnaire }) => {
  const { assessment, result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_AdversityQuotient;

  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="col-span-1 bg-gray-700 p-4 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
            Attributes
          </h3>
          <ul className="space-y-2">
            {result.attributes.map((attribute) => (
              <li
                key={attribute.id}
                className="flex items-center justify-between">
                <span className="font-semibold text-gray-300">
                  {attribute.description}
                </span>
                <span className="text-yellow-400 font-bold">
                  {attribute.value}
                </span>
              </li>
            ))}
          </ul>
          <div className="mt-4">
            <h3 className="font-light text-gray-300">Category</h3>
            <p className="font-semibold text-2xl md:text-5xl text-yellow-400">
              {conclusion.category}
            </p>
          </div>
        </div>

        <div className="lg:col-span-3 grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-gray-700 p-4 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Descriptions
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.descriptions.map((description, index) => (
                <li key={index}>{description}</li>
              ))}
            </ul>

            <h3 className="text-2xl font-semibold text-yellow-400 mb-4 mt-4">
              Cons
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.cons.map((contra, index) => (
                <li key={index}>{contra}</li>
              ))}
            </ul>
          </div>

          <div className="bg-gray-700 p-4 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Characteristics
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              {conclusion.characteristics.map((characteristic, index) => (
                <li key={index}>{characteristic}</li>
              ))}
            </ul>
          </div>

          <div className="bg-gray-700 p-4 rounded-lg shadow-md lg:col-span-2">
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
              Suggestion
            </h3>
            <p className="text-gray-300 space-y-2">
              <b>{conclusion.suggestions[0]}</b> {conclusion.suggestions[1]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsAdversityQuotient;
