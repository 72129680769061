// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  ISettingsContextProviderState,
  ISettingsContextProviderProps,
} from "./common";
// import { useSettingsContext } from "./hooks";

// ---------------------------------------------- create context
const initialState: ISettingsContextProviderState = {};

export const SettingsContext = createContext(initialState);

// ---------------------------------------------- the component
const SettingsContextProvider: FunctionComponent<
  ISettingsContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  // const {} = useSettingsContext();

  // ---------------------------------------------- content
  return (
    <SettingsContext.Provider value={{}}>{children}</SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
