// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsKondisiKeluargaV2Props } from "./common";
import { IConclusion_KondisiKeluargaV2 } from "../conclusion/kondisi_keluarga_v2/conclusion";

// ---------------------------------------------- the component
const DetailsKondisiKeluargaV2: FunctionComponent<
  IDetailsKondisiKeluargaV2Props
> = ({ questionnaire }) => {
  const { assessment, result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_KondisiKeluargaV2[];

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {conclusion.map((c, index) => {
          const { description, name, value } = c;

          return (
            <div
              key={index}
              className="bg-gray-700 p-4 rounded-lg shadow-md mb-4"
            >
              <h3 className="text-2xl font-semibold text-yellow-400 mb-4">
                {name}
              </h3>
              <div className="flex items-center">
                <div className="text-center p-4 bg-gray-800 text-yellow-400 font-bold rounded-lg mr-4">
                  {(value * 100).toFixed(0)}%
                </div>
                <div className="text-gray-300">{description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailsKondisiKeluargaV2;
