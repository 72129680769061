// ---------------------------------------------- modules import
import { useContext } from "react";

import { QuestionContext } from "../../../../contexts/question/context";

// ---------------------------------------------- the hooks
export const useQuestionList = () => {
  // ---------------------------------------------- consume context
  const { questions, error, fetching } = useContext(QuestionContext);

  // ---------------------------------------------- content
  return {
    error,
    fetching,
    questions,
  };
};
