// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IDetailsColouredProgressiveMatricesProps } from "./common";
import { IConclusion_ColouredProgressiveMatrices } from "../conclusion/coloured_progressive_matrices/conclusion";

// ---------------------------------------------- the component
const DetailsColouredProgressiveMatrices: FunctionComponent<
  IDetailsColouredProgressiveMatricesProps
> = ({ questionnaire }) => {
  const { assessment, result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_ColouredProgressiveMatrices;

  return (
    <div className="bg-gray-800 p-6 shadow-lg rounded-lg my-6">
      <h2 className="text-3xl font-bold mb-6 text-yellow-400">
        {assessment.name}
      </h2>

      <div className="mb-6">
        <h3 className="text-2xl font-semibold mb-4 text-yellow-400">
          Hasil Tes
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-700 p-4 rounded-lg shadow-md">
            <p className="font-semibold text-lg text-white">Skor Total</p>
            <p className="font-bold text-2xl text-yellow-400">
              {conclusion.scoreCategory}
            </p>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg shadow-md">
            <p className="font-semibold text-lg text-white">Kategori Hasil</p>
            <p className="font-bold text-2xl text-yellow-400">
              {conclusion.category}
            </p>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-2xl font-semibold mb-4 text-yellow-400">Skills</h3>
        {conclusion.skills.map((skill, index) => (
          <div key={index} className="mb-4">
            <h4 className="font-semibold text-lg text-white">{skill.name}</h4>
            <p className="text-gray-300">{skill.description}</p>
          </div>
        ))}
      </div>

      <div className="mb-6">
        <h3 className="text-2xl font-semibold mb-4 text-yellow-400">
          Tabel Norma
        </h3>
        <table className="w-full text-sm text-left text-gray-300">
          <thead className="text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Kategori
              </th>
              <th scope="col" className="py-3 px-6">
                Batas Bawah
              </th>
              <th scope="col" className="py-3 px-6">
                Batas Atas
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-800">
            {conclusion.normsTable.map((norm, index) => (
              <tr key={index} className="border-b border-gray-700">
                <td className="py-4 px-6">{norm.category}</td>
                <td className="py-4 px-6">{norm.lowerLimit || "-"}</td>
                <td className="py-4 px-6">{norm.upperLimit || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-6">
        <h3 className="text-2xl font-semibold mb-4 text-yellow-400">
          Saran Pengembangan
        </h3>
        {conclusion.suggestedGrowth.map((suggestion, index) => (
          <p key={index} className="mb-2 text-gray-300">
            {suggestion}
          </p>
        ))}
      </div>
    </div>
  );
};

export default DetailsColouredProgressiveMatrices;
