// ---------------------------------------------- modules import
import { useUnauthorized } from "./hooks/use_unauthorized";

// ---------------------------------------------- the component
const Unauthorized = () => {
  // ---------------------------------------------- local state
  const { location } = useUnauthorized();

  // ---------------------------------------------- content
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <h2>You are unauthorized to access</h2>
      &nbsp; : &nbsp;
      <code>{location.pathname}</code>
    </div>
  );
};

export default Unauthorized;
