// ---------------------------------------------- modules import
import { IPattern, reduceExtraConditions, validateData } from "./common";
import { ITag } from "../models/tag";

export const pattern: IPattern = {
  name: {
    minLength: 2,
    maxLength: 50,
    regex: /^[\w\s&,.():\-'/]{2,50}$/,
  },
};

const validateTag = (
  tag: ITag,
  propsToValidate = ["name"],
  extraConditionKeysToExclude: string[] = []
) => {
  const { subset } = tag;

  // ---------------------------------------------- extra conditions
  const extraConditions: { [key: string]: boolean } = {};

  extraConditions["subset"] = subset >= 0;

  /**
   * exclude specified keys from `extraConditions`
   */
  extraConditionKeysToExclude.forEach((key) => {
    delete extraConditions[key];
  });

  const extraCondition = reduceExtraConditions(extraConditions);

  // ---------------------------------------------- validate data
  const isValid = validateData(tag, pattern, propsToValidate, extraCondition);

  return isValid;
};

export default validateTag;
