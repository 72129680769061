// ---------------------------------------------- document interfaces
/**
 * Represents the data fields of a tag document in Firestore.
 *
 * Subcollections of each document:
 * - Questions
 */
export interface ITagRecord {
  dateCreated: string;
  lastModified: string;
  name: string;
  subset: number;
}

/**
 * Represents a tag returned to the frontend from Firestore.
 *
 * Extends the ITagRecord interface.
 */
export interface ITag extends ITagRecord {
  id: string;
  [key: string]: any;
}

// ---------------------------------------------- empty document
export const emptyTagRecord = (): ITagRecord => ({
  dateCreated: "",
  lastModified: "",
  name: "",
  subset: 0,
});

export const emptyTag = (): ITag => ({
  ...emptyTagRecord(),
  id: "",
});
