// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

// ---------------------------------------------- the component
const Footer: FunctionComponent = () => (
  <footer className="text-center py-2 text-sm  block sm:text-center text-gray-400 w-full max-w-screen-xl mx-auto md:py-8">
    &copy; {new Date().getFullYear()} AKTARA. Hak Cipta Dilindungi.
  </footer>
);

export default Footer;
