// ---------------------------------------------- modules import
import { useContext } from "react";

import { SessionContext } from "../../../contexts/session/context";

// ---------------------------------------------- the hooks
export const usePrivateRoute = () => {
  // ---------------------------------------------- consume context
  const { fetching, profile } = useContext(SessionContext);

  // ---------------------------------------------- return value
  return { fetching, profile };
};
