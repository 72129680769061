// ---------------------------------------------- modules import
import {
  faSortUp,
  faPlusCircle,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import ParticipantGroupRow from "./participant_group_row";
import SearchForm from "./search_form";
import LinkButton from "../../../components/ui/buttons/link_button";

import * as ROUTES from "../../../constants/routes";

import { IParticipantGroupTableProps } from "./common";
import { useParticipantGroupTable } from "./hooks/use_participant_group_table";

// ---------------------------------------------- the component
const ParticipantGroupTable: FunctionComponent<IParticipantGroupTableProps> = ({
  isAuthorizedToWrite,
}) => {
  // ---------------------------------------------- local state
  const {
    criteria,
    error,
    fetching,
    filtered,
    participantGroups,
    sortBy,
    onChangeParticipantGroup,
    onFilter,
    onSort,
  } = useParticipantGroupTable();

  // ---------------------------------------------- content
  return (
    <div className="flex flex-col h-screen p-8 md:m-8">
      <div className="flex items-center justify-between mb-4">
        <div className="py-4 px-6">
          <h1 className="md:text-3xl text-xl font-bold text-gray-100">
            PARTICIPANT GROUP
          </h1>
        </div>

        {isAuthorizedToWrite && (
          <div>
            <LinkButton
              className="whitespace-nowrap p-2 md:p-3 items-center justify-center w-10 h-10 text-white rounded-lg bg-gray-800 border-gray-700 hover:bg-gray-600"
              to={`${ROUTES.PARTICIPANT_GROUP}/add-participant-group`}
              valid
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-base md:text-lg"
              />

              <span className="text-sm md:text-base font-bold mx-2">
                New Group
              </span>
            </LinkButton>
          </div>
        )}
      </div>

      <SearchForm
        criteria={criteria}
        error={error}
        fetching={fetching}
        result={{
          filtered: filtered.length,
          total: participantGroups.length,
        }}
        onFilter={onFilter}
      />

      <section className="shadow-lg overflow-x-auto max-h-screen">
        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <div className="shadow-lg overflow-x-auto h-[calc(100vh-256px)]">
            <table className="w-full text-sm text-left text-gray-400">
              <thead className="text-xs uppercase bg-gray-500 text-gray-800 sticky top-0 z-10">
                <tr>
                  {[
                    {
                      prop: "name",
                      label: "Name",
                      className: "px-6 py-3",
                    },
                    {
                      prop: "province",
                      label: "Province",
                      className: "px-6 py-3 hidden md:table-cell",
                    },
                    {
                      prop: "regency",
                      label: "Regency",
                      className: "px-6 py-3",
                    },
                    {
                      prop: "lastModified",
                      label: "Last modified",
                      className: "px-6 py-3 hidden md:table-cell",
                    },
                  ].map((header, index) => {
                    const { prop, label, className } = header;

                    return (
                      <th
                        className={className}
                        key={index}
                        style={{ cursor: prop ? "pointer" : "default" }}
                        onClick={() => {
                          if (!prop) return;

                          onSort(prop);
                        }}
                      >
                        <div className="flex items-center ">
                          {label}

                          {sortBy.prop === header.prop && (
                            <FontAwesomeIcon
                              icon={sortBy.asc > 0 ? faSortDown : faSortUp}
                              className="ml-1"
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className="overflow-x-auto max-h-96">
                {!fetching
                  ? filtered.map((participantGroup) => (
                      <ParticipantGroupRow
                        key={participantGroup.id}
                        participantGroup={participantGroup}
                        onChangeParticipantGroup={onChangeParticipantGroup}
                      />
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ParticipantGroupTable;
