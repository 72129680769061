// ---------------------------------------------- modules import
import {
  Page,
  StyleSheet,
  Text,
  View,
  Image,
  Svg,
  Path,
} from "@react-pdf/renderer";
import { Fragment, FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-gayabelajar.png";

import { IResultPageGayaBelajarSdKelas1Sampai3Props } from "./common";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";
import { AttributeIdEnum_GayaBelajarSdKelas1Sampai3 } from "../../../../enums/attribute_id";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  section: {
    marginBottom: 4,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  heading: {
    fontSize: 12,
    margin: 5,
    fontFamily: "Helvetica-Bold",
    fontStyle: "bold",
  },
  description: {
    textAlign: "justify",
    fontSize: 11,
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  table: {
    display: "flex",
    width: "80%",
    borderColor: "#000000",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 60,
    textAlign: "center",
  },
  tableCell: {
    fontSize: 8,
    padding: 5,
    width: 60,
    textAlign: "center",
  },
  tableHeaderCellName: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 130,
  },
  tableCellName: {
    fontSize: 9,
    padding: 5,
    width: 130,
  },
  tableHeaderCellResult: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 50,
    textAlign: "center",
  },
  tableCellResult: {
    fontSize: 9,
    padding: 5,
    width: 50,
    textAlign: "center",
  },
  tableHeaderCellNo: {
    backgroundColor: "#4472C4",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  tableCellNo: {
    fontSize: 9,
    padding: 5,
    width: 25,
    textAlign: "center",
  },
  evenRow: {
    backgroundColor: "#D9E1F2",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  credit: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    width: 100,
    justifyContent: "center",
  },
  pieChartContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    marginBottom: 10,
  },
  pieChart: {
    width: 150,
    height: 150,
    alignSelf: "center",
  },
  chartLabel: {
    position: "absolute",
    fontSize: 12,
    textAlign: "center",
    width: "100%",
    color: "black",
    fontFamily: "Helvetica-Bold",
  },
  legend: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  legendColor: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  legendText: {
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    marginTop: 5,
    fontFamily: "Helvetica-Bold",
  },
  subtitle: {
    fontSize: 12,
    margin: 3,
    color: "black",
    textAlign: "justify",
  },
  text: {
    fontSize: 11,
    textAlign: "justify",
  },
  list: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 11,
  },
  listItem: {
    fontSize: 12,
  },
  bulletText: {
    fontSize: 11,
    marginRight: 5,
    textAlign: "justify",
  },
  bulletPointList: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 5,
  },
  textList: {
    fontSize: 11,
  },
});

// Fungsi untuk menghitung path dari doughnut chart
const describeArc = (
  x: number,
  y: number,
  outerRadius: number,
  innerRadius: number,
  startAngle: number,
  endAngle: number
) => {
  const startOuter = polarToCartesian(x, y, outerRadius, endAngle);
  const endOuter = polarToCartesian(x, y, outerRadius, startAngle);
  const startInner = polarToCartesian(x, y, innerRadius, endAngle);
  const endInner = polarToCartesian(x, y, innerRadius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  const d = [
    "M",
    startOuter.x,
    startOuter.y,
    "A",
    outerRadius,
    outerRadius,
    0,
    largeArcFlag,
    0,
    endOuter.x,
    endOuter.y,
    "L",
    endInner.x,
    endInner.y,
    "A",
    innerRadius,
    innerRadius,
    0,
    largeArcFlag,
    1,
    startInner.x,
    startInner.y,
    "Z",
  ].join(" ");

  return d;
};

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

// Fungsi untuk menghitung posisi tengah dari arc untuk label
const labelPosition = (
  x: number,
  y: number,
  innerRadius: number,
  outerRadius: number,
  startAngle: number,
  endAngle: number
) => {
  const middleAngle = (startAngle + endAngle) / 2;
  const labelRadius = (innerRadius + outerRadius) / 2; // Posisi label di tengah antara inner dan outer radius
  const position = polarToCartesian(x, y, labelRadius, middleAngle);
  position.x -= 17; // Menggeser label ke kiri
  return position;
};

// ---------------------------------------------- the component
const ResultPageGayaBelajarSdKelas1Sampai3: FunctionComponent<
  IResultPageGayaBelajarSdKelas1Sampai3Props
> = ({ worksheets }) => {
  // Menghitung total persentase
  const totalVisual = worksheets.reduce((acc, worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id === AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3
    );
    if (!questionnaire) return acc;
    const visualAttr = questionnaire.result.attributes.find(
      (attr) => attr.id === AttributeIdEnum_GayaBelajarSdKelas1Sampai3.VISUAL
    );
    return acc + (visualAttr ? visualAttr.value * 100 : 0);
  }, 0);

  const totalAuditory = worksheets.reduce((acc, worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id === AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3
    );
    if (!questionnaire) return acc;
    const auditoryAttr = questionnaire.result.attributes.find(
      (attr) => attr.id === AttributeIdEnum_GayaBelajarSdKelas1Sampai3.AUDITORY
    );
    return acc + (auditoryAttr ? auditoryAttr.value * 100 : 0);
  }, 0);

  const totalKinesthetic = worksheets.reduce((acc, worksheet) => {
    const questionnaire = worksheet.questionnaires.find(
      (q) =>
        q.assessment.id === AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3
    );
    if (!questionnaire) return acc;
    const kinestheticAttr = questionnaire.result.attributes.find(
      (attr) =>
        attr.id === AttributeIdEnum_GayaBelajarSdKelas1Sampai3.KINESTHETIC
    );
    return acc + (kinestheticAttr ? kinestheticAttr.value * 100 : 0);
  }, 0);

  const totalWorksheets = worksheets.length;
  const percentageVisual = (totalVisual / totalWorksheets).toFixed(2);
  const percentageAuditory = (totalAuditory / totalWorksheets).toFixed(2);
  const percentageKinesthetic = (totalKinesthetic / totalWorksheets).toFixed(2);

  const data = [
    { name: "Visual", value: parseFloat(percentageVisual), color: "#8A2BE2" },
    {
      name: "Auditory",
      value: parseFloat(percentageAuditory),
      color: "#4682B4",
    },
    {
      name: "Kinesthetic",
      value: parseFloat(percentageKinesthetic),
      color: "#D2691E",
    },
  ];

  let cumulativePercent = 0;

  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={styles.section}></View>
      <Text style={styles.heading}>Grafik Distribusi Frekuensi</Text>
      <View style={styles.pieChartContainer}>
        <Svg height="160" width="160" style={styles.pieChart}>
          {data.map((slice, index) => {
            const [startAngle, endAngle] = [
              Math.round(cumulativePercent * 360),
              Math.round((cumulativePercent + slice.value / 100) * 360),
            ];
            cumulativePercent += slice.value / 100;
            const { x, y } = labelPosition(
              80,
              80,
              30,
              70,
              startAngle,
              endAngle
            );
            return (
              <Fragment key={index}>
                <Path
                  d={describeArc(80, 80, 70, 30, startAngle, endAngle)}
                  fill={slice.color}
                />
                <Text x={x} y={y} style={styles.chartLabel}>
                  {`${Math.round(slice.value)}%`}
                </Text>
              </Fragment>
            );
          })}
        </Svg>
        <View style={styles.legend}>
          {data.map((slice, index) => (
            <View key={index} style={styles.legendItem}>
              <View
                style={[styles.legendColor, { backgroundColor: slice.color }]}
              />
              <Text style={styles.legendText}>
                {slice.name} - {`${Math.round(slice.value)}%`}
              </Text>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Tipe Gaya Belajar</Text>
        <Text style={styles.heading}>Visual</Text>
        <Text style={styles.description}>
          Gaya belajar visual mampu menyerap informasi terkait dengan visual,
          warna, gambar, peta, diagram dan belajar dari apa yang dilihat oleh
          mata. Memiliki memori kuat dari apa yang dilihat daripada yang
          didengar, lebih tertarik membaca dari pada dibacakan, pembaca cepat
          dan tekun, dan memiliki ketertarikan kepada seni.
        </Text>
        <Text style={styles.heading}>Ciri-ciri Gaya Belajar</Text>
        <View>
          <Text style={styles.bulletText}>
            • Mengingat apa yang dilihat, daripada yang didengar.
          </Text>
          <Text style={styles.bulletText}>
            • Suka mencoret-coret sesuatu, yang terkadang tanpa ada artinya saat
            di dalam kelas.
          </Text>
          <Text style={styles.bulletText}>• Pembaca cepat dan tekun.</Text>
          <Text style={styles.bulletText}>
            • Lebih suka membaca dari pada dibacakan.
          </Text>
          <Text style={styles.bulletText}>• Rapi dan teratur.</Text>
          <Text style={styles.bulletText}>
            • Mementingkan penampilan, dalam hal pakaian ataupun penampilan
            keseluruhan.
          </Text>
          <Text style={styles.bulletText}>• Teliti terhadap detail.</Text>
          <Text style={styles.bulletText}>
            • Pengeja yang baik. Lebih memahami gambar dan bagan daripada
            instruksi tertulis.
          </Text>
        </View>
        <Text style={styles.heading}>
          Strategi Belajar Yang Bisa Diterapkan
        </Text>
        <View>
          <Text style={styles.bulletText}>
            • Ikut berpartisipasi dalam diskusi baik di dalam kelas maupun di
            dalam keluarga.
          </Text>
          <Text style={styles.bulletText}>
            • Baca materi pelajaran dengan keras.
          </Text>
          <Text style={styles.bulletText}>• Gunakan musik sambil belajar.</Text>
          <Text style={styles.bulletText}>
            • Diskusikan ide dengan guru secara verbal.
          </Text>
          <Text style={styles.bulletText}>• Rapi dan teratur.</Text>
          <Text style={styles.bulletText}>
            • Biarkan anak merekam materi pelajarannya ke dalam kaset dan dorong
            dia untuk mendengarkannya sebelum tidur.
          </Text>
        </View>
        <Text style={styles.heading}>Kinesthetic</Text>
        <Text style={styles.description}>
          Memiliki rasa ingin tahu yang tinggi terhadap cara kerja suatu benda;
          ingin mengetahui alasan untuk memahami hubungan sebab-akibat berbagai
          hal; merasa perlu membedakan antara fakta dan opini; menyukai
          permainan yang mengandalkan olah pikir dan pemecahan masalah;
          mengurutkan sesuatu berdasar kategori tertentu, ataupun melibatkan
          angka.
        </Text>
        <Text style={styles.heading}>Ciri-ciri Gaya Belajar</Text>
        <View>
          <Text style={styles.bulletText}>
            • Saat belajar suka bicara kepada diri sendiri.
          </Text>
          <Text style={styles.bulletText}>
            • Mudah terganggu oleh keributan.
          </Text>
          <Text style={styles.bulletText}>
            • Belajar dengan mendengarkan dan mengingat apa yang didiskusikan
            dari pada yang dilihat.
          </Text>
          <Text style={styles.bulletText}>
            • Senang membaca dengan keras dan mendengarkan.
          </Text>
          <Text style={styles.bulletText}>
            • Menggerakkan bibir mereka dan mengucapkan tulisan di buku ketika
            membaca.
          </Text>
          <Text style={styles.bulletText}>
            • Biasanya ia pembicara yang fasih.
          </Text>
          <Text style={styles.bulletText}>
            • Lebih pandai mengeja dengan keras daripada menuliskannya.
          </Text>
          <Text style={styles.bulletText}>
            • Lebih suka gurauan lisan daripada membaca komik.
          </Text>
          <Text style={styles.bulletText}>
            • Mempunyai masalah dengan pekerjaan-pekerjaan yang melibatkan
            Visual.
          </Text>
          <Text style={styles.bulletText}>
            • Dapat mengulangi kembali dan menirukan nada, berirama dan warna
            suara.
          </Text>
        </View>
        <Text style={styles.heading}>
          Strategi Belajar yang Bisa Diterapkan
        </Text>
        <View>
          <Text style={styles.bulletText}>
            • Ikut berpartisipasi dalam diskusi baik di dalam kelas maupun di
            dalam keluarga.
          </Text>
          <Text style={styles.bulletText}>
            • Baca materi pelajaran dengan keras.
          </Text>
          <Text style={styles.bulletText}>• Gunakan musik sambil belajar.</Text>
          <Text style={styles.bulletText}>
            • Diskusikan ide dengan guru secara verbal.
          </Text>
          <Text style={styles.bulletText}>
            • Biarkan anak merekam materi pelajarannya ke dalam kaset dan dorong
            dia untuk mendengarkannya sebelum tidur.
          </Text>
        </View>
        <Text style={styles.heading}>Auditorial</Text>
        <Text style={styles.description}>
          Auditorial Gaya belajar auditori adalah gaya belajar dengan cara
          mendengar, yang memberikan penekanan pada segala jenis bunyi dan kata,
          baik yang diciptakan maupun yang diingat. Mudah mengingat sesuatu dari
          apa yang didengar daripada yang dilihat, mudah terdistraksi dengan
          keramaian, senang membaca dengan mengeluarkan suara, dan mudah dalam
          mengingat nama saat berkenalan dengan orang baru. Gaya belajar
          auditori lebih cepat menyerap informasi melalui apa yang ia dengarkan.
          Ciri-ciri Gaya Belajar Saat belajar suka bicara kepada diri sendiri
          Mudah terganggu oleh keributan Belajar dengan mendengarkan dan
          mengingat apa yang didiskusikan dari pada yang dilihat Senang membaca
          dengan keras dan mendengarkan Menggerakkan bibir mereka dan
          mengucapkan tulisan di buku ketika membaca Biasanya ia pembicara yang
          fasih Lebih pandai mengeja dengan keras daripada menuliskannya Lebih
          suka gurauan lisan daripada membaca komik Mempunyai masalah dengan
          pekerjaan-pekerjaan yang melibatkan Visual Dapat mengulangi kembali
          dan menirukan nada, berirama dan warna suara Strategi Belajar yang
          Bisa Diterapkan Jangan paksakan untuk belajar sampai berjam-jam.
          Belajar sambil mengeksplorasi lingkungannya (contohnya: gunakan obyek
          sesungguhnya untuk belajar konsep baru). Gunakan warna terang untuk
          menghilite/menandai hal-hal penting dalam bacaan. Belajar sambil
          mendengarkan musik.
        </Text>
        <Text style={styles.heading}>Ciri-ciri Gaya Belajar</Text>
        <View>
          <Text style={styles.bulletText}>
            • Saat belajar suka bicara kepada diri sendiri
          </Text>
          <Text style={styles.bulletText}>
            • Mudah terganggu oleh keributan
          </Text>
          <Text style={styles.bulletText}>
            • Belajar dengan mendengarkan dan mengingat apa yang didiskusikan
            dari pada yang dilihat
          </Text>
          <Text style={styles.bulletText}>
            • Senang membaca dengan keras dan mendengarkan
          </Text>
          <Text style={styles.bulletText}>
            • Menggerakkan bibir mereka dan mengucapkan tulisan di buku ketika
            membaca
          </Text>
          <Text style={styles.bulletText}>
            • Biasanya ia pembicara yang fasih
          </Text>
          <Text style={styles.bulletText}>
            • Lebih pandai mengeja dengan keras daripada menuliskannya
          </Text>
          <Text style={styles.bulletText}>
            • Lebih suka gurauan lisan daripada membaca komik
          </Text>
          <Text style={styles.bulletText}>
            • Mempunyai masalah dengan pekerjaan-pekerjaan yang melibatkan
            Visual
          </Text>
          <Text style={styles.bulletText}>
            • Dapat mengulangi kembali dan menirukan nada, berirama dan warna
            suara
          </Text>
        </View>
        <Text style={styles.heading}>
          Strategi Belajar Yang Bisa Diterapkan
        </Text>
        <View>
          <Text style={styles.bulletText}>
            • Jangan paksakan untuk belajar sampai berjam-jam.
          </Text>
          <Text style={styles.bulletText}>
            • Belajar sambil mengeksplorasi lingkungannya (contohnya: gunakan
            obyek sesungguhnya untuk belajar konsep baru).
          </Text>
          <Text style={styles.bulletText}>
            • Gunakan warna terang untuk menghilite/menandai hal-hal penting
            dalam bacaan.
          </Text>
          <Text style={styles.bulletText}>
            • Belajar sambil mendengarkan musik.
          </Text>
        </View>
      </View>
      <View style={styles.section} break>
        <Text style={styles.heading}>Tabel Data Peserta</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableHeaderCellNo}>No</Text>
            <Text style={styles.tableHeaderCellName}>Nama</Text>
            <Text style={styles.tableHeaderCell}>Visual</Text>
            <Text style={styles.tableHeaderCell}>Auditorial</Text>
            <Text style={styles.tableHeaderCell}>Kinestetik</Text>
            <Text style={styles.tableHeaderCellResult}>Hasil</Text>
          </View>
          {worksheets.map((worksheet, index) => {
            const questionnaire = worksheet.questionnaires.find(
              (q) => q.assessment.id === AssessmentIdEnum.GAYA_BELAJAR
            );
            if (!questionnaire) return null;
            const visualAttr = questionnaire.result.attributes.find(
              (attr) =>
                attr.id === AttributeIdEnum_GayaBelajarSdKelas1Sampai3.VISUAL
            );
            const auditoryAttr = questionnaire.result.attributes.find(
              (attr) =>
                attr.id === AttributeIdEnum_GayaBelajarSdKelas1Sampai3.AUDITORY
            );
            const kinestheticAttr = questionnaire.result.attributes.find(
              (attr) =>
                attr.id ===
                AttributeIdEnum_GayaBelajarSdKelas1Sampai3.KINESTHETIC
            );
            const hasil = Math.max(
              visualAttr ? visualAttr.value : 0,
              auditoryAttr ? auditoryAttr.value : 0,
              kinestheticAttr ? kinestheticAttr.value : 0
            );
            let hasilText = "";
            if (hasil === (visualAttr ? visualAttr.value : 0)) {
              hasilText = "Visual";
            } else if (hasil === (auditoryAttr ? auditoryAttr.value : 0)) {
              hasilText = "Auditorial";
            } else if (
              hasil === (kinestheticAttr ? kinestheticAttr.value : 0)
            ) {
              hasilText = "Kinestetik";
            }
            return (
              <View
                key={index}
                style={[
                  styles.tableRow,
                  index % 2 === 0 ? styles.evenRow : styles.oddRow,
                ]}
                wrap={false}>
                <Text style={styles.tableCellNo}>{index + 1}</Text>
                <Text style={styles.tableCellName}>
                  {worksheet.profile.name}
                </Text>
                <Text style={styles.tableCell}>
                  {visualAttr ? (visualAttr.value * 100).toFixed(2) : "0.00"}
                </Text>
                <Text style={styles.tableCell}>
                  {auditoryAttr
                    ? (auditoryAttr.value * 100).toFixed(2)
                    : "0.00"}
                </Text>
                <Text style={styles.tableCell}>
                  {kinestheticAttr
                    ? (kinestheticAttr.value * 100).toFixed(2)
                    : "0.00"}
                </Text>
                <Text style={styles.tableCellResult}>{hasilText}</Text>
              </View>
            );
          })}
        </View>
      </View>
    </Page>
  );
};

export default ResultPageGayaBelajarSdKelas1Sampai3;
