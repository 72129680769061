// ---------------------------------------------- modules import
import axios from "axios";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import voca from "voca";

import { ModuleContext } from "../../../../contexts/module/context";
import { SessionContext } from "../../../../contexts/session/context";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import * as ROUTES from "../../../../constants/routes";
import { newUpdateModuleDto } from "../../../../dtos/module";
import {
  EducationSubLevelEnum,
  EducationLevelEnum,
} from "../../../../enums/education_level";
import { emptyModule, IModule } from "../../../../models/module";
import validateModule from "../../../../validations/module";

// ---------------------------------------------- the hooks
export const useUpdateModuleForm = () => {
  // ---------------------------------------------- router state
  const { module_id } = useParams();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const participantGroupId = queryParams.get("participant_group_id");

  let navigate = useNavigate();

  // ---------------------------------------------- consume context
  const {
    modules,
    error: errModule,
    fetching: fetchingModule,
  } = useContext(ModuleContext);
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [module, setModule] = useState<IModule>(emptyModule());

  const [educationSubLevels, setEducationSubLevels] = useState<
    EducationSubLevelEnum[]
  >([EducationSubLevelEnum.OTHERS]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [errRequest, setErrRequest] = useState<string | null>(null);

  const isModuleValid = () => validateModule(module);

  const educationLevelOptions = Object.values(EducationLevelEnum).map(
    (value) => ({
      value,
      label: value,
    })
  );

  const educationSubLevelOptions = educationSubLevels.map((value) => ({
    value,
    label: value,
  }));

  // ---------------------------------------------- handlers
  const handleChange = (prop: string, value: string) => {
    if (prop === "education.level") {
      let subLevel = EducationSubLevelEnum.OTHERS;

      switch (value) {
        case EducationLevelEnum.ELEMENTARY: {
          subLevel = EducationSubLevelEnum.I;
          break;
        }
        case EducationLevelEnum.JUNIOR_HIGH: {
          subLevel = EducationSubLevelEnum.VII;
          break;
        }
        case EducationLevelEnum.SENIOR_HIGH: {
          subLevel = EducationSubLevelEnum.X;
          break;
        }
        case EducationLevelEnum.UNIVERSITY: {
          subLevel = EducationSubLevelEnum.S1;
          break;
        }
        case EducationLevelEnum.EMPLOYEE: {
          subLevel = EducationSubLevelEnum.ENTRY_LEVEL;
          break;
        }
        default:
          break;
      }

      setModule({
        ...module,
        education: {
          ...module.education,
          level: value as EducationLevelEnum,
          subLevel,
        },
      });
    } else if (prop === "education.subLevel") {
      setModule({
        ...module,
        education: {
          ...module.education,
          subLevel: value as EducationSubLevelEnum,
        },
      });
    } else {
      setModule({
        ...module,
        [prop]: value,
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const url = `${proxy}${API_ROUTES.MODULE}`;

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setLoading(true);

        axios
          .put(
            `${url}/${module.id}?participant_group_id=${participantGroupId}`,
            newUpdateModuleDto({
              ...module,
              name: voca.trim(module.name),
            }),
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
              },
            }
          )
          .then(() => {
            setLoading(false);
            setErrRequest(null);

            navigate(ROUTES.PARTICIPANT_GROUP, { replace: true });

            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    if (modules) {
      const currentModule = modules.find((module) => module.id === module_id);

      if (currentModule) setModule({ ...emptyModule(), ...currentModule });
    }
  }, [modules, module_id]);

  useEffect(() => {
    switch (module.education.level) {
      case EducationLevelEnum.ELEMENTARY: {
        setEducationSubLevels([
          EducationSubLevelEnum.I,
          EducationSubLevelEnum.II,
          EducationSubLevelEnum.III,
          EducationSubLevelEnum.IV,
          EducationSubLevelEnum.V,
          EducationSubLevelEnum.VI,
        ]);
        break;
      }
      case EducationLevelEnum.JUNIOR_HIGH: {
        setEducationSubLevels([
          EducationSubLevelEnum.VII,
          EducationSubLevelEnum.VIII,
          EducationSubLevelEnum.IX,
        ]);
        break;
      }
      case EducationLevelEnum.SENIOR_HIGH: {
        setEducationSubLevels([
          EducationSubLevelEnum.X,
          EducationSubLevelEnum.XI,
          EducationSubLevelEnum.XII,
        ]);
        break;
      }
      case EducationLevelEnum.UNIVERSITY: {
        setEducationSubLevels([
          EducationSubLevelEnum.D3,
          EducationSubLevelEnum.S1,
          EducationSubLevelEnum.S2,
          EducationSubLevelEnum.S3,
        ]);
        break;
      }
      case EducationLevelEnum.EMPLOYEE: {
        setEducationSubLevels([
          EducationSubLevelEnum.ENTRY_LEVEL,
          EducationSubLevelEnum.MID_LEVEL,
          EducationSubLevelEnum.SENIOR_LEVEL,
          EducationSubLevelEnum.MANAGER,
          EducationSubLevelEnum.DIRECTOR,
          EducationSubLevelEnum.EXECUTIVE,
        ]);
        break;
      }
      default: {
        setEducationSubLevels([EducationSubLevelEnum.OTHERS]);
        break;
      }
    }
  }, [module]);

  useEffect(
    () => setError(errModule || errRequest || errSession),
    [errModule, errRequest, errSession]
  );

  // ---------------------------------------------- return value
  return {
    module,
    participantGroupId,
    educationLevelOptions,
    educationSubLevelOptions,
    error,
    fetching: fetchingModule || fetchingSession,
    loading,
    isModuleValid,
    onChange: handleChange,
    onSubmit: handleSubmit,
  };
};
