// ---------------------------------------------- modules import
import {
  faArrowLeft,
  faEdit,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import LinkButton from "../../../components/ui/buttons/link_button";
import SelectInput from "../../../components/ui/inputs/select_input";
import TextInput from "../../../components/ui/inputs/text_input";
import UpdateModal from "../../../components/ui/modals/update_modal";
import StatusBadge from "../../../components/ui/others/status_badge";

import * as ROUTES from "../../../constants/routes";
import { emptyModule } from "../../../models/module";
import { pattern as modulePattern } from "../../../validations/module";

import { useUpdateModuleForm } from "./hooks/use_update_module_form";

// ---------------------------------------------- the component
const UpdateModuleForm: FunctionComponent = () => {
  // ---------------------------------------------- local state
  const {
    module,
    participantGroupId,
    educationLevelOptions,
    educationSubLevelOptions,
    error,
    fetching,
    loading,
    isModuleValid,
    onChange,
    onSubmit,
  } = useUpdateModuleForm();

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <div className="max-w-2xl mx-auto">
        <form
          onSubmit={onSubmit}
          className="bg-gray-800 rounded-lg shadow-lg overflow-hidden"
        >
          <div className="p-8">
            <h1 className="text-3xl font-bold text-white mb-2 flex items-center">
              <FontAwesomeIcon icon={faEdit} className="mr-3 text-blue-400" />
              UPDATE MODULE
            </h1>
            <p className="text-gray-400 mb-6">
              Update module name, description, and education details
            </p>

            <div className="space-y-6">
              <TextInput
                className_root="mb-4"
                className_input="w-full bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                id="name"
                label="Name"
                name="name"
                pattern={modulePattern.name}
                required
                type="text"
                value={module.name}
                onChange={onChange}
              />

              <TextInput
                className_root="mb-4"
                className_input="w-full bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                id="description"
                label="Description"
                name="description"
                pattern={modulePattern.description}
                required={false}
                type="text"
                value={module.description}
                onChange={onChange}
              />

              <div className="grid md:grid-cols-2 gap-4">
                <SelectInput
                  className_root="mb-4"
                  className_select="block w-full p-2.5 bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  defaultOption={{
                    label: emptyModule().education.level,
                    value: emptyModule().education.level,
                  }}
                  fallbackValue={emptyModule().education.level}
                  id="education-level"
                  label="Education Level"
                  name="education.level"
                  options={educationLevelOptions}
                  value={module.education.level}
                  onChange={onChange}
                />

                <SelectInput
                  className_root="mb-4"
                  className_select="block w-full p-2.5 bg-gray-700 border border-gray-600 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  defaultOption={{
                    label: emptyModule().education.subLevel,
                    value: emptyModule().education.subLevel,
                  }}
                  fallbackValue={emptyModule().education.subLevel}
                  id="education-sublevel"
                  label="Education Sub Level"
                  name="education.subLevel"
                  options={educationSubLevelOptions}
                  value={module.education.subLevel}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>

          <div className="bg-gray-700 px-8 py-4 flex justify-between items-center">
            <LinkButton
              className="flex items-center text-gray-300 hover:text-white transition duration-300 ease-in-out"
              to={`${ROUTES.PARTICIPANT_GROUP}/details/${participantGroupId}`}
              valid={!fetching && !loading}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back
            </LinkButton>

            <UpdateModal
              id="participant-group-update-modal"
              valid={!fetching && !loading && isModuleValid()}
              className={`flex items-center px-4 py-2 rounded-lg transition duration-300 ease-in-out ${
                !fetching && !loading && isModuleValid()
                  ? "bg-blue-500 hover:bg-blue-600 text-white"
                  : "bg-gray-600 text-gray-400 cursor-not-allowed"
              }`}
            >
              <FontAwesomeIcon icon={faGraduationCap} className="mr-2" />
              Update Module
            </UpdateModal>
          </div>
        </form>

        <div className="mt-6 flex justify-center">
          <StatusBadge fetching={fetching} loading={loading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default UpdateModuleForm;
