export const ASSESSMENT = "/assessment";
export const CONSOLIDATION = "/consolidation";
export const DASHBOARD = "/";
export const MODULE = "/module";
export const OTHERS = "*";
export const PARTICIPANT_GROUP = "/participant-group";
export const QUIZ = "/quiz";
export const REPORT = "/report";
export const SETTINGS = "/settings";
export const SIGN_IN = "/sign-in";
export const SUBMISSION = "/submission";
export const SUBSET_TOKEN = "/subset-token";
export const WORKSHEET = "/worksheet";
