// ---------------------------------------------- modules import
import dayjs from "dayjs";
import { FunctionComponent } from "react";
import voca from "voca";

import { IWorksheetRowProps } from "./common";
import { useWorksheetRow } from "./hooks/use_worksheet_row";

// ---------------------------------------------- the component
const WorksheetRow: FunctionComponent<IWorksheetRowProps> = ({
  worksheet,
  onChangeWorksheet,
}) => {
  const { dateCreated, profile } = worksheet;
  const { name, phone, gender } = profile;

  // ---------------------------------------------- local state
  const { onClick } = useWorksheetRow(worksheet, onChangeWorksheet);

  // ---------------------------------------------- content
  // console.log("Data yang ada: ", worksheet);
  return (
    <tr
      onClick={onClick}
      style={{ cursor: "pointer" }}
      className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600 "
    >
      <td className="px-6 py-4">{voca.titleCase(name.toLowerCase())}</td>
      <td className="px-6 py-4">{gender ? gender : "N/A"}</td>
      <td className="px-6 py-4">{phone}</td>

      <td className="px-6 py-4 hidden md:block">
        {dayjs(dateCreated).locale("id").format("DD/MM/YYYY, HH:mm:ss")}
      </td>
    </tr>
  );
};

export default WorksheetRow;
