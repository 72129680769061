// ---------------------------------------------- modules import
import { FunctionComponent, createContext } from "react";

import {
  IProvinceContextProviderState,
  IProvinceContextProviderProps,
} from "./common";
import { useProvinceContext } from "./hooks";

// ---------------------------------------------- create context
const initialState: IProvinceContextProviderState = {
  provinces: [],
  error: null,
  fetching: true,
};

export const ProvinceContext = createContext(initialState);

// ---------------------------------------------- the component
const ProvinceContextProvider: FunctionComponent<
  IProvinceContextProviderProps
> = ({ children }) => {
  // ---------------------------------------------- local state
  const { provinces, error, fetching } = useProvinceContext();

  // ---------------------------------------------- content
  return (
    <ProvinceContext.Provider value={{ provinces, error, fetching }}>
      {children}
    </ProvinceContext.Provider>
  );
};

export default ProvinceContextProvider;
