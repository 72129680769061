// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-majemuk.png";

import { IResultPageKecerdasanMajemukProps } from "./common";
import { IConclusion_KecerdasanMajemuk } from "../conclusion/kecerdasan_majemuk/conclusion";
import { colorTheme } from "../../../../constants/color_theme";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  section: {
    marginBottom: 8,
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
    width: "100%",
  },
  logo: {
    justifyContent: "center",
    width: 100,
  },
  headingPage: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
    marginTop: 30,
  },
  heading: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 18,
    marginBottom: 8,
  },
  attribute: {
    flexDirection: "row",
    fontSize: 14,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  attributeName: {
    fontSize: 14,
  },
  dominantAttribute: {
    marginTop: 20,
  },
  dominantName: {
    color: colorTheme.YELLOW_LIGHT,
    fontFamily: "Helvetica-Bold",
    fontSize: 16,
  },
  description: {
    marginBottom: 4,
    fontSize: 9,
  },
  traits: {
    marginBottom: 4,
    fontSize: 9,
  },
  suggestedExtracurriculars: {
    marginBottom: 4,
    fontSize: 9,
  },
  bulletPoint: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 4,
  },
  bulletText: {
    fontSize: 9,
    marginRight: 4,
  },
  text: {
    marginBottom: 4,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    color: colorTheme.YELLOW_LIGHT,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
  },
  attributeValue: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    textAlign: "right",
  },
  credit: {
    bottom: 30,
    color: colorTheme.GRAY_DOFF,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },

  barContainer: {
    height: 15,
    backgroundColor: "#ccc",
    width: "70%", // Adjusted width to 90% for a better fit
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
  },
  bar: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 5,
  },
  barText: {
    position: "absolute",
    right: 10,
    top: 2,
    color: "white",
    fontSize: 10,
  },
});

// ---------------------------------------------- the component
const ResultPageKecerdasanMajemuk: FunctionComponent<
  IResultPageKecerdasanMajemukProps
> = ({ questionnaire }) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_KecerdasanMajemuk;

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.logoContainer}></View>

      <View style={styles.section}>
        <Text style={styles.headingPage}></Text>

        <View style={styles.attributeTable}>
          <View style={styles.attributeRow}>
            <Text style={styles.attributeHeader}>Atribut</Text>

            <Text style={styles.attributeHeaderValue}>Nilai</Text>
          </View>

          {result.attributes.map((attribute) => (
            <View key={attribute.id} style={styles.attributeRow}>
              <Text style={styles.attributeDescription}>{attribute.name}</Text>
              <View style={styles.barContainer}>
                <View
                  style={[
                    styles.bar,
                    {
                      width: `${attribute.value * 100}%`,
                      backgroundColor: attribute.color || "#4682b4",
                    },
                  ]}
                />
                <Text style={styles.barText}>
                  {(attribute.value * 100).toFixed(0)}%
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.heading}>Tipe Kecerdasan Majemuk</Text>

        <Text style={styles.dominantName}>{conclusion.name}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.description}>
          {conclusion.descriptions.join(" ")}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.heading}>Karakter Yang Kuat</Text>

        <View style={styles.traits}>
          {conclusion.traits.map((trait, index) => (
            <View key={index} style={styles.bulletPoint}>
              <Text style={styles.bulletText}>•</Text>

              <Text style={styles.text}>{trait}</Text>
            </View>
          ))}
        </View>
      </View>

      {/* <View style={styles.section}>
        <Text style={styles.heading}>Saran Ekstrakulikuler</Text>

        <View style={styles.suggestedExtracurriculars}>
          {conclusion.suggestedExtracurriculars.map(
            (extracurricular, index) => (
              <View key={index} style={styles.bulletPoint}>
                <Text style={styles.bulletText}>•</Text>

                <Text style={styles.text}>{extracurricular}</Text>
              </View>
            )
          )}
        </View>
      </View> */}
      <Image src={BgPage} style={styles.backgroundImage} fixed />
    </Page>
  );
};

export default ResultPageKecerdasanMajemuk;
