// ---------------------------------------------- modules import
import { useContext, useEffect, useState } from "react";

import { ModuleContext } from "../../../../contexts/module/context";
import { ParticipantGroupContext } from "../../../../contexts/participant_group/context";
import { SubsetTokenContext } from "../../../../contexts/subset_token/context";

import { ICriteria } from "../common";
import { IParticipantGroup } from "../../../../models/participant_group";

// ---------------------------------------------- the hooks
export const useParticipantGroupTable = () => {
  // ---------------------------------------------- consume context
  const { participantGroups, error, fetching } = useContext(
    ParticipantGroupContext
  );
  const {
    onChangeParticipantGroup: onChangeParticipantGroupFromModuleContext,
  } = useContext(ModuleContext);
  const {
    onChangeParticipantGroup: onChangeParticipantGroupFromSubsetTokenContext,
  } = useContext(SubsetTokenContext);

  // ---------------------------------------------- local state
  const [criteria, setCriteria] = useState<ICriteria>({
    keywords: "",
    prop: "name",
  });
  const [filtered, setFiltered] = useState<IParticipantGroup[]>([]);
  const [sortBy, setSortBy] = useState({
    asc: 1,
    prop: "name",
  });
  const [sorted, setSorted] = useState<IParticipantGroup[]>([]);

  // ---------------------------------------------- handlers
  const handleChangeParticipantGroup = (
    participantGroup: IParticipantGroup
  ) => {
    onChangeParticipantGroupFromModuleContext(participantGroup);
    onChangeParticipantGroupFromSubsetTokenContext(participantGroup);
  };

  const handleFilter = (criterion: string, value: string) => {
    if (/^[^()[\\\]|]{0,}$/.test(value))
      setCriteria({
        ...criteria,
        [criterion]: value,
      });
  };

  const handleSort = (prop: string) =>
    setSortBy((prev) => ({
      ...prev,
      prop,
      asc: prev.asc * -1,
    }));

  // ---------------------------------------------- effects
  useEffect(() => {
    const { prop, asc } = sortBy;

    setSorted(
      [...participantGroups].sort((a, b) =>
        prop === "province" || prop === "regency"
          ? a.location[prop].name > b.location[prop].name
            ? asc
            : asc * -1
          : a[prop] > b[prop]
          ? asc
          : asc * -1
      )
    );
  }, [sortBy, participantGroups]);

  useEffect(() => {
    const { keywords, prop } = criteria;

    setFiltered(
      sorted.filter((participantGroup) =>
        RegExp(`${keywords}`, "i").test(
          prop === "province" || prop === "regency"
            ? participantGroup.location[prop].name
            : participantGroup[prop]
        )
      )
    );
  }, [criteria, sorted]);

  // ---------------------------------------------- content
  return {
    criteria,
    error,
    fetching,
    filtered,
    participantGroups,
    sortBy,
    onChangeParticipantGroup: handleChangeParticipantGroup,
    onFilter: handleFilter,
    onSort: handleSort,
  };
};
