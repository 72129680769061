// ---------------------------------------------- modules import
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import config from "./config";

class Firebase {
  public auth: firebase.auth.Auth;
  public firestore: firebase.firestore.Firestore;
  public storage: firebase.storage.Storage;

  constructor() {
    firebase.initializeApp(config);

    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
    this.storage = firebase.storage();
  }

  public signIn = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  public signOut = () => this.auth.signOut();

  public toTimeStamp = (date: Date) =>
    firebase.firestore.Timestamp.fromDate(date);

  listAllFiles = async (path: string) => {
    const storageRef = this.storage.ref(path);
    const result = await storageRef.listAll();
    const fileUrls = [];

    for (const itemRef of result.items) {
      const url = await itemRef.getDownloadURL();
      fileUrls.push(url);
    }

    return fileUrls;
  };

  logFileUrls = async () => {
    try {
      const directories = [
        "COLOURED_PROGRESSIVE_MATRICES/A",
        "COLOURED_PROGRESSIVE_MATRICES/AB",
        "COLOURED_PROGRESSIVE_MATRICES/B",
        "GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3",
      ];

      for (const dir of directories) {
        const urls = await this.listAllFiles(dir);
        console.log(`Files in ${dir}:`, urls);
      }
    } catch (error) {
      console.error("Error listing files:", error);
    }
  };
}

export default Firebase;
