// ---------------------------------------------- modules import
import { FunctionComponent } from "react";

import { IParticipantGroupRowProps } from "./common";

// ---------------------------------------------- the component
const ParticipantGroupRow: FunctionComponent<IParticipantGroupRowProps> = ({
  participantGroup,
}) => {
  const { location, name } = participantGroup;

  // ---------------------------------------------- content
  return (
    <tr className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600">
      <td className="px-6 py-4">{name}</td>

      <td className="px-6 py-4 hidden md:table-cell">
        {location.province.name}
      </td>

      <td className="px-6 py-4">{location.regency.name}</td>
    </tr>
  );
};

export default ParticipantGroupRow;
