// ---------------------------------------------- modules import
import dayjs from "dayjs";
import { FunctionComponent } from "react";

import { ISubsetTokenRowProps } from "./common";
import { useSubsetTokenRow } from "./hooks/use_subset_token_row";

// ---------------------------------------------- the component
const SubsetTokenRow: FunctionComponent<ISubsetTokenRowProps> = ({
  subsetToken,
  onChangeSubsetToken,
}) => {
  const { id, lastModified, name, status } = subsetToken;

  // ---------------------------------------------- local state
  const { onClick } = useSubsetTokenRow(subsetToken, onChangeSubsetToken);

  // ---------------------------------------------- content
  return (
    <tr
      onClick={onClick}
      style={{ cursor: "pointer" }}
      className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600 "
    >
      <td className="px-6 py-4">{name}</td>

      <td className="px-6 py-4 hidden md:block">{id}</td>

      <td className="px-6 py-4">{`${status ? "ACTIVE" : "INACTIVE"}`}</td>

      <td className="px-6 py-4">
        {dayjs(lastModified).locale("id").format("DD/MM/YYYY, HH:mm:ss")}
      </td>
    </tr>
  );
};

export default SubsetTokenRow;
