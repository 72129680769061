// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";

import BgPage from "../../../../assets/images/bg-keluarga.png";

import { IResultPageKondisiKeluargaV2Props } from "./common";
import { IConclusion_KondisiKeluargaV2 } from "../conclusion/kondisi_keluarga_v2/conclusion";
import { colorTheme } from "../../../../constants/color_theme";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 30,
    paddingLeft: 70,
    paddingRight: 60,
    paddingBottom: 50,
  },
  section: {
    marginBottom: 8,
  },
  pageSection: {
    marginTop: 50,
    textAlign: "center",
  },
  headingPage: {
    color: colorTheme.PURPLE_DARK,
    fontFamily: "Helvetica-Bold",
    fontSize: 20,
    marginBottom: 8,
    textAlign: "center",
    marginTop: 30,
  },
  heading: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    marginBottom: 4,
  },
  disclaimer: {
    marginBottom: 4,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
  },
  disclaimerText: {
    fontStyle: "italic",
    marginBottom: 2,
    fontSize: 9, // Smaller font size
    color: "#606060", // A medium grey color
  },
  attribute: {
    flexDirection: "row",
    fontSize: 14,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  attributeName: {
    fontSize: 14,
  },
  descriptionsContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 4,
  },
  attributeTable: {
    marginBottom: 8,
    marginTop: 10,
  },
  attributeRow: {
    borderBottom: "1px solid #555",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    paddingBottom: 5,
  },
  attributeHeader: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
  },
  attributeHeaderValue: {
    color: colorTheme.PURPLE_DARK,
    flex: 1,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  attributeDescription: {
    flex: 1,
    fontSize: 11,
  },

  credit: {
    bottom: 30,
    color: colorTheme.GRAY_DOFF,
    fontSize: 12,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },

  barContainer: {
    height: 15,
    backgroundColor: "#555",
    width: "70%", // Adjusted width to 90% for a better fit
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
  },
  bar: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 5,
  },
  barText: {
    position: "absolute",
    right: 10,
    top: 2,
    color: "white",
    fontSize: 11,
  },
  table: {
    display: "flex",
    width: "auto",
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCellHeader: {
    backgroundColor: colorTheme.PURPLE_DARK,
    color: "white",
    padding: 5,
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
    flex: 1,
    textAlign: "center",
  },
  tableCell: {
    padding: 5,
    fontSize: 11,
    flex: 1,
    textAlign: "center",
  },
  tableCellAlt: {
    backgroundColor: "#f0f0f0",
  },
  conclusion: {
    padding: 4,
  },
  conclusionSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  conclusionLeft: {
    width: "80%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    marginRight: 4,
    padding: 4,
  },
  conclusionRight: {
    width: "20%",
    border: "1px solid lightblue",
    borderRadius: "5px", // Menambahkan border yang rounded
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center", // Menambahkan text align untuk mengambang teks di tengah
    padding: 4,
  },
  descriptionValue: {
    marginTop: 17,
    fontSize: 10,
    flex: 1,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  descriptionText: {
    fontSize: 11,
  },
});

// ---------------------------------------------- the component
const ResultPageKondisiKeluargaV2: FunctionComponent<
  IResultPageKondisiKeluargaV2Props
> = ({ questionnaire }) => {
  const { result } = questionnaire;

  const conclusion = JSON.parse(
    result.conclusion
  ) as IConclusion_KondisiKeluargaV2[];

  // ---------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgPage} style={styles.backgroundImage} fixed />
      <Text style={styles.pageSection} fixed></Text>
      <View style={{ marginBottom: 16 }}>
        <View>
          <Text style={styles.disclaimer}>Disclaimer</Text>
          <Text style={styles.disclaimerText}>
            Tes ini tidak memberikan penilaian tentang kondisi keluarga.
          </Text>
          <Text style={styles.disclaimerText}>
            Tes ini hanya memberikan gambaran umum berdasarkan penilaian anak
            tentang perasaannya.
          </Text>
          <Text style={styles.disclaimerText}>
            Tes ini tidak dipublikasikan.
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        {result.attributes.map((attribute) => (
          <View key={attribute.id} style={styles.attributeRow}>
            <Text style={styles.attributeDescription}>{attribute.name}</Text>
            <View style={styles.barContainer}>
              <View
                style={[
                  styles.bar,
                  {
                    width: `${attribute.value * 100}%`,
                    backgroundColor: attribute.color || "#4682b4",
                  },
                ]}
              />
              <Text style={styles.barText}>
                {(attribute.value * 100).toFixed(0)}%
              </Text>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.section}>
        <Text style={styles.heading}>Tabel Psikogram</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader} wrap={true}>
              Kondisi Lingkungan Keluarga
            </Text>
            <Text style={styles.tableCellHeader} wrap={true}>
              {"Mulai\n Berkembang"}
            </Text>
            <Text style={styles.tableCellHeader} wrap={true}>
              {"Sedang\n Berkembang"}
            </Text>
            <Text style={styles.tableCellHeader} wrap={true}>
              {"Berkembang\n Sesuai Harapan"}
            </Text>
            <Text style={styles.tableCellHeader} wrap={true}>
              {"Sangat\n Berkembang"}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Cara Orang Tua Mendidik</Text>
            <Text style={styles.tableCell}>{"< 53%"}</Text>
            <Text style={styles.tableCell}>53% - 73%</Text>
            <Text style={styles.tableCell}>74% - 94%</Text>
            <Text style={styles.tableCell}>{"> 94%"}</Text>
          </View>
          <View style={[styles.tableRow, styles.tableCellAlt]}>
            <Text style={styles.tableCell}>
              Relasi Anak dan Anggota Keluarga
            </Text>
            <Text style={styles.tableCell}>{"< 53%"}</Text>
            <Text style={styles.tableCell}>53% - 73%</Text>
            <Text style={styles.tableCell}>74% - 94%</Text>
            <Text style={styles.tableCell}>{"> 94%"}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Suasana Rumah</Text>
            <Text style={styles.tableCell}>{"< 36%"}</Text>
            <Text style={styles.tableCell}>36% - 59%</Text>
            <Text style={styles.tableCell}>60% - 83%</Text>
            <Text style={styles.tableCell}>{"> 83%"}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        {conclusion.map((c, index) => (
          <View key={index} style={styles.conclusion}>
            <View style={styles.conclusionSection}>
              <View style={styles.conclusionLeft}>
                <Text style={styles.heading}>{c.name}</Text>
                <Text style={styles.descriptionText}>{c.description}</Text>
              </View>
              <View style={styles.conclusionRight}>
                <Text style={styles.descriptionValue}>
                  {c.newLinedDevLevel}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.section} wrap={false}>
        <Text style={styles.heading}>
          Saran untuk Pengembangan Orang Tua dan Anak
        </Text>
        <Text style={styles.descriptionText}>
          Ketika usia anak mulai bertambah, anak-anak akan lebih mudah dalam
          mengekspresikan emosinya. Mereka mulai memiliki kesenangan dan
          kesibukkannya sendiri. Terkadang anak-anak memilih untuk menghabiskan
          waktu bersama teman dibandingkan keluarga.
        </Text>
        <Text style={styles.descriptionText}>
          Selain itu, orang tua akan disibukkan dengan rutinitas pekerjaan.
          Sehingga perlu adanya upaya untuk menjaga hubungan yang baik antara
          anak dan orang tua dalam keluarga. Diantaranya dengan memberikan
          kesempatan bagi anak untuk mengekspresikan perasaannya baik positif
          ataupun negatif.
        </Text>
        <Text style={styles.descriptionText}>
          Orang tua diharapkan tidak menghakimi yang dilakukan oleh anak jika
          tidak sesuai dengan aturan yang diterapkan dalam keluarga karena akan
          menimbulkan upaya memberontak dari anak. Solusi agar menghindari
          menghakimi anak dengan memberikan ruang diskusi bersama, libatkan anak
          dalam setiap kegiatan keluarga, supaya anak dapat merasakan peran dan
          memiliki tanggung jawab dalam keluarga.
        </Text>
      </View>
    </Page>
  );
};

export default ResultPageKondisiKeluargaV2;
