// ---------------------------------------------- modules import
import {
  faAdd,
  faArrowLeft,
  faEdit,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";

import ModuleList from "./module_list";
import SubsetTokenList from "./subset_token_list";
import LinkButton from "../../../components/ui/buttons/link_button";
import DeleteModal from "../../../components/ui/modals/delete_modal";

import * as ROUTES from "../../../constants/routes";

import { IDetailsProps } from "./common";
import { useDetails } from "./hooks/use_details";

// ---------------------------------------------- the component
const Details: FunctionComponent<IDetailsProps> = ({ isAuthorizedToWrite }) => {
  // ---------------------------------------------- local state
  const { fetching, loading, participantGroup, onDelete } = useDetails();
  const { id, description, location, name } = participantGroup;
  const { province, regency } = location;

  // ---------------------------------------------- content
  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden mb-8">
          <div className="p-6">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
              <div>
                <h1 className="text-3xl font-bold text-white mb-2">
                  {name.toUpperCase()}
                </h1>
                <p className="text-sm text-gray-400">ID: {id}</p>
              </div>
              <div className="flex items-center mt-4 md:mt-0">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="text-blue-400 mr-2"
                />
                <span className="text-gray-300">
                  {`${regency.name}, ${province.name}`}
                </span>
              </div>
            </div>
            <p className="text-yellow-400 mb-6">
              {description || "No description available"}
            </p>
            {isAuthorizedToWrite && (
              <div className="flex justify-end space-x-3">
                <LinkButton
                  className="bg-green-500 hover:bg-green-600 text-white p-2 rounded-md transition duration-300 ease-in-out"
                  to={`${ROUTES.PARTICIPANT_GROUP}/add-module`}
                  valid={!fetching && !loading}
                >
                  <FontAwesomeIcon icon={faAdd} />
                </LinkButton>
                <LinkButton
                  className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md transition duration-300 ease-in-out"
                  to={`${ROUTES.PARTICIPANT_GROUP}/update-participant-group/${id}`}
                  valid={!fetching && !loading}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </LinkButton>
                <DeleteModal
                  className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-md transition duration-300 ease-in-out"
                  id="participant-group-delete-modal"
                  version="v2"
                  onDelete={onDelete}
                  valid={!fetching && !loading}
                />
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ModuleList isAuthorizedToWrite={isAuthorizedToWrite} />
          <SubsetTokenList isAuthorizedToWrite={isAuthorizedToWrite} />
        </div>
      </div>

      <div className="fixed bottom-8 right-8 z-50">
        <LinkButton
          className="flex items-center justify-center w-12 h-12 text-white bg-blue-500 rounded-full hover:bg-blue-600 shadow-lg transition duration-300 ease-in-out"
          to={ROUTES.PARTICIPANT_GROUP}
          valid
        >
          <FontAwesomeIcon icon={faArrowLeft} className="text-lg" />
        </LinkButton>
      </div>
    </div>
  );
};

export default Details;
