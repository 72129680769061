// ---------------------------------------------- modules import
import dayjs from "dayjs";
import { FunctionComponent } from "react";

import { IParticipantGroupRowProps } from "./common";
import { useParticipantGroupRow } from "./hooks/use_participant_group_row";

// ---------------------------------------------- the component
const ParticipantGroupRow: FunctionComponent<IParticipantGroupRowProps> = ({
  participantGroup,
  onChangeParticipantGroup,
}) => {
  const { lastModified, location, name } = participantGroup;

  // ---------------------------------------------- local state
  const { onClick } = useParticipantGroupRow(
    participantGroup,
    onChangeParticipantGroup
  );

  // ---------------------------------------------- content
  return (
    <tr
      onClick={onClick}
      style={{ cursor: "pointer" }}
      className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600 "
    >
      <td className="px-6 py-4">{name}</td>

      <td className="px-6 py-4 hidden md:table-cell">
        {location.regency.name}
      </td>

      <td className="px-6 py-4 hidden md:table-cell">
        {location.province.name}
      </td>

      <td className="px-6 py-4">
        {dayjs(lastModified).locale("id").format("DD/MM/YYYY, HH:mm:ss")}
      </td>
    </tr>
  );
};

export default ParticipantGroupRow;
