// ---------------------------------------------- modules import
import { FormEvent } from "react";

import { IOption } from "../../../ui/inputs/common";

import { IParticipantGroup } from "../../../../models/participant_group";

// ---------------------------------------------- the hooks
export const useSearchForm = (participantGroups: IParticipantGroup[]) => {
  // ---------------------------------------------- consume context
  const groupOptions: IOption[] = [
    { label: "All", value: "all" },
    ...[...participantGroups]
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((group) => ({
        label: group.name,
        value: group.id,
      })),
  ];
  const findByOptions: IOption[] = [
    { label: "Name", value: "profile" },
    { label: "Group", value: "participantGroup" },
    { label: "Token", value: "tokenId" },
    { label: "Status", value: "status" },
  ];

  const settlementStatusOptions: IOption[] = [
    { label: "All", value: "all" },
    { label: "Settled", value: "settled" },
    { label: "In Review", value: "review" },
    { label: "Pending", value: "pending" },
    { label: "Unsettled", value: "unsettled" },
  ];

  // ---------------------------------------------- handlers
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();

  // ---------------------------------------------- return value
  return {
    groupOptions,
    findByOptions,
    settlementStatusOptions,
    onSubmit: handleSubmit,
  };
};
