// ---------------------------------------------- modules import
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ModuleContext } from "../../../../contexts/module/context";
import { ParticipantGroupContext } from "../../../../contexts/participant_group/context";
import { SessionContext } from "../../../../contexts/session/context";

import * as API_ROUTES from "../../../../constants/api_routes";
import { proxy } from "../../../../constants/proxy";
import * as ROUTES from "../../../../constants/routes";
import {
  emptyParticipantGroup,
  IParticipantGroup,
} from "../../../../models/participant_group";

// ---------------------------------------------- the hooks
export const useDetails = () => {
  // ---------------------------------------------- router state
  const { participant_group_id } = useParams();
  let navigate = useNavigate();

  // ---------------------------------------------- consume context
  const {
    error: errParticipantGroup,
    fetching: fetchingParticipantGroup,
    participantGroups,
  } = useContext(ParticipantGroupContext);
  const {
    error: errModule,
    fetching: fetchingModule,
    modules,
  } = useContext(ModuleContext);
  const {
    authUser,
    error: errSession,
    fetching: fetchingSession,
  } = useContext(SessionContext);

  // ---------------------------------------------- local state
  const [participantGroup, setParticipantGroup] = useState<IParticipantGroup>(
    emptyParticipantGroup()
  );

  const [errRequest, setErrRequest] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // ---------------------------------------------- handlers
  const handleDelete = () => {
    const url = `${proxy}${API_ROUTES.PARTICIPANT_GROUP}`;

    if (authUser) {
      authUser.getIdToken().then((token) => {
        setLoading(true);

        axios
          .delete(`${url}/${participantGroup.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            setLoading(false);
            setErrRequest(null);

            navigate(ROUTES.PARTICIPANT_GROUP, { replace: true });
          })
          .catch((error) => {
            const message = error.response
              ? error.response.data.message
              : error.request
              ? "request was made but no response was received."
              : "bad request setup.";

            setLoading(false);
            setErrRequest(message);
          });
      });
    } else {
      setErrRequest("your session has expired, please sign in.");
    }
  };

  // ---------------------------------------------- effects
  useEffect(() => {
    if (participantGroups) {
      const currentParticipantGroup = participantGroups.find(
        (participantGroup) => participantGroup.id === participant_group_id
      );

      if (currentParticipantGroup) setParticipantGroup(currentParticipantGroup);
    }
  }, [participantGroups, participant_group_id]);

  useEffect(
    () =>
      setError(errParticipantGroup || errModule || errRequest || errSession),
    [errParticipantGroup, errModule, errRequest, errSession]
  );

  // ---------------------------------------------- return value
  return {
    error,
    fetching: fetchingParticipantGroup || fetchingModule || fetchingSession,
    loading,
    participantGroup,
    modules,
    onDelete: handleDelete,
  };
};
